import React, { memo } from 'react'
import PropTypes from 'prop-types'

function Veg_nonvegIcons(props) {
  return (
    <div>
      <div style={{
        display: 'flex', alignItems: 'center', justifyContent: 'center',
        marginTop: 2, border: `1px solid ${props.color}`,
        width: props.width, height: props.height,
        borderRadius: props.height / 6
      }}>
        <div style={{
          width: props.width / 2,
          height: props.height / 2, borderRadius: 5, backgroundColor: props.color
        }} />
      </div >
    </div>
  )
}

Veg_nonvegIcons.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string
}
export default memo(Veg_nonvegIcons) 

