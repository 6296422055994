import React, { useState, useEffect } from "react";
import './custom.css';
import { useSelector } from 'react-redux'
import { connect } from 'react-redux';
import { setNearestMerchantDetails } from '../../../actions';
import _ from 'lodash';
import { uniq } from 'lodash';
import { map as _map } from 'lodash';
import phoneimg from '../../../images/Group 30030.svg';
import MapIcon from '../../../images/icons/map-fill.svg';
import OfficeImg from '../../../images/Group 30275.png';
import { ClockFill } from '../../../images/icons/IconSets';
import TimingsModal from './TimingsModal'
function Index(props) {
   const { homepageDetails } = props;
   const [userCoords, setuserCoords] = useState()
   const [timingsOpen, settimingsOpen] = useState(false)
   const [selectedMerchantTimings, setselectedMerchantTimings] = useState()
   const reduxUser = useSelector(state => state.user)

   const countrycode = '';

   const filtered = homepageDetails.availableModesDetails && homepageDetails.availableModesDetails.data.filter(e => e.countryCode === countrycode).map(e => {
      const { city, name } = e;
      return { city, name }
   });


   const countries = homepageDetails.availableModesDetails && homepageDetails.availableModesDetails.data.map((v, i) => {
      return v.countryCode

   });

   const uniqcountries = [...new Set(countries)];

   const cities = homepageDetails.availableModesDetails && homepageDetails.availableModesDetails.data.map((v, i) => {
      return v.city + ',' + v.countryCode

   });

   const uniqcities = [...new Set(cities)];

   const [current, setcurrent] = useState('IN');

   const cityfilter = homepageDetails.availableModesDetails && homepageDetails.availableModesDetails.data
      .map(value => { return value.city })

    const citytwo = [...new Set(cityfilter)];

   useEffect(() => {
      setuserCoords(!!reduxUser.userLocation?.latitude ?
         {
            lat: reduxUser.userLocation?.latitude,
            lng: reduxUser.userLocation?.longitude
         } :
         {
            lat: reduxUser.userDetails?.locationPreference.latitude,
            lng: reduxUser.userDetails?.locationPreference.longitude,
         })
   }, [reduxUser.userLocation, reduxUser.userDetails])

   const handleDirection = (param) => {
      window.open(`https://www.google.com/maps?saddr=${userCoords.lat + ',' + userCoords.lng}&daddr=${param?.latitude + ',' + param?.longitude}`)
   }
   const handletimingsOpen = (param) => {
      setselectedMerchantTimings(param)
      settimingsOpen(true)

   }
   const handleCloseTimings = (param) => {
      settimingsOpen(param)
   }
   return (
      <div  >
         {timingsOpen === true ?
            <TimingsModal
               show={timingsOpen}
               setShow={handleCloseTimings}
               parentData={selectedMerchantTimings}

            /> : null}
         <section class="store-locator-locations">
            <h2 className="storedetailsheadermobile" >Our Store Contact Details</h2>
            {/* <div class="container-fluid">
                        <div class="row align-items-center-fr">
                            <div>
                                <div class="store-locator-locations-left">
                                    <h2 style={{ width: 300,marginLeft:'auto', marginRight:'auto' ,textAlign:'center'}} selectedMerchant={selectedMerchant}>Biryanis and more, {current}</h2>
                                </div>
                            </div>
                            <div className="dropdown-us">
                                <div class="store-locator-locations-right">
                                    <select class="form-control ml-auto" onClick={(e) => setcurrent(e.target.value)}>
                                        {uniqcountries.map((value, i) =>
                                            <option key={i} className="optiontext" value={value}>{value}</option>
                                        )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div> */}
            <section class="store-locator-locations-address">
               <div class="container-fluid">
                  <div class="row store-contact-list">
                     {
                        uniqcities?.map((value, i) =>
                           <div style={{
                              boxShadow: '0px 0px 20px #0000000d',
                              padding: '20px', marginBottom: '20px',
                              marginTop: '20px'
                           }} key={i}>
                              <div class="store-locator-locations-address-text">
                                 <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', color: '#553A28' }}>
                                    <text className="storecitytextmobile"  style={{ fontWeight: 'bold', fontSize: 21 }} >{value}</text>
                                 </div>
                                 <div className="store-list-names">
                                    {
                                       homepageDetails.availableModesDetails && homepageDetails.availableModesDetails.data.filter((e => e.city === citytwo[i])).map((value, i) =>
                                          <div style={{
                                             display: 'flex', flexDirection: 'column',
                                             marginBottom: 25
                                          }} key={i}>
                                             <div>
                                                <text className="storenamemobile" style={{ fontWeight: 'bold', fontSize: 17 }} >{value.name}</text>
                                                <img style={{ cursor: 'pointer' }}
                                                   src={OfficeImg} alt=""
                                                   target="_blank"
                                                   onClick={() => handleDirection(value)}
                                                />
                                             </div>
                                               <a style={{ marginBottom: 10, width: 300 }} >
                                                   {value.address1}
                                               </a>
                                             <a style={{ marginBottom: '10px' }} href={`tel:${value.phoneNumber}`}>
                                                <i class="fa fa-phone" aria-hidden="true">
                                                </i>
                                                {value.phoneNumber}
                                             </a>
                                             <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <ClockFill width={15} height={15}
                                                   color="#553A28" />
                                                <text style={{
                                                   fontSize: 15, cursor: 'pointer',
                                                   color: '#553A28', marginLeft: 15
                                                }}
                                                   onClick={() => handletimingsOpen(value)}
                                                >
                                                   {value.timings[0].timing}</text>
                                             </div>
                                          </div>
                                       )
                                    }
                                 </div>
                              </div>
                           </div>
                        )
                     }
                  </div>
               </div>
            </section>
         </section>
         {/* <!-- store-locator-locations section code End --> */}



         {/* <!-- store-locator-locations section code Start --> */}
      </div>
   );
}
const mapStateToProps = state => ({
   user: state.user,
   homepageDetails: state.homepage,
});

export default connect(mapStateToProps, { setNearestMerchantDetails })(Index);