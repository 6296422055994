import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import './userdetails.css'
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux';
import { setTemporaryInputFields } from '../../../actions/setState'
import { requestUserUpdatedProfile } from '../../../actions';
function UserDetails(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.user);

  const [address, setAddress] = useState({
    name: '',
    birthdate: '',
    gender: 'M',
    aniversarydate: '',
  });
  const [validated, setValidated] = useState(false);
  const userDetailsRedux = useSelector(state => state.user.userDetails)
  const { userDetails } = props;
  const [allDetails, setallDetails] = useState([])
  const [isEditDisabled, setisEditDisabled] = useState(true)
  const [showdetails, setshowdetails] = useState(false);
  const [filldetails, setfilldetails] = useState(true);



  useEffect(() => {
    setallDetails(
      {
        email: userDetailsRedux.profile.profile?.email,
        phoneNumber: userDetailsRedux.profile.profile.phoneNumber,
        name: userDetailsRedux.profile.profile.name,
        gender: userDetailsRedux.profile.profile.gender,
        birthdate: userDetailsRedux.profile.profile.birthDate,
        aniversarydate: userDetailsRedux.profile.profile.aniversaryDate
      })

  }, [])

  const handleEdit = () => {
    setisEditDisabled(!isEditDisabled)
    setshowdetails(true)
  }

  useEffect(() => {
    if (userDetailsRedux.profile.profile.name > 0 || userDetailsRedux.profile.profile.gender > 0 || userDetailsRedux.profile.profile.birthDate > 0) {
      setfilldetails(false);
    }
  }, [])

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const handleSave = (event) => {
    // const form = event.currentTarget;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    if (validationsTwo()) {
      setValidated(true);
      // dispatch(requestUserUpdatedProfile(address))
    }
  }

  const validationsTwo = (value,) => {
    if (allDetails.name?.length > 0 &&
      allDetails.birthdate?.length > 0 &&
      allDetails.gender?.length > 0 &&
      allDetails.aniversarydate?.length > 0
    ) {
      return true
    }
    else {
      return false
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setallDetails({
      ...allDetails,
      [name]: value
    })
  }

  return (
    <div className="whiteBox">
      <div className="myAccHeading">My Details</div>
      <div className="myAccSubHeading">Login information</div>
      <div className="row">
        <div className="col-md-4 pr-5">
          <p>This information will be used to login you to your Biryanis and More account to track purchases, save addresses and more!</p>
        </div>
        <div className="col-md-4 mmt">
          <label className="inlabel">Email ID</label>
          <div className="inputGroup">
            <input type="email" placeholder="Add your email" value={allDetails?.email} contentEditable={false} />
            {/* <button>Change</button> */}
          </div>
        </div>
        <div className="col-md-4 mmt">
          <label className="inlabel">Mobile Number</label>
          <div className="inputGroup">
            <input contentEditable={false} disabled={true} type="text" placeholder="Add Mobile Numbar"
              value={allDetails.phoneNumber} />
            <button><img src="assets/checked.svg" alt="checked" /></button>
          </div>
        </div>
      </div>

      {/* {validationsTwo()
        ?
        <div className="myAccSubHeading mt-4">Personal Information</div>
        :
        <div className="mt-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'baseline', borderBottom: '1px solid #F4F4F4', paddingBottom: 10 }}>
          <div>

            <text className='myAccSubHeading-bonus-alert'>Personal Information</text> */}
            {/* <p className='myAccSubHeading-bonus-alert' style={{fontWeight:'600', paddingTop:'10px'}} >*Fill your personal information and get 100 points.</p> */}
      {/* </div>
          {filldetails && (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>

              <p className='myAccSubHeading-bonus-alert' style={{ fontWeight: '600', paddingTop: '10px' }} >{reduxUser.userAddressChunks?.country_id === 'IN' ? "*Fill your personal information and get 100 points." : "*Fill your personal information"}</p>
              <button className="fillnow" style={{
                backgroundColor: '#553a28', color: '#fff', border: 0, height: 30, width: 80,
                padding: '1px 2px 1px 2px', borderRadius: '6px', letterSpacing: '1.2px', fontWeight: 'bold', marginLeft: '30px'
              }}
                onClick={handleEdit}
              >Fill Now</button>

            </div>
          )}
        </div>
      }
      {
        showdetails && (
          <div>
            <div className="row" style={{ paddingTop: 20 }}>
              <div className="col-md-4 pr-5">
                <p>We will use this information when we have to get in touch in you for updates, news and more!</p>
              </div>
              <Form className="modelForm-fr" noValidate validated={validated} onSubmit={handleSubmit}>

                <Form.Group md="4" className="nametext" controlId="validationCustom01">
                  <Form.Label>Name<span class="required-fr">*</span></Form.Label>
                  <Form.Control
                    name='name'
                    value={allDetails.name}
                    onChange={handleInputChange}
                    required="required-fr"
                    type="text"
                    className="name-fr"
                    placeholder="Enter your name"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please provide name.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group md="6" className="nametext" controlId="validationCustom02">
                  <Form.Label>Birthday<span class="required-fr">*</span></Form.Label>
                  <Form.Control
                    required={true}
                    name='birthdate'
                    value={allDetails.birthDate}
                    onChange={handleInputChange}
                    type="date"
                    className="name-fr"
                    placeholder="Enter your Birthday"
                  // required="required-fr"
                  />
                  <Form.Control.Feedback type="invalid">
                    Please select DOB.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="nametext" controlId="validationCustom03">
                  <Form.Label>Gender<span class="required-fr">*</span></Form.Label>
                  <Form.Control className="name-fr" name="gender" onChange={handleInputChange} as="select">
                    <option name="gender" tagname="M" value='M'>M</option>
                    <option name="gender" tagname="F" value='F'>F</option>
                  </Form.Control>
                </Form.Group>

                <Form.Group className="nametext" md="6" controlId="validationCustom04">
                  <Form.Label>Anniversary Date</Form.Label>
                  <Form.Control
                    name='aniversarydate'
                    value={address.anniversarydate}
                    onChange={handleInputChange}
                    type="date"
                    className="name-fr"
                    placeholder="Enter anniversary date"
                  /> */}
                  {/* <Form.Control.Feedback type="invalid">
                  Please mention anniversaryDate.
                  </Form.Control.Feedback> */}
      {/* </Form.Group>

                <button className="savebtn" type='submit' style={{
                  backgroundColor: '#553a28', color: '#fff', border: 0, height: 30, width: 80,
                  padding: '1px 2px 1px 2px', borderRadius: '6px', letterSpacing: '1.2px', fontWeight: 'bold', textAlign: 'center', float: 'right'
                }} onClick={handleSave} >Save</button>
              </Form> */}

              {/* <div className="col-md-4 mmt">
          <label className="inlabel">Name</label>
          <div className="inputGroup">
            <input type="text" placeholder="Your name"
              name='name'
              value={allDetails.name}
              onChange={handlechangenote}
              disabled={isEditDisabled}  required />
          </div>
        </div>

        <div className="col-md-4 mmt">
          <label className="inlabel">Date of Birth</label>
          <div className="inputGroup">
            <input type="date" placeholder="31/12/2000"
              name='birthDate'
              value={allDetails.birthDate}
              // onChange={handleInputChange}
              onChange={handlechangenote}
              disabled={isEditDisabled}  required />
          </div>
        </div>
      </div>
      <div className="row mt-3 footerlabel">
        <div className="col-md-4 offset-md-4 inlabel3">
          <label className="inlabel">Gender</label>
          <div className="inputGroup" >
            <select name="gender" onChange={handlechangenote}  required>
              <option value="1">Choose your gender</option>
              <option value="M">M</option>
              <option value="F">F</option>
            </select>
          </div>
        </div>
        <div className="col-md-4 mmt inlabel3">
          <label className="inlabel">Date of Aniversary</label>
          <div className="inputGroup">
            <input type="date" placeholder="31/12/2000"
              name='aniversaryDate'
              value={allDetails.aniversaryDate}
              onChange={handlechangenote}
              // onChange={handleInputChange}
              disabled={isEditDisabled} />
          </div>
        </div>
        */}
      {/* </div> */}
            {/* <button className="savebtn" type='submit' style={{
              backgroundColor: '#553a28', color: '#fff', border: 0, height: 30, width: 80,
              padding: '1px 2px 1px 2px', borderRadius: '6px', letterSpacing: '1.2px', fontWeight: 'bold', textAlign: 'center', float: 'right'
            }} onClick={handleSave}>Save</button> */}
      {/* </div>

        )
      } */}

    </div>
  );
}

export default UserDetails;