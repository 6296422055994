import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'react-phone-number-input/style.css'
import { Modal } from 'react-bootstrap';
import { setPhone, setLoginPassword, resetUserDetails } from '../../../actions';
import './loginModel.css';
import '../RegisterModal/Register.css';
import LoginPage from './Page1.Login'
import ValidateOtpModal from './Page2.validateOtp';
const LoginModal = (props) => {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [phone, setPhone] = useState();
  const [loginInfo, setloginInfo] = useState()
  const { show, setShow, user, } = props;

  const login = {
    display: 'table',
    position: 'absolute',
    top: '0',
    left: '0',
    width: '410px',
    height: '441px'
  }
  const setLoginParams = (param) => {
    setloginInfo(param)
    setPhone(param.mobileNumber)
  }
  const changeCurrentScreen = (param) => {
    setCurrentScreen(param)
  }
  const setShowModal = (param) => {
    setShow(param)
  }

  useEffect(() => {
    return () => {
      props.resetUserDetails()
      setCurrentScreen(0)
    }
  }, [])

  return (
    <div className="login" style={login}>

      <Modal show={show}
        onHide={() => {
          setShow(false);
        }}
        animation={false}>
        <Modal.Header closeButton />
        <Modal.Body>

          {currentScreen === 0 ?
            <LoginPage
              {...props}
              loginParams={setLoginParams}
              setCurrentScreen={changeCurrentScreen}
              setShowModal={setShowModal}
              referralCode={props.referralCode}
            />

            : currentScreen === 1 ?
              <ValidateOtpModal
                {...props}
                loginInfo={loginInfo}
                setCurrentScreen={changeCurrentScreen}
                setShowModal={setShowModal}
                referralCode={props.referralCode}
              />
              : null}

        </Modal.Body>
      </Modal>

    </div >
  )
};

const mapStateToProps = state => ({
  user: state.user,
  homepage: state,
  referralCode: state.user?.referralCode,
});

export default connect(mapStateToProps, { setPhone, setLoginPassword, resetUserDetails })(LoginModal);