import config from '../config';
import apiClient from '../api';
import { setCurrentAvailableModes } from '../actions';

const initialState = {
  orderMode: 'delivery'
};

const SERVICEABILITY = "SERVICEABILITY"
const ORDER_MODE = 'ORDER_MODE'
const INITIAL_ORDER_MODE = 'INITIAL_ORDER_MODE'

const CONTACT_US_FORM = 'CONTACT_US_FORM'
const FRANCHISE_ENQUIRY_FORM = 'FRANCHISE_ENQUIRY_FORM'

export const checkServiceability = (params) => async (dispatch, getState) => {
  try {

    const state = getState();
    const { initialOrderMode } = state.orders;
    const { merchant } = state.merchant;

    dispatch({
      type: SERVICEABILITY,
      payload: []
    });

    let reqParams = {
      merchantAccessToken: config.merchantAccessToken,
      selectedOrderMode: initialOrderMode,
      userLatitude: params.latitude,
      userLongitude: params.longitude,
      merchantId: params.type === 'all' ? null : merchant.id,
    }
    if (!!params.formatted_address) {
      reqParams.selectedLocation = {
        address2: params.formatted_address,
        locality: params.locality,
        city: params.administrative_area_level_2,
        state: params.administrative_area_level_1,
        country: params.country,
        pincode: params.postal_code,
      }
    }

    const response = await apiClient.post(apiClient.Urls.serviceability, reqParams);

    if (response.success) {

      /* *****  CODE TO SAVE AVAILABLE MODES OF SELECTED MERCHANT  ***** */

      let result = []
      let modes = { delivery: 'Delivery', takeAway: 'Takeaway', dineIn: 'Dine In', menu: 'Menu' }

      let selectedMerchant = response.merchants?.find(a => a.id === merchant?.id)
      /* Makes an array as per requirement */
      result = selectedMerchant?.modesServiceable?.map((item, i) => {
        let a = {
          id: merchant?.id,
          orderMode: item.type,
          label: modes[item.type],
          slug: selectedMerchant?.slug,
          deliveryEta: item.deliveryEta,
          serviceable: item.serviceable,
          unserviceableReason: item.unserviceableReason,
          unserviceableStatus: item.unserviceableStatus,
        }
        return (a)
      })

      /* Adds the result of response placed outside the array */
      result = result?.concat(
        {
          id: selectedMerchant?.id,
          orderMode: initialOrderMode,
          label: modes[initialOrderMode],
          slug: selectedMerchant?.slug,
          deliveryEta: selectedMerchant?.deliveryEta,
          serviceable: selectedMerchant?.serviceable,
          unserviceableReason: selectedMerchant?.unserviceableReason,
          unserviceableStatus: selectedMerchant?.unserviceableStatus,
        },
        {
          id: selectedMerchant?.id, serviceable: true, orderMode: "menu", label: 'Menu'
        }
      )
      console.log({ result })

    /* Serialize the array. and make serviceable as true on condition */

      const orderTypes = [
        { orderType: 'delivery' },
        { orderType: 'takeAway' },
        { orderType: 'dineIn' },
        { orderType: 'menu' }
      ]

      let last = orderTypes.map((item, i) => {
        let abc = result?.find(a => a.orderMode === item.orderType)
        if (abc?.orderMode !== 'delivery' && abc?.unserviceableStatus === 'STORE_CLOSED') {
          abc.serviceable = true
        }
        return (abc)
      })

      dispatch(setCurrentAvailableModes(last))

      /* *****    ***** */

      dispatch({
        type: SERVICEABILITY,
        payload: response
      });
    } else {
    }
  } catch (error) {
    console.log(error)
  }
};

export const contactUsFormSubmit = (params, callBack) => async (dispatch, getState) => {
  try {
    console.log({ params })
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.contactUsForm, {
      type: "contactUsForm",
      template: "biryanis-website",
      response: {
        name: params.name,
        email: params.email,
        phoneNumber: params.phoneNumber,
        message: params.message,
        storeselectedname: params.storeselectedname,
        merchantId: params.merchantId
      }
    }
    );

    if (response.success) {
      dispatch({
        type: CONTACT_US_FORM,
        payload: response
      });
      callBack(response)
      window.fbq('track', 'Contact');
    } else {
    }
  } catch (error) {

  }
};

export const franchiseEnquiryFormSubmit = (params) => async (dispatch) => {
  try {

    const response = await apiClient.post(apiClient.Urls.franchiseEnquiryFrom, {
      type: "franchiseEnquiryForm",
      template: "biryanis-website",
      response: {
        name: params.name,
        email: params.email,
        phoneNumber: params.phoneNumber,
        message: params.message,
        occupation: params.occupation,
        city: params.city,
        pastExperienceMessage: params.pastExperienceMessage,
        liquidasset: params.liquidasset
      }
    }
    )

    if (response.success) {
      dispatch({
        type: FRANCHISE_ENQUIRY_FORM,
        payload: response
      });
    } else {
    }
  } catch (error) {

  }
};



export const setOrderMode = (param) => async (dispatch, getState) => {

  try {
    const state = getState();
    dispatch({
      type: ORDER_MODE,
      orderMode: param
    });
  } catch (error) {

  }
};

export const setInitialOrderMode = (param) => async (dispatch, getState) => {

  try {
    const state = getState();
    dispatch({
      type: INITIAL_ORDER_MODE,
      orderMode: param
    });
  } catch (error) {

  }
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {

    case SERVICEABILITY: {
      return {
        ...state,
        serviceability: { ...action.payload }
      }
    }
    case ORDER_MODE: {
      return {
        ...state,
        orderMode: action.orderMode
      }
    }
    case INITIAL_ORDER_MODE: {
      return {
        ...state,
        initialOrderMode: action.orderMode
      }
    }
    case CONTACT_US_FORM: {
      return {
        ...state,
        contactUsForm: action.payload
      }
    }
    case FRANCHISE_ENQUIRY_FORM: {
      return {
        ...state,
        franchiseEnquiryForm: action.payload
      }
    }
    default: return state;
  }
};

export default orderReducer;
