import React from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CheckoutPage from "../Pages/Cart/checkout/CheckoutPage";
import { addCart, getCart } from "../reducers/cart";
import { getUser, getPoints, getCoupons, getFreebies, sendOtp, resendOtp, validateOtp, postApplyCoupon, postApplyPoints, postApplyFreebie } from "../reducers/checkout";
import { paymentMODES, validateVpa, savedCard, deleteCard, guestPlaceOrder, setAddress, getDeliveryCharges, setUserInfo, paymentCheckout } from "../reducers/PaymentTwo";
import { homeConfig } from '../reducers/home';

class Checkout extends React.Component {

  async componentDidMount() {
    const profile = this.props.user.userDetails?.profile
    await this.props.homeConfig();
    this.props.paymentMODES();
    this.props.savedCard({ phoneNumber: profile?.profile.phoneNumber, });
    this.props.paymentCheckout()
    this.props.getCart();
    this.props.getUser(
      this.props.merchantAccessToken,
      profile?.profile.phoneNumber,
      { authToken: localStorage.auth_token }
    )
    this.props.getDeliveryCharges();
    this.props.setUserInfo(
      {
        name: profile?.profile.name,
        phoneNumber: profile?.profile.phoneNumber,
        email: profile?.profile.email,
      }
    )
  }
  render() {
    return (
      <CheckoutPage
        points={this.props.points}
        freebies={this.props.freebies}
        coupons={this.props.coupons}
        amount={this.props.amount}
        cart={this.props.cart}
        cartId={this.props.cartId}
        addCart={this.props.addCart}
        bankList={this.props.netbanking}
        walletsList={this.props.wallets}
        cardsSaved={this.props.savedCards}
        guestPlaceOrder={this.props.guestPlaceOrder}
        getPoints={this.props.getPoints}
        getUser={this.props.getUser}
        getCoupons={this.props.getCoupons}
        getFreebies={this.props.getFreebies}
        sendOtp={this.props.sendOtp}
        resendOtp={this.props.resendOtp}
        postApplyCoupon={this.props.postApplyCoupon}
        postApplyPoints={this.props.postApplyPoints}
        postApplyFreebie={this.props.postApplyFreebie}
        validateOtp={this.props.validateOtp}
        resendResponse={this.props.resendResponse}
        validateResponse={this.props.validateResponse}
        setAddress={this.props.setAddress}
        addressList={this.props.addressList}
        orderMode={this.props.orderMode}
        userInfo={this.props.userInfo}
        config={this.props.merchantAccessToken}
        history={this.props.history}
        deliveryCharges={this.props.deliveryCharges}
        merchantAccessToken={this.props.merchantAccessToken}
        udid={this.props.udid}
        authToken={this.props.authToken}
        error={this.props.error}
        counponDiscount={this.props.counponDiscount}
        pointsDiscount={this.props.pointsDiscount}
        couponMessage={this.props.couponMessage}
        address={this.props.address}
        coords={this.props.coords}
        user={this.props.user}
      />
    );
  }
}

const mapState = (state, ownprops) => {
  return {
    coupons: state.checkout.coupons,
    freebies: state.checkout.freebies,
    points: state.checkout.points,
    amount: state.checkout.amount,
    cart: state.cart.items,
    cartId: state.cart.cartId,
    netbanking: state.payment.netbanking,
    wallets: state.payment.wallets,
    savedCards: state.payment.savedCards,
    addressList: state.payment.addressess,
    orderMode: state.menu.orderMode,
    resendResponse: state.checkout.resendResponse,
    validateResponse: state.checkout.validateResponse,
    userInfo: state.payment.userInfo,
    merchantAccessToken: state.home.config.merchantAccessToken,
    udid: state.checkout.udid,
    authToken: state.checkout.authToken,
    error: state.checkout.error,
    config: state.home.config,
    deliveryCharges: state.payment.deliveryCharges,
    pointsDiscount: state.checkout.pointsDiscount,
    counponDiscount: state.checkout.counponDiscount,
    couponMessage: state.checkout.couponMessage,
    address: state.home.address,
    coords: state.home.coords,
    user: state.user,
    homepage: state.homepage

  };
};

const mapDispatch = {
  addCart,
  paymentMODES,
  savedCard,
  paymentCheckout,
  validateVpa,
  deleteCard,
  guestPlaceOrder,
  sendOtp,
  resendOtp,
  validateOtp,
  postApplyCoupon,
  postApplyFreebie,
  postApplyPoints,
  setAddress,
  getPoints,
  getUser,
  getCoupons,
  getFreebies,
  homeConfig,
  getCart,
  getDeliveryCharges, setUserInfo
};

export default withRouter(connect(mapState, mapDispatch)(Checkout));


// import { loadStripe } from "@stripe/stripe-js";

// axios({
//                         url: this.placeOrderUrl,
//                         method: 'POST',
//                         data: request,
//                         headers: {
//                             'Content-Type': 'application/json'
//                         }
//                     }).then(async ({data: response}) => {
//                       this.saved = true;
//                       this.loading = false;
//                       //loader.hide();
//                       if (response.success) {
//                         if(response.goToPgPayment){
//                           this.pgDetails = response.pgDetails;
//                           if (response.pgDetails.gateway === 'stripe') {
//                             const {stripeApiKey, sessionId} = response.pgDetails;
//                             if (!stripePromises[stripeApiKey]) {
//                               stripePromises[stripeApiKey] = loadStripe(stripeApiKey);
//                             }
//                             const stripeLoader = stripePromises[stripeApiKey];
//                             try {
//                               const stripe = await stripeLoader;
//                               const result = await stripe.redirectToCheckout({
//                                 sessionId: sessionId,
//                               });
//                               if (result.error) {
//                                 alert(result.error.message);
//                               }
//                             } catch (error) {
//                             }

//                           } else {
//                             this.pgDetails = response.pgDetails;
//                             // this.hideCheckoutBtn = false;
//                             setTimeout(
//                                 () => this.$refs.form.submit(),
//                                 500
//                             );
//                           }
//                           // this.$refs.form.submit()

//                         });
