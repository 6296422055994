const config = {

  apiUrl: 'https://www.froogal.in/dedicated-api',      //  PROD
  // apiUrl: 'https://uat8.uat.froogal.ai/dedicated-api',    //  DEV

  merchantAccessToken: 'qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E',

  // googleMapApiKey: 'AIzaSyCXJHFeHIV9a4iX0i1Ek6OXe89khepBGjM', //google maps
  googleMapApiKey: 'AIzaSyAt6ZUt38nllarxV0jpSWDPYeU1HlQcD2I', //google maps

  appId: '567248643641114', //facebook login

  clientId: "624033529967-8ot3a7e83qep71hpok16ts7tkfspctrl.apps.googleusercontent.com" //google login -personal
};

export default config;

// For Biryanis, we need to update Google Maps Key: AIzaSyDhsSZcjup-oRd6bYV7OupZb9j5qh_Mwlg
