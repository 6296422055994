import React, { Component, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import { setTemporaryInputFields } from '../../../../../actions/setState'
import './Panel.css'
function AddInstructions(props) {
    const dispatch = useDispatch()
    const [note, setNote] = useState('');

    const handlechangenote = (e) => {
        setNote(e.target.value)
        dispatch(setTemporaryInputFields({ instructions: e.target.value }))
    }
    return (
        <div className="panel-card"  >
            <text style={{ color: '#553A28', fontWeight: 'bold', fontSize: 'large' }} >
                Any Special Instructions
                {/* <span class="required-fr">*</span> */}
                </text>
          <textarea style={{ marginTop: 10 }} className="form-control notebox" placeholder="If you have any special instruction, comments or suggestion, put it here" value={note} onChange={handlechangenote}></textarea>
       <p style={{ marginTop: 10 }} className="newnotetext" >A valid Govt Issued ID and/or Credit Card used to place the order and the order confirmation text send to you phone must be presented at the time of Pick-Up. Online Orders take 35-40 Mins Approximately.</p>
        </div>
    )
}

export default AddInstructions