import { 
  REQUEST_INIT_DEVICE,
  REQUEST_AVAILABLE_MODES,
  REQUEST_ALL_CATALOGUES,
  ACTIVATE_CONTENT_LOADER,
  SET_MERCHANT_DATA,
  SET_NEAREST_MERCHANT,
    SET_SELECTED_MERCHANT, SET_SELECTED_MERCHANT_SERVICEABILITY, SET_CURRENT_AVAILABLE_MODES
} from '../actions';


const initialState = {
  deviceDetails: null, 
  availableModesDetails: null,
  nearestMerchantDetails: null,
  allMerchantDetails: null,
  allCatalogues: null,
    currentAvailableModes: []
}; 

export default function homepageReducer (state = initialState, action) {
  switch (action.type) {
    case REQUEST_INIT_DEVICE:
      localStorage.setItem('udid', action.payload.udid);
      return { ...state, deviceDetails: { ...action.payload } };
    case REQUEST_ALL_CATALOGUES:
      return { ...state, allCatalogues: { ...action.payload } };
    case ACTIVATE_CONTENT_LOADER:
      return { ...state, contentLoader: { ...action.payload } };
    case REQUEST_AVAILABLE_MODES:
      return { ...state, availableModesDetails: { ...action.payload } };
    case SET_NEAREST_MERCHANT:
      return { ...state, nearestMerchantDetails: action.payload };
      case SET_SELECTED_MERCHANT:
        return { ...state, selectedMerchantDetails: action.payload };
        case SET_SELECTED_MERCHANT_SERVICEABILITY:
          return { ...state, selectedMerchantServiceability: action.payload };
      case SET_CURRENT_AVAILABLE_MODES:
          return { ...state, currentAvailableModes: action.payload };
    case SET_MERCHANT_DATA:
      return { ...state, allMerchantDetails: action.payload };
    default:
      return state;
  }
}
