import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import oflineicon from '../../../images/Group 35260.png';
import AddCartButton from '../addCartButton/AddCartButtton';
import './featuredItemcard.css';

function FeaturedItemCard(props) {
  const { image, name, description, rate, type } = props?.item || {}
  const currencySymbol = useSelector(state => state.cart.currencySymbol)
  const orderMode = useSelector(state => state.orders.orderMode)

  let charactersLimit = 60
  const [showAllDescription, setshowAllDescription] = useState(false)

  const handlePara = (param) => {
    if (param?.length > charactersLimit && showAllDescription === false) {
      return param?.slice(0, charactersLimit)
    }
    else {
      return param
    }
  }

  return (
    <div class="featured-carousel-wrap owl-carousel">
      <div class="featured-card"
        style={{ height: showAllDescription ? '200px' : '130px' }}>
        <div class="featured-card-img"
        >
          <img src={image} alt="offer-img" />
        </div>
        <div class="d-flex d-new">
          <div style={{ position: 'relative' }}>
            <h4 class="fc-item-name">
              {name}
            </h4>
            <img className="oflineicon" src={oflineicon} />
            <span className="onlineicon"></span>
            <p>
              {handlePara(description)}
              {description?.length > charactersLimit &&
                (showAllDescription === false ?
                  <text onClick={() => setshowAllDescription(!showAllDescription)} >
                    ...

                    <text style={{ fontWeight: 'bold', color: '#553a28', cursor: 'pointer' }}> more
                    </text>
                  </text>
                  :
                  <text
                    style={{ fontWeight: 'bold', color: '#227FBB', cursor: 'pointer' }}
                    onClick={() => setshowAllDescription(!showAllDescription)} >
                    {"\t"}less
                  </text>)
              }
            </p>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <h5 className="favprice">{currencySymbol}{rate}</h5>
              {/* {
                orderMode !== 'menu'
                  ?
                  <div>
                    <AddCartButton item={props.item} />
                  </div>
                  :
                  null
              } */}
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

FeaturedItemCard.propTypes = {}

export default memo(FeaturedItemCard) 