import React, { Fragment, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import LazyLoad from 'react-lazyload';
import { useDispatch } from 'react-redux';
import { setVeg } from '../../actions';
import { CheckCircle } from '../../images/icons/IconSets';
import openicon from '../../images/Open Icon.svg';
import dropdownicon from '../../images/Polygon 22.svg';
import AddCartButton from '../Common/addCartButton/AddCartButtton';
import { ExploreMenuCardLoader } from '../Common/contentLoaders/ContentLoader';
import LocationSwitchModal from '../Common/LocationSwitchModal/index';
import VegIcon from '../Common/vegnonvegIcons/Veg-nonvegIcons';
import './menu.css';

function SearchSection(props) {
  const { homepage, isVeg, allCatalogues } = props || {}

  const dispatch = useDispatch()

  const { nearestMerchantDetails, selectedMerchantServiceability, currentAvailableModes } = homepage || {}

  const [locationshow, setlocationshow] = useState(false);
  const [filterItems, setfilterItems] = useState([]);
  const [searchTerm, setsearchTerm] = useState('');
  const [allItems, setallItems] = useState([])
  const [allVegItems, setallVegItems] = useState([])
  const [loaded, setloaded] = useState(false)

  const handleVegToogle = () => {
    dispatch(setVeg(!isVeg))
  }

  const onSearch = (e) => {
    let item = e.target.value
    e.preventDefault();
    e.stopPropagation();
    setsearchTerm(item)
    var code = (item.keyCode ? item.keyCode : item.which);
    if (code == 13) { //Enter keycode
      filterResult()
    }
  };

  useEffect(() => {
    filterResult()
    if (searchTerm.length === 1) {
      window.fbq('track', 'Search');
    }
  }, [searchTerm])

  useEffect(() => {
    let cat = allCatalogues?.categories
    let fullList = []

    for (let i = 0; i < cat?.length; i++) {
      for (let j = 0; j < cat[i].items.length; j++) {
        fullList.push(cat[i].items[j])
      }
      for (let k = 0; k < cat[i].categories.length; k++) {
        for (let l = 0; l < cat[i].categories[k].items.length; l++) {
          fullList.push(cat[i].categories[k].items[l])
        }
      }
    }

    let allveg = fullList.filter(param => param.type === 'veg')
    setallItems(fullList)
    setallVegItems(allveg)
  }, [allCatalogues?.categories])


  const filterResult = () => {
    if (searchTerm.length > 0) {
      const updatelist = (isVeg ? allVegItems : allItems)
        .filter((i) => i.name.toLowerCase().match(searchTerm.toLowerCase()));
      setfilterItems(updatelist);
    }
  }


  const onLoad = () => {
    setloaded(true)
  };


  return (
    <Fragment>
      <LocationSwitchModal show={locationshow} setShow={(a) => setlocationshow(a)} navigateModal={1} />
      <div className="select-store-fr" >
        <span style={{ cursor: 'pointer' }}
          className="nav-fr" onClick={() => setlocationshow(true)}>
          Selected Store
          <a className="locationicon">
            <img src={dropdownicon} style={{ marginLeft: '5px' }} />
          </a>
        </span>
        <p className="navtext-fr">{nearestMerchantDetails?.name}, Eta • {selectedMerchantServiceability?.deliveryEta}</p>
      </div>

      <div className="select-search-veg"
        style={{
          marginLeft: '20px', marginRight: '20px', display: 'flex', flexDirection: 'row',
          alignItems: 'center', justifyContent: 'space-between'
        }}>

        <span className="browsetext" style={{ textAlign: 'start' }}>Selected Store • <span className="smallbrowsetext">{nearestMerchantDetails?.name}</span>
          <span className="browsechange"
            style={{ cursor: 'pointer' }}
            onClick={() => setlocationshow(true)}>
            Change <img src={openicon} style={{ paddingBottom: "2px" }} />
          </span>
        </span>

        <Dropdown style={{ marginRight: 40, width: '100%' }} >
          <Dropdown.Toggle
            style={{ backgroundColor: '#F0F1F2', border: 'none' }}
            id="dropdown-basic">
            <input type="text" value={searchTerm}
              className="inputsearch"
              placeholder="Search"
              onFocus={() => filterResult()}
              onChange={onSearch}
            />
          </Dropdown.Toggle>

          {searchTerm.length > 0 ?
            <Dropdown.Menu
              className='dropdown-search-menu'
            >
              {searchTerm.length ?
                <div style={{ padding: 10, paddingLeft: 15, height: 30 }}>
                  <text >
                    {filterItems.length} search results for "{searchTerm}"
                  </text>
                </div>
                : null}
              <div className="search-root">
                {filterItems && filterItems.map((item, i) => (
                  <div className="col-sm-12" key={i}>
                    <div className="exploreItem-fr" >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {
                          item.image !== null ?
                            <LazyLoad height={62} >
                              <img style={{ borderRadius: 6, marginRight: 15, height: 62 }}
                                className={
                                  `${!loaded ? "img-loading" : "img-loaded"}`
                                }
                                src={item.image}
                                alt={ExploreMenuCardLoader}
                                onLoad={onLoad} />
                            </LazyLoad>
                            : null
                        }
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', position: 'relative' }} >
                          <div style={{ position: 'absolute', }}>
                            <VegIcon width={12} height={12} color={item.type === 'nonveg' ? '#C60000' : 'green'} />
                          </div>
                          {item.name && <p className="name ml-3">{item.name}</p>}
                          {item.description && <p style={{ textAlign: 'initial', fontSize: 12 }}>{item.description}</p>}
                          <h4 className="price"> <sup className="currency">
                            {allCatalogues.currency}</sup>{item.rate}</h4>
                        </div>
                      </div>

                      <div style={{ position: 'absolute', right: 10, top: 10 }}>
                        {item.enabled
                          ?
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <AddCartButton item={item} />
                            {item.addons.length > 0 ?
                              <text className="descriptoin">Customisable</text>
                              : null}
                          </div>
                          :
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 0 }}>
                            <div style={{
                              width: 96,
                              height: 30,
                              margin: 0,
                              // fontWeight: 600,
                              // backgroundColor: '#E5AB4E',
                              borderRadius: 6,
                              borderColor: 'Grey',
                              textAlign: 'center',
                              fontSize: 'medium',
                              color: '#788495',
                              border: '1px solid #00000021',
                              padding: 2,
                            }}><text>Unavailable</text></div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Dropdown.Menu>
            : null}
        </Dropdown>

        <div className='veg-button'
          onClick={handleVegToogle} >
          <text
            style={{
              font: ' normal normal 600 12px/16px Lato',
              letterSpacing: 2.1,
              color: '#2C2C2C'
            }}
          >VEG ONLY&nbsp;</text>

          <CheckCircle width={18} height={18} color={isVeg === true ? "#28a745" : "#B2BAC3"} />

        </div>
      </div>
    </Fragment>
  )
}

SearchSection.propTypes = {}

export default SearchSection