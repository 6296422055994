import React from 'react';
import PropTypes from 'prop-types';
import ErrorImg from '../images/error_cross.svg'
class ErrorBoundry extends React.Component {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    info: { componentStack: '' }
  };

  static getDerivedStateFromError = error => {
    return { hasError: true };
  };

  componentDidCatch = (error, info) => {
    this.setState({ error, info });
  };


  render() {
    if (this.state.hasError) {
      return <div style={{ display: 'flex', flexDirection: 'column', top: 50, height: window.innerHeight, alignItems: 'center', justifyContent: 'center' }} >

        <img style={{ margin: 20 }} src={ErrorImg} alt="" />
        <text>Oops, something went wrong.</text>
        <text>Please refresh the page to try again.</text>
        <text>OR</text>
        <a href='/'> Go to Homepage</a>
        <details style={{ whiteSpace: 'pre-wrap' }}>
          {this.state.error && this.state.error.toString()}
          <br />
          {this.state.info.componentStack}
        </details>
      </div>;
    }
    return this.props.children;
  }

}

export default ErrorBoundry