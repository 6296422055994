import { fromJS } from 'immutable'
import { compose, createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { persistStore } from 'redux-persist';
import rootReducer from './reducers'

const configureStore = () => {
  const composeEnhancers = process.env.NODE_ENV !== 'production'
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: true,
       trace: true
    })
    : compose

  const middlewares = [
    thunk,
  ]

  const enhancers = [
    applyMiddleware(...middlewares),
  ]

  const store = createStore(
    rootReducer,
    composeEnhancers(...enhancers),
  )

  store.asyncReducers = {};

  const persistor = persistStore(store);

  if (module.hot) {
    // Enable webpack hot module replacement for reducers
    module.hot.accept(
      './reducers',
      () => {
        import('./reducers').then(reducerModule => {
          const createReducers = reducerModule.default
          const nextReducers = createReducers(store.asyncReducers)
          store.replaceReducer(nextReducers)
        })
      },
    )
  }

  return { store, persistor };
}

export default configureStore
