import React, { useState, useEffect } from 'react'
import './Settings.css';
import './address.css';
import './SettingsManageAdress.css';
import { Link } from 'react-router-dom';
import AddressModal from '../../Common/AddressModal';
export default function SettingsManageAddress(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [addressShow, addressSetShow] = useState(false);
    return (
        <div className="settingmanageaddress">
            <AddressModal show={addressShow} setShow={addressSetShow}  {...props} />
            <div className="settingmanagebody">
                <div className='settingheader'>
                    <h3 className="settinghead"><Link to='/Settings'><i class="fa fa-arrow-circle-left" aria-hidden="true"></i></Link>Manage Addresses</h3>
                </div>
                <div className="settingaddress">
                    <div className="delivery-address">
                        <div className="home-img">
                            <img src="assets/home.svg" alt="" />
                        </div>
                        <div className="delivery-text">
                            <h4>Home <p className="manageaddressp"><i class="fa fa-pencil" aria-hidden="true"></i>EDIT</p></h4>
                            <p>Evenue Banjara Homes, Road Number 14, Phase 1, GS Nagar, Nandi Nagar, Banjara Hills</p>
                        </div>
                    </div>
                    <div className="delivery-address">
                        <div className="home-img">
                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                        </div>
                        <div className="delivery-text">
                            <h4>Friends House <p className="manageaddressp"><i class="fa fa-pencil" aria-hidden="true"></i>EDIT</p></h4>
                            <p>Evenue Banjara Homes, Road Number 14, Phase 1, GS Nagar, Nandi Nagar, Banjara Hills</p>
                        </div>
                    </div>
                    <div className="addressbtn">
                        <a href="#" onClick={() => addressSetShow(true)}>
                            <div className="add-address">
                                <button className="addaddressbtn">ADD NEW ADDRESS</button>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
