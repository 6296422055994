import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput, { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input'
import { setTemporaryInputFields } from '../../../../../actions/setState';
import { CheckCircle } from '../../../../../images/icons/IconSets';
import styles from './guestDetails.module.css';
import { saveInfo } from "../../../../../reducers/checkout";
import Snackbar from "../../../../Common/alertSnackbar/snackbar";
import { getCart } from "../../../../../reducers/cart";
function GuestuserDetails(props) {
  const snackbarRef = useRef();
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const [showUserDetails, setShowUserDetails] = useState(false);


  const { fullname, email, phoneNumber, } = user.temporaryInputFields

  const countryCode = phoneNumber && formatPhoneNumberIntl(phoneNumber).split(' ')[0];
  const formattedPhone = phoneNumber && formatPhoneNumber(phoneNumber).slice(1).split(' ').join('');

  const handleshowlogin = (param) => {
    dispatch(setTemporaryInputFields({ guest: param }))
    // setloginShow(true)
  }

  const handleProceed = (param) => {
    if (!!fullname?.length === false) {
      snackbarRef.current.openSnackBar(
        {
          type: 'error',
          title: 'Please fill the required fields.',
          description: 'Please enter your full name.'
        }
      );
    }
    else if (!!email?.length === false) {
      snackbarRef.current.openSnackBar(
        {
          type: 'error',
          title: 'Please fill the required fields.',
          description: 'Please enter your email.'
        }
      );
    }
    else if (!!phoneNumber?.length === false) {
      snackbarRef.current.openSnackBar(
        {
          type: 'error',
          title: 'Please fill the required fields.',
          description: 'Please enter your mobile number.'
        }
      );
    }
    else {
      setShowUserDetails(true)
      dispatch(saveInfo({}, result => {
        if (result.success) {
          dispatch(getCart())
          // dispatch(setTemporaryInputFields({ tip: inputamount, custom: true, tipType: 'fixed' }))
          setisAddClicked(true)
        }
      }))
    }

  }

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    dispatch(setTemporaryInputFields({ [name]: value }))
  };



  return (
    <div  >
      <Snackbar ref={snackbarRef} />
      {showUserDetails === false ?
        <div style={{ display: 'flex', flexDirection: 'column', width: 300, }}>
          <text style={{ color: '#553A28', fontWeight: 'bold', fontSize: 'large' }} >
            Personal Details</text>

          <p className="accounttext-fr">Enter your phone number to continue.</p>

          <div className="ptext" style={{ width: '100%' }}>
            <input
              name='fullname'
              value={fullname}
              onChange={handleInputChange}
              className="phnnumber" placeholder="Enter your full name" type="text" />
          </div>

          <div className="ptext" style={{ width: '100%' }}>
            <input
              name='email'
              value={email}
              onChange={handleInputChange}
              className="phnnumber" placeholder="Enter your email" type="text" />
          </div>

          {/* <div className="ptext" style={{ width: '100%' }}>
            <input
              name='phoneNumber'
              value={phoneNumber}
              onChange={handleInputChange}
              className="phnnumber" placeholder="Enter your phone number" type="text" />
          </div> */}

          <div className="ptext" style={{ width: '100%' }}>

            <PhoneInput
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(param) => dispatch(setTemporaryInputFields({ phoneNumber: param }))}
              limitMaxLength={true}
              defaultCountry={'IN'}
              className='countryFlag'
            // className={styles.countryFlag}

            />
          </div>


          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <button className="accountregister" style={{ marginTop: 10, backgroundColor: 'grey' }}
              onClick={() => handleshowlogin(false)}>
              Cancel
            </button>

            <button className="accountregister" style={{ marginTop: 10 }}
              onClick={() => handleProceed()}>
              Proceed
            </button>
          </div>

        </div>
        :
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <text style={{
              color: '#553A28', fontWeight: 'bold',
              fontSize: 'large'
            }}>Your details&nbsp;&nbsp;</text>

            <CheckCircle width={20} height={20} color="#28a745" style={{ float: 'right' }} className="checkcircle-fr" />

            <button className="changebtn-fr"
              onClick={() => setShowUserDetails(false)}
            >
              change
            </button>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', color: '#282c3f', fontSize: '18px', fontWeight: '500' }}>
            <div>
              <span className="namepay">{fullname}</span>
            </div>
            <div>
              <span className="namepay">{`(${countryCode}) ${formattedPhone}`}</span>
            </div>
            <div>
              <span>{email}</span>
            </div>


          </div>

        </div>
      }
    </div>
  )
}

export default GuestuserDetails