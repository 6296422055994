import React, { Component } from 'react';
import { connect } from 'react-redux';
import greencircle from '../../../images/Ellipse 511.svg';
import foodrestaurantimg from '../../../images/food-and-restaurant.svg';
import foodimg from '../../../images/food.svg';
import phoneimg from '../../../images/Group 30030.svg';
import prepareorderimg from '../../../images/Group 33059.svg';
import scooterimg from '../../../images/scooter.svg';
import { transactionDetails } from '../../../reducers/cart';
import OrderDetailsModal from '../../Common/orderDetailsModal/OrderDetailsModal';
import './Trackorder.scss';
let orderStagescontent = [
  // {
  //   id: 1,
  //   pending: 0,
  //   heading: 'Order is pending',
  //   body: 'Waiting for restaurant to confirm your order',
  //   time: '1:30 PM',
  //   // icon: require('@order-images/prepare_icon.png'),
  //   isDone: true,
  // },
  {
    id: 1,
    placed: 1,
    heading: 'Order Placed Successfully!',
    body: 'Your payment is completed and your order has been placed successfully!',
    time: '1:30 PM',
    icon: prepareorderimg,
    isDone: true,
  },
  {
    id: 2,
    confirmed: 2,
    heading: 'Preparing Order',
    body: 'Your order is confirmed and is being prepared in our kitchen!',
    time: '1:40 PM',
    icon: prepareorderimg,
    isDone: true,
  },
  {
    id: 3,
    ready: 3,
    heading: 'Order is Ready',
    body: 'Your order is ready to be picked up!',
    bodyDineIn: 'Your order is ready to be served!',
    time: '1:50 PM',
    icon: foodimg,
    isDone: true,
  },
  {
    id: 4,
    dispatched: 4,
    heading: 'Order Picked up',
    body: 'Your order has been picked up!',
    time: '2:00 PM',
    icon: scooterimg,
    isDone: true,
  },
  {
    id: 5,
    completed: 5,
    taken: 5,
    heading: 'Order Delivered',
    body: 'Your order has been delivered to you successfully!',
    time: '2:00 PM',
    icon: foodrestaurantimg,
    isDone: true,
  },
]
class Trackorder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trackmapshow: false,
      checkOrder: false,
      orderModalShow: false,
      tracktext: 'Track Map',
      more: false,
      orderStages:
        { placed: 1, confirmed: 2, ready: 3, dispatched: 4, taken: 4, completed: 5 }

    }
    this.handletrackOrder = this.handletrackOrder.bind(this);
    this.handleviewmore = this.handleviewmore.bind(this);
  }

  componentDidMount() {
    this.props.transactionDetails(this.props.orderId);

    setTimeout(() => {
      this.props.transactionDetails(this.props.orderId);
    }, 3000);

    this.intervalID = setInterval(() => {
      this.props.transactionDetails(this.props.orderId);
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);

  }

  handleviewmore = () => {
    this.setState({ more: true })
  }
  handletrackOrder = () => {
    this.setState({ trackmapshow: !this.state.trackmapshow });
  };
  handlesettrackmap = (param) => {
    this.setState({ trackmapshow: param });
  }

  handleOrderModalShow = (param) => {
    this.setState({ orderModalShow: param });
  }

  render() {
    const { tracktext, orderModalShow } = this.state

    return (

      <div className="whiteBox" style={{ marginBottom: 20 }}>
        {orderModalShow &&
          <OrderDetailsModal
            show={orderModalShow}
            setShow={this.handleOrderModalShow}
            activeitem={this.props}
          />}
        {/* {this.state.trackmapshow && <TrackMapModal show={this.state.trackmapshow}
                            setShow={this.handlesettrackmap} />} */}
        <div className="orderwhite">
          <div className="mt-4rem container"  >
            <div className="columns">
              <div className="column" style={{ paddingBottom: '10px' }} >
                <div className="column" style={{ marginTop: '20px' }}>
                  <div>
                    <span className="tr-head-title merchantname-fr">
                      {this.props.transactiondetails.merchantName}
                    </span>
                  </div>
                </div>

                <div className="trackorder">
                  <div>
                    <span style={{ paddingRight: '10px' }}>{this.props.transactiondetails.status}</span>
                    <span>Order ID • {this.props.transactiondetails.orderId}</span>

                  </div>
                  <div>
                    <b>{this.props.transactiondetails?.currencySymbol}{this.props.transactiondetails?.details?.total}</b>
                  </div>
                </div>
                <span>Mode • {this.props.transactiondetails?.orderMode?.toUpperCase()}&nbsp;</span>
              </div>

              <div>
                <div>
                  <span style={{ fontSize: '15px' }}>{this.props.transactiondetails.details?.orderItems?.length} Items</span>
                </div>
                <div className="columns">
                  <div className="column is-6">
                    {this.state.more === false ?
                      <div>
                        <div className="lesstext" style={{ display: 'flex', flexDirection: 'column', marginRight: 10, width: '100%' }}>
                          {this.props.transactiondetails?.details?.orderItems.map((item, i) =>
                            <OrderItem {...item} />
                          )} </div>
                        {this.props.details?.orderItems?.length > 3 ?
                          <div style={{ marginTop: '20px' }}>
                            <button onClick={this.handleviewmore} className="viewbtn">View More</button>
                          </div>
                          :
                          null
                        }
                      </div>
                      :
                      <div className="moretext" style={{ display: 'flex', flexDirection: 'column', marginRight: 10, width: '100%', overflow: 'auto' }}>
                        {this.props.transactiondetails?.details?.orderItems.map((item, i) =>
                          <OrderItem {...item} />
                        )} </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {window.innerWidth > 768 ?
              <div style={{ marginBottom: '3rem' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <h2 className="etatext-track">{this.props.transactiondetails?.date?.date} </h2>

                  {!!this.props.transactiondetails?.driverDetails?.name.length ?
                    <div >
                      <div style={{ display: 'flex', flexDirection: 'row', width: 220 }}>
                        <a href={`tel:${this.props.transactiondetails?.driverDetails?.phoneNumber}`}>
                          <img src={phoneimg} style={{ width: 20 }} /></a>
                        <p className="trackmodalcontent">{this.props.transactiondetails?.driverDetails?.name}&nbsp;is on his way to deliver your order.</p>
                      </div>
                    </div>
                    :
                    null
                  }

                  <div>
                    <a style={{ backgroundColor: '#553a28', color: '#fff', border: 0, padding: '10px 20px', borderRadius: '6px', letterSpacing: '1.2px', marginRight: 10 }}
                      href={`tel:${this.props.transactiondetails?.outletPhoneNumber}`}>Call Restaurant</a>
                  </div>
                </div>

                {this.state.trackmapshow === true ?
                  <div>
                    <div className="modalHeading-track">
                      <h2 className="trackheader">
                        Track order here
                      </h2>
                      <button className="trackhelp-button">help</button>
                    </div>
                    <div className="row modelMap" style={{ alignItems: 'center' }}>
                      <div className="col-md-7">
                        <iframe
                          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.305935303!2d-74.25986548248684!3d40.69714941932609!2m3!1f0!2f0!3f0!3m2
        !1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1604749924401!5m2!1sen!2sin`}
                          width="100%"
                          height="244"
                          frameBorder="0"
                          allowfullscreen=""
                          aria-hidden="false"
                          tabIndex="0"
                        />
                      </div>
                      <div className="col-md-5 trackmapstatus">
                        <hr style={{ width: '73px' }} />
                        <div className="trackmapdetails">
                          <img src={scooterimg} />
                          <div>
                            <h2 className="trackmodalheadertext">Order picked up</h2>
                            <p className="trackmodalcontent">Nameer Khan is on his way to deliver your order.</p>
                            <p className="trackmodaltext"><img src={greencircle} style={{ marginRight: '10px' }} width="14px" height="14px" />Your food will arrive in 20 minutes</p>
                          </div>
                          <img src={phoneimg} />
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div>
                    <ol className="progtrckr" data-progtrckr-steps="5">
                      {
                        orderStagescontent.map((item, index) => {
                          if (this.props.transactiondetails?.orderMode === 'takeAway' && item.id > 4) {
                            return null
                          }
                          if (this.props.transactiondetails?.orderMode === 'dineIn' && item.id > 3) {
                            return null
                          }
                          return (
                            <li className={this.state.orderStages[this.props.transactiondetails?.status] >= item.id ? `progtrckr-done` : `progtrckr-todo`}>
                              <img className="statusimg" src={item.icon} />
                              <p className="foodstatusmodes">{item.heading}</p>
                              <p className="foodstatus">{item.body}</p>
                            </li>
                          )
                        }
                        )
                      }
                    </ol>
                    <p className="foodstatus-fr">{this.props.transactiondetails?.statusText}!</p>
                  </div>
                }
              </div>
              :
              <div style={{ marginBottom: '3rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <h2 className="etatext-track">{this.props.transactiondetails?.date?.date} </h2>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px', width: '100%' }}>
                    {!!this.props.transactiondetails?.driverDetails?.name.length ?
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <a href={`tel:${this.props.transactiondetails?.driverDetails?.phoneNumber}`}><img src={phoneimg} style={{ width: 20 }} /></a>
                        <p className="trackmodalcontent">{this.props.transactiondetails?.driverDetails?.name}&nbsp;is on his way to deliver your order.</p>
                      </div>
                      :
                      null
                    }
                    <div style={{ width: '100%', backgroundColor: '#553a28', color: '#fff', border: 0, padding: '10px 20px', borderRadius: '6px', letterSpacing: '1.2px', marginRight: 10 }}>
                      <a style={{ color: '#fff' }}
                        href={`tel:${this.props.transactiondetails?.outletPhoneNumber}`}>Call Restaurant</a>
                    </div>
                  </div>
                </div>

                {this.state.trackmapshow === true ?
                  <div>
                    <div className="modalHeading-track">
                      <h2 className="trackheader">
                        Track order here
                      </h2>
                      <button className="trackhelp-button">help</button>
                    </div>
                    <div className="row modelMap" style={{ alignItems: 'center' }}>
                      <div className="col-md-7">
                        <iframe
                          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.305935303!2d-74.25986548248684!3d40.69714941932609!2m3!1f0!2f0!3f0!3m2
        !1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1604749924401!5m2!1sen!2sin`}
                          width="100%"
                          height="244"
                          frameBorder="0"
                          allowfullscreen=""
                          aria-hidden="false"
                          tabIndex="0"
                        />
                      </div>
                      <div className="col-md-5 trackmapstatus">
                        <hr style={{ width: '73px' }} />
                        <div className="trackmapdetails">
                          <img src={scooterimg} />
                          <div>
                            <h2 className="trackmodalheadertext">Order picked up</h2>
                            <p className="trackmodalcontent">Nameer Khan is on his way to deliver your order.</p>
                            <p className="trackmodaltext"><img src={greencircle} style={{ marginRight: '10px' }} width="14px" height="14px" />Your food will arrive in 20 minutes</p>
                          </div>
                          <img src={phoneimg} />
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div>
                    <ol className="progtrckr" data-progtrckr-steps="4">
                      {
                        orderStagescontent.map((item, index) => {
                          if (this.props.transactiondetails?.orderMode === 'takeAway' && item.id > 4) {
                            return null
                          }
                          if (this.props.transactiondetails?.orderMode === 'dineIn' && item.id > 3) {
                            return null
                          }
                          return (
                            <li
                              className={this.state.orderStages[this.props.transactiondetails?.status] >= item.id ? `progtrckr-done` : `progtrckr-todo`}>
                              <img src={item.icon} />
                              {item.heading}
                              <text className="foodstatus-mobile-orderstatus">{item.body}</text>
                            </li>
                          )
                        }
                        )
                      }
                    </ol>
                  </div>
                }
              </div>
            }

            <hr />
            <button
              onClick={() => this.handleOrderModalShow(true)}
              className="viewbtn">
              View Order Details
            </button>
          </div >
        </div >
      </div >
    )
  }
}

class OrderItem extends Component {
  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', display: 'flex', justifyContent: 'space-between', marginRight: 30, }}>
        <b>{this.props.name} X {this.props.quantity}</b>
      </div>
    )
  }
}
const mapStateToProps = state => ({
  orderHistory: state.user.orderTransactionHistory,
  userDetails: state.user.userDetails?.profile,
  updatedDetails: state.user.updatedDetails?.profile,
  transactiondetails: state.cart?.transactiondetails
});

export default connect(mapStateToProps, {
  transactionDetails
})(Trackorder);