import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  decreaseItemQuantity, increaseItemQuantity, requestAddNewAddress, requestAllFreebies,
  requestNewAddress, requestOrderTimeSlots, requestOrderUserAddress, requestUserProfile, setOrderMode
} from '../../../actions';
import { addCart, getCart, getCartConfigAction, getCartCouponsAction, getCartFreebiesAction, getCartPointsAction, getServiceableAddressAction } from '../../../reducers/cart';
import { getFreebies } from '../../../reducers/freebie';
import { getPointsAction } from '../../../reducers/loyaltyPoints';
import { requestNearestMerchantAndAllCatalogues } from '../../Home/Container/utility';
import Layout from '../../Layout';
import CartComponent from '../Component/CartTwo';

class CartContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allItems: []
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    document.getElementById("splashspinner").style.display = "none";

    this.getData();

    this.props.getPointsAction()
    this.props.getCart();
    this.props.getCartConfigAction();
    this.props.requestOrderTimeSlots(this.props.homepageDetails.nearestMerchantDetails?.id)
    if (this.props.user.isAuthenticated) {
      this.props.getCartCouponsAction();
      this.props.getCartFreebiesAction();
      this.props.getCartPointsAction()
      this.props.requestUserProfile();
      this.props.getFreebies()
    }

    let cat = this.props.allCatalogues?.categories
    let fullList = []
    for (let i = 0; i < cat?.length; i++) {
      for (let j = 0; j < cat[i].items.length; j++) {
        fullList.push(cat[i].items[j])
      }
      for (let k = 0; k < cat[i].categories.length; k++) {
        for (let l = 0; l < cat[i].categories[k].items.length; l++) {
          fullList.push(cat[i].categories[k].items[l])
        }
      }
    }
    this.setState({ allItems: fullList })
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.homepageDetails.availableModesDetails !==
      this.props.homepageDetails.availableModesDetails
    ) {
      this.getData();
    }
  }

  getData = () => {
    const isLoggedIn = !!(this.props.user.userDetails && this.props.user.userDetails.authToken);
    requestNearestMerchantAndAllCatalogues(this.props);

    if (this.props.user.isAuthenticated) {
      this.props.getServiceableAddressAction({
        merchantId: this.props.homepageDetails.nearestMerchantDetails?.id,
        authToken: this.props.user.otpResponse?.authToken
      });
    }
  }

  render() {
    return (
      <div>
        <Layout >
          <CartComponent {...this.props}
            userDetails={this.props.userDetails}
            cartItems={this.props.cartItems}
            allItems={this.state.allItems}
          />
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  homepageDetails: state.homepage,
  userDetails: state.user.userDetails?.profile,
  user: state.user,
  cart: state.cart,
  allCatalogues: state.homepage.allCatalogues,
  cartItems: state.cart.items,
});

export default connect(mapStateToProps, {
  requestAllFreebies,
  requestNewAddress,
  increaseItemQuantity,
  decreaseItemQuantity,
  requestAddNewAddress,
  requestUserProfile,
  requestOrderTimeSlots,
  requestOrderUserAddress,
  getCart,
  addCart, setOrderMode, getServiceableAddressAction,
  getCartConfigAction,
  getFreebies,
  getPointsAction,
  getCartCouponsAction, getCartFreebiesAction, getCartPointsAction
})(CartContainer);