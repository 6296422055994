// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6z7Mfm3I5JOtqvoj17VG8ApG-X5ImPGI",
  authDomain: "biryanis-test.firebaseapp.com",
  projectId: "biryanis-test",
  storageBucket: "biryanis-test.appspot.com",
  messagingSenderId: "308396047408",
  appId: "1:308396047408:web:9f1766de38415f653f01f5",
  measurementId: "G-RV61J5P28X"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app)
const messaging = getMessaging(app);


export const getFirebaseToken = async (setTokenFound) => {
  return await getToken(messaging, { vapidKey: 'BGU-LZrAKD4r_2GV_jLd8rrHTJMMCo0xC-eTIksP1pzxm-JYr7rkw4oEjldhPckjfOon3zRfYsfcFObhjlj6HYQ' }).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      return currentToken
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {

      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });