import apiClient from '../api';
import config from '../config';
import { getDeliveryCharges } from '../reducers/payment';
const initialState = {
  cartId: null,
  items: [],
  activecart: [],
  transactiondetails: {}
};

const CART_STATUS = "cart/CART_STATUS"
const ADD_CART = "cart/ADD_CART"
const GET_CART = "cart/GET_CART"
const CLEAR_CART = 'cart/CLEAR_CART'
const CART_ACTIVE = 'cart/CART_ACTIVE'
const CART_INCREMENT = "cart/CART_INCREMENT"
const CART_DECREMENT = "cart/CART_DECREMENT"
const CART_ALERT = "cart/CART_ALERT"
const TRANSACTION_HISTORY = "cart/TRANSACTION_HISTORY"
const TRANSACTION_DETAILS = "cart/TRANSACTION_DETAILS"
const SERVICEABLE_ADDRESSES = "cart/SERVICEABLE_ADDRESSES"
const CART_CONFIG = "cart/CART_CONFIG"

const CART_POINTS = 'cart/CART_POINTS';
const CART_COUPONS = 'cart/CART_COUPONS';
const CART_FREEBIES = 'cart/CART_FREEBIES';

export const addCart = (id, addOn) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { cartId } = state.cart || {};
    const { nearestMerchantDetails } = state.homepage;
    const { merchant } = state.merchant;

    const { initialOrderMode } = state.orders;
    let merchantId = !!nearestMerchantDetails ? nearestMerchantDetails.id : merchant?.id
    const response = await apiClient.post(apiClient.Urls.addToCart, {
      items: [
        { itemId: id, choices: addOn || [] }
      ],
      cartId: cartId,
      merchantId: merchantId,
      orderMode: initialOrderMode
    });
    if (response.success) {
      dispatch({
        type: CART_STATUS,
        payload: response.data
      })
      window.fbq('track', 'AddToCart');
      dispatch(getDeliveryCharges());
    } else {
      dispatch({
        type: CART_ALERT,
        payload: response
      });
    }
  } catch (error) {
    console.log(error)
  }
};

export const removeCart = (id, addOn) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { nearestMerchantDetails } = state.homepage;
    const { merchant } = state.merchant;

    let merchantId = !!nearestMerchantDetails ? nearestMerchantDetails.id : merchant?.id

    const response = await apiClient.post(apiClient.Urls.removefromcart, {
      items: [
        { itemId: id, choices: addOn || [] }
      ],
      cartId: state.cart.cartId,
      merchantId: merchantId,
    });
    if (response.success) {
      dispatch({
        type: CART_STATUS,
        payload: response.data
      })
      dispatch(getDeliveryCharges());
    } else {
    }
  } catch (error) {
    console.log(error)
  }
};

export const getCart = (param) => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.get(apiClient.Urls.getCart, {
      cartId: state.cart.cartId,
      addressId: param ? param.id : null
    });
    if (response.success) {
      dispatch({
        type: CART_STATUS,
        payload: response.data
      })
    } else {
    }
  } catch (error) {
    console.log(error)
  }
};

export const clearCart = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.get(apiClient.Urls.cartClear, {
      cartId: state.cart.cartId,
    });
    if (response.success) {
      dispatch({
        type: CLEAR_CART,
      });

    } else {
      dispatch({
        type: CART_ALERT
      })
    }
  } catch (error) {
    console.log(error)
  }
  dispatch({
    type: CLEAR_CART
  });
};

export const activeCart = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.get(apiClient.Urls.activeCart, {
      authToken: localStorage.getItem('auth_token')
    });
    if (response.success) {
      dispatch({
        type: CART_STATUS,
        payload: response.data
      })
    } else {
    }
  } catch (error) {
    console.log(error)
  }
};


export const cartIncrement = (args) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken } = state.user.otpResponse;
    const { nearestMerchantDetails } = state.homepage;
    const { merchant } = state.merchant;

    let merchantId = !!nearestMerchantDetails ? nearestMerchantDetails.id : merchant?.id

    let reqParams = {
      items: [
        { itemId: args.id, cartItemId: args.cartItemId, choices: args.choices || [] }
      ],
      cartId: state.cart.cartId,
      merchantId: merchantId,
    }
    reqParams.authToken = !!authToken ? authToken : null

    const response = await apiClient.post(apiClient.Urls.cartIncrement, reqParams);
    if (response.success) {
      dispatch({
        type: CART_STATUS,
        payload: response.data
      })
      window.fbq('track', 'AddToCart');
      dispatch(getCartPointsAction())
      dispatch(getDeliveryCharges());
    } else {
    }
  } catch (error) {
    console.log(error)
  }
};

export const cartDecrement = (args) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken } = state.user.otpResponse;
    const { nearestMerchantDetails } = state.homepage;
    const { merchant } = state.merchant;

    let merchantId = !!nearestMerchantDetails ? nearestMerchantDetails.id : merchant?.id

    let reqParams = {
      items: [
        { itemId: args.id, cartItemId: args.cartItemId, choices: args.choices || [] }
      ],
      cartId: state.cart.cartId,
      merchantId: merchantId,
    }
    reqParams.authToken = !!authToken ? authToken : null

    const response = await apiClient.post(apiClient.Urls.cartDecrement, reqParams);
    if (response.success) {
      dispatch({
        type: CART_STATUS,
        payload: response.data
      })
      dispatch(getCartPointsAction())
      dispatch(getDeliveryCharges());
    } else {
    }
  } catch (error) {
    console.log(error)
  }
};

export const transactionHistory = (args) => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.orders, {
      authToken: args.authToken,
    });
    if (response.success) {
      dispatch({
        type: TRANSACTION_HISTORY,
        payload: response.data,
      });
    } else {
      dispatch({
        type: TRANSACTION_HISTORY,
        payload: response,
      });
    }
  } catch (error) {
    console.log(error)
  }
};


export const transactionDetails = (orderId, callBack) => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.getOrderDetails, {
      authToken: state.user.otpResponse?.authToken,
      id: orderId,
    });
    if (response.success) {
      dispatch({
        type: TRANSACTION_DETAILS,
        transactiondetails: response.order,
      });
      callBack(response)
    }
  } catch (error) {
    console.log(error)
  }
};

export const getServiceableAddressAction = (args) => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.getServiceableAddress, {
      authToken: args.authToken,
      merchantAccessToken: config.merchantAccessToken,
      merchantId: args.merchantId
    });
    if (response.success) {
      dispatch({
        type: SERVICEABLE_ADDRESSES,
        payload: response
      })
      dispatch(getDeliveryCharges());
    } else {
    }
  } catch (error) {
    console.log(error)
  }
};

export const getCartConfigAction = (args) => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.getCartConfig, {
      merchantAccessToken: config.merchantAccessToken,
      merchantId: state.homepage.selectedMerchantServiceability.id
    });
    if (response.success) {
      dispatch({
        type: CART_CONFIG,
        payload: response
      })
      dispatch(getDeliveryCharges());
    } else {
    }
  } catch (error) {
    console.log(error)
  }
};


export const addServiceableAddressAction = (args, callback) => async (dispatch, getState) => {
  try {
    const response = await apiClient.post(apiClient.Urls.addServiceableAddress, {
      ...args,
      merchantAccessToken: config.merchantAccessToken,
    });
    if (response.success) {
      dispatch({
        type: SERVICEABLE_ADDRESSES,
        payload: response
      })
      dispatch(getDeliveryCharges());
    } else {
    }
    callback(response)
  } catch (error) {
    console.log(error)
  }
};

export const editServiceableAddressAction = (args, callback) => async (dispatch, getState) => {
  try {
    const response = await apiClient.post(apiClient.Urls.editServiceableAddress, {
      ...args,
      merchantAccessToken: config.merchantAccessToken,
    });
    if (response.success) {
      dispatch({
        type: SERVICEABLE_ADDRESSES,
        payload: response
      })
      dispatch(getDeliveryCharges());
    } else {
    }
    callback(response)
  } catch (error) {
    console.log(error)
  }
};


export const getCartPointsAction = (callBack) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { cartId } = state.cart;
    const { authToken } = state.user.otpResponse;

    const args = {
      cartId: cartId,
      authToken: authToken,
      merchantAccessToken: config.merchantAccessToken,
    }
    const response = await apiClient.get(apiClient.Urls.cartPoints, args);
    if (response.success) {
      dispatch({
        type: CART_POINTS,
        payload: response,
      });

    } else {
    }
    callBack && callBack(response)
  } catch (error) { console.log(error); }
};

export const getCartCouponsAction = (callBack) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { cartId } = state.cart;
    const { authToken } = state.user.otpResponse;

    const args = {
      cartId: cartId,
      authToken: authToken,
      merchantAccessToken: config.merchantAccessToken,
    }
    const response = await apiClient.post(apiClient.Urls.cartCoupons, args);
    if (response.success) {
      dispatch({
        type: CART_COUPONS,
        payload: response.coupons,
      });

    } else {
    }
    callBack && callBack(response)
  } catch (error) { console.log(error); }
};

export const getCartFreebiesAction = (callBack) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { cartId } = state.cart;
    const { authToken } = state.user.otpResponse;

    const args = {
      cartId: cartId,
      authToken: authToken,
      merchantAccessToken: config.merchantAccessToken,
    }
    const response = await apiClient.get(apiClient.Urls.cartFreebies, args);
    if (response.success) {
      dispatch({
        type: CART_FREEBIES,
        payload: response.freebies,
      });

    } else {
    }
    callBack && callBack(response)
  } catch (error) { console.log(error); }
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CART_STATUS: {
      return {
        ...state,
        ...state.cart,
        ...action.payload
      }
    }
    case ADD_CART: {
      return {
        ...state,
        items: action.items || [],
        cartId: action.cartId || null,
      }
    }

    case GET_CART: {
      return {
        ...state,
        items: action.items || [],
        cartId: action.cartId || null,
        cart: action.cart || null
      }
    }
    case CART_ACTIVE: {
      return {
        ...state,
        activecart: action.activecart || [],
        cartId: action.cartId || null,
      }
    }
    case CART_INCREMENT: {
      return {
        ...state,
        items: action.items || [],
        cartId: action.cartId || null,
      }
    }
    case CART_DECREMENT: {
      return {
        ...state,
        items: action.items || [],
        cartId: action.cartId || null,
      }
    }

    case CART_ALERT: {
      return {
        ...state,
        cartAlert: action.payload
      }
    }
    case TRANSACTION_HISTORY: {
      return {
        ...state,
        transactionHistory: action.payload
      }
    }
    case TRANSACTION_DETAILS: {
      return {
        ...state,
        transactiondetails: action.transactiondetails
      }
    }
    case SERVICEABLE_ADDRESSES: {
      return {
        ...state,
        addresses: action.payload
      }
    }

    case CART_CONFIG: {
      return {
        ...state,
        cartConfig: action.payload
      }
    }

    case CART_POINTS: {
      return {
        ...state,
        cartPoints: action.payload,
      };
    }

    case CART_COUPONS: {
      return {
        ...state,
        cartCoupons: action.payload,
      };
    }

    case CART_FREEBIES: {
      return {
        ...state,
        cartFreebies: action.payload,
      };
    }

    case CLEAR_CART:
      return { ...initialState };

    default: return state;
  }
};

export default cartReducer;