import apiClient from '../api';
import config from '../config';

const initialState = {
  merchant: null
};

const HOME_PAGE_CONFIG_START = "merchant/HOME_PAGE_CONFIG_START"
const SELECTED_MERCHANT = "merchant/SELECTED_MERCHANT"
const GET_TIME_SLOTS = "merchant/GET_TIME_SLOTS"


export const setMerchantAction = (args) => async (dispatch, getState) => {
  const state = getState();
  const { availableModesDetails } = state.homepage
  let merchant = args
  let allMerchants = availableModesDetails?.data
  let selectedMerchant = null
  for (let i = 0; i < allMerchants?.length; i++) {
    if (allMerchants[i].id === merchant.id) {
      selectedMerchant = allMerchants[i]
    }
  }
  console.log({ selectedMerchant })

  dispatch({
    type: SELECTED_MERCHANT,
    payload: selectedMerchant,
  });
};

export const getMerchantIdfromSlugAction = (args) => async (dispatch, getState) => {

  try {
    const response = await apiClient.post(apiClient.Urls.getMerchantIdfromSlug, {
      merchantSlug: args.merchantSlug,
      merchantAccessToken: config.merchantAccessToken
    });
    if (response.success) {
      dispatch({
        type: SELECTED_MERCHANT,
        payload: response
      });
    } else {

    }
  } catch (error) {
  }
};

export const getTimeSlotsAction = (params, callBack) => async (dispatch, getState) => {
  const state = getState();
  const { authToken } = state.user.otpResponse;
  let merchantId = !!params ? params?.merchantId : ''
  try {
    const response = await apiClient.post(apiClient.Urls.getTimeSlots, {
      authToken,
      merchantId: merchantId
    });
    if (response.success) {
      dispatch({
        type: GET_TIME_SLOTS,
        payload: response.availableTimeSlots,
      });
    }
    !!callBack && callBack(response);
  } catch (e) {
    console.log(e)
  }
};


const merchantReducer = (state = initialState, action) => {
  switch (action.type) {

    case HOME_PAGE_CONFIG_START: {
      return {
        ...state,
        loader: true,
      }
    }
    case SELECTED_MERCHANT: {
      return {
        ...state,
        merchant: action.payload,
      }
    }
    case GET_TIME_SLOTS: {
      return {
        ...state,
        timeSlots: action.payload,
      }
    }

    default: return state;
  }
};

export default merchantReducer;
