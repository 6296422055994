import React, { memo, useState } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useDispatch, useSelector } from 'react-redux';
import { Info } from '../../../../../images/icons/IconSets';
import PointsImg from '../../../../../images/loyaltyPoint.png';
import { removeCouponAction } from "../../../../../reducers/coupon";
import { removeFreebieAction } from "../../../../../reducers/freebie";
import { removePointsAction } from "../../../../../reducers/loyaltyPoints";
import { CouponModal } from "../../../../Common/CouponModal/Modal";
import FreebiesModal from "../../../../Common/FreebiesModal";
import { PointsModal } from "../../../../Common/PointsModal/Modal";
import CartAlertModal from "../../CartAlertModal";
import "../../custom.css";
import CartItem from './CartItem';
import './CartPanel.css';
import Tip from './Tip.js';
import Tooltip from './Tooltip';

function CartPanel(props) {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user)
  const reduxCart = useSelector(state => state.cart)
  const freebies = useSelector(state => state.freebie.freebies)

  const [couponShow, couponSetShow] = useState(false);
  const [freebiesShow, freebiesSetShow] = useState(false);
  const [pointsShow, pointsSetShow] = useState(false);
  const [alertShow, setalertShow] = useState(
    { status: false, message: 'Please select Address' })

  const { authToken } = user.otpResponse || {}

  const {
    cartId, merchantId, currencySymbol, cartTotal, cartTax, cartDiscountType,
    cartDiscountValue, cartDiscount, cartDeliveryCharge, cartPackagingCharge,
    cartSubTotal, orderMode, addressId, pickupAt, notes, tableId, tableName,
    tip, tipType, tipRate, cartStatus, pointsToBeEarned, cartConfig, cartTaxes,
    discounts, cartCoupons, cartPoints, cartFreebies,
  } = reduxCart || {}



  let pointDiscount = discounts?.find(a => a.group === 'points')
  let couponDiscount = discounts?.find(a => a.group === 'coupon')
  let freebieDiscount = discounts?.find(a => a.group === 'freebie')

  const handlePoints = () => {
    if (!!authToken) {
      if (pointDiscount?.group === 'points' && !!pointDiscount?.amount) {
      dispatch(removePointsAction(result => {
        if (result.success === true) {
        }
        else {
          setalertShow({ status: true, message: result.message })
        }
      }))
    }
    else {
      pointsSetShow(true)
    }
    }
    else {
      setalertShow({ status: true, message: "You need to login to view your points." })
    }

  }

  const handleFreebies = () => {
    if (!!authToken) {
      if (freebieDiscount?.group === 'freebie' && !!freebieDiscount?.amount) {
      dispatch(removeFreebieAction())
    }
    else {
      freebiesSetShow(true);
    }
    }
    else {
      setalertShow({ status: true, message: "You need to login to view your freebies." })
    }
  }


  const handleCoupons = () => {
    if (couponDiscount?.group === 'coupon' && !!couponDiscount?.amount) {
      dispatch(removeCouponAction())
    }
    else {
      couponSetShow(true)
    }
  }


  const addToCart = (item, id, qty) => {
    props.addCart(id, qty, null);
  }

  return (
    <div
      className="col-lg-4 col-md-12" >

      {!!alertShow.status &&
        <CartAlertModal
          show={alertShow}
          setShow={setalertShow} />}

      {!!couponShow &&
        <CouponModal
          show={couponShow}
          setShow={couponSetShow} />}

      {!!freebiesShow &&
        <FreebiesModal
          show={freebiesShow}
          setShow={freebiesSetShow}
          freebies={props.cart.allFreebies}
        />
      }

      {!!pointsShow &&
        <PointsModal
          show={pointsShow}
          setShow={pointsSetShow}
        />
      }

      <div style={{
        boxShadow: ' 0px 0px 20px #0000000D',
        backgroundColor: ' #fff',
        borderRadius: '0px 0px 6px 6px',
        opacity: 1, height: 'fit-content',
        padding: 15
      }} >
        <div className="cart-list">
          {reduxCart?.items.map((item, index) =>
          (<CartItem
            {...props}
            item={item}
            key={index}
            addToCart={(qty) => addToCart(item, item.id, qty)} />)
          )}
        </div>

        <div className="coupon-list">
          {/* COUPON >>> */}
          <div className="loyalty-check">
            <div className="coupon-percent">
              <img src="assets/coupon.svg" alt="" />
            </div>
            {
              (couponDiscount?.group === 'coupon' && !!couponDiscount?.amount)
                ?
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 5 }}>
                  <text style={{
                    color: '#2C2C2C',
                    fontSize: 16,
                    fontWeight: '700'
                  }}>Code '{couponDiscount.reason}'</text>
                  <text style={{ fontSize: 12 }}>Coupon is applied for the bill.</text>
                </div>
                :
                <text style={{
                  marginLeft: 5,
                  color: '#2C2C2C',
                  fontSize: 16,
                  fontWeight: '700'
                }}>Apply Coupon</text>
            }

            <button
              onClick={() => handleCoupons()}
              className="freebie-btn"
              style={
                (couponDiscount?.group === 'coupon' && !!couponDiscount?.amount)
                  ?
                  {
                    background: '#E94B35 0% 0% no-repeat zzpadding-box',
                    backgroundColor: '#E94B35'
                  }
                  :
                  {
                    background: '#E5AB4E 0% 0% no-repeat padding-box',
                    backgroundColor: '#E5AB4E'
                  }
              }
            >
              <span>
                {(couponDiscount?.group === 'coupon' && !!couponDiscount?.amount)
                  ?
                  'REMOVE'
                  :
                  `${!!cartCoupons ? cartCoupons?.length : 0} AVAILABLE`
                }
              </span>
            </button>
          </div>
          {/* <<< COUPON  */}



          {/* POINTS >>> */}
          {/* <div className="loyalty-check">
            <div className="coupon-percent">
              <img src="assets/star.svg" alt="" />
            </div>
            {
              (pointDiscount?.group === 'points' && !!pointDiscount?.amount)
                ?
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: 5 }}>
                  <text style={{
                    color: '#2C2C2C',
                    fontSize: 16,
                    fontWeight: '700'
                  }}>Points used: {pointDiscount?.reason}</text>
                </div>
                :
                <text style={{
                  marginLeft: 5,
                  color: '#2C2C2C',
                  fontSize: 16,
                  fontWeight: '700'
                }}>Use Points</text>
            }

            <button
              onClick={() => handlePoints()}
              className="freebie-btn"
              style={
                (pointDiscount?.group === 'points' && !!pointDiscount?.amount)
                  ?
                  {
                    background: '#E94B35 0% 0% no-repeat padding-box',
                    backgroundColor: '#E94B35'
                  }
                  :
                  {
                    background: '#E5AB4E 0% 0% no-repeat padding-box',
                    backgroundColor: '#E5AB4E'
                  }
              }
            >
              <span>
                {
                  (pointDiscount?.group === 'points' && !!pointDiscount?.amount)
                    ?
                    'REMOVE'
                    :
                    `${!!cartPoints ? cartPoints?.availablePoints : 0} AVAILABLE`
                }
              </span>
            </button>
          </div> */}
          {/* <<< POINTS */}


          {/* FREEBIES >>> */}
          <div className="loyalty-check">
            <div className="coupon-percent">
              <img src="assets/freebies.svg" alt="" />
            </div>
            <div >
              {
                (freebieDiscount?.group === 'freebie' && !!freebieDiscount?.amount)
                  ?
                  <div style={{
                    display: 'flex', flexDirection: 'column',
                    alignItems: 'flex-start', marginLeft: 5, width: 190
                  }}>
                    <text style={{
                      color: '#2C2C2C',
                      fontSize: 16,
                      fontWeight: '700'
                    }}>Freebie added:</text>
                    <text style={{
                      color: '#2C2C2C',
                      fontSize: 14,
                      fontWeight: '700'
                    }}>{freebieDiscount?.reason}</text>
                    <text style={{ fontSize: 12 }}>Freebie is applied for the bill.</text>
                  </div>
                  :
                  <text style={{
                    marginLeft: 5,
                    color: '#2C2C2C',
                    fontSize: 16,
                    fontWeight: '700'
                  }}>Freebies</text>
              }

            </div>
            <button
              onClick={() => handleFreebies()}
              className="freebie-btn"
              style={
                (freebieDiscount?.group === 'freebie' && !!freebieDiscount?.amount)
                  ?
                  {
                    background: '#5CB346 0% 0% no-repeat padding-box',
                    backgroundColor: '#E94B35'
                  }
                  :
                  {
                    background: '#E5AB4E 0% 0% no-repeat padding-box',
                    backgroundColor: '#E5AB4E'
                  }
              }
            >
              <span>
                {(freebieDiscount?.group === 'freebie' && !!freebieDiscount?.amount)
                  ?
                  'REMOVE'
                  :
                  `${!!cartFreebies ? cartFreebies?.length : 0} AVAILABLE`
                }
              </span>
            </button>
          </div>
          {/* <<< FREEBIES  */}

        </div>

        {cartConfig?.cartTipConfig?.enableTip === true ?
          <Tip />
          :
          null
        }


        <div className="main-item-total">
          <div className="cp-item-total">
            <p>Items Total</p>
            <p>{currencySymbol}{!!cartSubTotal ? cartSubTotal : 0}</p>
          </div>
          <div className="cp-item-total">
            <p>Discounts</p>
            <p>{currencySymbol}{!!cartDiscount ? cartDiscount : 0}</p>
          </div>
          <div className="cp-item-total">
            <p>Delivery Fee</p>
            <p>{currencySymbol}{!!cartDeliveryCharge ? cartDeliveryCharge : 0}</p>
          </div>


        </div>
        <hr />
        <div className="cp-item-total">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
            <p>Tax & Charges&nbsp;</p>
            <OverlayTrigger
              placement={"top"}
              overlay={
                <div id={`tooltip-top`}>
                  <Tooltip cartData={reduxCart} />
                </div>
              }
            >
              <div >
                <Info width={13} height={13}
                  color="#000000" />
              </div>
            </OverlayTrigger>

          </div>

          <p>{currencySymbol}{cartTax}</p>
        </div>

        {
          cartConfig?.cartTipConfig?.enableTip === true ?
            <div className="cp-item-total">
              <p>Tip</p>
              <p>{currencySymbol}{!!tip ? tip : 0}</p>
            </div>
            : null
        }

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
          <h6 style={{ letterSpacing: 2 }}>TO PAY</h6>
          <h6>{currencySymbol}{!!cartTotal ? cartTotal : 0}</h6>
        </div>

        {pointsToBeEarned ?
          <div className="main-item-total">
            <div className="cp-item-total" style={{}}>
              <p style={{}}>Points Earned</p>
              <div style={{ display: 'flex', flexDirection: 'row', width: 'max-content' }}>
                <img src={PointsImg} style={{ height: 18, marginTop: 4 }} alt="" />
                <text style={{ color: '#1AAF5D' }}>
                  &nbsp;+{!!pointsToBeEarned ? pointsToBeEarned : 0}
                </text>
              </div>
            </div>
          </div>
          : null}
      </div>

    </div>
  )
}
export default memo(CartPanel) 

