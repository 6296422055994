import React, { Component, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import { CheckCircle, RightArrow } from '../../../../../images/icons/IconSets';
import LoginModal from '../../../../Common/LoginModal';
import AddressModal from "../../../../Common/AddressModal";
import { saveInfo, selectAddress } from '../../../../../reducers/checkout'
import './Panel.css'
import OfficeImg from '../../../../../images/Group 30275.png';
import { getCart } from "../../../../../reducers/cart";
import { setTemporaryInputFields } from "../../../../../actions";


function DeliveryMode(props) {
  const dispatch = useDispatch()
  const reduxUserDetails = useSelector(state => state.user.userDetails)
  const reduxCheckout = useSelector(state => state.checkout)
  const user = useSelector(state => state.user)
  const cartRedux = useSelector(state => state.cart)
  const reduxmerchant = useSelector(state => state.homepage?.nearestMerchantDetails?.name)
  const reduxMerchantId = useSelector(state => state.merchant.merchant)
  const [selectedaddress, setselectedaddress] = useState(false)
  const [addressShow, addressSetShow] = useState(false);
  const [loginShow, setloginShow] = useState(false)
  const [selectedaddressobject, setselectedaddressobject] = useState()
  const [operationType, setoperationType] = useState('')

  const { otpResponse, temporaryInputFields } = user
  const {
    guest, fullname, email, phoneNumber,
    instructions, userSelectedAddress, userSelectedAddressId
  } = temporaryInputFields || {}

  const handleaddresschange = (param) => {
    setselectedaddressobject(param)
    setselectedaddress(true)
    setTimeout(() => {
      props.scrollDown()
    }, 300);
    props.selectAddress(param);
    props.saveInfo({
      merchantId: reduxMerchantId.id,
      addressId: param.id
    }, result => {
      if (result.success) {
        props.getCart()
      }
    })
  }

  const handlepreviousaddress = () => {
    setselectedaddress(false)
  }

  const handleNewAddress = (param) => {
    setoperationType(param)
    if (user.isAuthenticated || !!guest === true) {
      addressSetShow(true)
    } else
      setloginShow(true)
  }

  const handleDeleteAddress = () => {
    dispatch(setTemporaryInputFields({
      userSelectedAddress: null,
      userSelectedAddressId: null
    }))
  }

  return (
    <div className="panel-card" style={{ marginBottom: '30px' }}>
      {loginShow && (
        <LoginModal show={loginShow} setShow={setloginShow} {...props} />
      )}
      {addressShow && (
        <AddressModal show={addressShow} setShow={addressSetShow} type={operationType} {...props} />
      )}
      {selectedaddress === false ?
        <div>
          <text className="choose-delivery" style={{
            color: '#553A28', fontWeight: 'bold',
            fontSize: 'large'
          }}>Choose Delivery Location</text>
          <div className="delivery-scroll" >
            {cartRedux.addresses?.addresses?.map((value, i) =>
              <div key={i} className="col-lg-6 col-md-6 order-address-fr">
                <div className="delivery-address" style={{ position: 'relative' }} >
                  {value.id === reduxCheckout?.selectedDeliveryAddressId &&
                    <div style={{ right: 15, top: 5, position: 'absolute' }}>
                      <CheckCircle width={15} height={15} color="#28a745" />
                    </div>
                  }
                  <div style={{ margin: '0px 15px 0px 0px' }}>
                    {
                      value.tag === 'home' ?
                        <div >
                          <img src="assets/home.svg" alt="" />
                        </div>
                        :
                        <div >
                          <img src={OfficeImg} alt="" />
                        </div>
                    }
                  </div>
                  <div style={{ position: "relative", paddingBottom: 45, width: "100%" }}>
                    <h4>{value.tagName || value.tag}</h4>
                    <text style={{ color: '#748090', fontSize: 14 }}>
                      {
                        value.address1 + ', ' +
                        value.landmark + ', ' +
                        value.address2 + ', ' +
                        value.city + ', ' +
                        value.locality
                      }</text>
                    <h5>{ }</h5>
                    {!!value.serviceable ?
                      <button className="btn delivery-btn"
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: 35,
                          margin: 0,
                          right: 0, bottom: 0,
                        }}
                        onClick={() => { handleaddresschange(value) }}>
                        DELIVER HERE
                      </button>
                      :

                      <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: 35,
                        margin: 0,
                        right: 0, bottom: 0,
                        borderRadius: 6,
                        borderColor: 'Grey',
                        textAlign: 'center',
                        fontSize: 'medium',
                        color: '#788495',
                        border: '1px solid #00000021',
                        padding: 2,
                      }}><text>Not Serviceable</text></div>

                    }
                  </div>

                </div>
              </div>
            )}


            {/* GUEST ADDRESS CARD */}
            {
              (!!guest === true && !!userSelectedAddress) ?
                (
                  <div className="col-lg-6 col-md-6">
                    <div className="delivery-address" style={{ position: 'relative' }} >
                      <div style={{ right: 15, top: 5, position: 'absolute' }}>
                        <CheckCircle width={15} height={15} color="#28a745" />
                      </div>

                      <div style={{ margin: '0px 15px 0px 0px' }}>
                        {
                          userSelectedAddress.tag === 'home' ?
                            <div >
                              <img src="assets/home.svg" alt="" />
                            </div>
                            :
                            <div >
                              <img src={OfficeImg} alt="" />
                            </div>
                        }
                      </div>
                      <div style={{ position: "relative", paddingBottom: 5, width: "100%" }}>
                        <h4>{userSelectedAddress.tagName || userSelectedAddress.tag}</h4>
                        <text style={{ color: '#748090', fontSize: 14 }}>
                          {
                            userSelectedAddress.address1 + ', ' +
                            userSelectedAddress.landmark + ', ' +
                            userSelectedAddress.address2 + ', ' +
                            userSelectedAddress.city + ', ' +
                            userSelectedAddress.locality
                          }</text>
                      </div>

                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 100, right: 15, bottom: 5, position: 'absolute' }}>
                        <div
                        // onClick={() => handleDeleteAddress()}
                        // style={{ cursor: 'pointer' }}
                        >
                          {/* <text style={{ color: '#748090', fontSize: 14 }}>Delete</text> */}
                        </div>
                        <div
                          onClick={() => handleNewAddress('update')}
                          style={{ cursor: 'pointer' }}>
                          <text style={{ color: '#748090', fontSize: 14 }}>Edit</text>
                        </div>
                      </div>

                    </div>

                  </div>
                )
                : null
            }

            {
              // ((user.isAuthenticated || !!guest === true) && (!!userSelectedAddressId === false)) ?
              ((!!userSelectedAddressId === false && !!guest === true) || !!user.isAuthenticated) ?

                <div className="col-lg-6 col-md-6" >
                  <div className="delivery-address"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', cursor: 'pointer', color: '#E5AB4E', padding: 20 }}
                    onClick={() => handleNewAddress('create')} >
                    <img src="assets/plus.svg" alt="" />
                    <text>ADD NEW ADDRESS</text>
                  </div>
                </div>
                :
                null
            }



          </div>
        </div>
        :
        <div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <text style={{ color: '#553A28', fontWeight: 'bold', fontSize: 'large' }} >
              Delivery address&nbsp;&nbsp;&nbsp;</text>
            <CheckCircle width={20} height={20} color="#28a745" style={{ float: 'right' }} className="checkcircle-fr" />
            <button className="changebtn-fr" style={{ marginRight: 10 }} onClick={handlepreviousaddress}>change
            </button>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
            {
              selectedaddressobject.tag === 'home' ?
                <div >
                  <img src="assets/home.svg" alt="" />
                </div>
                :
                <div className="home-img">
                  <img src={OfficeImg} alt="" />
                </div>
            }
            <h4 className="hometext-fr">
              &nbsp;   {selectedaddressobject.tagName || selectedaddressobject.tag}
            </h4>
          </div>
          <p className="selectedaddress-fr-text">
            {selectedaddressobject.address2 + ', ' + selectedaddressobject.landmark + ', ' + selectedaddressobject.locality + ', ' + selectedaddressobject.city}</p>
          {/* <h5 className="etaaddress-fr">13 MINS</h5> */}
          <h5 style={{ fontSize: '18px' }}>Delivery From {reduxmerchant} </h5>
        </div>
      }
    </div>
  )
}



export default connect(() => { }, {
  selectAddress, getCart, saveInfo
})(DeliveryMode)

