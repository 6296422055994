import React, { Component } from 'react';
import LocationComponent from '../Components/index'
import Layout from '../../Layout/index'

class index extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = 'Location - Biryanis and More'
    }

    render() {
        return (
            <div>
                <Layout >
                    <LocationComponent />
                </Layout>
            </div>
        );
    }
}

export default index;
