import config from '../config';

const initialState = {
  sociallinks: [],
  homepagefeatures: [],
  homefeatureditems: [],
  ordermodebuttons:[],
  customerTestimonials:[],
  homepageaboutus:[],
  contactinfo:[],
  outletimages:[],
  aboutUsCountries:[],
  // contactusform:''
};

const ABOUT_US_COUNTRIES = "ABOUT_US_COUNTRIES";

const CONTACT_US_INFO = "CONTACT_US_INFO"
const OUTLET_IMAGES = "OUTLET_IMAGES"
const CUSTOMER_TESTINOMIALS = "CUSTOMER_TESTINOMIALS"
const ORDER_MODE_BUTTONS ='ORDER_MODE_BUTTONS'
const SOCIAL_LINKS = "SOCIAL_LINKS"


const HOME_PAGE_ABOUT_US = "HOME_PAGE_ABOUT_US"

const HOME_PAGE_FEATURES = "HOME_PAGE_FEATURES"
const HOME_FEATURED_ITEMS = "HOME_FEATURED_ITEMS"
const APP_STORE_CONFIGURATION = "APP_STORE_CONFIGURATION"

const SET_ANCHOR = "SET_ANCHOR"
const CONTACT_US_FORM = 'CONTACT_US_FORM'
function getRequestOptionsfor() {
  return {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  };
}
function getRequestOptions() {
  return {
    method: 'POST',
    headers: {
      'Accept': 'application/json'
    }
  };
}

export const requestCustomerTestinomials = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'customerTestimonials'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const customerTestimonials = await response.json();

    if (customerTestimonials.success) {
      dispatch({
        type: CUSTOMER_TESTINOMIALS,
        customerTestimonials: customerTestimonials.data
      });
    } else {

    }

  } catch (error) {


  }
  
};

export const requestaboutcountries = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'aboutUsCountries'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const aboutUsCountries = await response.json();
    // console.log("configsocial datattat data", customerTestimonials.data)
    if (aboutUsCountries.success) {
      dispatch({
        type: ABOUT_US_COUNTRIES,
        aboutUsCountries: aboutUsCountries.data
      });
    } else {
      console.log("not fetching data");
    }

  } catch (error) {


  }
  
};


export const socialLinks = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'socialLinks'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const sociallinks = await response.json();

    if (sociallinks.success) {
      dispatch({
        type: SOCIAL_LINKS,
        sociallinks: sociallinks.data
      });
    } else {

    }

  } catch (error) {


  }
};


export const orderModeButtons = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'orderModesButtons'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const ordermodebuttons = await response.json();

    if (ordermodebuttons.success) {
      dispatch({
        type:ORDER_MODE_BUTTONS,
        ordermodebuttons: ordermodebuttons
      });
    } else {

    }

  } catch (error) {
    

  }
};
export const contactUsInfo = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'contactInfo'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const contactinfo = await response.json();

    if (contactinfo.success) {
      dispatch({
        type:CONTACT_US_INFO,
        contactinfo: contactinfo
      });
    } else {

    }

  } catch (error) {
    

  }
};
export const outletImages = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'outletImages'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const outletimages = await response.json();

    if (outletimages.success) {
      dispatch({
        type:OUTLET_IMAGES,
        outletimages: outletimages
      });
    } else {

    }

  } catch (error) {
    

  }
};

export const homePageFeatures = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'homePageFeatures'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const homepagefeatures = await response.json();

    if (homepagefeatures.success) {
      dispatch({
        type: HOME_PAGE_FEATURES,
        homepagefeatures: homepagefeatures.data
      });
    } else {
    }
  } catch (error) {
  }
};

export const getAppStoreInfoAction = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'configuration'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const appInfo = await response.json();

    console.log({ appInfo })
    if (appInfo.success) {
      dispatch({
        type: APP_STORE_CONFIGURATION,
        payload: appInfo.data,
      });
    } else {
    }
  } catch (error) { }
};


export const homepageAboutUs = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'homeAboutBrand'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const homepageaboutus = await response.json();

    if (homepageaboutus.success) {
      dispatch({
        type: HOME_PAGE_ABOUT_US,
        homepageaboutus: homepageaboutus.data
      });
    } else {

    }

  } catch (error) {


  }
};
export const homeFeaturedItems = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'homeFeaturedItems'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const homefeatureditems = await response.json();

    if (homefeatureditems.success) {
      dispatch({
        type: HOME_FEATURED_ITEMS,
        homefeatureditems: homefeatureditems.data
      });
    } else {

    }
  } catch (error) {
  }
};

export const contactUSForm = (params) => async (dispatch, getState) => {
  try {
    const requestOptions = getRequestOptions();
    const response = await fetch(`${config.apiUrl}/content/form/submit?type=${'contactUsForm'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}&response=${[
     { "name": "Harshit"},
      {"email":"harshit@froogal.ai"},
      {"phoneNumber":"8897170712"},
      {"message": ""}
    ]

    }`, requestOptions)
    const contactusform = await response.json();

    if (contactusform.success) {
      dispatch({
        type: CONTACT_US_FORM,
        contactusform: contactusform
      });
    } else {

    }
  } catch (error) {
  }
};

export const setAnchorAction = (params) => async (dispatch) => {
  dispatch({
    type: SET_ANCHOR,
    payload: params
  });
};

const homepagecmsReducer = (state = initialState, action) => {
  switch (action.type) {

    case CUSTOMER_TESTINOMIALS: {
      return {
        ...state,
        customerTestimonials: action.customerTestimonials
      }
    }
    case ABOUT_US_COUNTRIES: {
      return {
        ...state,
        aboutUsCountries: action.aboutUsCountries
      }
    }
    case ORDER_MODE_BUTTONS: {
      return {
        ...state,
        ordermodebuttons: action.ordermodebuttons
      }
    }
    case SOCIAL_LINKS: {
      return {
        ...state,
        sociallinks: action.sociallinks      }
    }
    case HOME_PAGE_ABOUT_US: {
      return {
        ...state,
        homepageaboutus: action.homepageaboutus
      }
    }
    case HOME_PAGE_FEATURES: {
      return {
        ...state,
        homepagefeatures: action.homepagefeatures
      }
    }
    case HOME_FEATURED_ITEMS: {
      return {
        ...state,
        homefeatureditems: action.homefeatureditems
      }
    }

    case APP_STORE_CONFIGURATION: {
      return {
        ...state,
        configuration: action.payload
      }
    }
    
    case CONTACT_US_INFO: {
      return {
        ...state,
        contactinfo: action.contactinfo
      }
    }
    
    case OUTLET_IMAGES: {
      return {
        ...state,
        outletimages: action.outletimages
      }
    }
    // case CONTACT_US_FORM: {
    //   return {
    //     ...state,
    //     contactusform:action.contactusform
    //   }
    // }
    case SET_ANCHOR: {
      return {
        ...state,
        setAnchor: action.payload
      }
    }


    default: return state;
  }
};


export default homepagecmsReducer;
