import React,{useState, useEffect} from 'react';
import './Tabs.scss';
import '../../About/Components/aboutUs.css';

const EmptyTabContent = (tabKey) => {
  return null;
}

export const VTabs = ({ tabs, renderTabContent } = {}) => {
//   if (!tabs || tabs.length <= 0) {
//     return null;
//   }
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const RenderTab = typeof renderTabContent === "function" ? renderTabContent : EmptyTabContent;
  const ActiveTabContent = RenderTab(activeTab.key);
  const device_width = window.innerWidth;
  return (
    <div className="theme-v-tabs">
      <ul>
        {tabs.map(tab => (

          <li>
            <button
              onClick={e => setActiveTab(tab)}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
              className={`is-size-3 theme-vtab-tab is-capitalized ${activeTab.key === tab.key ? 'active' : ''}`}
            >
              {tab.label}
               {/* {device_width > 756 ? '' : <i className="fas fa-angle-down"></i>} */}
            </button>
            {/* <div className="theme-vtab-content theme-vtab-touch is-hidden-desktop" style={{ display: activeTab.key === tab.key ? 'block' : 'none' }}>
              {RenderTab(tab.key)}
            </div> */}
          </li>
        ))}
      </ul>
      <div className="theme-vtab-content theme-vtab-desktop is-hidden-touch">
        {ActiveTabContent}
      </div>
    </div>
  )
}

export const HTabs = ({ tabs, renderTabContent }) => {
//   if (!tabs || tabs.length <= 0) {
//     return null;
//   }
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const TabContent = renderTabContent(activeTab.key);
  return (
    <div className="theme-h-tabs">
      <ul className="block-list">
        <li>
          <p className="is-size-6 is-mobile has-text-weight-medium has-text-black mr-6 mb-1">
            PREFERRED PAYMENT
          </p>
        </li>
        {tabs.map(tab => (
          <li>
            <button
              onClick={e => setActiveTab(tab)}
              className={`is-size-6 ${tab === activeTab ? 'active' : ''} is-capitalized`}
            >
              {tab.label}
            </button>
          </li>
        ))}
      </ul>
      <div className="theme-htab-content">
        {TabContent}
      </div>
    </div>
  )
}
