import React from 'react'
import PropTypes from 'prop-types'
import Alert from 'react-bootstrap/Alert'
import './alertToast.css'
function AlertToast(props) {
  const { title, showToast } = props.alertToastValues || {}
  return (
    <div style={{ display: 'flex', position: 'absolute', alignItems: 'center', bottom: 0 }}>
      <Alert
        variant='primary'
        className="alert-toast-anim"
        onClose={() => showToast(false)}>
        {title}
      </Alert>
    </div>
  )
}

AlertToast.propTypes = {}

export default AlertToast
