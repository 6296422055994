import React from 'react'
import { GiftingRoot } from '@froogal.ai/gifting-sdk-js'
import '@froogal.ai/gifting-sdk-js/dist/index.css'
import config from '../../../config'
import { useSelector } from 'react-redux'

function Gifting(props) {
  const user = useSelector(state => state.user)
  const { authToken } = user?.otpResponse || {}

  return (
    <div  >
      <GiftingRoot
        authToken={authToken}
        merchantAccessToken={config.merchantAccessToken}
        baseUrl={config.apiUrl + '/'}
        imageBaseUrl="https://assets-cdn.froogal.ai/gifting-sdk/v1.0.31/images/"
      />
    </div>
  )
}
export default Gifting
