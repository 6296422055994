import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector , useDispatch} from 'react-redux';
import Spinner from 'react-bootstrap/Spinner'
import apiClient from '../../../api/index'
import {getDeliveryCharges} from  '../../../reducers/payment'
import './OrderDetailsModal.css'

function OrderDetailsModal(props) {
    const userRedux = useSelector(state => state.user)
    const reduxCurrency = useSelector(state => state.homepage.allCatalogues?.currency)
   
    const [orderDetails, setOrderDetails] = useState()
    const dispatch = useDispatch()
    useEffect(() => {
        async function getTransactionDetails() {
            const response = await apiClient.post(apiClient.Urls.getOrderDetails, {
                authToken: userRedux.otpResponse?.authToken,
                id: props.activeitem.orderId,
            });
            if (response.success) {
                setOrderDetails(response.order)

            }
        }
        getTransactionDetails()
    }, [])

    const { show, setShow , deliveryCharges} = props;
    // const reduxdeliveryCharges = useSelector(state => state.payment.deliveryCharges)
    return (
        <div>
            <Modal show={show} onHide={() => { setShow(false) }} animation={false}>
                <Modal.Header closeButton />
                {
                    !!orderDetails === false ?
                        <div style={{ display: 'flex', height: 400, justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner animation="border" style={{ alignSelf: 'center' }} />
                        </div>
                        :
                        <Modal.Body>
                            <div className="odr-modalHeading-track">
                                <h2 className="odr-trackheader">Order Details</h2>
                            </div>
                            {/* <hr style={{ width: 150 }} /> */}
                            <p className="odr-trackmodalcontent">Order ID • {props.activeitem.orderId} </p>
                            <hr />
                            <div className="odr-lesstext" >
                                {orderDetails?.details?.orderItems?.map((item, i) =>
                                    <div key={i}
                                        style={{
                                            display: 'flex', flexDirection: 'row',
                                          justifyContent: 'space-between', marginBottom: 5,
                                          font: "normal normal normal 15px/18px Lato",
                                        }}>
                                        <text>{item.name} x {item.quantity}</text>
                                    <text>{orderDetails.currencySymbol}{item.quantity * item.rate}</text>
                                    </div>
                                )}
                            </div>
                            <hr />
                            <div className="odr-item-total">
                                <p>Item Total</p>
                  <p>{orderDetails.currencySymbol}{orderDetails?.details?.subTotal}</p>
                            </div>
                            <div className="odr-item-total">
                                <p>Discount</p>
                  <p>{orderDetails.currencySymbol}{orderDetails?.details?.discount}</p>
                            </div>
                            <div className="odr-item-total">
                                    <p>Delivery Fee</p>
                                    <p>{deliveryCharges}</p>
                                </div>
                            <hr />
                            <div className="odr-item-total">
                                <p>Tax & Charges</p>
                  <p>{orderDetails.currencySymbol}{orderDetails?.details?.tax}</p>
                            </div>
                            <div className="odr-pay-fr">
                                <h6>Paid amount</h6>
                  <h6>{orderDetails.currencySymbol}{orderDetails?.details?.total}</h6>
                            </div>
                        </Modal.Body>
                }
            </Modal>
        </div>
    )
};

export default OrderDetailsModal;