// import apiClient from '../../../api';
import apiClient from '../api';
import { homeConfig } from './home';

const initialState = {
  sectionOne: null,
  sectionTwo: null,
  sectionThree: null,
  sectionOffer: null,
  baseFreebies: null,
  loyatltyTiers: null,
  referAndEarn: null,
  welcomeLoyalty: null,
  signUpOffer: null,
};

const GET_LOYALTY_IDS = 'loyalty/GET_LOYALTY_IDS';
const GET_SECTION_ONE = 'loyalty/GET_SECTION_ONE';
const GET_SECTION_TWO = 'loyalty/GET_SECTION_TWO';
const GET_SECTION_THREE = 'loyalty/GET_SECTION_THREE';
const GET_SECTION_OFFER = 'loyalty/GET_SECTION_OFFER';
const GET_BASE_FREEBIES = 'loyalty/GET_BASE_FREEBIES';
const LOYALTY_TIERS = 'loyalty/LOYALTY_TIERS';
const REFER_AND_EARN = 'loyalty/REFER_AND_EARN';
const GET_WELCOME_LOYALTY = 'loyalty/GET_WELCOME_LOYALTY';
const GET_SIGNUP_OFFER = 'loyalty/GET_SIGNUP_OFFER';


export const callLoyaltySectionApis = (callBack) => async (dispatch, getState) => {
  await callBack(false)

  await dispatch(getloyaltyIds())
  await dispatch(getSectionOne());
  await dispatch(getSectionTwo());
  await dispatch(getSectionThree());
  await dispatch(getSectionOffer());
  await dispatch(getLoyaltyTiers());

  await dispatch(getReferTerms());
  await dispatch(getWelcomeSection());
  await dispatch(getSignUpOffer());
  await dispatch(getBaseFreebies());
  await callBack(true)
};

export const getloyaltyIds = () => async (dispatch, getState) => {
  // API: BASE_URL + 'content'
  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'loyaltyIds',
    });
    if (response.success) {
      dispatch({
        type: GET_LOYALTY_IDS,
        payload: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const getBaseFreebies = () => async (dispatch, getState) => {
  dispatch(homeConfig());
  const state = getState();
  const { config } = state.home;
  const { userAddressChunks } = state.user;
  const { primaryLoyaltyId } = state.user.userDetails?.profile || {}
  const { loyaltyIds } = state.loyaltystatic
  const { merchantAccessToken } = config;

  let loyalty = loyaltyIds?.find(item => parseInt(item.loyaltyId) === primaryLoyaltyId)

  let userCountryCode = userAddressChunks?.country_id

  let selectedBaseCard = loyalty ? loyalty : loyaltyIds.find(a => a.countryCode === userCountryCode)
  let selectedPrimaryLoyalty = !!primaryLoyaltyId ? primaryLoyaltyId : loyaltyIds.find(a => a.countryCode === userCountryCode).loyaltyId

  console.log('#######123', selectedPrimaryLoyalty, selectedBaseCard.baseCardId)

  try {
    const response = await apiClient.get(apiClient.Urls.getCardFreebies(selectedPrimaryLoyalty, selectedBaseCard.baseCardId), {
      merchantAccessToken,
    });
    console.log('#######', response)
    if (response.success) {
      dispatch({
        type: GET_BASE_FREEBIES,
        baseFreebies: response.freebies,
      });
    } else {
    }
  } catch (e) {
    console.log(e)
  }
};




export const getSectionOne = () => async (dispatch, getState) => {
  // API: BASE_URL + 'content'

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'loyaltySectionOne',
    });
    if (response.success) {
      dispatch({
        type: GET_SECTION_ONE,
        sectionOne: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const getSignUpOffer = () => async (dispatch, getState) => {
  // API: BASE_URL + 'content'

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'loyaltySignUpOffer',
    });
    if (response.success) {
      dispatch({
        type: GET_SIGNUP_OFFER,
        signUpOffer: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const getSectionTwo = () => async (dispatch, getState) => {
  // API: BASE_URL + 'content'

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'loyaltySectionTwo',
    });
    if (response.success) {
      dispatch({
        type: GET_SECTION_TWO,
        sectionTwo: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const getWelcomeSection = () => async (dispatch, getState) => {

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'loyaltyWelcome',
    });
    if (response.success) {
      dispatch({
        type: GET_WELCOME_LOYALTY,
        welcomeLoyalty: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const getLoyaltyTiers = () => async (dispatch, getState) => {
  // API: BASE_URL + 'content'

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'loyaltyTierSection',
    });

    console.log("loyaltyTierSection", response);

    if (response.success) {
      dispatch({
        type: LOYALTY_TIERS,
        loyatltyTiers: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};


export const getReferTerms = () => async (dispatch, getState) => {
  // API: BASE_URL + 'content'

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'referAndEarn',
    });

    console.log("refer", response);

    if (response.success) {
      dispatch({
        type: REFER_AND_EARN,
        referAndEarn: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const getSectionThree = () => async (dispatch, getState) => {
  // API: BASE_URL + 'content'

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'loyaltySectionThree',
    });
    if (response.success) {
      dispatch({
        type: GET_SECTION_THREE,
        sectionThree: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const getSectionOffer = () => async (dispatch, getState) => {
  // API: BASE_URL + 'content'

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'loyaltySectionOffer',
    });
    if (response.success) {
      dispatch({
        type: GET_SECTION_OFFER,
        sectionOffer: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};


const loyaltyReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOYALTY_IDS: {
      return {
        ...state,
        loyaltyIds: action.payload,
      }
    }
    case GET_SECTION_ONE: {
      return {
        ...state,
        sectionOne: action.sectionOne,
      }
    }
    case GET_SECTION_TWO: {
      return {
        ...state,
        sectionTwo: action.sectionTwo,
      }
    }
    case GET_SECTION_THREE: {
      return {
        ...state,
        sectionThree: action.sectionThree,
      }
    }
    case GET_SIGNUP_OFFER: {
      return {
        ...state,
        signUpOffer: action.signUpOffer,
      }
    }
    case REFER_AND_EARN: {
      return {
        ...state,
        referAndEarn: action.referAndEarn
      }
    }
    case GET_SECTION_OFFER: {
      return {
        ...state,
        sectionOffer: action.sectionOffer,
      }
    }
    case GET_BASE_FREEBIES: {
      return {
        ...state,
        baseFreebies: action.baseFreebies
      }
    }
    case LOYALTY_TIERS: {
      return {
        ...state,
        loyatltyTiers: action.loyatltyTiers
      }
    }
    case GET_WELCOME_LOYALTY: {
      return {
        ...state,
        welcomeLoyalty: action.welcomeLoyalty
      }
    }

    default: return state;
  }
}


export default loyaltyReducer;