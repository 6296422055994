import React, { Component } from 'react'
import { connect } from 'react-redux';
import Config from '../../config'
import { initialize, initializeActiveBenefits, initializeHistoryBenefits }
  from '@froogal.ai/loyalty-sdk-js';
import '@froogal.ai/loyalty-sdk-js/dist/index.css';
import './loyalty-custom.css';
class Loyalty extends Component {
  componentDidMount() {
    initialize(document.getElementById('loyalty-root'), {
      authToken: this.props.user.otpResponse?.authToken,
      merchantAccessToken: Config.merchantAccessToken,
      loyaltyId: this.props.user.userDetails?.profile.primaryLoyaltyId,
      knowMoreUrl: `${window.location.origin}/myloyalty`,
      termsUrl: `${window.location.origin}/loyaltytermsandcondition`,
      tabStyle: true,
      imageBaseUrl: 'https://assets-cdn.froogal.ai/loyalty-sdk/v1.0.34/images/',
    })
  }

  render() {
    return (<div id="loyalty-root"></div>)
  }

}

const mapStateToProps = (state) => ({
  homepage: state.homepage,
  userDetails: state.user.userDetails?.profile,
  user: state.user,
  cart: state.cart,
  allCatalogues: state.homepage.allCatalogues,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Loyalty)