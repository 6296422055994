import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import ContentLoader from "react-content-loader"
import { useDispatch, useSelector } from 'react-redux'
import makeStyles from '@mui/styles/makeStyles';
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import { getAddressFromCoordinates } from '../../../utils/index'
import LoginModal from '../../Common/LoginModal';
import GiftModal from '../../Common/GiftModal';
import RegisterModal from '../../Common/RegisterModal';
import CartModal from '../../Common/CartModal'
import AddressModal from '../../Common/AddressModal'

import { requestExactAddressByReverseGeocoding, clearTemporaryInputFields } from '../../../actions';
import userimg from '../../../../src/images/Group 36509.svg';
import searchimg from '../../../../src/images/Group 36511.svg';
import cartimg from '../../../../src/images/Group 35379.svg';
import 'font-awesome/css/font-awesome.min.css';
import './header.css';
import LocationSwitchModal from '../../Common/LocationSwitchModal/index';
import dropdownicon from '../../../../src/images/Polygon 22.svg';
import homeicon from '../../../images/icons/homeicon.png'
import basketicon from '../../../../src/images/Group 35379.png';
import config from "../../../config";
import { setAnchorAction } from "../../../reducers/homepagecms";
import { Hamburger } from '../../../images/icons/IconSets';
import globallogo from '../../../images/Group 30146.svg';
import locationmark from '../../../images/Group 30275.svg';
function Header(props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.user)
  const reduxOrderStates = useSelector(state => state.orders)
  const reduxhomepage = useSelector(state => state.homepage)
  const reduxCart = useSelector(state => state.cart)
  const { navbardetails, homepage, allCatalogues, userDetails } = props;


  const reduxState = useSelector(state => state.user?.otpResponse);

  const [show, setShow] = useState(false);
  const [giftShow, giftSetShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  const [loginshow, setLoginShow] = useState(false);
  const [locationshow, setlocationshow] = useState(false);
  const [locationText, setlocationText] = useState()
  const [cartShow, setcartShow] = useState(false)
  const [addressShow, addressSetShow] = useState(false);

  let total = 0;

  const [prevScrollpos, setprevScrollpos] = useState(window.pageYOffset)
  const [visible, setvisible] = useState(true)
  const data = allCatalogues !== null ? allCatalogues : null;


  const getCartCount = () => {
    const total = reduxCart.items && Object.values(reduxCart.items).reduce((prev, { quantity }) => prev + 1 * quantity, 0)
    return total;
  }

  // useEffect(() => {
  //   if (props.user.userLocation !== null) {
  //     const requestOptions = getRequestOptionsForExactAddress(props.user);
  //     props.requestExactAddressByReverseGeocoding(requestOptions);
  //   }
  // }, [props.user.userLocation?.latitude]);

  // useEffect(() => {
  //   if (props.user.userLocation && !Object.keys(props.user.userLocation).includes('results')) {
  //     props.requestExactAddressByReverseGeocoding(getRequestOptionsForExactAddress(props.user));
  //   }
  //   else if (!props.user.userLocation) {
  //     props.requestExactAddressByReverseGeocoding(props.user.userDetails?.locationPreference);
  //   }
  // }, [props.user.userLocation, props.user.userDetails]);



  useEffect(() => {
    /*  async function abc() {
       let a = await getAddressFromCoordinates(props.user.userLocation)

       setlocationText(a)
       setlocationText(reduxUser.userAddressChunks)
       if (props.user.userLocation) {
         setlocationText(await getAddressFromCoordinates(props.user.userLocation))
       }
       else if (props.user.userDetails) {
         setlocationText(await getAddressFromCoordinates(props.user.userDetails?.locationPreference))
       }
     }
     abc() */
    const location = reduxUser.userAddressChunks
    if (!!reduxUser.userAddressChunks) {
      let chuncks = reduxUser.userAddressChunks

      let headerAddress =
        (
          (!!chuncks.sublocality ? chuncks.sublocality : "")
          ||
          (!!chuncks.locality ? chuncks.locality : "")
        )

        +
        (
          (
            chuncks.sublocality !== chuncks.sublocality_level_1 &&
            chuncks.sublocality !== chuncks.sublocality_level_2 &&
            chuncks.sublocality !== chuncks.sublocality_level_3 &&
            chuncks.locality !== chuncks.sublocality_level_1 &&
            chuncks.locality !== chuncks.sublocality_level_2 &&
            chuncks.locality !== chuncks.sublocality_level_3
          )
            ?
            (
              (!!chuncks.sublocality_level_1 ? chuncks.sublocality_level_1 : "")
              ||
              (!!chuncks.sublocality_level_2 ? chuncks.sublocality_level_2 : "")
              ||
              (!!chuncks.sublocality_level_3 ? chuncks.sublocality_level_3 : "")
            )
            :
            ""
        )

        +
        (
          (!!chuncks.administrative_area_level_2 ? ", " + chuncks.administrative_area_level_2 : "")
          ||
          (!!chuncks.administrative_area_level_1 ? ", " + chuncks.administrative_area_level_1 : "")
        )

      setlocationText(headerAddress.replace(/^,|,$/g, ''))

      // setlocationText(
      //     (location.sublocality || location.locality) + ', ' +
      //     (location.administrative_area_level_2 || location.administrative_area_level_1))
    }

  }, [reduxUser.userAddressChunks]);



  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, [])

  const { nearestMerchantDetails } = props.homepage || {};
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos + 200 > currentScrollPos;
    setprevScrollpos(currentScrollPos)
    if (window.innerWidth <= 768 && (history.location.pathname.indexOf('order') > -1 || window.location.pathname.indexOf('cart') > -1)) {
      setvisible(visible)
    }
  };

  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: 'relative',
      backgroundColor: '#553A28'
    },
    title: {
      flex: 1,
    },
  }));

  const classes = useStyles();

  const getRequestOptionsForExactAddress = (options = {}) => {
    if (options.userLocation) {
      return {
        latitude: options.userLocation.latitude,
        longitude: options.userLocation.longitude,
        ...options,
      };
    }
    return {};
  }

  const handleShowModel = () => {
    if (reduxUser.isAuthenticated) {
      history.push("/myDetails")
    } else {
      dispatch(clearTemporaryInputFields())
      return setLoginShow(true);
    }
  };

  const handleCartRoute = () => {
    if (getCartCount() > 0) {
      history.push("/cart")
    } else {
      history.push('/emptycart')
      // return setLoginShow(true);
    }
    // if (reduxState?.success) {
    //   setTimeout(() => {

    //     if (!window.location.hash) {
    //       window.location = window.location + '#loaded';
    //       window.location.reload();
    //     }
    //   }
    //     , 2000)
    // }

  };

  const handleOrderNow = () => {
    dispatch(setAnchorAction(true))
    history.replace({ pathname: '/' })
  };


  const handleShowlocationModel = (param) => {
    return setlocationshow(param);
  };

  const loader = <ContentLoader
    speed={1}
    width={100}
    height={15}
    // viewBox="0 0 400 160"
    backgroundColor="#ecebeb"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="0" y="8" rx="3" ry="3" width="200" height="7" />
  </ContentLoader>

  // const location = props.user.userLocation !== null && Object.keys(props.user.userLocation).includes('results') ? props.user.userLocation.results[4]?.formatted_address : loader;



  const handleLoginShow = (param) => {
    setLoginShow(param)
  }

  const handleRegisterShow = (param) => {
    setRegisterShow(param)
  }
  const handleCartShow = (param) => {
    setcartShow(param)
  }
  const handleAddressShow = (param) => {
    addressSetShow(param)
  }
  return (
    <>
      {loginshow && <LoginModal show={loginshow} setShow={handleLoginShow} setRegisterShow={handleRegisterShow} />}

      {giftShow && <GiftModal show={giftShow} setShow={giftSetShow} />}

      {registerShow && <RegisterModal show={registerShow} setShow={handleRegisterShow} setLoginShow={handleLoginShow} />}

      {locationshow && <LocationSwitchModal show={locationshow} setShow={setlocationshow}
        navigateModal={0} showMerchantList={false} addressSetShow={handleAddressShow} />}

      {addressShow && <AddressModal show={addressShow} setShow={addressSetShow} />}

      {cartShow && <CartModal show={cartShow} setShow={handleCartShow} />}

      <div class={visible ? "themed-navbar fixed-top" : "themed-navbar-hidden  fixed-top"}>
        <div class="container-fluid  headernav">
          {/*
         class={visible ? "themed-navbar navbar-expand-lg navbar-light bg-white" :
              "themed-navbar--hidden"
            }
         */}
          <nav class="navbar navbar-expand-lg navbar-light bg-white">
            {/* {window.location.pathname.indexOf('order') > -1 ?
              null :
              <a class="navbar-brand" href="/">
                <img src={navbardetails.navbarLogo} className="logoheader" height="68" alt="brand-logo" />
              </a>
            } */}
            {/* <img src={globallogo} className="logoheader" width="51" height="58" alt="brand-logo" /> */}

            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <Hamburger width={30} height={30} color="#553A28" />
            </button>
            {/* {
              window.innerWidth < 768 && window.location.pathname.indexOf('order') > -1 ?
                <a style={{ marginLeft: '41px' }}>
                  <span style={{ cursor: 'pointer' }}

                    onClick={() => setlocationshow(true)}>
                    Selected Store
                   <a className="locationicon">
                      <img src={dropdownicon} style={{ marginLeft: '5px' }} />
                    </a>
                  </span>

                  <p className="navtext-fr">{props.homepage?.nearestMerchantDetails?.name}</p>
                </a>
                : */}

            {/* } */}

            {
              window.innerWidth > 768 ?
                <a class="navbar-brand" href="/">
                  <img src={navbardetails.navbarLogo} className="logoheader" height="68" alt="brand-logo" />
                  {/* <img src={globallogo} className="logoheader" width="51" height="58" alt="brand-logo" /> */}
                </a>
                :
                <a class="navbar-brand" href="/">
                  {/* <img src={navbardetails.navbarLogo} className="logoheader" height="68" alt="brand-logo" /> */}
                  <img src={navbardetails.navbarMobileLogo} className="logoheader" width="51" height="58" alt="brand-logo" />
                </a>
            }

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                  <Link to="/"
                    className={
                      `nav-link ${window.location.pathname.length === 1 && "homenew"}`
                    }
                  >Home <span class="sr-only">(current)</span></Link>
                </li>
                {/* <li class="nav-item">
                  <Link to={"/order/" + reduxhomepage.nearestMerchantDetails?.id + "/" + reduxOrderStates.orderMode} className="nav-link">Order</Link>
                </li> */}
                <li class="nav-item">
                  <Link to='/aboutUs'
                    className={
                      `nav-link ${window.location.pathname.indexOf('/aboutUs') > -1 && "homenew"}`
                    }
                  >About</Link>
                </li>
                {/* <li class="nav-item">
                  <Link class="nav-link" to='/ourStory'>Our Story</Link>
                </li> */}
                <li class="nav-item">
                  <Link to='/location'
                    className={
                      `nav-link ${window.location.pathname.indexOf('/location') > -1 && "homenew"}`
                    }
                  >Locations</Link>
                </li>
                <li class="nav-item">
                  <Link to='/contactUs'
                    className={
                      `nav-link ${window.location.pathname.indexOf('/contactUs') > -1 && "homenew"}`
                    }
                  >Contact Us</Link>
                </li>
                <li class="nav-item">
                  <Link to="/franchiseEnquiry"
                    className={
                      `nav-link ${window.location.pathname.indexOf('/franchiseEnquiry') > -1 && "homenew"}`
                    }
                  >Franchise Enquiry</Link>
                </li>
                <li class="nav-item">
                  <Link to="/myLoyalty"
                    className={
                      `nav-link ${window.location.pathname.indexOf('/myLoyalty') > -1 && "homenew"}`
                    }
                  >Rewards</Link>
                </li>
              </ul>
              {
                window.innerWidth > 768 ?
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{
                      display: 'flex', alignItems: 'center',
                      cursor: 'pointer'
                    }}
                      onClick={() => handleShowlocationModel(true)}
                    >
                      <span

                        class="nav-address-select">
                        <img src={locationmark} />
                        {/* <a className="locationicon" href="#">
                              <img src={dropdownicon} style={{ marginLeft: '5px' }} />
                            </a> */}
                      </span>
                      <p className="locationtext-fr-new" >
                        {!!locationText
                          ?
                          locationText
                          :
                          reduxUser.userDetails?.locationPreference?.latitude === null ? 'Allow location'
                            :
                            loader}</p>
                    </div>
                    <div style={{ width: '145px', marginLeft: '20px' }} >
                      {/* <a class="nav-a-fr" onClick={handleShowModel} > */}
                      {
                        window.innerWidth > 768 ?
                          <div>
                            {reduxUser.isAuthenticated ?
                              <div className="accountuser-fr" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '-27px', cursor: 'pointer' }}
                                onClick={handleShowModel}
                              >{
                                  reduxUser.otpResponse.loginType ?
                                    <Image className="profileimg" src={reduxUser.otpResponse.imageUrl} height={40} roundedCircle />
                                    :
                                    <img src={userimg} className="profileimg" alt="user profile icon" />
                                }

                                <div>
                                  <p className="newname">Hi {userDetails?.profile.name}  <img src={dropdownicon} style={{ marginLeft: '5px' }} /></p>
                                  {/* <a className="myaccountname">My Account</a> */}
                                </div>

                              </div>
                              :
                              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                onClick={handleShowModel}
                              >
                                <img src={userimg} className="profileimg" alt="user profile icon" />
                                <p className="locationtext-fr-new">Login<img src={dropdownicon} style={{ marginLeft: '5px' }} /></p>

                              </div>

                            }
                          </div>
                          :
                          <div>
                            {reduxUser.isAuthenticated ?
                              <div className="accountuser-fr" style={{ display: 'flex', flexDirection: 'column', marginLeft: '-27px' }}>
                                {/* <img src={userimg} className="profileimg" alt="user profile icon" /> */}
                                <hr />
                                <p className="newname">Hi {userDetails?.profile.name}  <img src={dropdownicon} style={{ marginLeft: '5px' }} /></p>
                                {/* <a className="myaccountname">My Account</a> */}


                              </div>
                              :
                              <div>
                                {/* <img src={userimg} className="profileimg" alt="user profile icon" /> */}
                                <p className="locationtext-fr"><img src={dropdownicon} style={{ marginLeft: '5px' }} />Login</p>

                              </div>

                            }
                          </div>
                      }


                      {/* </a> */}
                    </div>

                    <div style={{ marginLeft: '0px' }}>
                      {getCartCount() > 0 ?

                        <Button onClick={handleCartRoute} className="orderitbtn orderitbtn-fr" style={{
                          height: 37,
                          width: 146, marginTop: 7, backgroundColor: '#4D3A21'
                        }}
                          varliant="dark">VIEW CART({getCartCount()})</Button>
                        :
                        <Button onClick={handleOrderNow} className="orderitbtn" style={{ height: 37, width: 146, marginTop: 7, backgroundColor: '#4D3A21' }} varliant="dark">ORDER NOW</Button>
                      }
                    </div>
                  </div>
                  :
                  <div>
                    <div onClick={handleShowModel}>
                      {/* <a class="nav-a-fr" onClick={handleShowModel} > */}

                      <div>
                        {reduxUser.isAuthenticated ?
                          <div className="accountuser-fr account-fr-mobile">
                            {/* <img src={userimg} className="profileimg" alt="user profile icon" /> */}
                            <hr className="accountseperator" />
                            <p className="newname">Hi {userDetails?.profile.name}  <img src={dropdownicon} style={{ marginLeft: '5px' }} /></p>
                            {/* <a className="myaccountname">My Account</a> */}
                          </div>
                          :
                          <div>
                            {/* <img src={userimg} className="profileimg" alt="user profile icon" /> */}
                            <p className="locationtext-mobile">Login</p>

                          </div>
                        }
                      </div>
                    </div>
                  </div>
              }


            </div>


            {/* <p className="navtext-fr">{props.homepage?.nearestMerchantDetails?.name}1.2 km, Eta •{props.homepage.selectedMerchantServiceability?.deliveryEta}</p> */}
            {/* {getCartCount() > 0 ?
              <div>
                {window.innerWidth < 768 ?
                  <li class="right-fr-cart" style={{ display: 'auto' }}  >
                    <a class="nav-link-fr nav-b" onClick={handleCartRoute} >
                      <img src={cartimg} className="profileimg-mobile-fr" />
                      {
                        !isNaN(getCartCount()) && getCartCount() ? <div className="quanty" style={{
                          display: 'flex', alignItems: 'center', justifyContent: 'center',
                          backgroundColor: '#CE4632', right: 10, top: -5,
                          width: 20, height: 20, borderRadius: 10,
                          position: 'absolute', color: '#FFFFFF'
                        }} >
                          <text style={{ fontSize: 12, fontWeight: 'bold' }}>{getCartCount()}</text>
                        </div> : null
                      }
                    </a>
                  </li>
                  :
                  null
                }
              </div>

              :
              <Button onClick={handleOrderNow} className="orderitbtn" style={{ height: 37, width: 146, marginTop: 7, backgroundColor: '#4D3A21' }} varliant="dark">ORDER NOW</Button>

            } */}
            {/* {window.innerWidth < 768 ?
           <li class="right-fr-cart" style={{ display: 'auto'}}  >
           <a class="nav-link-fr nav-b" onClick={handleCartRoute} >
           <img src={cartimg} className="profileimg-mobile-fr" />
           {
             !isNaN(getCartCount()) && getCartCount() ? <div className="quanty" style={{
               display: 'flex', alignItems: 'center', justifyContent: 'center',
                 backgroundColor: '#CE4632', right: 10, top: -5,
               width: 20, height: 20, borderRadius: 10,
               position: 'absolute', color: '#FFFFFF'
             }} >
               <text style={{ fontSize: 12, fontWeight: 'bold' }}>{getCartCount()}</text>
             </div> : null
           }
           </a>
           </li>
           :
           null
          } */}
            {
              window.innerWidth <= 768 ?
                <div className="location-mobile">
                  <span style={{ cursor: 'pointer' }}
                    onClick={() => handleShowlocationModel(true)}
                    class="nav-address-select">
                    <img src={locationmark} />
                    {/* <a className="locationicon" href="#">
                              <img src={dropdownicon} style={{ marginLeft: '5px' }} />
                            </a> */}
                  </span>
                  <p className="locationtext-fr-new" >{!!locationText ? locationText : loader}</p>
                </div>
                :
                null
            }
            {
              window.innerWidth <= 768 ?
                <div className="ordernow-mobile">
                  {getCartCount() > 0 ?

                    <Button onClick={handleCartRoute} className="orderitbtn orderitbtn-fr" style={{
                      height: 37,
                      width: 146, marginTop: 7, backgroundColor: '#4D3A21'
                    }}
                      varliant="dark">VIEW CART({getCartCount()})</Button>
                    :
                    <Button onClick={handleOrderNow} className="orderitbtn" style={{ height: 37, width: 146, marginTop: 7, backgroundColor: '#4D3A21' }} varliant="dark">ORDER NOW</Button>
                  }
                </div>
                :
                null
            }
          </nav>
        </div>
      </div>

    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    homepage: state.homepage,
    allCatalogues: state.homepage.allCatalogues,
    cartItems: state.cart.items,
  }
};

export default connect(mapStateToProps, { requestExactAddressByReverseGeocoding })(Header);