import React, { useEffect, useState } from 'react';
import { Button as ButtonMain } from 'react-bootstrap/';
import { useDispatch, useSelector } from 'react-redux';
import { addCart } from '../../../reducers/cart';

export default function AddAddonButton(props) {
  const { addons, choiceList, item, totalAmount, handleAlert } = props || {}

    const dispatch = useDispatch()
    const [isVerified, setisVerified] = useState([])
    // const [prompt, setprompt] = useState('')
    const reduxHomepage = useSelector(state => state.homepage)

    useEffect(() => {
        let temp = []
      addons?.map((item, i) => {
            temp.push({
                id: item.id,
                minChoices: item.minChoices,
                name: item.name,
                verified: item.minChoices === 0 ? true : false
            })
        })
        setisVerified(temp)
    }, [addons])

    const handleSubmit = () => {
        // console.log('choiceList:', choiceList)
        // console.log('addons:', addons)
        let prompt = ''
        // let choiceList = choiceList

        let abc = isVerified.map((item, i) => {
            let choiceLength = choiceList.filter((choice, j) => choice.addonId === item.id).length
            if (choiceLength >= item.minChoices) {
                return { ...item, verified: true }
            }
            else {
                prompt = 'Please select a minimum 1 choice for ' + item.name
                return item
            }
        })

        setisVerified(abc)

        if (abc.filter((val, i) => val.verified === false).length > 0) {
          handleAlert(true, prompt)
        }
        else {
            dispatch(
                addCart(
                  item.id,
                  choiceList
                ))
            props.setShow(false)
        }
    }

    return (
        <ButtonMain
            style={{ height: 40, marginTop: 5, width: '100%', backgroundColor: '#E5AB4E', borderColor: '#E5AB4E' }}
            onClick={handleSubmit}
        >
            <div style={{
                display: 'flex', flexDirection: 'row',
                alignItems: 'center', justifyContent: 'space-between',
                paddingLeft: 10, paddingRight: 10
            }}>
                <div>
                    <text>
                        TOTAL :&nbsp;&nbsp;{reduxHomepage.allCatalogues.currency}
              {(parseFloat(totalAmount) + parseFloat(item?.rate))?.toFixed(2)}</text>
                </div>
                <text>Add</text>
            </div>
        </ButtonMain >
    );
}