import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import AddonModal from '../../../../Common/AddonModal/index'
import { Plus, Dash } from '../../../../../images/icons/IconSets'
import './AddButton.css'
import { cartIncrement, cartDecrement } from '../../../../../reducers/cart'
export default function AddButton(props) {

  const dispatch = useDispatch()
  const [addonOpen, setaddonOpen] = useState(false)
  const { count, changeQuantity } = props;
  const countNum = count && !isNaN(count) ? Number(count) : 0;
  const reduxCount = useSelector(state => state.cart?.items)
  const handleAddonOpen = (param) => {
    setaddonOpen(true)
  }
  const handleCloseAddon = (param) => {
    setaddonOpen(param)
  }

  const handleChangeQty = (param) => {
    let apiArgs = { id: props.item.id, cartItemId: props.item.cartItemId, choices: props.item.choices }

    if (param === 'add')
      dispatch(cartIncrement(apiArgs))
    else if (param === 'remove')
      dispatch(cartDecrement(apiArgs))
  }

  return (

    <div>

      {addonOpen === true ?
        <AddonModal
          show={addonOpen}
          setShow={handleCloseAddon}
          {...props}
          countNum={countNum}
        /> : null}

      <div  >
        <ButtonGroup className="add-button-group" color='default' size="sm"
          style={{ borderRadius: 5 }}  >
          <Button className="add-button-group" onClick={() => handleChangeQty('remove')}>
            <Dash width={23} height={23} color='#5CB346' />
          </Button>

          <Button disabled={true}
            style={{
              backgroundColor: '#ECEEF4C4',
              color: '#5CB346', opacity: 1,
              fontWeight: 'bold',
            }}>{props.item.quantity}</Button>

          <Button className="add-button-group"
            onClick={() => handleChangeQty('add')}>
            <Plus width={23} height={23} color='#5CB346' />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
