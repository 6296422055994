import React, { Component } from 'react';
import './ourStory.css'; 

function index(props){    
    return (
    <div>
    <div className="ourStory">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="storyHeading">
              <img src="assets/left-quote.svg" />
              <h3>Love is sweet, sometimes annoying also. <br />But, you can always ignore ilaechi for biryani</h3>
              <img src="assets/right-quote.svg" />
            </div>
            <div className="storyList">
              <ul>
                <li>
                  <h4>2000</h4>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam</p>
                </li>
                <li>
                  <h4>2005</h4>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam</p>
                </li>
                <li>
                  <h4>2010</h4>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam</p>
                </li>
                <li>
                  <h4>2015</h4>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam</p>
                </li>
                <li>
                  <h4>2018</h4>
                  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam</p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-5">
            <div className="storyImg">
              <img src="assets/ourStory.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
        );
    
}

export default index;