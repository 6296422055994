import React, { useState, useEffect, createRef } from "react";
import { useSelector } from 'react-redux';
import { userDetailsServices } from '../services';
import config from '../config';
import apiClient from '../api';

export async function currentOrderModes(location, merchant) {

  let addressChunks = await userDetailsServices.getAddressChunksFromCoordinates(location)
    .then(res => res)

  const orderTypes = [
    { id: 0, orderType: 'delivery', label: 'Delivery', },
    { id: 1, orderType: 'takeAway', label: 'Takeaway' },
    { id: 2, orderType: 'dineIn', label: 'Dine In' }
  ]

  async function getData(item, rs) {
    let reqParams = {
      merchantAccessToken: config.merchantAccessToken,
      selectedOrderMode: item.orderType,
      userLatitude: location.latitude,
      userLongitude: location.longitude,
      merchantId: merchant.id,

    }
    if (!!addressChunks?.formatted_address) {
      reqParams.selectedLocation = {
        address2: addressChunks.formatted_address,
        locality: addressChunks.locality,
        city: addressChunks.administrative_area_level_2,
        state: addressChunks.administrative_area_level_1,
        country: addressChunks.country,
        pincode: addressChunks.postal_code,
      }
    }
    const response = await apiClient.post(apiClient.Urls.serviceability, reqParams);
    let selectedMerchant = response?.merchants?.filter((item) => {
      return item.id === merchant.id
    })
    let { id, serviceable, deliveryEta, unserviceableReason, slug } = selectedMerchant[0] ? selectedMerchant[0] : {}
    rs({
      id, serviceable, unserviceableReason, deliveryEta, slug,
      orderMode: item.orderType, label: item.label
    })
  }

  let requests = orderTypes.map((item) => {
    return new Promise((resolve) => {
      getData(item, resolve);
    });
  })

  return Promise.all(requests).then((val) => { return val });
}


export const useGetLocation = () => {
  const reduxUser = useSelector(state => state.user)
  let location = reduxUser.userLocation?.latitude ?
    reduxUser.userLocation :
    reduxUser.userDetails?.locationPreference
  return location
}


export const useMerchantOrderModes = () => {
  const orders = useSelector(state => state.orders)
  const merchant = useSelector(state => state.merchant)
  const { serviceability, initialOrderMode } = orders || {}

  let result = []
  let modes = { delivery: 'Delivery', takeAway: 'Takeaway', dineIn: 'Dine In', menu: 'Menu' }

  try {
    let selectedMerchant = serviceability.merchants?.find(a => a.id === merchant.merchant?.id)
    /* Makes an array as per requirement */
    result = selectedMerchant?.modesServiceable?.map((item, i) => {
      let a = {
        id: item.id,
        orderMode: item.type,
        label: modes[item.type],
        slug: selectedMerchant?.slug,
        deliveryEta: item.deliveryEta,
        serviceable: item.serviceable,
        unserviceableReason: item.unserviceableReason,
        unserviceableStatus: item.unserviceableStatus,
      }
      return (a)
    })

    /* Adds the result of response placed outside the array */
    result = result?.concat(
      {
        id: selectedMerchant?.id,
        orderMode: initialOrderMode,
        label: modes[initialOrderMode],
        slug: selectedMerchant?.slug,
        deliveryEta: selectedMerchant?.deliveryEta,
        serviceable: selectedMerchant?.serviceable,
        unserviceableReason: selectedMerchant?.unserviceableReason,
        unserviceableStatus: selectedMerchant?.unserviceableStatus,
      },
      {
        id: selectedMerchant?.id, serviceable: true, orderMode: "menu", label: 'Menu'
      }
    )
    /* Serialize the array. and make serviiceable as true on conndition */

    const orderTypes = [
      { id: 0, orderType: 'delivery', label: 'Delivery', },
      { id: 1, orderType: 'takeAway', label: 'Takeaway' },
      { id: 2, orderType: 'dineIn', label: 'Dine In' },
      { id: 3, orderType: 'menu', label: 'Menu' }

    ]

    let last = orderTypes.map((item, i) => {
      let abc = result?.find(a => a.orderMode === item.orderType)
      if (abc?.orderMode !== 'delivery' && abc?.unserviceableStatus === 'STORE_CLOSED') {
        abc.serviceable = true
      }

      return (abc)
    })

    return (last)

  } catch (error) {
    console.log({ error })
  }
}