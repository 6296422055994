import React, { Component, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import "./Trackorder.scss";
import TrackMapModal from "../Common/TrackMap/TrackMapModal";
import TrackorderTwo from "../MyDetails/Components/TrackorderTwo";
import OrderDetailsModal from '../Common/orderDetailsModal/OrderDetailsModal'

export const OrderHistory = (props) => {
    // useEffect(() => {
    //     props.requestOrderTransactionHistory();
    // }, []);
    const [viewdetails, setviewdetails] = useState(false);
    const [more, setmore] = useState(false);
    const [activeitem, setactiveitem] = useState();
  const orderStages = { placed: 1, confirmed: 2, ready: 3, dispatched: 4, taken: 4, completed: 5 }


    const handleRateOrder = () => {

    };

    const handleReorder = () => {

    };
    const handleVieworder = (param) => {

        setactiveitem(param)
        setviewdetails(true)
    }

    const handlesettrackmap = (param) => {
        setviewdetails(false)
    }
    const handleviewmore = () => {
        setmore(true)
    }

    return (
        <div>
            {viewdetails &&
                <OrderDetailsModal
                    show={viewdetails}
                    setShow={handlesettrackmap}
                    activeitem={activeitem}
                />}

            {/* {viewdetails && <TrackMapModal show={viewdetails} setShow={handlesettrackmap} activeitem={activeitem} />} */}
            <span className="tr-head-title new-order order-history">{'Order History'}</span>
            {
                props.cart.transactionHistory?.map((item, i) =>
                  orderStages[item.status] === 5 &&
                    <div className="orderBox" key={i}>

                        <div className="orderDetails">
                            <h6>{item.merchantName}</h6>
                            <div className="orderItems">
                                <div>{item.details?.orderItems?.length}  Items</div>
                                <div className="orderPrice">{item.details.total}</div>
                                <img src="assets/checked.svg" alt="" />
                                <span>{item.status}</span></div>
                            {more === false ?
                                <div>
                                    <div className="lesstext" style={{ display: 'flex', flexDirection: 'column' }}>  {
                                        item.details.orderItems?.map((orderItems, j) =>
                                            <div className="orderQty">{orderItems.name}X{orderItems.quantity},&nbsp; </div>
                                        )
                                    }</div>
                                    {item.details?.orderItems?.length > 3 ?
                                        <div style={{ marginTop: '20px' }}>
                                            <button onClick={() => handleviewmore()} className="viewbtn">View More</button>
                                        </div>
                                        :
                                        null
                                    }
                                </div>

                                :
                                <div className="more" style={{ display: 'flex', flexDirection: 'column' }}>  {
                                    item.details.orderItems?.map((orderItems, j) =>
                                        <div className="orderQty">{orderItems.name}X{orderItems.quantity},&nbsp; </div>
                                    )
                                }</div>
                            }
                            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>  {
                                item.details.orderItems?.map((orderItems, j) =>
                                    <div className="orderQty">{orderItems.name}X{orderItems.quantity},&nbsp; </div>
                                )
                            }</div> */}

                            <div>{`Order ID • ${item.orderId}, ${item.date}`}</div>
                            <button onClick={() => handleVieworder(item)} className="viewbtn">View Details</button>
                        </div>
                        {/* <div className="orderBtns">
                            <button onClick={handleReorder} className="lightBtn">reorder</button>
                            <button onClick={handleRateOrder} className="darkBtn">rate order</button>
                        </div> */}
                    </div>
                )
            }






            {/* <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <button onClick={handleviewmore} className="viewmorebtn">View More</button>
            </div> */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    homepage: state.homepage,
    userDetails: state.user.userDetails?.profile,
    user: state.user,
    cart: state.cart,
    allCatalogues: state.homepage.allCatalogues,
    cartItems: state.cart.items,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory)
