import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addCart, removeCart } from '../../../reducers/cart'
import './alertModal.css';
import {
    Popover,
    Tooltip,
    Modal
} from 'react-bootstrap';
import Button from 'react-bootstrap/Button'
import { deleteAddressAction, requestNewAddress } from '../../../actions';
import { useGetLocation } from '../../../utils/utilities';

export function AlertAddressModal(props) {
    const dispatch = useDispatch()
    const location = useGetLocation()
    const handleDeleteAddress = async () => {
        let params = {
            id: props.selectedAddress?.id, addressId: 2000
        }
        dispatch(deleteAddressAction(params, result => {

            if (result.success === true) {
                const params2 = { ...params, ...location }
                dispatch(requestNewAddress(params2))
                props.setShow(false)
            }
            else {
                props.setShow(false)
            }
        }
        ))
    }
    return (
        <div>
            <Modal show={props.show} onHide={() => { props.setShow(false) }} animation={false}>
                <Modal.Header closeButton />
                <Modal.Body>
                    <div className="modalHeading">Alert</div>
                    <p className="pointpara">{'Are you sure you want to delete this address?'}</p>
                    <div className="px-2"
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <button className="modalPrimaryBtn justify-content-center"
                            style={{ width: 150 }}
                            onClick={handleDeleteAddress}  >Delete</button>
                        <button closeButton className="modalSecondaryBtn justify-content-center px-1" onClick={() => props.setShow(false)}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
};
