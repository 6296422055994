import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MainComponent from '../Components'
import Layout from '../../Layout'
import {
  requestOrderTransactionHistory,
  requestAddNewAddress,
  requestNewAddress,
  requestUserProfile,
  requestUserUpdatedProfile
} from '../../../actions';
import {transactionDetails} from '../../../reducers/cart';

class MyDetailsContainer extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    document.getElementById("splashspinner").style.display = "none";

    this.props.requestUserProfile();
    this.props.requestUserUpdatedProfile();
    this.props. requestOrderTransactionHistory();
    // this.props.transactionDetails();
    this.props.requestNewAddress({ addressId: 2000 });

  }

  render() {
    return (
      <div>
        <Layout>
          <MainComponent userDetails={this.props.userDetails} transactionDetails={this.props.transactionDetails}  updatedDetails={this.props.updatedDetails}  {...this.props} />
        </Layout>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  orderHistory: state.user.orderTransactionHistory,
  userDetails: state.user.userDetails?.profile,
  updatedDetails:state.user.updatedDetails?.profile,
  transactionDetails:state.cart?.transactionDetails
});

MyDetailsContainer.propTypes = {

};

export default connect(mapStateToProps, {
  requestOrderTransactionHistory,
  requestAddNewAddress,
  requestNewAddress,
  requestUserProfile,
  requestUserUpdatedProfile,
  transactionDetails
})(MyDetailsContainer);