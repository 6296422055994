import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './Tip.css';
import { useDispatch, useSelector } from 'react-redux';
import { saveInfo, selectAddress } from '../../../../../reducers/checkout'
import { getCart } from "../../../../../reducers/cart";
import { setTemporaryInputFields } from '../../../../../actions';
import imgremove from '../../../../../images/error_cross.svg'
import Spinner from 'react-bootstrap/Spinner'
import { DeleteCircle } from '../../../../../images/icons/IconSets';

function Tip(props) {
  const dispatch = useDispatch()
  const reduxCart = useSelector(state => state.cart)
  const reduxMerchantId = useSelector(state => state.merchant.merchant)
  const reduxCheckout = useSelector(state => state.checkout)
  const temporaryInputFields = useSelector(state => state.user.temporaryInputFields)
  const [errors, seterrors] = useState('')
  const [isCustom, setisCustom] = useState(false)
  const [amount, setamount] = useState('')
  const [inputamount, setinputamount] = useState('')
  const [isAddClicked, setisAddClicked] = useState(false)
  const [loading, setloading] = useState({})
  const { cartTipConfig } = reduxCart?.cartConfig || {}

  const rawData = ['10', '20', '30', '40']

  const handleSelectAmount = async (param, index) => {
    seterrors('')
    setinputamount('')
    if (parseInt(param || 0) === parseInt(reduxCart?.tipRate || 0) && reduxCart?.tipType === 'percent' && reduxCart?.tip !== '0.00') {
      // TO REMOVE ADDED TIP

      setloading({ index: index, loading: true })

      dispatch(setTemporaryInputFields({
        tip: 0, custom: false, tipType: cartTipConfig?.tipType,
      }))

      dispatch(saveInfo({
        merchantId: reduxMerchantId.id,
        addressId: reduxCheckout?.selectedDeliveryAddressId || '',
        tip: 0,
        tipType: cartTipConfig?.tipType,
        custom: false,
      }, result => {
        if (result.success) {
          dispatch(getCart())
          // setamount('')
          setinputamount('')
          setloading({ index: index, loading: false })
        }
      }))
    }
    else {
      // TO ADD TIP
      setloading({ index: index, loading: true })

      dispatch(setTemporaryInputFields({
        tip: param, custom: false, tipType: cartTipConfig?.tipType,
      }))

      dispatch(saveInfo({
        merchantId: reduxMerchantId.id,
        addressId: reduxCheckout?.selectedDeliveryAddressId || '',
        tip: param,
        tipType: cartTipConfig?.tipType,
        custom: false,
      }, result => {
        if (result.success) {
          dispatch(getCart())

          setloading({ index: index, loading: false })
        }
      }))
      setisCustom(false)
      setamount(param)
    }
  }

  const handleAddRemove = () => {
    if (isAddClicked === false) {
      if (inputamount > 0) {
        //TO ADD TIP

        dispatch(setTemporaryInputFields({ tip: inputamount, custom: true, tipType: 'fixed' }))

        dispatch(saveInfo({
          merchantId: reduxMerchantId.id,
          addressId: reduxCheckout?.selectedDeliveryAddressId || '',
          tip: inputamount,
          custom: true,
          tipType: 'fixed'
        }, result => {
          if (result.success) {
            dispatch(getCart())
            setisAddClicked(true)
          }
        }))
      }
      else if (inputamount < 0) {
        seterrors('*Amount should not be a negative value.')
      }
      else {
        seterrors('*Please select correct amount.')
      }
    }
    else {
      seterrors('')
      // TO REMOVE TIP

      dispatch(setTemporaryInputFields({ tip: 0, custom: true, tipType: 'fixed' }))

      dispatch(saveInfo({
        merchantId: reduxMerchantId.id,
        addressId: reduxCheckout?.selectedDeliveryAddressId || '',
        tip: 0,
        custom: true,
        tipType: 'fixed'
      }, result => {
        if (result.success) {
          dispatch(getCart())
          setinputamount('')
          setisAddClicked(false)
        }
      }))
    }
  }

  const handleOnChangeText = (e) => {
    seterrors('')
    setisAddClicked(false)
    const { name, value } = e.currentTarget;
    setinputamount(parseInt(value))
  }

  const handleOther = () => {
    let isFromOptions = (!!cartTipConfig.tipValues ? cartTipConfig.tipValues :
      rawData).find(a => a === amount)
    if (isFromOptions || !!amount === false || parseInt(amount) === 0) {
      setisCustom(!isCustom)
      setisAddClicked(false)
    }
  }

  useEffect(() => {
    if (reduxCart?.tipType === 'fixed') {
      setisCustom(true)
      setinputamount(reduxCart.tip)
    }
  }, [reduxCart?.tipType])


  return (
    <div style={{
      display: 'flex', flexDirection: 'column', alignItems: 'center',
      marginTop: 10, marginBottom: 10, width: '-webkit-fill-available'
    }}>
      <div className="tip-heading">
        Add a tip (Optional):
      </div>

      <div style={{
        display: 'flex', flexDirection: 'row',
        alignItems: 'center', justifyContent: 'space-between',
        flexWrap: 'wrap', width: '100%'
      }}
      >
        {(!!cartTipConfig.tipValues ? cartTipConfig.tipValues :
          rawData).map((item, i) => (

            <div
              key={i}
              style={{
                display: 'grid', minWidth: 60,
                height: 35, borderWidth: 1, borderRadius: 6,
                alignItems: 'center', justifyContent: 'center',
                marginBottom: 10,
                backgroundColor:
                  (parseInt(item) === parseInt(reduxCart?.tipRate) && reduxCart?.tipType === 'percent' && reduxCart?.tip !== '0.00')
                    ? '#C4703E' : '#fff',
                color:
                  (parseInt(item) === parseInt(reduxCart?.tipRate) && reduxCart?.tipType === 'percent' && reduxCart?.tip !== '0.00')
                    ? '#fff' : '#41434A',
                cursor: 'pointer',
                boxShadow: '0px 0px 5px -2px rgba(0,0,0,0.5)'
              }}
              onClick={() => handleSelectAmount(item, i)}
            >
              {
                (loading.index === i && loading.loading === true) ?
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    color="#41434A"
                  />
                  :

                  <div style={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center', justifyContent: 'space-between',
                  }}>
                    <div style={{ fontSize: 14, marginRight: 5 }} >
                      {cartTipConfig.tipType === 'percent'
                        ?
                        <text >{item}%</text>
                        :
                        <text >{reduxCart?.currencySymbol}{item}</text>
                      }
                    </div>
                    {
                      (parseInt(item) === parseInt(reduxCart?.tipRate) && reduxCart?.tipType === 'percent' && reduxCart?.tip !== '0.00')
                        ?
                        <DeleteCircle color='#fff' height={13} width={13} />
                        : null
                    }
                  </div>
              }

            </div>
          ))
        }

        <div
          style={{
            display: 'flex', width: 'max-content', paddingInline: 15,
            height: 35, borderWidth: 1, borderRadius: 6,
            alignItems: 'center', justifyContent: 'center', marginLeft: 10,
            marginBottom: 10,
            cursor: 'pointer',
            boxShadow: '0px 0px 5px -2px rgba(0,0,0,0.5)'
          }}
          onClick={() => handleOther()}
        >
          Other
        </div>
      </div>
      {
        isCustom ?
          <div className='tip-custom-div'>
            <div className='tip-input'  >
              <div>
                {reduxCart?.currencySymbol}
              </div>
              <input
                style={{
                  background: '#F0F1F2 0% 0% no-repeat padding-box'
                }}
                name='amount'
                type='number'
                defaultValue="₹500"
                value={inputamount}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => handleOnChangeText(e)}
              />
            </div>
            <div
              style={{
                display: 'flex', width: 'max-content', paddingInline: 15,
                borderWidth: 1, borderRadius: 6,
                alignItems: 'center', justifyContent: 'center', marginLeft: 10,
                cursor: 'pointer', boxShadow: '0px 0px 5px -2px rgba(0,0,0,0.5)'
              }}
              onClick={() => handleAddRemove()}
            >
              {isAddClicked === false ? 'Add' : 'Remove'}
            </div>

          </div>
          :
          null
      }
      {!!errors.length === true
        ?
        <text className='tip-warning-label'>
          {errors}
        </text>
        :
        null
      }

    </div>
  )
}

Tip.propTypes = {

}

export default Tip

