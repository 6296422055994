import 'font-awesome/css/font-awesome.min.css';
import React, { createRef, Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { LeftArrowShort, RightArrowShort } from '../../images/icons/IconSets';
import { ExploreMenuCardLoader } from '../Common/contentLoaders/ContentLoader';
import ExplorerMenuCard from './ExplorerMenuCard';
import Sticky from 'react-stickynode';
import './menu.css';

function ExploreMenu(props) {
  const { cartItems, orders } = props;

  const myRef = createRef();
  const submyRef = createRef();
  const measuredRef = useRef();
  const [leftBtnHidden, setleftBtnHidden] = useState(true)
  const [rightBtnHidden, setrightBtnHidden] = useState(false)
  const [subleftBtnHidden, setsubleftBtnHidden] = useState(true)
  const [subrightBtnHidden, setsubrightBtnHidden] = useState(false)
  const [current, setCurrent] = useState(0);
  const [subCatcurrent, setsubCatCurrent] = useState(0);
  const [allCatalogues, setallCatalogues] = useState()
  const reduxUser = useSelector(state => state.user)
  const reduxHomepage = useSelector(state => state.homepage)

  useEffect(() => {
    setallCatalogues(reduxHomepage.allCatalogues)
  }, [reduxHomepage])


  const scroll = (scrollOffset) => {
    myRef.current.scrollLeft += scrollOffset;
  };

  const scrollSub = (scrollOffset) => {
    submyRef.current.scrollLeft += scrollOffset;
  };

  const handleScroll = (e) => {
    const rightend = e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;
    if (rightend) {
      setrightBtnHidden(true)

    } else setrightBtnHidden(false)

    if (e.target.scrollLeft === 0) {
      setleftBtnHidden(true)
    }
    else setleftBtnHidden(false)
  }

  const handleSubScroll = (e) => {
    const rightend = e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;
    if (rightend) {
      setsubrightBtnHidden(true)

    } else setsubrightBtnHidden(false)

    if (e.target.scrollLeft === 0) {
      setsubleftBtnHidden(true)
    }
    else setsubleftBtnHidden(false)
  }

  return (
    <Fragment>
      {
        !!(allCatalogues !== undefined && allCatalogues !== null) || allCatalogues?.catagories ?
          <div ref={measuredRef} style={{ padding: 15 }}>

            {/* ALL CATAGORY TABS */}
            <Sticky enabled={true}>
              <div className='sticky-menus' style={{ backgroundColor: '#FAFBFC', zIndex: 5 }}>
                <div className={"exploreMenu"}
                  style={{
                    // paddingBottom: 15,
                    // borderBottom: "1.5px solid #E7EAF0",

                  }} >
                  <h2 style={{}}>Explore Menu</h2>
                  <div hidden={leftBtnHidden}
                    style={{
                      display: 'flex', float: 'left', position: 'absolute',
                      zIndex: 2, width: 65,
                      background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.25) 100%)',

                      color: '#FFFFFF', marginTop: 30
                    }}
                    onClick={() => scroll(-250)}>
                    <div style={{ marginRight: 'auto', }}>
                      <LeftArrowShort color='#707070' height={25} width={25} />
                    </div>
                  </div>

                  <div className='rec-root' ref={myRef} onScroll={handleScroll}>
                    {allCatalogues.categories?.map((category, index) => (
                      <a
                        key={index}
                        className='rec-maincard active text-capitalize'
                        style={
                          index === current ?
                            {
                              color: "#2C2C2C",
                              textDecoration: "none",
                              fontWeight: 'bold',
                              borderBottom: "3px solid #E5AB4E"
                            } :
                            {
                              opacity: 0.6,
                              color: "#2C2C2C",
                              textDecoration: "none",
                              fontWeight: 'normal'
                            }
                        }

                        onClick={() => { setCurrent(index); setsubCatCurrent(0) }}
                        href={`#${category.id}`}
                      >
                        <pre
                          style={{
                            font: 'normal normal 16px/19px Lato',
                            marginBottom: 0, marginLeft: 5, marginRight: 5
                          }}>
                          {category.name.replace(/^\s+|\s+$/g, "")}
                        </pre>
                      </a>
                    ))}
                  </div>
                  <div hidden={rightBtnHidden}
                    style={{
                      display: 'flex', float: 'right', right: 0, position: 'absolute', zIndex: 2, width: 65,
                      background: 'linear-gradient(90deg, rgba(255,255,255,0.25) 100%, rgba(255,255,255,1) 0%)',
                      color: '#FFFFFF', marginTop: 30
                    }}
                    onClick={() => scroll(+250)}>
                    <div style={{ marginLeft: 'auto', right: 0 }}>
                      <RightArrowShort color='#707070' height={25} width={25} />
                    </div>
                  </div>
                </div>

                {/* SUB-CATAGORY TABS */}
                {
                  <div className={"exploreMenu"}
                    style={{
                      paddingBottom: 10,

                    }} >
                    <div hidden={subleftBtnHidden}
                      style={{ display: 'flex', float: 'left', position: 'absolute', zIndex: 2, width: 65, background: 'linear-gradient(to left, transparent 0%, #FAFBFC 75%)', color: '#FFFFFF' }}
                      onClick={() => scrollSub(-250)}>
                      <div style={{ marginRight: 'auto', }}>
                        <LeftArrowShort color='#707070' height={25} width={25} />
                      </div>
                    </div>

                    <div className='rec-root' ref={submyRef} onScroll={handleSubScroll}>
                      {allCatalogues?.categories[current]?.categories.map((category, index) => (
                        <a
                          key={index}
                          className='rec-maincard active text-capitalize'
                          style={
                            index === subCatcurrent ?
                              {
                                color: "#2C2C2C",
                                textDecoration: "none",
                                fontWeight: 'bold',
                                borderBottom: "3px solid #E5AB4E"
                              } :
                              {
                                opacity: 0.6,
                                color: "#2C2C2C",
                                textDecoration: "none",
                                fontWeight: 'normal'
                              }
                          }
                          onClick={() => { setsubCatCurrent(index) }}
                          href={`#${category.id}`}
                        >
                          <pre style={{
                            font: 'normal normal 16px/19px Lato',
                            marginBottom: 0, marginLeft: 5, marginRight: 5
                          }}>
                            {category.name.replace(/^\s+|\s+$/g, "")}
                          </pre>
                        </a>
                      ))}
                    </div>
                    <div hidden={subrightBtnHidden}
                      style={{
                        display: 'flex', float: 'right', right: 0, position: 'absolute', zIndex: 2, width: 65,
                        background: 'linear-gradient(to right, transparent 0%, #FAFBFC 75%)', color: '#FFFFFF'
                      }}
                      onClick={() => scrollSub(+250)}>
                      <div style={{ marginLeft: 'auto', right: 0 }}>
                        <RightArrowShort color='#707070' height={25} width={25} />
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Sticky>

            {/* ITEMS LIST IN MASONRY */}
            {reduxUser.isVeg === true && !!(allCatalogues !== undefined && allCatalogues !== null) || allCatalogues?.catagories

              ?

              allCatalogues.categories[current]?.categories.length !== 0
                ?
                <div class="menuanimation menuanimation-fr" >
                  {allCatalogues.categories[current]?.categories[subCatcurrent]?.items.filter(item => item.type === 'veg').map((item, index) => (
                    <ExplorerMenuCard
                      key={index}
                      item={item}
                      cartItem={cartItems && cartItems.find(o => o.id === item.id)}
                      orderMode={orders?.orderMode}
                      currencySymbol={allCatalogues.currency}
                    />
                  ))}
                </div>
                :
                <div class="menuanimation menuanimation-fr">
                  {allCatalogues.categories[current]?.items
                    .map((item, index) => (
                      <ExplorerMenuCard
                        key={index}
                        item={item}
                        cartItem={cartItems && cartItems.find(o => o.itemid === item.id)}
                        orderMode={orders?.orderMode}
                        currencySymbol={allCatalogues.currency}
                      />
                    ))}
                </div>
              :

              !!allCatalogues.categories && allCatalogues.categories[current]?.categories.length !== 0
                ?
                <div class="menuanimation menuanimation-fr">
                  {allCatalogues.categories[current]?.categories[subCatcurrent]?.items.map((item, index) => (
                    <ExplorerMenuCard
                      key={index}
                      item={item}
                      cartItem={cartItems && cartItems.find(o => o.id === item.id)}
                      orderMode={orders?.orderMode}
                      currencySymbol={allCatalogues.currency}
                    />
                  ))}
                </div>
                :
                <div class="menuanimation menuanimation-fr" >
                  {allCatalogues.categories[current]?.items
                    .map((item, index) => (
                      <ExplorerMenuCard
                        key={index}
                        item={item}
                        cartItem={cartItems && cartItems.find(o => o.itemid === item.id)}
                        orderMode={orders?.orderMode}
                        currencySymbol={allCatalogues.currency}
                      />
                    ))}
                </div>
            }

          </div>
          :
          <div className="exploreMenu" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }} >
            {[0, 1, 2].map((v, i) =>
              ExploreMenuCardLoader)}
          </div>
      }
    </Fragment>
  );
}

export default ExploreMenu;