import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addCart, removeCart } from '../../../reducers/cart'
import './alertModal.css';
import {
  Popover,
  Tooltip,
  Modal
} from 'react-bootstrap';
import { clearCart } from '../../../reducers/cart';
import Button from 'react-bootstrap/Button'


export const AlertModal = (props) => {
  const dispatch = useDispatch()
  const reduxCart = useSelector(state => state.cart)
  const reduxUser = useSelector(state => state.user?.temporaryInputFields)

  const handleClearCart = () => {
    dispatch(clearCart(reduxCart.cartId))
    setTimeout(() => {
      dispatch(addCart(reduxUser?.id, null, null))
      props.setShow(false)
    }, 1000);
  }
  return (
    <div>
          <Modal show={props.show} onHide={() => { props.setShow(false) }} animation={false}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="modalHeading">Alert</div>
          <p className="pointpara">{reduxCart.cartAlert?.message}</p>
          <div className="px-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <button className="modalPrimaryBtn justify-content-center px-1" onClick={handleClearCart}  >Clear Cart</button>
            <button closeButton className="modalSecondaryBtn justify-content-center px-1" onClick={() => props.setShow(false)}>Cancel</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
};