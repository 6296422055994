import { setUserLocation } from '.';
import { getHomepageService, getUserService, freebiesServices, userDetailsServices, userOrderServices } from '../services';


export function requestExactAddressByReverseGeocoding(options) {
  console.log('requestExactAddressByReverseGeocoding')
  const loading = () => ({ type: REQUEST_EXACT_ADDRESS });
  const success = payload => ({ type: RESPONSE_EXACT_ADDRESS_SUCCESS, payload });

  return async (dispatch) => {
    dispatch(loading());
    const response = await getUserService.getExactAddressByReverseGeocoding(options);
    if (response) {
      dispatch(success(response));
    }
  };
}

/* REGISTRATION STEP 1 */
export function requestRegister(options) {
  const register = () => ({ type: REQUEST_REGISTER });
  const registerConfirm = payload => ({ type: RESPONSE_REGISTER_ONE, payload });
  return async (dispatch) => {
    dispatch(register());
    const response = await getUserService.register(options);
    dispatch(registerConfirm(response));
  }
}

/* REGISTRATION STEP 2 */
export function requestVerifyOtp(options, callBack) {
  const verfiyOtp = () => ({ type: VERIFY_OTP_PROGRESS });
  const otpVerify = payload => ({ type: RESPONSE_REGISTER_TWO, payload });

  return async (dispatch) => {
    dispatch(verfiyOtp());
    const response = await getUserService.verifyOtp(options);
    dispatch(otpVerify(response));
    await callBack(response)
  }
}

/* REGISTRATION STEP 3 */
export function requestRegisterConfirm(options, callback) {
  const registerConfirmBuffer = () => ({ type: REGISTER_CONFIRM_BUFFER });
  const responseregisterConfirm = payload => ({ type: RESPONSE_REGISTER_THREE, payload });

  return async (dispatch) => {
    dispatch(registerConfirmBuffer())
    const response = await getUserService.registerConfirm(options);
    dispatch(responseregisterConfirm(response));
    await localStorage.setItem("auth_token", response.authToken)
    await callback(response)
    window.fbq('track', 'CompleteRegistration');
    // if (response.success) {
    //   dispatch(responseregisterConfirm(response));
    // } else {
    //   dispatch(responseregisterConfirm(response));
    // }
  }
}

/* LOGIN STEP 1 */

export function requestLogin(options, phone, callBack) {
  const loginProgress = (phone) => ({ type: LOGIN_PROGRESS, mobileNumber: phone });
  const loginResponse = payload => ({ type: RESPONSE_LOGIN, payload });
  return async (dispatch) => {
    dispatch(loginProgress(phone))
    const response = await getUserService.login(options);
    dispatch(loginResponse(response));
    await callBack(response)
  }
}

/* LOGIN STEP 2 */
export function requestValidateOtp(options, callBack) {
  const otpProgress = () => ({ type: VERIFY_OTP_PROGRESS });
  const otpValidateSuccess = payload => ({ type: RESPONSE_OTP_VALIDATE, payload });

  return async (dispatch) => {
    dispatch(otpProgress());
    const response = await getUserService.validateOtp(options);
    dispatch(otpValidateSuccess(response));
    await localStorage.setItem("auth_token", response.authToken)
    await callBack(response)
  }
}

/* LOGOUT */
export function requestLogout(options) {
  const logoutProgress = payload => ({ type: LOGOUT_PROGRESS, payload });
  const logoutResponse = payload => ({ type: RESPONSE_LOGOUT, payload });
  return async (dispatch) => {
    dispatch(logoutProgress({ logoutProgress: true }))
    const response = await getUserService.logoutConfirm(options);
    dispatch(logoutResponse(response));
  }
};

export function requestUniqueDeviceId(options, callBack) {
  const initDevice = payload => ({ type: REQUEST_INIT_DEVICE, payload });
  return async (dispatch) => {
    const response = await getHomepageService.deviceInit(options);
    if (response.success) {
      dispatch(initDevice({ ...response, ...options }));
      await callBack(response)
    }
  };
};

export function requestAllCatalogues(options) {
  const allCatalogues = payload => ({ type: REQUEST_ALL_CATALOGUES, payload });
  const activateContentLoader = payload => ({ type: ACTIVATE_CONTENT_LOADER, payload });

  return async (dispatch) => {
    dispatch(activateContentLoader({ isLoaderActive: true }))
    const response = await getHomepageService.getAllCatalogues(options);
    if (response?.success) {
      dispatch(allCatalogues(response));
    }
    dispatch(activateContentLoader({ isLoaderActive: true }))
  }
}
export function requestAvailableModes(options) {
  try {
    const availableModes = payload => ({ type: REQUEST_AVAILABLE_MODES, payload });
    return async (dispatch) => {
      const response = await getHomepageService.getAvailableOrderModes(options);
      if (response?.success) {
        dispatch(availableModes(response));
      }
    }
  } catch (error) {
    console.log(error)
  }

}

export function requestAllFreebies(options) {
  const request = payload => ({ type: REQUEST_ALL_FREEBIES, payload });
  const requestFailed = payload => ({ type: REQUEST_ALL_FREEBIES_FAILED, payload });
  return async (dispatch) => {
    const response = await freebiesServices.getAllFreebies(options);
    if (response.success) {
      dispatch(request(response));
    } else {
      dispatch(requestFailed(response));
    }
  }
}

export function requestOrderTransactionHistory() {
  const request = payload => ({ type: REQUEST_ORDER_TRANSACTION_HISTORY, payload });
  return async (dispatch) => {
    const response = await userDetailsServices.getOrderTransactionHistory();

    if (response.success) {
      dispatch(request(response.data));
    }
  }
}

export function requestAddNewAddress(options, callback) {
  const request = payload => ({ type: REQUEST_ADD_NEW_ADDRESS, payload });
  return async (dispatch) => {
    const response = await userDetailsServices.addNewAdress(options);
    dispatch(request(response));
    callback(response);
  }
}

export function deleteAddressAction(options, callback) {
  return async (dispatch) => {
    await userDetailsServices.deleteAddress(options).then(res => {
      callback(res)
      // if (res.success) {
      //     requestNewAddress(options)
      // }
    }
    )
  }
}

export function requestNewAddress(options) {
  const request = payload => ({ type: REQUEST_NEW_ADDRESS, payload });
  return async (dispatch) => {
    const response = await userDetailsServices.getNewAddress(options);
    if (response.success) {
      dispatch(request(response));
    }
  }
}

export function requestUserProfile() {
  const request = payload => ({ type: REQUEST_USER_PROFILE, payload });
  return async (dispatch) => {
    const response = await userDetailsServices.getUserProfile();
    if (response.success) {
      dispatch(request(response));
    }
  }
}

export function requestUserUpdatedProfile(options) {
  const request = payload => ({ type: REQUEST_USER_UPDATED_PROFILE, payload });
  return async (dispatch) => {
    const response = await userDetailsServices.getUserUpatedProfile(options);
    if (response.success) {
      dispatch(request(response));
    }
  }
}



export function requestUserLocationPreference(params) {
  try {
    const request = payload => ({ type: REQUEST_USER_LOCATION_PREFERENCE, payload });
    return async (dispatch) => {
      const response = await userDetailsServices.getUserLocationPreference(params);
      if (response?.success) {
        dispatch(request(response));
      }
      else {
        dispatch(request(response));
      }

    }
  } catch (error) {

    console.log(error)
    if (navigator.geolocation) {
      // window.fbq('track', 'FindLocation');
      setgeolocationAllowed(true)
      navigator.geolocation.getCurrentPosition(async (position) => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        // setcurrentCoordinates(location)
        // setcurrentLocation(await getAddressFromCoordinates(location))
        dispatch(setUserLocation(location));
        // dispatch(requestAvailableModes(location))
      });
    }
  }

}


export function requestOrderUserAddress(params) {
  const request = payload => ({ type: REQUEST_ORDER_USER_ADDRESS, payload });
  return async (dispatch) => {
    const response = await userOrderServices.getOrderUserAddress(params);
    if (response.success) {
      dispatch(request(response));
    }
  }
}

export function requestOrderTimeSlots(params) {
  const request = payload => ({ type: REQUEST_ORDER_TIME_SLOTS, payload });
  return async (dispatch) => {
    const response = await userOrderServices.getOrderTimeSlots(params);
    if (response.success) {
      dispatch(request(response));
    }
  }
}

export function requestAddressChunksfromCoordinates(params, callBack) {
  console.log('requestAddressChunksfromCoordinates')
  const request = payload => ({ type: ADDRESS_CHUNKS_FROM_COORDINATES, payload });
  return async (dispatch) => {
    const response = params && (await userDetailsServices.getAddressChunksFromCoordinates(params));
    if (!!response) {
      dispatch(request(response));
    }
    callBack(response)
  };
}

export const REQUEST_AVAILABLE_MODES = 'REQUEST_AVAILABLE_MODES';
export const REQUEST_ALL_CATALOGUES = 'REQUEST_ALL_CATALOGUES';
export const REQUEST_INIT_DEVICE = 'REQUEST_INIT_DEVICE';
export const LOGIN_PROGRESS = 'LOGIN_PROGRESS';
export const VERIFY_OTP_PROGRESS = 'VERIFY_OTP_PROGRESS';
export const REQUEST_REGISTER = 'REQUEST_REGISTER';
export const REGISTER_CONFIRM_BUFFER = 'REGISTER_CONFIRM_BUFFER';
export const RESPONSE_LOGIN = 'RESPONSE_LOGIN';
export const RESPONSE_OTP_VALIDATE = 'RESPONSE_OTP_VALIDATE';
export const RESPONSE_REGISTER_ONE = 'RESPONSE_REGISTER_ONE';
export const RESPONSE_REGISTER_TWO = 'RESPONSE_REGISTER_TWO';
export const RESPONSE_REGISTER_THREE = 'RESPONSE_REGISTER_THREE';
export const RESPONSE_OTP_VERIFY_FAILED = 'RESPONSE_OTP_VERIFY_FAILED';
export const REQUEST_EXACT_ADDRESS = 'REQUEST_EXACT_ADDRESS';
export const RESPONSE_EXACT_ADDRESS_SUCCESS = 'RESPONSE_EXACT_ADDRESS_SUCCESS';
export const RESPONSE_REGISTER_FAILED = 'RESPONSE_REGISTER_FAILED';
export const REQUEST_ALL_FREEBIES = 'REQUEST_ALL_FREEBIES';
export const REQUEST_ALL_FREEBIES_FAILED = 'REQUEST_ALL_FREEBIES_FAILED';
export const REQUEST_ORDER_TRANSACTION_HISTORY = 'REQUEST_ORDER_TRANSACTION_HISTORY';
export const REQUEST_ADD_NEW_ADDRESS = 'REQUEST_ADD_NEW_ADDRESS';
export const REQUEST_NEW_ADDRESS = 'REQUEST_NEW_ADDRESS';
export const REQUEST_USER_PROFILE = 'REQUEST_USER_PROFILE';
export const REQUEST_USER_UPDATED_PROFILE = 'REQUEST_USER_UPDATED_PROFILE';
export const RESPONSE_LOGIN_FAILED = 'RESPONSE_LOGIN_FAILED';

export const REQUEST_USER_LOCATION_PREFERENCE = 'REQUEST_USER_LOCATION_PREFERENCE';
export const REQUEST_ORDER_USER_ADDRESS = 'REQUEST_ORDER_USER_ADDRESS';
export const REQUEST_ORDER_TIME_SLOTS = 'REQUEST_ORDER_TIME_SLOTS';
export const ACTIVATE_CONTENT_LOADER = 'ACTIVATE_CONTENT_LOADER';
export const LOGOUT_PROGRESS = 'LOGOUT_PROGRESS';
export const RESPONSE_LOGOUT = 'RESPONSE_LOGOUT';

export const ADDRESS_CHUNKS_FROM_COORDINATES = 'ADDRESS_CHUNKS_FROM_COORDINATES';

