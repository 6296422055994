import React from 'react';
import './Tooltip.css';
function Tooltip(props) {
  const { cartData } = props || {}
  return (
    <div style={{
      display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
      boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05)',
      backgroundColor: ' #fff',
      borderRadius: 6,
      opacity: 1,
      padding: 15,
    }}>
      <text style={{ marginBottom: 5 }}>Taxes and Charges</text>

      {cartData?.cartPackagingCharge ?
        <div style={{ display: 'flex', justifyContent: 'space-between', width: 200, marginTop: 5 }}>
          <text className='tt-name'>Restaurant Packaging</text>
          <text className='tt-name'>{cartData?.currencySymbol}{cartData?.cartPackagingCharge}</text>
        </div>
        :
        null
      }

      {!!cartData?.cartTaxes ?
        cartData?.cartTaxes.map((item, i) =>
          <div
            key={i}
            style={{
              display: 'flex',
              justifyContent: 'space-between', width: 200
            }}>
            <text className='tt-name'>{item.taxName}</text>
            <text className='tt-name'>{cartData?.currencySymbol}{item.taxValue}</text>
          </div>
        )
        : null
      }
    </div>
  )
}

export default Tooltip