import React from 'react';
import regularimg from '../../../images/Regular-M.svg';
import './HomepageNew.css';
function OrdermodeSelection(props) {
  let ordermodetemp = [{ "label": "Delivery", "slug": "delivery", "description": " Enjoy our delicacies in the comfort of your home.\r\n", "icon": "https:\/\/media-cdn.froogal.ai\/media\/websites\/182\/images\/uploads\/1613805367yQSL.svg" }, { "label": "Takeaway", "slug": "takeAway", "description": "Order and collect at the restaurant.", "icon": "https:\/\/media-cdn.froogal.ai\/media\/websites\/182\/images\/uploads\/1613805376NKYf.svg" }, { "label": "Dine In", "slug": "dineIn", "description": "Find your nearest Biryani's and more..!", "icon": "https:\/\/media-cdn.froogal.ai\/media\/websites\/182\/images\/uploads\/1613805382vl7A.svg" }, { "label": "Menu", "slug": "menu", "description": "What we offer..!!", "icon": "https:\/\/media-cdn.froogal.ai\/media\/websites\/182\/images\/uploads\/1613805387NOhp.svg" }]

  const { handleShowlocationModel, ordermodeButtons } = props || {}
  return (
    <div className="order-body-fr"  >
      <div
        style={{
          padding: 15, width: '100%', height: '100%', borderRadius: 6,
          background: 'linear-gradient(to left, transparent 35%, rgba(62, 51, 29, 0.95) 70%)'
        }}>

        <div style={{ paddingTop: 10, paddingLeft: 10 }}>
          <h3 className="orderheader">Biryanis and More<span className="suborderheader">&nbsp;Online Ordering</span></h3>
          <p className="orderptag">Prepared fresh and delivered fast!</p>
        </div>

        <div className="ordermodeContainer"  >
          {(!!ordermodeButtons ? ordermodeButtons : ordermodetemp)?.map((item, i) => (
            <div key={i} className="ordermodebox"
              style={{ display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer' }}
              onClick={() => handleShowlocationModel(i)}>
              <div>
                <img src={item.icon} height="40px"
                  style={{ color: "#553a28", marginTop: -10, marginRight: -5 }} />
                <strong className="ordertitle">{item.label}</strong>
              </div>

              <p className="ordertext">{item.description}</p>
              <img src={regularimg}
                style={{
                  alignSelf: 'center', position: 'absolute', right: 11,
                  marginTop: 'auto', marginBottom: 'auto', textliAgn: 'center',
                  top: 0, bottom: 0
                }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}


export default OrdermodeSelection