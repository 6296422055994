import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCartFreebiesAction } from '../../../reducers/cart';
import { applyFreebieAction } from '../../../reducers/freebie';
import Snackbar from '../alertSnackbar/snackbar';
import AlertToast from '../alertToast';
import './freebiesModel.css';

const FreebiesModal = ({ show, setShow, freebies }) => {
  const dispatch = useDispatch()
  const snackbarRef = useRef();

  const freebie = useSelector(state => state.freebie)
  const cart = useSelector(state => state.cart)
  const [alertToastValues, setAlertToastValues] = useState({});


  const { active, stalled } = freebie.freebies || {}
  const {
    cartId,
    currencySymbol,
    cartDiscountType,
    cartDiscountValue,
    cartDiscount,
    discounts,
    cartCoupons,
    cartPoints,
    cartFreebies,
  } = cart || {}


  useEffect(() => {
    dispatch(getCartFreebiesAction())
  }, [])


  const _showSnackbarHandler = (data) => {
    snackbarRef.current.openSnackBar(data);
  }

  const handleApplyNow = (param) => {
    dispatch(applyFreebieAction(param, result => {
      if (result.success === true) {
        let data = {
          title: 'Freebie  is added successfully.',
          type: 'success',
          description: null
        }
        _showSnackbarHandler(data)
        setTimeout(() => {
          setShow(false)
        }, 1000);
      }
      else if (result.success === false) {
        let data = {
          title: 'Cannot add freebie.',
          type: 'info',
          description: result.message
        }
        _showSnackbarHandler(data) 
      }
    }
    ))
  };


  return (
    <Modal show={show} onHide={() => { setShow(false) }} animation={false}>
      <Modal.Header closeButton />
      <Modal.Body>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative'
        }}>

          <Snackbar ref={snackbarRef} />

          <div className="modalHeading">Freebies</div>
          <p className="freebiepara">
            View all your available freebies
          </p>
          <div className='frb-root' >

            {cartFreebies?.map((item, i) => (
              <div key={i} className="frb-card">
                <img className="frb-img" src={item.image} alt="Img" />
                <div className="frb-card-details">
                  <h6>{item.name}</h6>
                  <p>{item.description}</p>
                  <div className='frb-btn'>
                    <button onClick={() => handleApplyNow(item)}>Apply now</button>
                    <div>
                      <span> <img src="assets/rewardCircle.svg" alt="Img" />{!!item.points ? Math.round(item.points) : 0}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {
            alertToastValues.showToast === true ?
              <AlertToast
                alertToastValues={alertToastValues}
              />
              : null
          }
        </div>

      </Modal.Body>
    </Modal>
  )
};

export default FreebiesModal;