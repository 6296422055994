import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ContentLoader from "react-content-loader"
import './YourLocation.css'
import { CheckCircle, RightArrow } from '../../../images/icons/IconSets';
import { getAddressFromCoordinates } from '../../../utils/index'
import LocationSearch from './LocationSearch'
import locationicon from '../../../../src/images/Group 30275.png';
import { requestAvailableModes, requestExactAddressByReverseGeocoding, setUserLocation, getRequestOptionsForExactAddress, removeItemFromCart, addItemToCart, requestNewAddress, } from '../../../actions';
import { selectAddress } from '../../../reducers/checkout'
import OfficeImg from '../../../images/Group 30275.png';
import { getTimeSlotsAction } from '../../../reducers/merchant';


function YourLocation(props) {
  const dispatch = useDispatch()
  const reduxState = useSelector(state => state)
  const reduxCheckout = useSelector(state => state.checkout)
  const reduxUser = useSelector(state => state.user)

  const [currentLocation, setcurrentLocation] = useState()
  const [currentCoordinates, setcurrentCoordinates] = useState()
  const [geolocationAllowed, setgeolocationAllowed] = useState(false)

  const loader = <ContentLoader
    speed={1}
    width={240}
    height={15}
    backgroundColor="#ecebeb"
    foregroundColor="#fafafa"
    {...props}
  >
    <rect x="0" y="8" rx="3" ry="3" width="200" height="7" />
  </ContentLoader>
  const location = reduxState.user.userLocation !== null && Object.keys(reduxState.user.userLocation).includes('results') ? reduxState.user.userLocation.results[4]?.formatted_address : loader;

  const setupCurrentLocation = async () => {
    if (navigator.geolocation) {
      // window.fbq('track', 'FindLocation');
      setgeolocationAllowed(true)
      navigator.geolocation.getCurrentPosition(async (position) => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        setcurrentCoordinates(location)
        setcurrentLocation(await getAddressFromCoordinates(location))
        // dispatch(requestAvailableModes(location))
      });
    }
  };

  const handleCurrentLocationClick = () => {
    if (currentCoordinates) {
      if (window.environment === 'production') {
        window.fbq('track', 'FindLocation');
      }
      dispatch(setUserLocation(currentCoordinates));
      dispatch(requestExactAddressByReverseGeocoding(currentCoordinates))// google api
      if (props.showMerchantList === false) {
        props.setShow(false)
      } else {
        props.tabSelected(1)
      }

    }
    else if (reduxUser.userDetails) {

      dispatch(requestExactAddressByReverseGeocoding(reduxUser.userDetails.locationPreference))// google api
      if (props.showMerchantList === false) {
        props.setShow(false)
      } else {
        props.tabSelected(1)
      }
    }
  }

  const handleAddressClick = (param) => {
    dispatch(selectAddress(param))
    dispatch(setUserLocation(param));
    dispatch(requestAvailableModes(param))
    dispatch(requestExactAddressByReverseGeocoding(param))// google api
    window.fbq('track', 'FindLocation');
    if (props.showMerchantList === false) {
      props.setShow(false)
    } else {
      props.tabSelected(1)
    }
  }

  useEffect(() => {
    setupCurrentLocation()
    dispatch(getTimeSlotsAction())
    if (reduxState.user.isAuthenticated) {
      dispatch(requestNewAddress({ addressId: 2000 }));
    }
  }, [])

  return (
    <div>
      <div className="locationhead">Location</div>
      <p className="freebiepara">
        Select or enter your location
      </p>
      <div style={{ display: 'flex', width: '100%' }}>
        <LocationSearch {...props} />
      </div>

      <div className="currentlocationi" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => handleCurrentLocationClick()}>
        <i class="fa fa-map-o" aria-hidden="true"></i>
        <div>
          <h6 className="yourlocation-fr" style={{ textAlign: 'start' }}>Select Your Current Location</h6>
          <p className="locationtext">{currentLocation ? currentLocation.formatted_address : "Allow Location."}</p>
        </div>

      </div>
      {reduxState.user.isAuthenticated ?
        <div>
          <h4 className="savetext">Saved addresses</h4>
          <div className="yr-root">
            {reduxState.user.userDetails.address?.addresses.map((value, i) =>
              <div key={i} className="yr-card" style={{ position: 'relative' }} onClick={() => handleAddressClick(value)}>
                {value.id === reduxCheckout.selectedDeliveryAddressId &&
                  <div style={{ right: 15, top: 5, position: 'absolute' }}>
                    <CheckCircle width={15} height={15} color="#28a745" />
                  </div>
                }
                {
                  value.tag === 'home' ?
                    <div className="home-img">
                      <img src="assets/home.svg" alt="" />
                    </div>
                    :
                    <div className="home-img">
                      <img src={OfficeImg} alt="" />
                    </div>
                }
                <div style={{
                  display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
                  paddingLeft: 20
                }}>
                  <text style={{ fontWeight: 'bold', color: '#553A28' }}>{value.tagName || value.name}</text>
                  <p className="locationmodal-text">
                    {
                      value.address1 + ', ' +
                      value.landmark + ', ' +
                      value.address2 + ', ' +
                      value.city + ', ' +
                      value.locality
                    }
                  </p>
                  <text style={{}}></text>

                </div>
              </div>
            )
            }

          </div>
          <div className="switchfooter"
            style={{ cursor: 'pointer' }}
            onClick={() => { props.setShow(false); props.addressSetShow(true) }}>
            <a className="switchmodalfooter"><i class="fa fa-plus" aria-hidden="true"></i>ADD NEW ADDRESS</a>
          </div>
        </div>

        : null}
    </div>
  )
}

export default YourLocation

