import deliveryimg from '../../../../src/images/Group 35369.png';
import new1 from '../../../images/Group 36756.svg';
export function getShortestDistance(position = {}, allMerchants) {
  const currentLat = Number.parseFloat(position?.latitude);
  const currentLong = Number.parseFloat(position?.longitude);
  let minimumDistance = Number.MAX_SAFE_INTEGER;
  let merchant;
  allMerchants.forEach((element, idx) => {
    const lat = Number.parseFloat(element.latitude);
    const long = Number.parseFloat(element.longitude);
    let distance;
    if (lat && long) {
      distance = calculateDistance(currentLat, currentLong, lat, long);
      if (distance < minimumDistance) {
        minimumDistance = distance;
        merchant = element;
      }
    }
  });

  return merchant;
}

function calculateDistance(currentLatitude, currentLongitude, latitude, longitude) {
  if (currentLatitude === latitude && currentLongitude === longitude) {
    return 0;
  } else {
    let radianLat1 = Math.PI * (currentLatitude / 180);
    let radianLat2 = Math.PI * (latitude / 180);
    let radianTheta = Math.PI * ((currentLongitude - longitude) / 180);
    let distance = Math.sin(radianLat1) * Math.sin(radianLat2) + Math.cos(radianLat1) * Math.cos(radianLat2) * Math.cos(radianTheta);
    if (distance > 1) {
      distance = 1;
    }
    distance = Math.acos(distance);
    distance = distance * (180 / Math.PI);
    distance = distance * 60 * 1.1515;
    distance = distance * 1.609344;
    return distance;
  }
}

export const availableModesConstants = [
  {
    name: "Delivery",
    type: "delivery",
    imgUrl: "assets/delivery.png"
  },
  {
    name: "Takeaway",
    type: "takeAway",
    imgUrl: "assets/takeaway.png"
  },
  {
    name: "Dine-In",
    type: "dineIn",
    imgUrl: "assets/dinein.png"
  },
  {
    name: "Menu",
    imgUrl: "assets/menu.png"
  }
]





function getNearestMerchantDetails(props) {
  const {
    user,
    homepageDetails: { availableModesDetails },
  } = props;
  if (availableModesDetails !== null) {
    const nearestMerchant = getShortestDistance(
      user.userLocation,
      availableModesDetails.data
    );
    return nearestMerchant;
  }
}

export const requestNearestMerchantAndAllCatalogues = (props) => {
  const nearestMerchant = getNearestMerchantDetails(props);
};

