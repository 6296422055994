import React, { useEffect, useState, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import PhoneInput, { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input'
import { Modal } from 'react-bootstrap';
import OtpModal from '../LoginModal/verifyOtp';
import {
   requestRegister,
   requestRegisterConfirm,
   setPhone,
   setCustomerData, setRegisterResponseReset
} from '../../../actions';

import 'react-phone-number-input/style.css'
import '../LoginModal/loginModel.css';
import './Register.css';
import icon from '../../../../src/images/Group_29936.png';
import Register from './Page1.register'
import VerifyOTP from './Page2.verifyOTP'
import Form from './Page3.form'

const screenMapping = {
   1: 'details',
   0: 'phone',
};

const RegisterModal = (props) => {
   const dispatch = useDispatch()


   const [phone, setPhone] = useState('');
   const [otp, setOtp] = useState();
   const [registerInfo, setregisterInfo] = useState()

   const [customerData, setCustomerData] = useState({
      fullname: '',
      email: '',
      phoneNumber: '',
      country: '',
      city: '',
      fbAccessToken: ''
   });

   const [currentScreen, setCurrentScreen] = useState(0);
   const { show, setShow, user, homepage, requestRegister, setLoginShow } = props;
   const userDetails = user.registerResponse !== null ? user.registerResponse : null;
  const userRedux = useSelector(state => state.user)
   const handleProceed = () => {
      const countryCode = phone && formatPhoneNumberIntl(phone).split(' ')[0];
      const formattedPhone = phone && formatPhoneNumber(phone).slice(1).split(' ').join('');
      const body = {
         phoneNumber: formattedPhone,
         countryCode,
         udid: homepage.deviceDetails.udid
      };

      props.setPhone(formattedPhone);
      props.setCustomerData(customerData);
      requestRegister(body);
   };

   const setregisterParams = (param) => {
      setregisterInfo(param)
      setPhone(param.mobileNumber)
   }
   const changeCurrentScreen = (param) => {
      setCurrentScreen(param)
   }
   const changeSetPhone = (param) => {
      setPhone(param)
   }

   const changeSetOtp = (param) => {
      setOtp(param)
   }

   const setShowModal = (param) => {
      setShow(param)
   }



   return (
      <div>
         <Modal show={show} onHide={() => { setShow(false), dispatch(setRegisterResponseReset()) }} animation={false}>
            <Modal.Header closeButton />

            <Modal.Body>
               {currentScreen === 0 ?
                  <Register
                     {...props}
                     registerParams={setregisterParams}
                     setPhone={changeSetPhone}
                     setCurrentScreen={changeCurrentScreen}
               referralData={props.referralData}
               referralCode={props.referralCode}
                  />
                  : currentScreen === 1 ?
                     <VerifyOTP
                        {...props}
                        registerInfo={registerInfo}
                        customerData={customerData}
                        setOtp={changeSetOtp}
                        setCurrentScreen={changeCurrentScreen}
                 referralCode={props.referralCode}
                     />
                     : currentScreen === 2 ?
                        <Form
                           {...props}
                           phoneNumber={phone}
                           registerInfo={registerInfo}
                           otp={otp}
                           setCurrentScreen={changeCurrentScreen}
                           setShowModal={setShowModal}
                   referralCode={props.referralCode}
                        />
                        : null}

            </Modal.Body>
         </Modal>
      </div>
   )
};

const mapStateToProps = state => ({
   user: state.user,
  homepage: state.homepage,
  referralCode: state.user?.referralCode,
  referralData: state.user?.referralData
});

export default connect(mapStateToProps, {
   requestRegister,
   setPhone,
   setCustomerData,

})(RegisterModal);
