import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import "react-datetime/css/react-datetime.css";
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LeftArrow } from '../../../images/icons/IconSets';
import TransactionError from '../../Common/SomethingWentWrongModal/index';
import PaymentSubmit from "../checkout/PaymentSubmit";
import "./custom.css";
import CartPanel from "./panels/cartPanel/CartPanel";
import CheckoutPanel from './panels/checkoutPanel/CheckoutPanel';
import ProceedDrawer from './ProceedDrawer';
function Cart(props) {
  const history = useHistory()
  const reduxCart = useSelector(state => state.cart)
  const reduxOrder = useSelector(state => state.orders?.orderMode)
  const reduxHomepage = useSelector(state => state.homepage)

  const [currentScreen, setCurrentScreen] = useState(0);

  const stripe = loadStripe(
    "{PUBLIC-KEY}"
  );


  const changeCurrentScreen = (param) => {
    setCurrentScreen(param)
  }

  const [showTransactionError, setShowTransactionError] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const params = new URLSearchParams(history.location.search);
      if (params.get('paymentStatus') === 'failure') {
        history.replace({
          pathname: window.location.pathname,
          search: '',
        })
        setShowTransactionError(true);
      }
    }, 500);
  }, [])

  const setFailureShow = () => {
    setShowTransactionError(false)
  }
  useEffect(() => {
    if (reduxCart.items?.length === 0) {
      history.replace('/emptycart')
    }
  }, [reduxCart.items])

  return (
    <Elements stripe={stripe}>
      <div style={{ marginTop: 85 }}>
        < TransactionError setShow={setFailureShow} show={showTransactionError} />
        {
          window.innerWidth > 768 ?
            <div
              style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center',
                cursor: 'pointer', width: 150, marginLeft: '20px'
              }}
              onClick={() => history.push({
                pathname:
                  `/order/${reduxHomepage.currentAvailableModes[0]?.slug}/${reduxOrder}`
              })}>
              <LeftArrow color='#B2BAC3' height={20} width={20} /> &nbsp; Go back to Menu
            </div>
            :
            <div>
              {
                currentScreen === 1 ?
                  null :
                  <div
                    style={{ cursor: 'pointer', marginLeft: '10px', paddingTop: '10px' }}
                    onClick={() => history.push({
                      pathname:
                        `/order/${reduxHomepage.currentAvailableModes[0]?.slug}/${reduxOrder}`
                    })}>
                    <LeftArrow color='#B2BAC3' height={20} width={20} /> &nbsp; Go back to Menu
                  </div>
              }
            </div>
        }

        {
          window.innerWidth < 768 ?
            <div style={{ position: 'relative' }}>
              {/* {currentScreen === 0 ? */}
              <ProceedDrawer currentScreen={currentScreen} setCurrentScreen={changeCurrentScreen} />
              {/* :
              null

            }
               */}
            </div>
            : null
        }
        <div style={{ marginLeft: 20, marginRight: 20, paddingTop: '16px' }} className="checkout">
          <div className="row paymentmain">
            {window.innerWidth > 768 ?
              <>
                <CheckoutPanel  {...props} />
                <CartPanel {...props} />
              </>
              :
              currentScreen === 0 ?
                <CartPanel
                  {...props}
                  setCurrentScreen={changeCurrentScreen}
                />
                : currentScreen === 1 ?
                  <CheckoutPanel
                    {...props}
                    setCurrentScreen={changeCurrentScreen}
                  />
                  :
                  null
            }

            {
              props.orderDetails ?
                (<PaymentSubmit
                  pgDetails={props.orderDetails}
                />)
                : null
            }
          </div>
        </div>
      </div>
    </Elements>
  );
}

const mapStateToProps = state => ({
  props: state.propsState,
  orderDetails: state.payment.orderDetails,
})

export default connect(mapStateToProps)(Cart);

