import React from 'react';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card'
import { alpha } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import InputBase from '@mui/material/InputBase';
import { GeoLocation } from '../../../images/icons/IconSets'
import {
  setUserLocation, 
    setSelectedMerchantDetails,

} from '../../../actions';
const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            // marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
      backgroundColor: '#fff',
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '100%',
        padding: '10px 12px',
        // transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
          boxShadow: `${alpha('#fff', 0.25)} 0 0 0 0.2rem`,
          borderColor: '#fff',
        },
    },
}))(InputBase);

class LocationSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = { address: '', selectedAddress: null };
    }

    handleChange = address => {
        this.setState({ address });
    };

    handleSelect = async address => {
        let coordinates = null
        await geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => coordinates = latLng)
            .catch(error => console.error('Error', error))
        if (coordinates !== null) {
            const location = {
                latitude: coordinates.lat,
                longitude: coordinates.lng,
            }
            this.props.setUserLocation(location);
            window.fbq('track', 'FindLocation');
            if (this.props.showMerchantList === false) {
                this.props.setShow(false)
            } else {
                this.props.tabSelected(1)
            }

        }
    };

    render() {
        return (
            <PlacesAutocomplete
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div style={{ width: 'inherit', position: 'relative', zIndex: 2, }}>
                        <BootstrapInput style={{ display: 'flex', width: '100%' }} defaultValue="react-bootstrap" id="bootstrap-input"
                            {...getInputProps({
                                placeholder: 'Search for your location  ...',
                                className: 'location-search-input',
                            })}
                        />

                        <Card style={{ width: 'max-content', width: '100%', position: 'absolute', boxShadow: '0px 0px 20px #0000000D' }}  >
                            {suggestions.map(suggestion => {

                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {

                                        })}
                                        style={{ display: 'flex', borderBottom: '1px solid #F7F7F7' }}
                                    >

                                        <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', fontSize: 13, padding: 10 }} onClick={() => this.setState({ selectedAddress: suggestion })}>
                                            <div>
                                                <GeoLocation width={16} height={16} color='grey' />
                                            </div>
                                            <text style={{ color: '#000000', textAlign: 'left' }}>
                                                &nbsp;{suggestion.description}
                                            </text>
                                        </div>
                                    </div>
                                );
                            })}
                        </Card>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    orders: state.orders,
})
const mapDispatchToProps = {
  setUserLocation, 
    setSelectedMerchantDetails
};
export default connect(mapStateToProps, mapDispatchToProps)(LocationSearchInput)
