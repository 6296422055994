import React, { useState, useEffect, createRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

import { Modal } from 'react-bootstrap';
import GoogleMaps from './GoogleMapsDraggable'
import Form from 'react-bootstrap/Form'
import FormControl from 'react-bootstrap/FormControl'
import InputGroup from 'react-bootstrap/InputGroup'
import Button from 'react-bootstrap/Button'
import LocationSearch from './LocationSearch'
import { getRequestOptionsForExactAddress, removeItemFromCart, addItemToCart, requestAddNewAddress, requestNewAddress } from '../../../actions';
import { setTemporaryInputFields } from '../../../actions/setState'
import config from '../../../config';
import { getServiceableAddressAction, addServiceableAddressAction, editServiceableAddressAction } from '../../../reducers/cart';
import Alert from 'react-bootstrap/Alert'
import './addressModel.css'
function AddressModal(props) {
  const anchorRef0 = createRef()

  const dispatch = useDispatch()
  const userRedux = useSelector(state => state.user)
  const homepageRedux = useSelector(state => state.homepage)

  const [showAlert, setShowAlert] = useState(false);
  const [coordinates, setcoordinates] = useState(null)
  const [validated, setValidated] = useState(false);
  const [warningText, setWarningText] = useState('')
  const [enableWarning, setenableWarning] = useState(false)
  const [locationFromSearch, setlocationFromSearch] = useState()
  const [googleAddress, setgoogleAddress] = useState()
  const [locationFromMaps, setlocationFromMaps] = useState()
  const [address, setAddress] = useState({
    houseNumber: '',
    landmark: '',
    street: '',
    city: '',
    locality: '',
    tag: 'home',

  });
  const { temporaryInputFields } = userRedux
  const {
    guest, userSelectedAddressId, userSelectedAddress, fullname, email, phoneNumber, instructions
  } = temporaryInputFields || {}

  const { show, setShow, type } = props;

  const handleAddAddress = () => {
    let tagger = ''
    if (address.tag === 'home') {
      tagger = "Home"
    }
    else if (address.tag === "work") {
      tagger = "Work"
    }
    else if (address.tag === 'other' && address.tagName === 'other') {
      tagger = ''
    }
    else {
      tagger = address.tagName
    }

    let location = !!userRedux.userLocation?.latitude ?
      userRedux.userLocation :
      userRedux.userDetails?.locationPreference

    let requestOptions = {
      ...address,
      tagName: tagger,
      city: address.houseNumber,
      address2: address.houseNumber,//google address
      address1: address.street,
      latitude: locationFromSearch?.latitude ? locationFromSearch.latitude : location.latitude,
      longitude: locationFromSearch?.longitude ? locationFromSearch.longitude : location.longitude,
      // authToken: userRedux.otpResponse.authToken,

    };
    requestOptions.authToken = !!userRedux?.otpResponse ? userRedux.otpResponse.authToken : null

    if (
      address.houseNumber.length > 0
      &&
      address.street.length > 0
      &&
      address.tag.length > 0
      // &&
      // (userRedux.userAddressChunks.country_id !== "US" ? (address.landmark.length > 0) : true)
    ) {
      if (window.location.pathname === '/cart') {
        requestOptions.merchantId = homepageRedux.selectedMerchantServiceability?.id

        if (!!type === false || type === 'create') {
          dispatch(addServiceableAddressAction((requestOptions), (result) => {
            if (result.success === false) {
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(false)
              }, 2500);
            }
            else if (result.success === true) {
              dispatch(setTemporaryInputFields({
                userSelectedAddress: requestOptions,
                userSelectedAddressId: result.addressId
              }))
              dispatch(getServiceableAddressAction(requestOptions))
              setShowAlert(false)
              setShow(false);
            }
          }))
        }
        else if (!!type && type === 'update') {
          //comment as of now
          // requestOptions.addressId = !!userSelectedAddressId ? userSelectedAddressId : null

          dispatch(editServiceableAddressAction((requestOptions), (result) => {
            if (result.success === false) {
              setShowAlert(true)
              setTimeout(() => {
                setShowAlert(false)
              }, 2500);
            }
            else if (result.success === true) {
              dispatch(setTemporaryInputFields({
                userSelectedAddress: requestOptions,
                userSelectedAddressId: result.addressId
              }))
              dispatch(getServiceableAddressAction(requestOptions))
              setShowAlert(false)
              setShow(false);
            }
          }))
        }

      }
      else {
        dispatch(requestAddNewAddress((requestOptions), (result) => {
          if (!!result.success) {
            let location = !!userRedux.userLocation?.latitude ?
              userRedux.userLocation :
              userRedux.userDetails?.locationPreference
            dispatch(requestNewAddress({
              addressId: 2000,
              ...location
            }))
            setShow(false);
          }
        }))
      }
    }
    else {
      setValidated(true);
    }
  };

  const handleInputChange = e => {

    const { name, value, tagname } = e.currentTarget;
    if (value === 'other') {
      handleAnchorRef0()
    }

    setAddress({
      ...address,
      [name]: value,
    });

  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
  };

  const handleCoordinates = async (param) => {
    // setcoordinates(param)
    let a = await param
    dispatch(setTemporaryInputFields(a))
  }

  useEffect(() => {
    let location = null
    if (!!type === false || type === 'create') {
      location = !!userRedux.userLocation?.latitude ?
        userRedux.userLocation :
        userRedux.userDetails?.locationPreference
    }
    else if (!!type === true || type === 'update') {
      const {
        houseNumber,
        landmark, street, city, locality, tag, premise, plus_code, political, sublocality, sublocality_level_3, sublocality_level_2, sublocality_level_1, administrative_area_level_2, administrative_area_level_1, state_code, country, country_id, postal_code, pincode, state, tagName, address2, address1, latitude, longitude, authToken, merchantId, } = userSelectedAddress || {}
      location = { latitude, longitude }

      setAddress({
        houseNumber: address2,
        landmark: landmark,
        street: address1,
        city: city,
        locality: '',
        tag: tag,
        country: country,
        pincode: pincode,
        state: state,
      })
    }
    if (!!type === false || type === 'create') {
      getAddressByReverseGeocoding(location)
    }

  }, [])

  async function getAddressByReverseGeocoding(params) {
    
    let response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${params.latitude},${params.longitude}&key=${config.googleMapApiKey}`)
    response = await response.json()
    if (response.status === 'OK') {
      const address_components = response?.results[0].address_components;
      let components = {};
      address_components.map((value, index) => {
        value.types.map((value2, index2) => {
          components[value2] = value.long_name;
          if (value2 === 'country')
            components.country_id = value.short_name;
          if (value2 === 'administrative_area_level_1')
            components.state_code = value.short_name;
        })
      })

      setgoogleAddress(response)
      
      setAddress({
        ...address, ...components,
        pincode: components.postal_code,
        state: components.administrative_area_level_1,
        city: components.sublocality || components.locality,
        /* GOOGLE ADDRESS (houseNumber) */
        // houseNumber: response.results[0]?.formatted_address,

        houseNumber:
          (!!components.street_number ? components.street_number + ", " : "")
          +
          (!!components.route ? components.route + ", " : "")
          +
          (!!components.premise ? components.premise + ", " : "")
          +
          (!!components.neighborhood ? components.neighborhood + ", " : "")
          +
          (!!components.sublocality_level_3 ? components.sublocality_level_3 + ", " : "")
          +
          (!!components.sublocality_level_2 ? components.sublocality_level_2 + ", " : "")
          +
          (!!components.sublocality_level_1 ? components.sublocality_level_1 + "" : "")
        // +
        // (!!components.sublocality ? components.sublocality + ", " : "")
        // +
        // (!!components.locality ? components.locality + ", " : "")
        ,

        // street: userRedux.userAddressChunks.country_id === "US" ? response.results[0]?.formatted_address : "",
        locality: components.locality,
        street: '',
        landmark: ''
      });
    }
  }

  useEffect(() => {

    if (locationFromSearch?.latitude) {
      getAddressByReverseGeocoding(locationFromSearch)
    }
  }, [locationFromSearch?.latitude])

  const getLocationFromSearch = (param) => {
    setlocationFromSearch(param)
  }

  const handleAnchorRef0 = (reference) => {
    if (anchorRef0.current) {
      anchorRef0.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      })
      //  window.scrollBy(0, -105); // Adjust scrolling with a negative value here
    }
  }

  return (
    <Modal
      style={{ padding: 0 }}
      dialogClassName={"addr-main-modal"}
      show={show} onHide={() => { setShow(false) }} animation={false}>
      <Modal.Header closeButton />
      <Modal.Body className="newaddressmodal" >
        <div className="address-heading">
          <text> Add your address
          </text>
          <LocationSearch
            getLocationFromSearch={getLocationFromSearch} />
        </div>

        <div className="modelMap">
          <GoogleMaps
            user={userRedux}
            getLocationFromSearch={getLocationFromSearch}
            locationfromSearch={locationFromSearch}
            handleCoordinates={handleCoordinates}
            setTemporaryInputFields={setTemporaryInputFields} />
        </div>

        <Form className="modelForm"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}>

          {/* ADDRESS 1 */}
          <Form.Group controlId="validationCustom01">
            {/* <Form.Label className="labellandmark">Locality& City</Form.Label> */}
            <Form.Control
              className="addr-form-control"
              disabled={true}
              readOnly
              contentEditable={false}
              name='houseNumber'
              value={address.houseNumber}
              onChange={handleInputChange}
              required
              tag="text"
              placeholder="Locality& City"
            />
            <Form.Control.Feedback type="invalid">
              Please provide locality
            </Form.Control.Feedback>
          </Form.Group>

          {/* ADDRESS 2 */}
          <Form.Group controlId="validationCustom03">
            <Form.Control
              className="addr-form-control"
              name="street"
              value={address.street}
              required
              onChange={handleInputChange}
              tag="text"
              placeholder="Apartment No. / Floor / House No. / Building Name"
            // placeholder={
            //     userRedux.userAddressChunks.country_id === "US" ?
            //         "House No. / Street Name" :
            //         "Flat No. / Floor / House No. / Building Name"
            // }
            />
            <Form.Control.Feedback type="invalid">
              {/* {userRedux.userAddressChunks.country_id === "US" ?
                                "Please provide House No. / Street Name" :
                                "Please provide a flat no"} */}
              Please provide Apartment No. / Floor / House No. / Building Name
            </Form.Control.Feedback>
          </Form.Group>

          {/* LANDMARK */}
          <Form.Group controlId="validationCustom05">
            {/* <Form.Label className="labellandmark">landmark</Form.Label> */}
            <Form.Control

              // required={userRedux.userAddressChunks.country_id !== "US" ? true : false}
              className="addr-form-control"
              name='landmark'
              value={address.landmark}
              onChange={handleInputChange}
              tag="text"
              placeholder="Landmark"
            />
            <Form.Control.Feedback type="invalid">
              Please enter the closest landmark.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="validationCustom06">
            {/* <Form.Label className="labellandmark">Address Type</Form.Label> */}
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', }}
            >
              <label className="container"
                style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer', fontSize: 14, marginTop: 10 }} >
                <div>
                  <input type="radio" className='radio-compatible'
                    style={{ marginRight: 5, opacity: 1, appearance: 'auto' }}
                    name="tag" tagname="Home" value='home'
                    checked={address.tag === 'home' ? true : false}
                    onChange={handleInputChange}
                  />Home
                </div>
                <div>
                  <input type="radio" className='radio-compatible'
                    style={{ marginRight: 5, opacity: 1, appearance: 'auto' }}
                    name="tag" tagname="Work" value='work'
                    checked={address.tag === 'work' ? true : false}
                    onChange={handleInputChange}
                  />Work
                </div>
                <div>
                  <input type="radio" className='radio-compatible'
                    style={{ marginRight: 5, opacity: 1, appearance: 'auto' }}
                    name="tag" tagname="Other" value='other'
                    checked={address.tag === 'other' ? true : false}
                    onChange={handleInputChange}
                  />Other
                </div>
              </label>
              {
                address.tag === 'other' ?
                  <Form.Group controlId="validationCustom05" >
                    <Form.Control
                      className="addr-form-control"
                      name='tagName'
                      value={address.tagName}
                      onChange={handleInputChange}
                      tag="text"
                      placeholder="Address Type"
                      required />
                    <Form.Control.Feedback type="invalid">
                      Please enter the Address Type
                    </Form.Control.Feedback>
                  </Form.Group>
                  :
                  <div style={{ height: 50, width: 250 }} />
              }
            </div>

          </Form.Group>


        </Form>
        <button onClick={handleAddAddress} type="submit" className="modalAddressBtn justify-content-center">ADD ADDRESS</button>
        {showAlert ?
          <Alert
            variant='primary'
            className="address-alert-anim"
            onClose={() => setShowAlert(false)}   >
            This address is not Serviceable!
          </Alert>

          : null}
        {/* <div className="addr-modelFooter">
                    <button onClick={handleAddAddress} type="submit" className="modalAddressBtn justify-content-center">ADD ADDRESS</button>
                </div> */}
      </Modal.Body>
    </Modal>
  )
};

export default AddressModal;