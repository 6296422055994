import React, { Component, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import { transactionHistory, transactionDetails } from '../../../reducers/cart'
import OrderHistory from '../../orderStatus/OrderHistory';
import TrackorderThree from "./TrackorderThree";
export default function Orders(props) {
  const cartRedux = useSelector(state => state.cart)
  const userRedux = useSelector(state => state.user)
  const dispatch = useDispatch()

  const orderStages = { placed: 1, confirmed: 2, ready: 3, dispatched: 4, taken: 4, completed: 5 }

  useEffect(() => {
    let apiArgs = { authToken: userRedux.otpResponse?.authToken }
    dispatch(transactionHistory(apiArgs))

    let transHis = setInterval(() => dispatch(transactionHistory(apiArgs)), 15000);
    return () => clearInterval(transHis);
  }, [])


  return (
    <div  >
      <span className="tr-head-title order-history fr-active" style={{ paddingBottom: '20px', paddingLeft: '20px' }}>{'Active Order Details'}</span>
      {
        !!cartRedux.transactionHistory?.length
          ?
          <div>
            {cartRedux.transactionHistory?.map((item, i) =>
              orderStages[item.status] < 5
                ?
                <TrackorderThree {...item} {...props} />
                :
                null
            )}
            <OrderHistory />
          </div>
          :
          !!cartRedux.transactionHistory
            ?
            <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 70, color: 'gray', font: "normal normal bold 18px/20px Lato" }}>You don't have any orders.</div>
            : null
      }

    </div>
  );
}