import React from 'react'
import './sociallogin.css'
import PropTypes from 'prop-types'
import GoogleIcon from './google.svg'
import FbLogin from './Login.Fb'
import LoginGoogle from './Login.Google'

function SocialLogin(props) {

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="divider">
        <div className="dividerBorder"></div>
        <div className="dividerText">OR</div>
        <div className="dividerBorder"></div>
      </div>
      <div className="social">
        <FbLogin
          setLoginShow={props.setLoginShow}
          setRegisterShow={props.setRegisterShow}
          gotoRegister={props.gotoRegister}
        />
        <LoginGoogle gotoRegister={props.gotoRegister} />
      </div>
    </div>
  )
}

SocialLogin.propTypes = {

}

export default SocialLogin

