import React, { Component, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
// import TrackOrderPage from './TrackorderPage'
import Trackorder from '../MyDetails/Components/TrackorderTwo';
import Layout from '../Layout/index';
import TransactionSuccessful from '../Common/SuccessModal/Modal'
import { transactionHistory, transactionDetails } from '../../reducers/cart'
import { requestOrderTransactionHistory } from '../../actions/index';
import OrderHistory from '../MyDetails/Components/OrderHistory';
import { clearCart } from '../../reducers/cart';
function OrderStatus(props) {
    let { orderId } = useParams();
    const dispatch = useDispatch()
    const history = useHistory();
    const cartRedux = useSelector(state => state.cart)
    const [showTransactionSuccessful, setShowTransactionSuccessful] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(history.location.search);
        if (params.get('paymentStatus') === 'success') {
            history.replace({
                pathname: history.location.pathname,
                search: '',
            })
            setShowTransactionSuccessful(true);
            dispatch(clearCart(cartRedux?.cartId));

            dispatch(transactionDetails(orderId, result => {
                if (result.success === true) {
                    window.fbq('track', 'Purchase',
                        {
                            value: result?.order.details?.total,
                            currency: result?.order.currencyISO
                        }
                    );
                }
            }));
        }
    }, [])

    const setSuccessShow = () => {
        setShowTransactionSuccessful(false)
    }
    return (
        <Layout>
            <TransactionSuccessful setShow={setSuccessShow} show={showTransactionSuccessful} />
            <div style={{ marginTop: '100px', marginLeft: '20px', marginRight: '20px' }}>
                {/* <TrackOrderPage /> */}
                <div>
                    <span className="tr-head-title order-history fr-active" style={{ paddingBottom: '10px' }}>{'Active Order Details'}</span>
                </div>

                <Trackorder orderId={orderId} />

            </div>
        </Layout>
    )
}

export default OrderStatus

