import React, { useEffect } from "react";
import { Box, BoxBody, BoxTitle } from '../../Common/box/Box';
// import Address from '../common/Address';
// import { CartItem } from '../common/MenuItem';
import { VTabs, HTabs } from '../../Common/tabs/Tabs';
import { connect } from 'react-redux';
// import CouponModal from '../common/CouponModal';
// import OtpModal from '../common/OtpModal';
// import OtpPointsModal from '../common/OtpPointsModal';
// import OtpFreebiesModal from '../common/OtpFreebiesModal';
// import LoyaltyPointsModal from '../common/LoyaltyPointsModal';
// import FreebieModal from '../common/FreebieModal';
import "./_Checkout.scss";
import Form from 'react-bootstrap/Form'
import '../../About/Components/aboutUs.css';
import PaymentSubmit from './PaymentSubmit';
import '../../Cart/Component/custom.css';
import checkright from '../../../images/Group 30012.svg';

import { CheckCircle, RightArrow } from '../../../images/icons/IconSets';



const PayWithButton = ({ imageSrc, title, active, onClick, type, paymentDetails }) => {
	return (
		<button style={{ position: 'relative' }} className={`pay-method-button ${active ? 'is-outlined-primary' : ''}`} onClick={() => onClick()}>
			<span className="pmb-name" >
				{paymentDetails?.type === type
					&&
					<div style={{ right: 5, top: 0, position: 'absolute' }}>
						<CheckCircle width={15} height={15} color="#28a745" />
					</div>
				}

				<img src={imageSrc} alt={title} />
				<label className="is-size-7 mt-0">{title}</label>
			</span>

		</button>
	)
}

const SavedCard = ({ active, cardId, maskedCard, selectCard, cvv, changeCvv, cardScheme }) => {
	return (
		<button onClick={() => selectCard()} className={`saved-payment-card ${active ? 'has-background-link' : ''} mb-2`}>
			<h4 className={`has-text-weight-medium is-size-6 mb-0 saved-card-name ${active ? 'has-text-light ' : ''}`}>
				<span className="icon mr-3">
					<i className="fas fa-credit-card"></i>
				</span>
				<input type="hidden" name="cardId" value={cardId} onChange={e => this.setState({ cardId: e.target.value })} />
				{maskedCard}
			</h4>

			<input className="fr-cvv-input" type="number" min="0" name="saved_card_cvv" placeholder="CVV" value={cvv} onChange={e => changeCvv(e.target.value)} />


		</button>
	)
}

const AddNewCardButton = ({ onClick }) => {
	return (
		<div
			onClick={onClick && onClick}
			className="add-new-card-button mt-2"
		>
			<button className="button">+</button>
			<div className="ml-4">
				<h4 className="mb-1 is-size-6 has-text-weight-medium new-fr-frr">NEW CARD</h4>
				<p className="is-size-7 has-text-grey add-text">Add your card and save time</p>
			</div>
		</div>
	)
}

class CheckoutPage extends React.Component {

	constructor(props) {
		super(props);
		this.renderTabContent = this.renderTabContent.bind(this);
		this.renderSavedCards = this.renderSavedCards.bind(this);
		this.renderCards = this.renderCards.bind(this);
		this.renderUPI = this.renderUPI.bind(this);
		this.renderNetBanking = this.renderNetBanking.bind(this);
		this.openCouponModal = this.openCouponModal.bind(this);
		this.openOtpModal = this.openOtpModal.bind(this);
		this.openOtpPointsModal = this.openOtpPointsModal.bind(this);
		this.openOtpFreebiesModal = this.openOtpFreebiesModal.bind(this);
		this.openLoyaltyPointsModal = this.openLoyaltyPointsModal.bind(this);
		// this.openFreebiesModal = this.openFreebiesModal.bind(this);
		// this.submitOrder = this.submitOrder.bind(this);
		// this.applyPoints = this.applyPoints.bind(this);
		// this.applyCoupon = this.applyCoupon.bind(this);
		// this.applyFreebie = this.applyFreebie.bind(this);
		this.submitOtp = this.submitOtp.bind(this);
		this.onSendOtp = this.onSendOtp.bind(this);
		this.onResendOtp = this.onResendOtp.bind(this);
		this.onOtp = this.onOtp.bind(this);
		this.savedCardSubmit = this.savedCardSubmit.bind(this);
		this.openFreebiesModal = this.openFreebiesModal.bind(this);
		this.showPaymentsScreenForMobile = this.showPaymentsScreenForMobile.bind(this);
		this.hidePaymentsScreenForMobile = this.hidePaymentsScreenForMobile.bind(this);
		this.cartAmount = this.cartAmount.bind(this);
		this.cartTaxAmount = this.cartTaxAmount.bind(this);
		this.state = {
			redeem: '',
			otp: '',
			mobileNumber: this.props.userInfo?.phoneNumber,
			addNewCard: false,
			couponModalOpen: false,
			otpModalOpen: false,
			otpPointsModalOpen: false,
			otpFreebiesModalOpen: false,
			loyaltyModalOpen: false,
			freebieModalOpen: false,
			paymentDetails: {},
			card_holder: '',
			card_number: '',
			card_expiryMonth: '',
			card_expiryYear: '',
			card_cvv: '',
			card_save: "0",
			saved_card_cvv: '',
			cardId: '',
			errorMessage: '',
			showPayment: false,
			validated:false

		}
	}

	async onOtp(otp) {
		this.setState({
			otp,
		});
	}

	applyPoints(e) {
		e.preventDefault();
		this.props.postApplyPoints(this.props.merchantAccessToken, this.props.points, this.state.mobileNumber, this.props.authToken);
		this.setState({
			loyaltyModalOpen: false,
		})
	}

	// async applyCoupon(code) {
	// 	await this.props.postApplyCoupon(this.props.merchantAccessToken, code, this.props.authToken, this.finalAmount());
	// 	this.props.couponMessage ? alert(this.props.couponMessage) : ''
	// 	this.props.couponMessage === "Coupon Applied Successfully" ?
	// 		this.setState({
	// 			couponModalOpen: false,
	// 		}) : ''
	// }

	// applyFreebie(freebie) {
	// 	this.props.postApplyFreebie(this.props.merchantAccessToken, freebie, this.props.authToken);
	// 	this.setState({
	// 		freebieModalOpen: false,
	// 	})
	// }

	async submitOtp() {
		this.state.otp ? await this.props.validateOtp(this.props.merchantAccessToken, this.state.mobileNumber, this.state.otp, this.props.udid) : this.setState({ errorMessage: 'Please enter OTP' });

		//(this.props.validateResponse === "Invalid OTP" || this.props.validateResponse === "The otp must be 6 digits.") ?
		//alert(this.props.validateResponse) :
		(this.props.authToken) ?
			(this.state.redeem === 'coupon' ?
				this.setState({
					otpModalOpen: false,
					couponModalOpen: true,
				})
				: (this.state.redeem === 'points' ?
					this.setState({
						otpModalOpen: false,
						loyaltyModalOpen: true,
					})
					: this.setState({
						otpModalOpen: false,
						freebieModalOpen: true,
					})))
			: this.setState({
				otpModalOpen: true,
				couponModalOpen: false,
			})
		// this.props.authToken ? this.props.getPoints(this.props.merchantAccessToken, this.props.udid, this.props.authToken) : '';
		// this.props.authToken ? this.props.getCoupons(this.props.merchantAccessToken, this.props.authToken) : '';
		// this.props.authToken ? this.props.getFreebies(this.props.merchantAccessToken, this.props.udid, this.props.authToken) : '';
		// this.props.authToken ? (this.state.redeem === 'coupon' ? this.openCouponModal : (this.state.redeem === 'points' ? this.openLoyaltyPointsModal : this.openFreebiesModal)) : '';
	}

 handleSubmit = (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
		  event.preventDefault();
		  event.stopPropagation();
		}
	  this.setState ({
		  validated : true
	  })

	  };
	onSendOtp(e) {
		this.props.sendOtp(this.props.merchantAccessToken, this.state.mobileNumber);
	}

	onResendOtp(e) {
		this.props.resendOtp(this.props.merchantAccessToken, this.props.udid);
	}

	submitOrder = (e) => {
		e.preventDefault();
		let paymentDetails = {
			paymentOption: 'card',
			card_holder: this.state.card_holder,
			card_number: this.state.card_number,
			card_expiryMonth: this.state.card_expiryMonth,
			card_expiryYear: this.state.card_expiryYear,
			card_cvv: this.state.card_cvv,
			card_save: this.state.card_save,
			phoneNumber: this.props.user.userDetails.profile.profile.phoneNumber
			// showMobilePayments: false,
		}
		if(
			this.state.card_holder > 0,
			this.state.card_number>0,
			this.state.card_expiryMonth>0,
			this.state.card_expiryYear>0,
			this.state.card_cvv >0
		){
			this.props.guestPlaceOrder(paymentDetails) //place-order api
		}
		else {
			this.setState({
				validated:true
			})
		}

	}

	savedCardSubmit(e) {
		e.preventDefault();
		let paymentDetails = {
			paymentOption: 'savedCard',
			card_id: this.state.cardId,
			card_cvv: this.state.saved_card_cvv,
		}
		this.props.guestPlaceOrder(paymentDetails)
	}

	openOtpModal(type) {
		this.setState({
			otpModalOpen: true,
			redeem: type,
		})
	}

	openOtpPointsModal() {
		this.setState({
			otpPointsModalOpen: true,
			redeem: 'points',
		})
	}

	openOtpFreebiesModal() {
		this.setState({
			otpFreebiesModalOpen: true,
			redeem: 'freebies',
		})
	}

	shouldShowMobileCart() {
		// const urlParams = new URLSearchParams(window.location.search);
		// return urlParams.get('hidecart') === "true";
	}

	componentDidMount() {
		if (this.shouldShowMobileCart()) {
			this.showPaymentsScreenForMobile();
		} else {
			this.hidePaymentsScreenForMobile();
		}
	}

	showPaymentsScreenForMobile() {
		this.setState({
			showMobilePayments: true,
		});
		// window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		// this.props.history.push('/checkout?hidecart=true');
	};

	hidePaymentsScreenForMobile() {
		this.setState({
			showMobilePayments: false,
		});
		// window.scroll({ top: 0, left: 0, behavior: 'smooth' });
		// this.props.history.push('/checkout?hidecart=false');
	};


	openCouponModal() {
		this.setState({
			couponModalOpen: true,
		})
	}

	openLoyaltyPointsModal() {
		this.setState({
			loyaltyModalOpen: true,
		})
	}

	openFreebiesModal() {
		this.setState({
			freebieModalOpen: true,
		})
	}

	cartAmount() {
		// return this.props.cart?.reduce(function (prev, current) {
		// 	return prev + (current.amount);
		// }, 0);
	}

	cartTaxAmount() {
		// return this.props.cart?.reduce(function (prev, current) {
		// 	return parseFloat(prev) + parseFloat(current.tax * current.quantity);
		// }, 0);
	}

	finalAmount() {
		const discount = this.props.pointsDiscount ? this.props.pointsDiscount : this.props.counponDiscount;
		const finalAmount = (this.cartAmount() + this.props.deliveryCharges + this.cartTaxAmount() - discount)
		return finalAmount
	}

	renderSavedCards() {
		if (this.state.addNewCard) {
			return (
				<React.Fragment>
					 <div>
					 <Form className="modelForm-fr" noValidate validated={this.state.validated} onSubmit={this.handleSubmit}>

						<Form.Group className="gapform" md="4" controlId="validationCustom01">
						<Form.Label>Name on card</Form.Label>
						<Form.Control
							className="input input-fr" type="text" name="card_holder"
							value={this.state.card_holder} placeholder="e.g Alex Smith" onChange={e => this.setState({ card_holder: e.target.value })}

							required

						/>
						<Form.Control.Feedback type="invalid">
							Please provide name.</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="gapform" md="4" controlId="validationCustom02">
						<Form.Label>Card Details</Form.Label>
						<Form.Control
							className="input input-fr" type="text" name="card_number" placeholder="e.g. 4160-XXXXXXXX-9094" onChange={e => this.setState({ card_number: e.target.value })} required
						/>
						<Form.Control.Feedback type="invalid">
							Please Card details.</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="gapform" md="4" controlId="validationCustom02">
						<Form.Label>Exp month</Form.Label>
						<Form.Control
							className="input input-fr" type="number" name="card_expiryMonth" min="0" placeholder="Month (07)" onChange={e => this.setState({ card_expiryMonth: e.target.value })} required
						/>
						<Form.Control.Feedback type="invalid">
							Please provide Exp Month.</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="gapform" md="4" controlId="validationCustom03">
						<Form.Label>Exp Year</Form.Label>
						<Form.Control
							className="input input-fr" type="number" name="card_expiryYear" min="0" placeholder="Year (23)" onChange={e => this.setState({ card_expiryYear: e.target.value })}
							required
						/>
						<Form.Control.Feedback type="invalid">
							Please provide Exp year.</Form.Control.Feedback>
						</Form.Group>
						<Form.Group className="gapform" md="4" controlId="validationCustom04">
						<Form.Label>Cvv</Form.Label>
						<Form.Control
							 className="input input-fr" type="number" min="0" name="card_cvv" placeholder="e.g. XXXX" onChange={e => this.setState({ card_cvv: e.target.value })} required
						/>
						<Form.Control.Feedback type="invalid">
							Please provide CVV.</Form.Control.Feedback>
						</Form.Group>
						{/* <Form.Group className="gapform" md="4" controlId="validationCustom01">
						<img src={checkright} style={{ marginRight: '10px' }} />
						<Form.Control
							type="checkbox" name="card_save" onChange={e => this.setState({ card_save: this.state.card_save === "0" ? "1" : "0" })} vale="Save Card" required
						/>

						</Form.Group> */}

						</Form>
					 </div>
					 < div>
					 <img src={checkright} style={{ marginRight: '10px' }} />
					 <input type="checkbox" name="card_save" onChange={e => this.setState({ card_save: this.state.card_save === "0" ? "1" : "0" })} vale="Save Card" required  />
					 </div>




					<button
						className=" mt-2 mr-2 button"
						onClick={() => this.setState({ addNewCard: false })}
					>
						Cancel
					</button>
					<button className=" mt-2 button is-success" onClick={this.submitOrder}>Add Card & Place Order</button>
				</React.Fragment>
			);
		}
		return (
			<React.Fragment>
				{this.props.cardsSaved && this.props.cardsSaved.length > 0 ? this.props.cardsSaved.map((card, index) => {
					return (<SavedCard
						cardId={card.cardId}
						maskedCard={card.maskedCard}
						selectCard={() => this.setState({ cardId: card.cardId })}
						changeCvv={(cvv) => this.setState({ saved_card_cvv: cvv })}
						cvv={this.state.saved_card_cvv}
						cardScheme={card.cardScheme}

						active
					/>)
				}) : null}

				<AddNewCardButton
					onClick={() => this.setState({ addNewCard: true })}
				/>
				<button className="mt-4 button is-fullwidth is-success is-hidden-touch" onClick={this.savedCardSubmit}>Place Order</button>
			</React.Fragment>
		)
	}

	renderCards() {
		return (
			<div className="payment-cards-wrap">
				{this.renderSavedCards()}
			</div>
		)
	}

	renderUPI() {
		return (
			<div className="upi-wrap">
				<PayWithButton
					className="upi-box"
					paymentDetails={this.state.paymentDetails}
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "gpay", paymentOption: "upi", upiMode: "gpay" } })}
					imageSrc={require('./gpay.png')}
					title="GPay"
					type="gpay"
					active={this.state.paymentDetails.value === "gpay" ? true : false}
				/>
				<PayWithButton
				className="upi-box"
					paymentDetails={this.state.paymentDetails}
					imageSrc={require('./paypal.svg')}
					title="PayPal"
					type="paypal"
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "paypal", paymentOption: "upi", upiMode: "paypal" } })}
				/>
				<PayWithButton
				className="upi-box"
					paymentDetails={this.state.paymentDetails}
					imageSrc={require('./phonepe.svg')}
					title="Phone Pe"
					type="phonepe"
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "phonepe", paymentOption: "upi", upiMode: "phonepe", paymentCode: "4009" } })}
				/>
				<PayWithButton
				className="upi-box"
					paymentDetails={this.state.paymentDetails}
					imageSrc={require('./paytm.svg')}
					title="PayTm"
					type="paytm"
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "paytm", paymentOption: "upi", upiMode: "paytm", paymentCode: "4007" } })}
				/>
				<div style={{ width: '100%' }} className="field-fr mt-4">
					<div className="control">
						<input className="input field-fr upi" type="text" name='upi_vpa' placeholder="Enter UPI ID" onChange={(e) => {
							this.setState({ paymentDetails: { ...this.state.paymentDetails, upi_vpa: e.target.value } });
						}} />
					</div>
				</div>
				<button className="mt-4 button is-fullwidth is-success is-hidden-touch" onClick={() => this.props.guestPlaceOrder(this.state.paymentDetails)}>Authorize &amp; Place Order</button>
			</div>
		)
	}
	renderWallets() {
		return (
			<div className="upi-wrap">
				<PayWithButton
					paymentDetails={this.state.paymentDetails}
					onClick={() => this.setState({
						paymentDetails: {
							...this.state.paymentDetails, type: "paytm",
							paymentOption: "wallet", paymentCode: "4007"
						}
					})}
					imageSrc={require('./paytm.svg')}
					title="Paytm"
					type="paytm"
					active={this.state.paymentDetails.value === "gpay" ? true : false}
				/>
				<PayWithButton
					paymentDetails={this.state.paymentDetails}
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "amazonpay", paymentOption: "wallet", paymentCode: "4008" } })}
					imageSrc={require('./amazonpay.png')}
					title="Amazon Pay"
					type="amazonpay"
					active={this.state.paymentDetails.value === "gpay" ? true : false}
				/>
				<PayWithButton
					paymentDetails={this.state.paymentDetails}
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "phonepe", paymentOption: "wallet", paymentCode: "4009" } })}
					imageSrc={require('./phonepe.svg')}
					title="PhonePe"
					type="phonepe"
					active={this.state.paymentDetails.value === "gpay" ? true : false}
				/>
				<PayWithButton
					paymentDetails={this.state.paymentDetails}
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "freecharge", paymentOption: "wallet", paymentCode: "4001" } })}
					imageSrc={require('./freecharge.png')}
					title="FreeCharge"
					type="freecharge"
					active={this.state.paymentDetails.value === "gpay" ? true : false}
				/>
				{/* <PayWithButton
					onClick={() => this.setState({ paymentDetails: { paymentOption: "wallet", paymentCode: "4002" } })}
					imageSrc={require('../images/mobikwik.svg')}
					title="MobiKwik"
					active={this.state.paymentDetails.value === "gpay" ? true : false}
				/> */}
				<button className="mt-4 button is-fullwidth is-success is-hidden-touch" onClick={() => this.props.guestPlaceOrder(this.state.paymentDetails)}>Authorize &amp; Place Order</button>
			</div>
		)
	}

	renderNetBanking() {
		return (
			<div className="netbanking-wrap">
				<PayWithButton
					paymentDetails={this.state.paymentDetails}
					imageSrc={require('./axis.png')}
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "axis", paymentOption: "nb", paymentCode: "3003" } })}
					title="Axis"
					type="axis"
				/>
				<PayWithButton
					imageSrc={require('./hdfc.svg')}
					active={true}
					title="HDFC"
					type="hdfc"
					paymentDetails={this.state.paymentDetails}
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "hdfc", paymentOption: "nb", paymentCode: "3021" } })}
				/>
				<PayWithButton
					imageSrc={require('./sbi.png')}
					title="SBI"
					type="sbi"
					paymentDetails={this.state.paymentDetails}
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "sbi", paymentOption: "nb", paymentCode: "3044" } })}
				/>
				<PayWithButton
					imageSrc={require('./icici.svg')}
					title="ICICI"
					type="icici"
					paymentDetails={this.state.paymentDetails}
					onClick={() => this.setState({ paymentDetails: { ...this.state.paymentDetails, type: "icici", paymentOption: "nb", paymentCode: "3022" } })}
				/>
				<div style={{ width: '100%' }} className="field">
					{window.innerWidth > 768 ? <hr style={{ marginTop: 20 }} /> : <hr style={{ marginTop: 0 }} />}

					<label className="label">Select other bank</label>
					<div className="control">
						<div style={{ width: '100%' }} className="select">
							<select value={this.state.paymentDetails.paymentOption === "nb" ? this.state.paymentDetails.paymentCode : ''} onChange={(e) => this.setState({ paymentDetails: { paymentOption: "nb", paymentCode: e.target.value } })}>
								<option value="">Select other bank</option>
								{this.props.bankList && this.props.bankList.length > 0 ? this.props.bankList.map((bank, index) => {
									return (<option value={bank.slug}>{bank.name}</option>)
								}) : null}
							</select>
						</div>
					</div>
				</div>
				<button className="mt-4 button is-fullwidth is-success is-hidden-touch" onClick={() => this.props.guestPlaceOrder(this.state.paymentDetails)}>Place Order</button>
			</div>
		)
	}

	renderTabContent(tabKey = '') {

		switch (tabKey) {
			case 'card':
				return this.renderCards();
			case 'upi':
				return this.renderUPI();
			case 'net-banking':
				return this.renderNetBanking();
			case 'wallets':
				return this.renderWallets();
			default: return null;
		}
	}

	render() {
		const showCartInMobile = this.shouldShowMobileCart();
		const isDelivery = this.props.orderMode;
		const { userInfo } = this.props;
		const profile = this.props.user.userDetails?.profile
		const device_width = window.innerWidth;

		const addressItem = this.props.addressList?.length > 0 ? this.props.addressList[0] : '';


		let boxTitle;
		let address;
		let hrLine;
		if (isDelivery === "delivery") {
			boxTitle = <BoxTitle title="Choose Delivery Location" />;
			// address = <Address
			// 	setAddress={this.props.setAddress}
			// 	addressList={this.props.addressList}
			// 	address={this.props.address}
			// 	coords={this.props.coords}
			// />;
			hrLine = <hr style={{ margin: '3em 1em 2em' }} />;
		}
		return (
			<React.Fragment>
				<div className="container mb-5 100-vh mt-6">
					<div className="columns is-justify-content-center">

						<div className={`mt-5-fr  ${showCartInMobile ? '' : 'is-hidden-touch'}`}>


							<div className='box-checkout-step'>
								{/* <div className="checkout-step-icon">


									<span className="icon is-large" style={{ color: '#fff' }}>
										<i className="fa fa-user"></i>
									</span>

								</div> */}
								{/* <BoxTitle title="Your Details" />
								<div style={{ display: 'flex', justifyContent: "flex-start", paddingLeft:0, alignItems: device_width > 756 ? 'center' : 'flex-start', flexDirection: device_width > 756 ? 'row' : 'column' }}>

									<div style={{ marginRight: 20 }}>
										<span className="namepay">Name</span><br />
										<input value={profile?.profile.name} className="button" style={{ textAlign: 'initial', cursor: 'text' }} disabled />
									</div>
									<div>
										<span className="paynumber">Phone Number</span><br />
										<input value={profile?.profile.phoneNumber} className="button" style={{ textAlign: 'initial' }} disabled />
									</div>

								</div> */}
							</div>


							{isDelivery === "delivery" ?
								<div className='box-checkout-step'>
									<div className="checkout-step-icon">

										<span className="icon is-large" style={{ color: '#fff' }}>
											<i className="fas fa-map-marker-alt"></i>
										</span>
									</div>
									{boxTitle}
									{/* {address} */}
								</div>

								: null}



							<div className='box-checkout-step'>
								<div className="checkout-step-icon">
									<span className="icon is-large" style={{ color: '#fff' }}>
										<i className="fas fa-wallet"></i>
									</span>

								</div>

								{/* <div className='fr-payment-box-head'>
									<BoxTitle title="Choose Payment Method" />
									<div className="ordertotal-fr" style={{ padding: 15 }}>
										<h2>Order Total </h2>
										<span className="fr-checkout-price-tag">₹ {this.finalAmount}</span>
									</div>
								</div> */}


								{
									<VTabs
										tabs={[
											{
												key: 'card',
												label: 'Credit/Debit card',
											},
											{
												key: 'upi',
												label: 'UPI',
											},
											{
												key: 'wallets',
												label: 'Wallets',
											},
											{
												key: 'net-banking',
												label: 'Net banking',
											},
										]}
										renderTabContent={this.renderTabContent}
									/>
									// :
									// <div>

									// 	{
									// 		[
									// 			{
									// 				key: 'card',
									// 				label: 'Credit/Debit card',
									// 			},
									// 			{
									// 				key: 'upi',
									// 				label: 'UPI',
									// 			},
									// 			{
									// 				key: 'wallets',
									// 				label: 'Wallets',
									// 			},
									// 			{
									// 				key: 'net-banking',
									// 				label: 'Net banking',
									// 			},
									// 		].map(tab => {
									// 			return (
									// 				<div style={{ padding: 10 }}>

									// 					<div className="fr-payment-type-head">
									// 						<span>{tab.label}</span>
									// 					</div>
									// 					{this.renderTabContent(tab.key)}
									// 				</div>
									// 			)
									// 		})
									// 	}

									// 	<button
									// 		className="mt-1 mb-0 button is-radiusless is-hidden-desktop is-uppercase is-fullwidth is-success pay-button-mobile"
									// 		onClick={() => this.props.guestPlaceOrder(this.state.paymentDetails)}
									// 	>
									// 		Pay
									// 		</button>
									// </div>
								}
							</div>
						</div>
						{/* <div className={`column is-one-third-fullhd is-one-third-widescreen is-5-desktop is-10-tablet is-full-mobile ${showCartInMobile ? 'is-hidden-touch' : ''}`}>
							<Box>
								{this.props.cart && this.props.cart.length > 0 ? this.props.cart.map((item, index) => {
									return <CartItem
										item={item}
										addCart={this.props.addCart}
									/>
								}) : null}
								<button
									onClick={() => this.openOtpModal('coupon')}
									className="button is-fullwidth is-medium cart-loyalty-button"
								>
									<span className="is-size-6">
										<img src={require('./coupon.svg')} alt="icon" />
										Apply Coupon
									</span>
									{this.props.authToken ? <span className="tag is-theme is-uppercase" style={{ backgroundColor: '#6f6f6f' }}>{this.props.coupons.length} AVAILABLE</span> : null}
								</button>
								<button
									onClick={() => this.openOtpModal('points')}
									className="button is-fullwidth is-medium cart-loyalty-button"
								>
									<span className="is-size-6">
										<img src={require('./star.svg')} alt="icon" />
										Use Points
									</span>
									{this.props.authToken ? <span className="tag is-theme is-uppercase" style={{ backgroundColor: '#6f6f6f' }}>{this.props.points} Points</span> : null}
								</button>
								<button
									onClick={() => this.openOtpModal('freebie')}
									className="button is-fullwidth is-medium cart-loyalty-button"
								>
									<span className="is-size-6">
										<img src={require('./gift.svg')} alt="icon" />
										Freebies
									</span>
									{this.props.authToken ? <span className="tag is-success is-uppercase">Applied</span> : null}
								</button>
								<div className="cart-footer-section mt-3 py-0">
									<table className="table cart-footer-totals">
										<tbody>
											<tr className="is-size-7 is-text-grey-light">
												<td>Item Total</td>
												<td>₹ {this.cartAmount()}</td>
											</tr>
											<tr className="is-size-7 is-text-grey-light">
												<td>Delivery Fee</td>
												<td>₹ {this.props.deliveryCharges}</td>
											</tr>
											<tr className="is-size-7 is-text-grey-light tax-row">
												<td>Tax and Charges</td>
												<td>₹ {this.cartTaxAmount()}</td>
											</tr>
											<tr className="is-size-6 is-text-dark has-text-weight-bold total-row is-uppercase">
												<td>To pay</td>
												<td>₹ {this.finalAmount()}</td>
											</tr>
										</tbody>
									</table>
								</div>
								<button
									className="pay-button-mobile mt-1 mb-0 button is-radiusless is-hidden-desktop is-uppercase is-fullwidth is-success"
									onClick={this.showPaymentsScreenForMobile}
								>
									Proceed To checkout
								</button>
							</Box>
						</div> */}
					</div>
				</div >
				{/* <OtpModal
					open={this.state.otpModalOpen}
					otp={this.state.otp}
					onOtp={this.onOtp}
					errorMessage={this.state.errorMessage}
					error={this.props.error}
					mobileNumber={this.state.mobileNumber}
					onRequestOtp={this.submitOtp}
					onSendOtp={this.onSendOtp}
					onResendOtp={this.onResendOtp}
					onValidateOtp={(otp) => this.submitOtp(otp)}
					onClose={() => this.setState({ otpModalOpen: false })}
				/>
				<OtpPointsModal
					otp={this.state.otp}
					onOtp={this.onOtp}
					errorMessage={this.errorMessage}
					error={this.error}
					open={this.state.otpPointsModalOpen}
					mobileNumber={this.state.mobileNumber}
					onRequestOtp={this.submitOtp}
					onSendOtp={this.onSendOtp}
					onResendOtp={this.onResendOtp}
					onValidateOtp={this.submitOtp}
					onClose={() => this.setState({ otpPointsModalOpen: false })}
				/>
				<OtpFreebiesModal
					otp={this.state.otp}
					onOtp={this.onOtp}
					errorMessage={this.errorMessage}
					error={this.error}
					open={this.state.otpFreebiesModalOpen}
					mobileNumber={this.state.mobileNumber}
					onRequestOtp={this.submitOtp}
					onSendOtp={this.onSendOtp}
					onResendOtp={this.onResendOtp}
					onValidateOtp={this.submitOtp}
					onClose={() => this.setState({ otpFreebiesModalOpen: false })}
				/>
				<CouponModal
					coupons={this.props.coupons}
					open={this.state.couponModalOpen}
					onRequestCoupon={(code) => this.applyCoupon(code)}
					onClose={() => this.setState({ couponModalOpen: false })}
				/>
				<LoyaltyPointsModal
					points={this.props.points}
					amount={this.props.amount}
					originalAmount={this.props.cart.reduce(function (prev, current) { return prev + (current.rate * current.quantity); }, 0)}
					open={this.state.loyaltyModalOpen}
					onRequestClose={this.applyPoints}
					onClose={() => this.setState({ loyaltyModalOpen: false })}
				/>
				<FreebieModal
					open={this.state.freebieModalOpen}
					freebies={this.props.freebies}
					onRequestFreebie={(freebie) => this.applyFreebie(freebie)}
					onClose={() => this.setState({ freebieModalOpen: false })}
				/> */}
			</React.Fragment >
		)
	}
}

export default CheckoutPage;
