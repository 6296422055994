import React from 'react';
import './giftModel.css';
import {
  Popover,
  Tooltip,
  Modal
} from 'react-bootstrap';

const GiftModal = ({ show, setShow }) => {
  return (
    <div>
          <Modal show={show} onHide={() => { setShow(false) }} animation={false}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="giftCard">
            <div className="modalHeading">Gift card history</div>
            <p className="pera">Add a new address that we can deliver to!</p>
            <div className="ModalTabs">
              <button className="active">Myself</button>
              <button>Sent</button>
            </div>
            <div className="giftCardBox">
              <div><img src="assets/homeicon.png" alt="logo" /></div>
              <div className="giftCardDetails">
                <div className="giftPrice"><sup>₹</sup>2000</div>
                <div className="giftDate">20th March, 2020</div>
                <div className="giftId">BIYR1882323</div>
              </div>
              <div className="giftCardBalance">
                <div>Balance</div>
                <div className="balancePrice"><sup>₹</sup>500</div>
              </div>
            </div>
            <div className="giftCardBox">
              <div className=""><img src="assets/homeicon.png" alt="logo" /></div>
              <div className="giftCardDetails">
                <div className="giftPrice"><sup>₹</sup>2000</div>
                <div className="giftDate">20th March, 2020</div>
                <div className="giftId">BIYR1882323</div>
              </div>
              <div className="giftCardBalance">
                <div>Balance</div>
                <div className="balancePrice"><sup>₹</sup>500</div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
};
export default GiftModal;