import apiClient from '../api/index';
import config from './../config';
import { getCart } from './cart';
import { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input'

let unique = arr => arr.filter((item, i, ar) => ar.indexOf(item) === i);

const initialState = {
  points: 0,
  amount: 0,
  coupons: [],
  freebies: [],
  otpResponse: '',
  resendResponse: '',
  validateResponse: '',
  pointsDiscount: '',
  counponDiscount: '',
  freebieDiscount: '',
  couponApplying: null,
  udid: '',
  authToken: '',
  error: '',
  couponMessage: '',
  saveInfo: {},
  currencyConversionRate: 5,
  selectedDeliveryAddressId: '',
  selectedDateTime: ''
};

const GET_POINTS = "checkout/GET_POINTS"
const GET_COUPONS = "checkout/GET_COUPONS"
const GET_FREEBIES = "checkout/GET_FREEBIES"
const SEND_OTP = "checkout/SEND_OTP"
const RESEND_OTP = "checkout/RESEND_OTP"
const VALIDATE_OTP = "checkout/VALIDATE_OTP"
const VALIDATE_OTP_ERROR = "checkout/VALIDATE_OTP_ERROR"
const APPLY_COUPON = "checkout/APPLY_COUPON"
const APPLY_POINTS = "checkout/APPLY_POINTS"
const APPLY_FREEBIE = "checkout/APPLY_FREEBIE"
const GET_USER = "checkout/GET_USER"
const SELECTED_ADDRESS = "checkout/SELECTED_ADDRESS"
const SELECTED_DATETIME = "checkout/SELECTED_DATETIME"
const APPLY_COUPON_ERROR = "checkout/APPLY_COUPON_ERROR"
const SET_AUTH_TOKEN = "checkout/SET_AUTH_TOKEN"

const SAVE_INFO_CONFIRM = 'SAVE_INFO_CONFIRM'


function getRequestOptionsfor() {
  return {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  };
}

export const getUser = (merchantAccessToken, mobile) => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.getUser, {
      merchantAccessToken: merchantAccessToken,
      mobileNumber: mobile,
    });

    if (response) {
      dispatch({
        type: GET_USER,
        udid: response.id,
      });
    } else {

    }

  } catch (error) {

  }
};

export const getFreebies = (merchantAccessToken, udid, authToken) => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.getFreebies, {
      merchantAccessToken: merchantAccessToken,
      uuid: udid,
      authToken: authToken,
    });

    if (response.success) {
      dispatch({
        type: GET_FREEBIES,
        freebies: response.active.freebies,
      });
    } else {

    }

  } catch (error) {

  }
};

// export const getPoints = (merchantAccessToken, udid, authToken) => async (dispatch, getState) => {
//   try {
//     const state = getState();
//     const response = await apiClient.get(apiClient.Urls.getPoints, {
//       merchantAccessToken: merchantAccessToken,
//       uuid: udid,
//       merchantId: state.menu.id,
//       authToken: authToken,
//     });

//     if (response.success) {
//       dispatch({
//         type: GET_POINTS,
//         points: response.loyaltyPoints ? response.loyaltyPoints : 0,
//         amount: response.discount ? response.discount : 0,
//       });
//     } else {

//     }

//   } catch (error) {

//   }
// };
export const getPoints = (merchantAccessToken, udid, authToken) => async (dispatch, getState) => {
  try {
    const state = getState();
    const requestoptions = getRequestOptionsfor()
    const response = await fetch(`${config.apiUrl}/loyalty/v2/${state.homepage?.nearestMerchantDetails.loyaltyId}/user/details?&authToken=${state.user.otpResponse?.authToken}&merchantAccessToken=${'qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E'}`, requestoptions).then(res =>
      res.json()

    )

    // const response = await apiClient.get(apiClient.Urls.getPoints, {
    //   merchantAccessToken: merchantAccessToken,
    //   uuid: udid,
    //   merchantId: state.menu.id,
    //   authToken: authToken,
    // });

    if (response.success) {
      dispatch({
        type: GET_POINTS,
        currencyConversionRate: response.currencyConversionRate,
        points: response.points,
        amount: response.discount ? response.discount : 0,
      });
    } else {

    }

  } catch (error) {

  }
};

export const getCoupons = (merchantAccessToken, udid, authToken) => async (dispatch, getState) => {
  try {
    const state = getState();
    const requestoptions = getRequestOptionsfor()
    const response = await fetch(`${config.apiUrl}/loyalty/v2/${state.homepage?.nearestMerchantDetails.loyaltyId}/user/freebies/live?&authToken=${state.user.otpResponse?.authToken}&merchantAccessToken=${'qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E'}`, requestoptions).then(res =>
      res.json()
    )

    // const response = await apiClient.get(apiClient.Urls.getPoints, {
    //   merchantAccessToken: merchantAccessToken,
    //   uuid: udid,
    //   merchantId: state.menu.id,
    //   authToken: authToken,
    // });

    if (response.success) {
      dispatch({
        type: GET_COUPONS,
        coupons: response.freebies,
      });
    } else {

    }

  } catch (error) {

  }
};

// export const getCoupons = (merchantAccessToken, authToken) => async (dispatch, getState) => {
//   try {
//     const response = await apiClient.get(apiClient.Urls.getCoupons, {
//       merchantAccessToken: merchantAccessToken,
//       authToken: authToken,
//     });

//     if (response.success) {
//       dispatch({
//         type: GET_COUPONS,
//         coupons: response.coupons,
//       });
//     } else {

//     }

//   } catch (error) {

//   }
// };

// export const postApplyPoints = (merchantAccessToken, points, mobileNumber, authToken) => async (dispatch, getState) => {
//   try {
//     const state = getState();
//     const response = await apiClient.get(apiClient.Urls.postApplyPoints, {
//       merchantAccessToken: merchantAccessToken,
//       loyaltyPointsToRedeem: points,
//       mobileNumber: mobileNumber,
//       merchantId: state.menu.id,
//       authToken: authToken,
//     });

//     if (response.success) {
//       dispatch({
//         type: APPLY_POINTS,
//         pointsDiscount: response.discount
//       });
//     } else {

//     }

//   } catch (error) {

//   }
// };

export const postApplyPoints = (params) => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.postApplyPoints, {
      merchantAccessToken: 'qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E',
      pointsToRedeem: 200,
      merchantId: state.homepage.nearestMerchantDetails?.id,
      authToken: state.user.otpResponse.authToken,
      cartId: state.cart.cartId
    });

    if (response.success) {
      dispatch({
        type: APPLY_POINTS,
        pointsDiscount: response.discount
      });
      dispatch(getCart())
    } else {

    }

  } catch (error) {

  }
};

export const postApplyCoupon = (param) => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.postApplyCoupon, {
      merchantAccessToken: 'qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E',
      authToken: state.user.otpResponse.authToken,
      cartId: state.cart.cartId,
      couponCode: param.couponCode,
      merchantId: param.merchantId,
      totalAmount: 120,
    });

    if (response.success) {
      dispatch({
        type: APPLY_COUPON,
        counponDiscunt: response.discount,
        couponMessage: "Coupon Applied Successfully",
      });
    } else {
      dispatch({
        type: APPLY_COUPON_ERROR,
        // couponMessage: response.message,
        couponMessage: "Coupon does not exist.",
      });
    }

  } catch (error) {

  }
};

export const saveInfo = (param, callBack) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { cartId } = state.cart;
    const { otpResponse, temporaryInputFields } = state.user
    const { nearestMerchantDetails } = state.homepage;
    const { merchant } = state.merchant;
    const { initialOrderMode } = state.orders;
    const { selectedDeliveryAddressId } = state.checkout;

    const {
      guest, userSelectedAddressId, fullname,
      email, phoneNumber, instructions, pickup,
      tip, tipType, custom
    } = temporaryInputFields || {}

    let merchantId = !!nearestMerchantDetails ? nearestMerchantDetails.id : merchant?.id

    const countryCode = phoneNumber && formatPhoneNumberIntl(phoneNumber).split(' ')[0];
    const formattedPhone = phoneNumber && formatPhoneNumber(phoneNumber).slice(1).split(' ').join('');

    let req = {
      cartId: cartId,
      orderMode: initialOrderMode,
      // addressId: param.addressId,
      // pickupAt: !!param.pickupAt ? param.pickupAt : pickup,
      // tip: param.tip,
      // tipType: param.tipType,
      // custom: param.custom,
      // notes: param.note,
      // countryCode: countryCode,
      // mobileNumber: formattedPhone,
      // username: fullname,
      // email: email,
      // merchantId: param.merchantId,
      // tableId: '',
      merchantAccessToken: 'qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E',
      // authToken: otpResponse.authToken
    }

    req.pickupAt = !!param.pickupAt ? param.pickupAt : pickup
    req.notes = !!instructions ? instructions : null
    req.merchantId = !!param.merchantId ? param.merchantId : merchantId
    req.authToken = !!otpResponse?.authToken ? otpResponse?.authToken : null

    /* TIP */
    req.tip = tip
    req.tipType = tipType
    req.custom = custom

    /* FOR GUEST USERS PLACING ORDER */
    if (guest === true) {
      req.countryCode = !!countryCode ? countryCode : null
      req.mobileNumber = !!formattedPhone ? formattedPhone : null
      req.name = !!fullname ? fullname : null
      req.email = !!email ? email : null

      if (!!userSelectedAddressId) {
        req.addressId = userSelectedAddressId
      }
    }
    else {
      if (!!param.addressId) {
        req.addressId = param.addressId
      }

      // req.addressId =
      //   !!param.addressId ? param.addressId
      //     :
      //     !!selectedDeliveryAddressId ? selectedDeliveryAddressId
      //       :
      //       null
    }

    const response = await apiClient.get(apiClient.Urls.saveInfo, req);
    if (response.success) {
      dispatch({
        type: SAVE_INFO_CONFIRM,
        saveInfo: response,
      });
    } else {
    }
    !!callBack && await callBack(response)
  } catch (error) {
    console.log(error)
  }
};


// export const postApplyCoupon = (merchantAccessToken, code, authToken, amount) => async (dispatch, getState) => {
//   try {
//     const response = await apiClient.get(apiClient.Urls.postApplyCoupon, {
//       merchantAccessToken: merchantAccessToken,
//       authToken: authToken,
//       id: code,
//       totalAmount: amount,
//     });

//     if (response.success) {
//       dispatch({
//         type: APPLY_COUPON,
//         counponDiscount: response.discount,
//         couponMessage: "Coupon Applied Successfully",
//       });
//     } else {
//       dispatch({
//         type: APPLY_COUPON_ERROR,
//         couponMessage: response.message,
//       });
//     }

//   } catch (error) {

//   }
// };

export const postApplyFreebie = (merchantAccessToken, freebie, authToken) => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.postApplyFreebie, {
      merchantAccessToken: merchantAccessToken,
      authToken: authToken,
      id: freebie,
    });

    if (response.success) {
      dispatch({
        type: APPLY_FREEBIE,
        coupons: response.coupons,
      });
    } else {

    }

  } catch (error) {

  }
};

export const setAuthToken = (authToken) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_AUTH_TOKEN,
      authToken,
    })
  }
  catch (e) {

  }
}

export const selectAddress = params => async (dispatch, getState) => {
  dispatch({
    type: SELECTED_ADDRESS,
    selectedDeliveryAddressId: params.id,
  })
};

export const selectDateTime = params => async (dispatch, getState) => {
  dispatch({
    type: SELECTED_DATETIME,
    payload: params,
  })
};

export const validateOtp = (merchantAccessToken, mobile, otp, id) => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.validateOtp, {
      merchantAccessToken: merchantAccessToken,
      mobileNumber: mobile,
      otp: otp,
      udid: id,
    });

    if (response.success) {
      dispatch({
        type: VALIDATE_OTP,
        validateResponse: response.message,
        authToken: response.authToken,
        error: '',
      });
    } else {
      dispatch({
        type: VALIDATE_OTP_ERROR,
        validateResponse: response.message,
        error: response.message,
      });
    }

  } catch (error) {

  }
}

export const resendOtp = (merchantAccessToken, udid) => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.resendOTP, {
      merchantAccessToken: merchantAccessToken,
      uuid: udid,
    });

    if (response.success) {
      dispatch({
        type: RESEND_OTP,
        resendResponse: response,
      });
    } else {

    }

  } catch (error) {

  }
}

export const sendOtp = (merchantAccessToken, mobile) => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.sendOtp, {
      merchantAccessToken: merchantAccessToken,
      mobileNumber: mobile,
      loginAsGuest: true,
    });

    if (response.success) {
      dispatch({
        type: SEND_OTP,
        otpResponse: response.message,
      });
    } else {

    }

  } catch (error) {

  }
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POINTS: {
      return {
        ...state,
        points: action.points,
        reduxcurrencyConversionRate: action.reduxcurrencyConversionRate,
        amount: action.amount,
      }
    }
    case GET_COUPONS: {
      return {
        ...state,
        coupons: action.coupons,
      }
    }
    case GET_USER: {
      return {
        ...state,
        udid: action.udid,
      }
    }
    case APPLY_COUPON: {
      return {
        ...state,
        counponDiscount: action.counponDiscount,
        couponMessage: action.couponMessage,
        couponApplying: true
      }
    }
    case APPLY_COUPON_ERROR: {
      return {
        ...state,
        couponMessage: action.couponMessage,
      }
    }
    case APPLY_FREEBIE: {
      return {
        ...state,
        coupons: action.coupons,
      }
    }
    case APPLY_POINTS: {
      return {
        ...state,
        pointsDiscount: action.pointsDiscount,
      }
    }
    case SAVE_INFO_CONFIRM: {
      return {
        ...state,
        saveInfo: action.saveInfo,
      }
    }
    case SEND_OTP: {
      return {
        ...state,
        otpResponse: action.otpResponse,
      }
    }
    case SET_AUTH_TOKEN: {
      return {
        ...state,
        authToken: action.authToken,
      }
    }
    case RESEND_OTP: {
      return {
        ...state,
        resendResponse: action.resendResponse,
      }
    }
    case VALIDATE_OTP: {
      return {
        ...state,
        validateResponse: action.validateResponse,
        authToken: action.authToken,
      }
    }
    case VALIDATE_OTP_ERROR: {
      return {
        ...state,
        validateResponse: action.validateResponse,
        error: action.error,
      }
    }
    case GET_FREEBIES: {
      return {
        ...state,
        freebies: action.freebies,
      }
    }
    case SELECTED_ADDRESS: {
      return {
        ...state,
        selectedDeliveryAddressId: action.selectedDeliveryAddressId,
      }
    }
    case SELECTED_DATETIME: {
      return {
        ...state,
        selectedDateTime: action.payload,
      }
    }
    default: return state;
  }
};

export default checkoutReducer;
