import config from '../config';

const initialState = {
  aboutUsCountries:[]
};

const ABOUT_US = "ABOUT_US"
const ABOUT_US_COUNTRIES = "ABOUT_US_COUNTRIES"
const ABOUT_US_AWARDS = 'ABOUT_US_AWARDS'


function getRequestOptionsfor() {
  return {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  };
}

export const requestAboutUs = () => async (dispatch, getState) => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  await fetch(`${config.apiUrl}/content?model=aboutUs&template=biryanis-website&merchantAccessToken=${config.merchantAccessToken}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      dispatch({
        type: ABOUT_US,
        payload: result.data,
      });
    })
    .catch(error => console.log('error', error));
};

// export const requestAboutUsCountries = () => async (dispatch, getState) => {
//   var requestOptions = {
//     method: 'GET',
//     redirect: 'follow'
//   };
//   fetch(`${config.apiUrl}/content?model=aboutUsCountries&template=biryanis-website&merchantAccessToken=${config.merchantAccessToken}`, requestOptions)
//     .then(response => response.json())
//     .then(result => {
//       dispatch({
//         type: ABOUT_US_COUNTRIES,
//         aboutUsCountries: result.data,
//       });
//     })
//     .catch(error => console.log('error', error));
// };

export const requestAboutUsCountries = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'aboutUsCountries'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const homepagefeatures = await response.json();
    // console.log("confighomepageeeeeeeeedata", homepagefeatures.data)
    if (homepagefeatures.success) {
      dispatch({
        type: ABOUT_US_COUNTRIES,
        aboutUsCountries: aboutUsCountries.data
      });
    } else {
      console.log("not fetching data");
    }

  } catch (error) {


  }
};

export const requestAboutUsAwards = () => async (dispatch, getState) => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  fetch(`${config.apiUrl}/content?model=awardsRecognitions&template=biryanis-website&merchantAccessToken=${config.merchantAccessToken}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      dispatch({
        type: ABOUT_US_AWARDS,
        payload: result.data,
      });
    })
    .catch(error => console.log('error', error));
};




const aboutUsReducer = (state = initialState, action) => {
  switch (action.type) {

    case ABOUT_US: {
      return {
        ...state,
        aboutUs: action.payload
      }
    }
    case ABOUT_US_COUNTRIES: {
      return {
        ...state,
        aboutUsCountries: action.aboutUsCountries
      }
    }
    case ABOUT_US_AWARDS: {
      return {
        ...state,
        aboutUsAwards: action.payload
      }
    }


    default: return state;
  }
};


export default aboutUsReducer;
