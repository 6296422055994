import React from 'react'
import { googleLoginCheckAction } from '../../../reducers/user';
import { useDispatch } from 'react-redux';
import GoogleLogin from 'react-google-login';
import GoogleIcon from './google.svg'
import config from '../../../config'
function LoginGoogle(props) {
  const dispatch = useDispatch()

  const onSuccess = (res) => {
    console.log({ res })
    const response = {
      accessToken: res.accessToken,
      googleData: res.profileObj,
      tokenObj: res.tokenObj
    }
    console.log({ response })
    if (response.accessToken) {
      dispatch(googleLoginCheckAction(response))
      props.gotoRegister()
    } else {
    }

  };

  const onFailure = (res) => {
    console.log('Login failed: res:', res);
    // alert(
    //   `Failed to login. 😢`
    // );
  };



  return (
    <div>
      <GoogleLogin
        clientId={config.clientId}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
          <img style={{ cursor: 'pointer' }} onClick={renderProps.onClick} disabled={renderProps.disabled} src={GoogleIcon} alt="Google" />
        )}
      />
    </div>
  )
}
export default LoginGoogle