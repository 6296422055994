import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import arrowleft from '../../../images/leftarrow.svg';
import arrowright from '../../../images/rightarrow.svg';
import FeaturedItemCard from '../../Common/featuredItemCard/FeaturedItemCard';
import './featureditem.css';

function FeaturedItem() {
  const homefeatureditems = useSelector(state => state.homepagecms.homefeatureditems)

  const getImageSlides = () => {
    return homefeatureditems?.map((value, i) => (
      <Fragment key={i}>
      <FeaturedItemCard
        item={value}
      />
      </Fragment>
    ))
  }

    const settings = {
      dots: true,
      // infinite: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: true,
      prevArrow: <img src={arrowleft} />,
      nextArrow: <img src={arrowright} width='45px' height="45px" />, 

      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            arrows: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
          }
        }
      ]
    };
    return (
      <div className="feature">
        <div className='container-fluid'>
          <h2 className="heading-fr-feat">FEATURED ITEMS</h2>
        </div>
        <Slider
          dots={true}
          slidesToShow={3}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={3000}
          arrows={true} 
          {...settings}
        >
          {getImageSlides()}
        </Slider>
      </div>
    );
}

export default FeaturedItem;