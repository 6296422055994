import React from 'react'
import './index.css'
import Logo from './biryanis_logo.svg'
function Loader() {
  return (
    <div id="splashspinnertwo" class="divLoadertwo">
      <img src={Logo} class="svgLoadertwo" alt="" />
    </div>
  )
}

export default Loader
