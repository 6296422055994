import React, { Component } from 'react';
import PrivacyComponent from '../Components/index'
import Layout from '../../Layout/index'
class PrivacyAndPolicy extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = 'Privacy and Policy - Biryanis and More'
    }

    render() {
        return (
            <div>
                <Layout >
                    <PrivacyComponent />
                </Layout>
            </div>
        );
    }
}

export default PrivacyAndPolicy;
