import apiClient from '../api/index';
import config from '../config';
import { getCart } from './cart';

const initialState = {

};

const GET_COUPONS = "coupon/GET_COUPONS"
const APPLY_COUPON = "coupon/APPLY_COUPON"
const REMOVE_COUPON = "coupon/REMOVE_COUPON"



export const getCouponsAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken, uuid } = state.user.otpResponse || {}
    const response = await apiClient.get(apiClient.Urls.getCoupons, {
      merchantAccessToken: config.merchantAccessToken,
      authToken: authToken,
      merchantId: state.cart.merchantId,
    });

    if (response.success) {
      dispatch({
        type: GET_COUPONS,
        payload: response.coupons,
      });
    } else {

    }
  } catch (error) {
  }
};


export const postApplyCoupon = (param, callback) => async (dispatch, getState) => {
  try {
    const state = getState();

    const response = await apiClient.post(apiClient.Urls.postApplyCoupon, {
      merchantAccessToken: 'qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E',
      authToken: state.user.otpResponse.authToken,
      cartId: state.cart.cartId,
      merchantId: state.cart.merchantId,
      couponCode: param.couponCode,
    });

    if (response.success) {
      // dispatch({
      //   type: APPLY_COUPON,
      //   payload: response,
      // });
      dispatch(getCart())
    } else {

    }
    callback(response)
  } catch (error) {

  }
};

export const removeCouponAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken } = state.user.otpResponse || {}
    const { merchantId, cartId } = state.cart
    const response = await apiClient.get(apiClient.Urls.removeCoupon, {
      merchantAccessToken: config.merchantAccessToken,
      authToken: authToken,
      merchantId: merchantId,
      cartId: cartId,
    });
    if (response.success) {
      // dispatch({
      //   type: REMOVE_COUPON,
      //   payload: response,
      // });
      dispatch(getCart())
    } else {

    }
  } catch (error) {
  }
};

const couponReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_COUPON: {
      return {
        ...state,
        coupons: action.payload,
      }
    }

    case REMOVE_COUPON: {
      return {
        ...state,
        coupons: action.payload,
      }
    }

    case GET_COUPONS: {
      return {
        ...state,
        coupons: action.payload,
      }
    }
    default: return state;
  }
};

export default couponReducer;
