import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { requestVerifyOtp, requestRegister, setRegisterResponseReset, requestLogin, requestRegisterConfirm } from '../../../../../../actions';
import './authFlow.css';
import OtpInput from 'react-otp-input';
import rightArrowLight from '../../../../../../images/icons/rightArrowLight.svg'
import resend from '../../../../../../images/icons/resend.svg'
import phone from '../../../../../../images/icons/phone.svg'

function VerifyOTPCard(props) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const reduxOtpRes = useSelector(state => state.user?.otpResponse)
    const homepage = useSelector(state => state.homepage)

    const [counter, setCounter] = React.useState(30);
    const [otp, setOtp] = useState('');
    const inputRefs = useRef([]);
    const [registershow, setRegisterShow] = useState(false);
    const [isResendOtp, setisResendOtp] = useState(false)
    const [errorPrompt, setErrorPrompt] = useState('')

    const counterTime = () => {

        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }
    const resendOtp = () => {
        if (counter === 0) {
            setCounter(30);
            setisResendOtp(true)
            dispatch(requestRegister(props.registerInfo));
            counterTime();
        }
        else {

        }
    }


    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const handleVerifyOtp = () => {
        let requestOptions = {
            udid: homepage.deviceDetails.udid,
            uuid: user.registerResponse.uuid,
            fullname: user.registrationDetails.name,
            email: user.registrationDetails.email,
            password: user.registrationDetails.password,
            gender: user.registrationDetails.gender,
            country: user.registrationDetails.country,
            city: user.registrationDetails.city,
            countryCode: props.loginInfo.countryCode,
            phoneNumber: props.loginInfo.mobileNumber,
            otp: Number.parseInt(otp),
        }

        props.setOtp(Number.parseInt(otp))
        dispatch(requestVerifyOtp(requestOptions, result => {
            if (result.success === true) {
                if (window.clarity && !!result.uuid) {
                    window.clarity('set', 'UserId', result.uuid)
                }
                props.setCurrentScreen(3)
            }
            else {
                setErrorPrompt(result?.message)
            }
        }));
    };


    useEffect(() => {
        // if (user.registerStepCompleted === 2) {
        //     props.setCurrentScreen(2)
        // } else if (user.registerStepCompleted === 1) {
        //     props.setCurrentScreen(1)
        // }
        // else {
        //     props.setCurrentScreen(0)
        // }
    }, [user.registerStepCompleted]);

    useEffect(() => {
        return () => {
            setisResendOtp(false)
        }
    }, [])

    const handleGoBack = () => {
      dispatch(setRegisterResponseReset())
        props.setCurrentScreen(0)
    };
    return (
        <div>
            {/* <div className="modalHeading">This is Verify OTP Page</div>
            <p className="pera">Please verify your account to continue with your order</p>
            <div className="contactNumber">
                <img src={phone} alt="Mobile" />
                {props.registerInfo?.phoneNumber.replace(/[^a-zA-Z0-9]/g, '')}
                <button onClick={handleGoBack}>Edit</button>
            </div> */}
            <p className="pera">We have sent you a verify OTP to your phone number.</p>

            <div className="otp">
                <OtpInput
                    inputStyle={{ padding: 0 }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    isInputNum={true}
                    separator={<span>-</span>}
                />
            </div>
            <div onClick={resendOtp} className="resend" style={{ cursor: 'pointer' }}>
                <div>Resend OTP code</div>
                <img src={resend} alt="Mobile" />
            </div>
            <div style={{ color: '#49CE8B' }}>00: {counter}</div>
            {isResendOtp && <p className='my-2 text-success h6'>OTP is sent.</p>}

            {!user.otpVerifyInProgress && user.otpResponse && !user.otpResponse.success && <p className='my-2 text-danger h6'>   {errorPrompt}</p>}
            <div style={{ cursor: 'pointer' }} className="contactNumber" onClick={handleGoBack}>Change Number?</div>
            <button onClick={handleVerifyOtp} className="modalRegBtn">Verify OTP
                {user.otpVerifyInProgress ?
                    <div className='spinner-border text-primary' />
                    :
                    <img src={rightArrowLight} alt="Arrow" />}
            </button>
        </div>
    )
}

export default VerifyOTPCard
