import React from 'react';
import '../Layout/Header/header.css';
import '../Layout/index';
import Layout from '../Layout/index';

function Notfound(props) {

  return (
    <Layout>
      <div className="notfound">
        <h2 className="notfound-text">404</h2>
        <h6 className="notfound-tag">OOPS! PAGE NOT FOUND</h6>
      </div>
    </Layout>
  );
}

export default Notfound;
