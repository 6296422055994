import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import './index.css';

import CartList from './CartList'
const modalStyles = {
    position: 'absolute',
    top: 20,
    right: 10,
    transform: 'translate(-50%, -50%) !important'
};
export default function CartModal(props) {
    const { show, setShow } = props;
    const reduxState = useSelector(state => state.user)
    const googleLocation = reduxState.userLocation !== null && Object.keys(reduxState.userLocation).includes('results') ? reduxState.userLocation.results[4].formatted_address : null;


    const handleShow = (param) => {
        setShow(param)
    }


    return (
        <Modal dialogClassName={"primaryModal"} show={show} onHide={() => { handleShow(false) }} animation={false} >
            <div className="loactionswitch"
            >
                <CartList {...props} setShow={handleShow} />
            </div>
        </Modal>

    )
}
