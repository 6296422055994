import React, { useState, } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import LoginModal from '../../Common/LoginModal';

import AddressModal from '../../Common/AddressModal';
import { CheckCircle, RightArrow, Delete } from '../../../images/icons/IconSets';
import OfficeImg from '../../../images/Group 30275.png';
import { AlertAddressModal } from '../../Common/alertModal/AlertAddressModal'
import { selectAddress } from '../../../reducers/checkout';

function Address(props) {
    const dispatch = useDispatch()
    const reduxCheckout = useSelector(state => state.checkout)

    const [addressShow, addressSetShow] = useState(false);
    const reduxState = useSelector(state => state.user?.userDetails)
    const isLoggedIn = !!(props.user?.userDetails && props.user.userDetails.authToken);
    const ifLoggedin = useSelector(state => state.user)
    const [loginShow, setloginShow] = useState(false)
    const [alertShow, setAlertShow] = useState(false)
    const [selectedAddress, setSelectedAddress] = useState()

    return (
        <div>
            {addressShow && <AddressModal show={addressShow} setShow={addressSetShow}  {...props} />}
            {loginShow && (
                <LoginModal show={loginShow} setShow={setloginShow} {...props} />
            )}
            {alertShow && <AlertAddressModal show={alertShow} setShow={setAlertShow} selectedAddress={selectedAddress} />}


            <div className="whiteBox">
                <div className="myAccHeading marBot30">ADDRESSES</div>
                <div className="row addressmain-scroll">

                    {reduxState.address && reduxState.address.addresses.length > 0 ? reduxState.address.addresses.map((value, i) =>
                        <div key={i} className="col-lg-6 col-md-6 mydetailsaddress">
                            <div className="address-style" style={{ display: 'flex', position: 'relative' }}>
                                <div
                                    onClick={() => {
                                        setSelectedAddress(value)
                                        setAlertShow(true)
                                    }} style={{ right: 15, top: 5, position: 'absolute', cursor: 'pointer' }}>
                                    <Delete width={15} height={15} color="#C60000" />
                                </div>
                                <div>
                                    {
                                        value.tag === 'home' ?
                                            <div className="home-img">
                                                <img src="assets/home.svg" alt="" />
                                            </div>
                                            :
                                            <div className="home-img">
                                                <img src={OfficeImg} alt="" />
                                            </div>
                                    }
                                </div>
                                <div style={{ margin: 5, }} >
                                    <h6>{value.tagName}</h6>
                                    <div style={{ color: '#748090', }}>
                                        <text>
                                            {
                                                value.address1 + ', ' +
                                                value.landmark + ', ' +
                                                value.address2 + ', ' +
                                                value.city + ', ' +
                                                value.locality
                                            }
                                        </text>
                                    </div>
                                    {/* {value.id === reduxCheckout?.selectedDeliveryAddressId ?
                                        <div style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 20 }}>
                                            <CheckCircle width={15} height={15} color="#28a745" />
                                            <text>&nbsp; Default</text>
                                        </div>
                                        :
                                        <div
                                            onClick={() => dispatch(selectAddress(value))}
                                            style={{ display: 'flex', alignItems: 'center', position: 'absolute', bottom: 20, cursor: 'pointer' }}>
                                            <CheckCircle width={15} height={15} color="#B2BAC3" />
                                            <text> &nbsp;Set as Default</text>
                                        </div>
                                    } */}
                                </div>
                            </div>
                        </div>
                    )
                        :
                        null}
                    <div className="col-lg-6 col-md-6" >
                        <div className="delivery-address"
                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', cursor: 'pointer', color: '#E5AB4E', padding: 20 }}
                            onClick={() => addressSetShow(true)} >
                            <img src="assets/plus.svg" alt="" />
                            <text>ADD NEW ADDRESS</text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Address;