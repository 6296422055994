import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import './contactUs.css';
import Form from 'react-bootstrap/Form'
import {
    contactUsFormSubmit
} from "../../../actions";
import { contactUsInfo } from '../../../reducers/homepagecms';
import { contactUSForm } from '../../../reducers/homepagecms';
import LocationStore from '../../Locations/Container/index';
import ContactSuccessModal from '../../Common/ContactSuccess/ContactSuccessModal';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
function ContactUs(props) {
    const dispatch = useDispatch()
    const reduxcontactinfonew = useSelector(state => state.homepagecms);
    const reduxhome = useSelector(state => state.homepage);
    const { userDetails } = props;
    const [validated, setValidated] = useState(false);
    const [showFormSuccessful, setFormSuccessful] = useState(false);
    const [singleSelections, setSingleSelections] = useState([]);
    const [options, setoptions] = useState([])
    const [address, setAddress] = useState({
        name: '',
        message: '',
        storeselectedname: '',
        email: '',
        phoneNumber: ''
    });
    useEffect(() => {
        let savedData = {
            email: userDetails?.profile.email,
            phoneNumber: userDetails?.profile.phoneNumber
        }
        let merged = { ...address, ...savedData }
        setAddress(merged)

    }, [])

    useEffect(() => {
        dispatch(contactUsInfo());
    }, [])

    useEffect(() => {
        if (reduxhome.availableModesDetails) {
            setoptions(["Biryanis and More, Corporate", ...reduxhome.availableModesDetails?.data?.map((item, i) => item.name)])
        }
    }, [reduxhome.availableModesDetails])

    //   const [validated, setValidated] = useState(false);
    const handlechangenote = (e) => {
        const { name, value } = e.currentTarget;
        setAddress({
            ...address,
            [name]: value
        });
    }
    const handlechangenotetwo = (e) => {

        setAddress({
            ...address,
            storeselectedname: { storeselectedname: e }
        });
    }
    // const [ showerror , setshowerror] = useState(false);




    const handleSubmitTwo = () => {
        const requestOptions = {
            ...address,
            name: address.name,
            message: address.message,
            storeselectedname: singleSelections[0]
        };
        if (
            requestOptions.name?.length > 0 &&
            requestOptions.message?.length > 0 &&
            requestOptions.storeselectedname?.length > 0
        ) {
            dispatch(contactUsFormSubmit(requestOptions, result => {
                if (result.success === true) {
                    setFormSuccessful(true)
                }
            }))
        }
        else {
            setValidated(true);
        }
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };

    const setSuccessShow = () => {
        setFormSuccessful(false)
    }

    return (
        <div className="contactUs">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-6 noPadding">
                        <div className="contactDetails">
                            <h4 style={{ marginTop: '63px' }}>Contact Us</h4>
                            <div className="contactBox">
                                <img src="assets/white-map-pin.svg" alt="Location" />
                                <div><p>HQ-INDIA</p></div>
                                {/* <p>40-1-52E, Sai Nag Complex</p>
                                <p>MG Road, Vijayawada - 520010</p> */}
                                {/* <div className="content" dangerouslySetInnerHTML={{ __html: reduxcontactinfonew?.contactinfo?.data && reduxcontactinfonew?.contactinfo?.data[0].address }}></div> */}
                            </div>
                            <div className="contactBox">
                                <img src="assets/white-phone.svg" alt="Phone" />
                                <div><small>Call us on</small></div>
                                {/* <p className="phn"><a href="tel:+917032202222">+91 703 220 2222</a></p>
                                <p className="phn"><a href="tel:+919069041234">+91 906 904 1234</a></p>
                                <p className="phn"><a href="tel:+918662477422">+91 866 247 7422</a></p> */}
                                <div className="content" dangerouslySetInnerHTML={{ __html: reduxcontactinfonew?.contactinfo?.data && reduxcontactinfonew?.contactinfo?.data[0]?.contactNumber }}></div>
                                {/* <div><small>11:00 am - 07:00 pm IST</small></div> */}
                            </div>
                            <div className="contactBox">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
                                <div><small>Write to us on</small></div>
                                {/* <p>info@biryanis.com</p> */}
                                <div className="content" dangerouslySetInnerHTML={{ __html: reduxcontactinfonew?.contactinfo?.data && reduxcontactinfonew?.contactinfo?.data[0]?.contactEmail }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-6 noPadding">
                        {ContactSuccessModal && <ContactSuccessModal setShow={setSuccessShow} show={showFormSuccessful} />}
                        <div className="formSection contactForm">
                            <h2 className="writeto">Write to us</h2>
                            <p>Any special request or query please send us a message</p>
                            <Form className="modelForm-fr" noValidate validated={validated} onSubmit={handleSubmit}>

                                <Form.Group md="4" className="nametext" controlId="validationCustom01">
                                    <Form.Label>Name<span class="required-fr">*</span></Form.Label>
                                    <Form.Control
                                        name='name'
                                        value={address.name}
                                        onChange={handlechangenote}
                                        required="required-fr"
                                        type="text"
                                        className="name-fr"
                                        placeholder="Enter your name"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide name.</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group md="4" className="nametext" controlId="validationCustom01">
                                    <Form.Label>Email<span class="required-fr">*</span></Form.Label>
                                    <Form.Control
                                        name='email'
                                        value={address.email}
                                        onChange={handlechangenote}
                                        type="text"
                                        required="required-fr"
                                        className="name-fr"
                                        placeholder="Enter your email"

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide email</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group md="4" className="nametext" controlId="validationCustom01">
                                    <Form.Label>Phone number<span class="required-fr">*</span></Form.Label>
                                    <Form.Control
                                        name='phoneNumber'
                                        value={address.phoneNumber}
                                        onChange={handlechangenote}
                                        type="text"
                                        required="required-fr"
                                        className="name-fr"
                                        placeholder="Enter your phone number"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide phonenumber</Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group md="4" className="nametext" controlId="validationCustom01">
                                    <Form.Label>Store Selection<span class="required-fr">*</span></Form.Label>
                                    <Typeahead
                                        name='storeselectedname'
                                        id="pagination-example"
                                        inputProps={{ required: true }}
                                        // onPaginate={(e) => console.log('Results paginated')}
                                        options={options}
                                        placeholder="Select store..."
                                        // onChange={handlechangenote}
                                        // className="name-fr"
                                        value={singleSelections[0]}
                                        //    defaultInputValue="Biryanis-corporative"

                                        onChange={setSingleSelections}
                                        selected={singleSelections}

                                    />
                                    {/* <Form.Control as="select"
                                name='storeselectedname'
                                type="text"
                                required="required-fr"
                                onChange={handlechangenote}
                                className="name-fr"
                                placeholder="Enter your query"
                                value={address.storeselectedname}
                            >
                                 <option name="storedefaultname" className="storedefaultname" value="Biryanis-corporative">Biryanis-corporative</option>
                                 {reduxhome.availableModesDetails?.data?.map((item,i) => 
                                   
                                          <option name="storeselectedname" value={item.name}>{item.name}</option>
                                   
                                 )}
                            </Form.Control> */}
                                    <Form.Control.Feedback type="invalid">
                                        Please select the store.</Form.Control.Feedback>
                                    {/* { showerror === true && (
                                <p style={{color:'red'}}>
                                    Please select the store.
                                </p>
                            )} */}
                                </Form.Group>

                                <Form.Group md="4" className="nametext" controlId="validationCustom01">
                                    <Form.Label>Message<span class="required-fr">*</span></Form.Label>
                                    <Form.Control as="textarea" rows={3}
                                        name='message'
                                        value={address.message}
                                        type="text"
                                        required="required-fr"
                                        onChange={handlechangenote}
                                        className="name-fr"
                                        placeholder="Enter your query"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide your query here.</Form.Control.Feedback>
                                </Form.Group>

                            </Form>
                            {/* <div className="row">
                                <div className="col-md-6">
                                    <input type="text"  name='name' value={address.name} onChange={handlechangenote} className="form-control" placeholder="Full name"   />
                                </div>
                            </div> */}
                            {/* <div className="row">
                                <div className="col-md-6">
                                    <input type="text" className="form-control" placeholder="Email ID" value={userDetails?.profile.email} />
                                </div>
                                <div className="col-md-6 mmt">
                                    <input type="text" className="form-control" placeholder="Phone number" value={userDetails?.profile.phoneNumber} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <textarea className="form-control"  name='message' value={address.message} onChange={handlechangenote} placeholder="Message"></textarea>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <button onClick={() => handleSubmitTwo()} className="prbtn">SUBMIT</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LocationStore />
            </div>
        </div>
    );

}

export default ContactUs;
