import React, { useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import { Card, Image } from 'react-bootstrap';
import Fbicon from './facebook.svg'
import { facebookLoginCheckAction } from '../../../reducers/user';
import { useDispatch } from 'react-redux';
import config from '../../../config'
function LoginFacebook(props) {
  const dispatch = useDispatch()
  const [login, setLogin] = useState(false);

  const responseFacebook = (response) => {
    console.log(response);
    if (response.accessToken) {
      dispatch(facebookLoginCheckAction(response, result => {
        if (result.authToken) {
          props.setRegisterShow(false);
          props.setLoginShow(false);
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
        else {
          setLogin(true);
          props.gotoRegister()
        }
      }))

    } else {
      setLogin(false);
    }
  }

  return (
    <div >
      {!login &&
        <FacebookLogin
          buttonStyle={{ backgroundColor: '#fff' }}
        appId={config.appId}
          autoLoad={false}
          fields="name,email,picture"
          scope="email"
          callback={responseFacebook}
          cssClass="my-facebook-button-class"
          icon={<img src={Fbicon} alt="Facebook" />}
          textButton=''
        />
      }

    </div>
  );
}

export default LoginFacebook;