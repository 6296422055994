import React, { Component } from 'react';
import { Map, Marker } from 'google-maps-react';
import LocationImg from './location.svg'
import locationblank from './locationblank.svg'

class GoogleMapsDraggable extends Component {
    constructor(props) {
        super(props);
         let location = !!this.props.user.userLocation?.latitude ?
      this.props.user.userLocation :
      this.props.user.userDetails?.locationPreference

        this.state = {
            location: {
                lat: location.latitude,
                lng: location.longitude
            },
            selectedPlace: 'props',
            activeMarker: {},
            showingInfoWindow: true,
            selectedMerchantid: 0,

            markers: [
                {
                    name: "Current position",
                    position: {
                        lat: 37.77,
                        lng: -122.42
                    }
                }
            ]
        }
    }

    onMarkerClick = (props, marker) =>
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
        });

    onInfoWindowClose = () => {
        this.props.setselectedMerchant()
        this.setState({
            activeMarker: null,
        });

    }
    onMarkerDragEnd = (coord, index) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();

        const location = {
            latitude: lat,
            longitude: lng,
        }
        this.props.getLocationFromSearch(location)
        this.setState(
            { location: { lat: lat, lng: lng } }
        );
    };
    onMapDragEnd = (mapProps, map) => {
        const location = {
            latitude: map.center.lat(),
            longitude: map.center.lng(),
        }
        this.props.getLocationFromSearch(location)
    };
    render() {
        let location = !!this.props.user.userLocation?.latitude ?
      this.props.user.userLocation :
      this.props.user.userDetails?.locationPreference

        return (
            <div style={{
                position: 'relative', width: '100%', height: 220,
                marginTop: 10, marginBottom: 10
            }}
            >
                <img style={{ position: 'absolute', zIndex: 2, top: '30%', left: '47%' }} src={LocationImg} alt="images" />
                <Map
                    style={{ height: 220 }}
                    initialCenter={
                        {
                            lat: location.latitude,
                            lng: location.longitude
                        }}
                    center={
                        this.props.locationfromSearch ?
                            {
                                lat: this.props.locationfromSearch.latitude,
                                lng: this.props.locationfromSearch.longitude
                            }
                            :
                            {
                                lat: location.latitude,
                            lng: location.longitude
                            }}

                    google={window.google}
                    zoom={this.props.locationfromSearch ? 13 : 10}
                    onClick={this.onMapClicked}
                    onDragend={this.onMapDragEnd}
                    mapTypeControl={false}
                    streetViewControl={false}
                    fullscreenControl={false}
                >
                    <Marker
                        style={{ height: 0 }}
                        id={1}
                        icon={locationblank}
                        position={
                            this.props.locationfromSearch ?
                                {
                                    lat: this.props.locationfromSearch.latitude,
                                    lng: this.props.locationfromSearch.longitude
                                }

                                :
                                {
                                    lat: this.state.location.lat,
                                    lng: this.state.location.lng
                                }
                        }
                        onClick={this.onMarkerClick}
                        draggable={true}
                        onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                    />
                </Map>
            </div>
        );
    }
}
export default GoogleMapsDraggable