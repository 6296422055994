import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import './StoreLocation.css';
import { RightArrow, LeftArrow } from '../../../images/icons/IconSets'
import {
  setSelectedMerchantDetails, setSelectedMerchantServiceability, checkServiceability, setCurrentAvailableModes, requestAddressChunksfromCoordinates, setOrderMode, setInitialOrderMode
} from '../../../actions';
import HotelImg from '../../../images/hotel.png'
import { useGetLocation } from '../../../utils/utilities'
import { setMerchantAction } from '../../../reducers/merchant';
import moment from 'moment'
function StoreLocation(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const reduxHomepage = useSelector(state => state.homepage)
  const reduxUserStates = useSelector(state => state.user)
  const reduxOrderStates = useSelector(state => state.orders)
  const merchant = useSelector(state => state.merchant)

  const userLocation = useGetLocation()
  const [isLoading, setisLoading] = useState(false)
  const [selectedOrderMode, setselectedOrderMode] = useState('')
  const orderTypes = { delivery: 'Delivery', takeAway: 'Takeaway', dineIn: 'Dine In', online: 'Online' }

  const handleMerchant = async (param) => {
    if (reduxHomepage.nearestMerchantDetails === null
      ||
      (param.id === reduxHomepage.nearestMerchantDetails?.id)) {
      setisLoading(true)
      dispatch(setMerchantAction(param))
      dispatch(setSelectedMerchantServiceability(param));
      await props.setShow(false)
      setisLoading(false)
      history.push({ pathname: `/order/${param.slug}/${reduxOrderStates.orderMode}` })
    }
    else {
      props.handleSelectMerchant(param)
      props.tabSelected(2)
    }
  }

  useEffect(() => {
    setisLoading(true)
    let location = reduxUserStates.userLocation?.latitude ?
      reduxUserStates.userLocation :
      reduxUserStates.userDetails?.locationPreference
    console.log('check no.  of exec')
    dispatch(requestAddressChunksfromCoordinates(location, result => {
      if (result.success) {
        const params = { type: 'all', ...location, ...reduxOrderStates, ...result }
        dispatch(checkServiceability(params))
      }
      else {
        const params = { type: 'all', ...location, ...reduxOrderStates }
        dispatch(checkServiceability(params))
      }
    }))
    setselectedOrderMode(reduxOrderStates.orderMode)
    setisLoading(false)
  }, [])


  const getOpeningTimefromtimeSlots = (merchantId) => {
    try {
      const { timeSlots: a } = merchant || {}
      let dates = a[merchantId]
      // console.log(dates)
      let date = Object.keys(dates)[0]
      // console.log(date)

      let time = dates[date][0]
      // console.log(date, time)
      var myDate = new Date(date);
      // console.log({ myDate })
      let combinedDte = `${date} ${time}`
      // return moment(combinedDte).calendar()
      return (moment(combinedDte).format('Do MMMM, h:mm A'))
    } catch (error) {
      console.log(error)
    }

  }


  const RenderChips = ({ value }) => {
    const { serviceable, slug, unserviceableStatus } = value
    const allModesStatus = [
      {
        type: selectedOrderMode,
        serviceable: serviceable,
      },
      ...value.modesServiceable
    ]

    /* SORT FOR SERVICEABLE=TRUE */
    let sorted = allModesStatus.filter(a => a.type !== 'menu').sort((x, y) => {
      return (x.serviceable === y.serviceable) ? 0 : x.serviceable ? -1 : 1;
    }
    )

    const handleChipClick = (item) => {
      dispatch(setOrderMode(item.type))
      if (item.type !== 'menu' || item.type !== 'online') {
        dispatch(setInitialOrderMode(item.type))
      }
      if (reduxHomepage.nearestMerchantDetails === null
        ||
        (value.id === reduxHomepage.nearestMerchantDetails?.id)) {
        setisLoading(true)
        dispatch(setMerchantAction(value))
        dispatch(setSelectedMerchantServiceability(value));
        props.setShow(false)
        setisLoading(false)
        history.push({ pathname: `/order/${value.slug}/${item.type}` })
      }
      else {
        props.handleSelectMerchant(value)
        props.tabSelected(2)
      }
    }

    return (
      <div style={{
        display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 12
      }}>
        {sorted.map((item, i) => (
          <div
            className={
              !!item.serviceable
                ||
                (reduxOrderStates.orderMode === 'takeAway'
                  &&
                  item.type === 'takeAway'
                  &&
                  unserviceableStatus === "STORE_CLOSED"
                )
                ? 'mode-chip' : 'mode-chip-disabled'
            }
            onClick={() => {
              item.serviceable === true
                ||
                (reduxOrderStates.orderMode === 'takeAway'
                  &&
                  item.type === 'takeAway'
                  &&
                  unserviceableStatus === "STORE_CLOSED"
                )
                ? handleChipClick(item) : null
            }}
          >
            {orderTypes[item.type]}
            {(item.serviceable === true && serviceable === false) ?
              <>
                &nbsp;Available&nbsp;&nbsp;
                <RightArrow width={15} height={15} color="#fff" />
              </>
              : ''
            }
          </div>
        ))
        }
      </div>
    )
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      <div style={{ display: 'flex' }}>
        <div style={{
          cursor: 'pointer',
          top: 6,
          left: 12,
          position: 'absolute',
          opacity: 0.6
        }}
          onClick={() => props.tabSelected(0)}>
          <LeftArrow width={18} height={18} color="#4D3A21" />
        </div>
      </div>

      <div className="locationhead">Select Store</div>
      {!reduxOrderStates.serviceability?.merchants ?
        <div style={{ display: 'flex', height: 'max-content', justifyContent: 'center', alignItems: 'center' }}>
          <Spinner animation="border" style={{ alignSelf: 'center', marginTop: 150 }} />
        </div>
        :
        reduxOrderStates.serviceability?.merchants?.length === 0 ?
          <div style={{ display: 'flex', height: 'max-content', justifyContent: 'center', alignItems: 'center' }}>
            <text style={{
              alignSelf: 'center', marginTop: 150,
              font: 'normal normal bold 16px/21px Lato'
            }}>No nearby stores found.</text>
          </div>
          :
          <div className='str-root' >
            {
              reduxOrderStates.serviceability && reduxOrderStates.serviceability.merchants?.map((value, i) =>
                <div
                  key={i}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: ' 1px solid #F7F7F7'
                  }}>
                  <div
                    key={i}
                    className='str-card'
                    style={{ cursor: 'default' }}
                  >
                    <div >
                      <div className={!value.serviceable && 'serv-img'}
                        onClick={() => handleMerchant(value)}
                        style={{ display: 'flex', flexDirection: 'row', position: 'relative', cursor: 'pointer' }}>
                        <img
                          style={{
                            height: 60, marginRight: 10,
                          }} src={HotelImg} alt="" />
                        <div  >
                          <p className={value.serviceable || reduxOrderStates.orderMode === 'menu' ? 'text-color' : 'text-color-faint'}>{value.name}</p>
                          {value.deliveryEta?.length > 0 &&
                            <p className='text-color-faint' >
                              {value.deliveryEta}</p>}
                        </div>
                      </div>
                    </div>
                    <div style={{
                      display: 'flex', flexDirection: 'row',
                      alignItems: 'center'
                    }}>
                      {value.serviceable || reduxOrderStates.orderMode === 'menu' ?
                        <div
                          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', width: 65 }}
                          onClick={() => handleMerchant(value)}>
                          <text>Select</text >
                          <RightArrow width={16} height={16} color="grey" />
                        </div>
                        :
                        <text style={{ marginLeft: 10, fontSize: 'small' }}>Unserviceable</text >
                      }
                    </div>
                  </div >

                  {selectedOrderMode === 'takeAway'
                    ?
                    <>
                      <text style={{ margin: '5px 5px 0px 5px', fontSize: 'small', textAlign: 'initial' }}>
                        {value.unserviceableReason}



                      </text >
                      {selectedOrderMode === 'takeAway'
                        &&
                        value.unserviceableStatus === "STORE_CLOSED"
                        &&
                        !!getOpeningTimefromtimeSlots(value.id)
                        ?
                        <text style={{ margin: '0px 5px 10px 5px', fontSize: 'small', textAlign: 'initial' }}>
                          {`The store will open at ${getOpeningTimefromtimeSlots(value.id)}. You can place a pre-order.`}
                        </text>
                        :
                        ''
                      }
                    </>
                    :
                    null
                  }

                  {
                    !!value.modesServiceable === true
                      ?
                      <RenderChips value={value} />
                      :
                      null
                  }
                </div>
              )
            }
          </div>
      }
    </div >
  );
}

export default StoreLocation;