import apiClient from '../api/index';
// import { identical } from 'ramda';

let unique = arr => arr.filter((item, i, ar) => ar.indexOf(item) === i);

const initialState = {
  items: [],
  currency: '',
  cities: [],
  addons: [],
  merchantIds: [],
  id: '',
  name: '',
  orderMode:'',
  loader: true,
  error: '',
};

const MENU_INIT = "menu/MENU_INIT"
const MENU_CATALOGUE = "menu/MENU_CATALOGUE"


export const menuInit = (slug,mode) => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.merchantSlug, {
      merchantSlug: slug,
    });

    if(response.success) {
      const id = response.id;
      const name = response.name;
      const orderMode = mode;
      dispatch({
        type: MENU_INIT,
        data: response,
        id,
        name,
        orderMode,
      });
    } else {

    }

  } catch (error) {

  }
};
export const merchantCatalogue = () => async (dispatch, getState) => {
  const state = getState();
  try {
    const { merchantAccessToken } = state.home.config;
    const response = await apiClient.get(apiClient.Urls.catalogue, {
      merchantAccessToken,
      merchantId: state.menu.id,
    });

    if(response.success) {
      const items = unique(response.categories);
      const currency = response.currency;
      const addons = response.addons;
      const loader = false;
      dispatch({
        type: MENU_CATALOGUE,
        data: response,
        items,
        currency,
        addons,
        loader,
      });
    } else {
    }

  } catch (error) {

  }
};

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_INIT: {
      return {
        ...state,
        id: action.id,
        name: action.name,
        orderMode: action.orderMode,
      }
    }
    case MENU_CATALOGUE: {
      return {
        ...state,
        items: action.items || [],
        currency: action.currency,
        addons: action.addons,
        loader: action.loader,
      }
    }

    default: return state;
  }
};

export default menuReducer;
