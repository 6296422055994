import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    requestUserProfile
  } from '../../../actions/index';

import ContactUsPage from '../Components/index'
import Layout from '../../Layout/index'

class ContactUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = 'Contact Us - Biryanis and More'
        if (this.props.user.isAuthenticated) {
            this.props.requestUserProfile();
        }
    }

    render() {
        return (
            <div>
                <Layout >
                    <ContactUsPage userDetails={this.props.userDetails} />
                </Layout>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    userDetails: state.user.userDetails?.profile,
  });

export default connect(mapStateToProps, {
    requestUserProfile,
})(ContactUs);
