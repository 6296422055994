import React, { Component, useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import './pointModel.css';
import {
  Popover,
  Tooltip,
  Modal
} from 'react-bootstrap';
import { applyPointsAction } from '../../../reducers/loyaltyPoints'
import AlertToast from "../alertToast";


export function PointsModal(props) {
  const { show, setShow } = props || {}
  const checkout = useSelector(state => state.checkout)
  const reduxCart = useSelector(state => state.cart)
  const loyaltyPointsRedux = useSelector(state => state.loyaltyPoints)

  const dispatch = useDispatch();

  const { items, addresses, selectedDate, selectedTime, cartId, tip, tipType, tipRate, currencySymbol, cartSubTotal, cartPoints } = reduxCart || {}
  const { loyaltyPoints } = loyaltyPointsRedux.points || {}
  const { currencyConversionRate, } = checkout || {}
  const [alertToastValues, setAlertToastValues] = useState({});

  const { availablePoints, availablePointsValue,
    cartTotalAfterPoints, pointsToRedeem } = cartPoints || {}

  const handleApplyPoints = () => {

    dispatch(applyPointsAction(result => {
      if (result.success === true) {
        setShow(false)
      }
      else {
        setAlertToastValues({
          ...alertToastValues,
          showToast: true,
          title: result.message,
        })

        setTimeout(() => {
          setAlertToastValues({
            ...alertToastValues,
            showToast: false,

          })
        }, 3500);

      }
    }));
  }


  return (
    <div>
      <Modal show={show} onHide={() => { setShow(false) }} animation={false}>
        <Modal.Header closeButton />
        <Modal.Body>
          {
            alertToastValues.showToast === true ?
              <AlertToast
                alertToastValues={alertToastValues}
              />
              : null
          }
          <div className="modalHeading">Points</div>
          <p className="pointpara">Apply points from here</p>
          <div className="pointBox">
            <img src="assets/star.svg" alt="Img" />
            <p className="pointpara">You have</p>
            <div className="pointCount">{availablePoints} POINTS</div>
          </div>
          <div className="pointTable">
            <div className="row align-items-center">
              <div className="col-md-6 text-left">
                Order sub value before applying points
              </div>
              <div className="col-md-6 text-right">
                <strong><sup>{currencySymbol}</sup>{cartSubTotal}</strong>
              </div>
            </div>
            <div className="row align-items-center mt-3 border-bottom pb-3">
              <div className="col-md-6 text-left">
                Points value
              </div>
              <div className="col-md-6 text-right">
                <strong>{currencySymbol}{cartPoints?.availablePointsValue}</strong>
              </div>
            </div>
            <div className="row mt-2 align-items-center">
              <div className="col-md-6 text-left">
                Order sub value after applying points
              </div>
              <div className="col-md-6 text-right">

                <strong className="yellow"><sup>{currencySymbol}</sup>
                  {cartPoints?.cartTotalAfterPoints}
                </strong>

              </div>
            </div>
          </div>
          <button className="modalPrimaryBtn justify-content-center"
            onClick={() => handleApplyPoints()}>APPLY POINTS</button>
        </Modal.Body>
      </Modal>
    </div>
  )
};

// points: state.checkout.points,