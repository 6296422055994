import config from '../config';
import { getRequestOptions } from './homepage.service';

export const freebiesServices = {
  getAllFreebies,
};

function getAuthToken () {
  const authToken = localStorage.getItem('auth_token');
  return authToken;
}

async function getAllFreebies (params = {}) {
  const requestOptions = getRequestOptions();
  const authToken = getAuthToken();

  const response = await fetch(`${config.apiUrl}/loyalty/my-freebie/list?merchantAccessToken=${config.merchantAccessToken}&authToken=${authToken}`, requestOptions);

  if (response.status !== 200) {
    Promise.reject('Unable to fetch freebies');
  }
  return response.json();
}

