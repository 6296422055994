import React from "react";
import { useSelector } from "react-redux";
import './banner.css';
import 'font-awesome/css/font-awesome.min.css';
import GooglePlayBadge from '../../../images/google-play-badge.png'
import BottomBannerImg from '../../../images/bottom-banner.png'
function Banner() {
  const homepagecms = useSelector(state => state.homepagecms)
  const { appStoreLink, playStoreLink, latestAppVersion, footerCopyright } = homepagecms?.configuration || {}
  return (
    <div className="appDetails">
      <div className="appnewrow">
        <img className="bannerimg-fr" src={BottomBannerImg} />
        <div className="appnewseconddiv" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginLeft: '49px', paddingTop: 20 }}>
          <h2 className="appnewh2">Do more with our <span className="appnewspan">Biryanis and More App</span></h2>

          {/* <div class="input-group appnewbody mb-3">
            <input type="text" class="form-control" placeholder="Enter your phone number" aria-label="Recipient's username" aria-describedby="basic-addon2" />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">Send Link</span>
            </div>
          </div> */}

          <div >
            <text className="appnewdownloadtext">Download app from</text>
            <div className="appLinkButtons" >
              <a href={appStoreLink}>
                <img src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png" height="40" width="" alt="" />
              </a>

              <a href={playStoreLink}>
                <img src={GooglePlayBadge} height="60" width="" alt="" />
              </a>
            </div>
          </div>
        </div>



        {/*
        <div className="appnewseconddiv" style={{ marginLeft: '49px' }}>
     <h2 className="appnewh2">Do more with our <span className="appnewspan">Biryanis App</span></h2>
          <p className="appnewtext">We will send you a link to download our app</p>
          <div class="input-group appnewbody mb-3">
            <input type="text" class="form-control" placeholder="Enter your phone number" aria-label="Recipient's username" aria-describedby="basic-addon2" />
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">Send Link</span>
            </div>
          </div>
          <p className="appnewdownloadtext">Download app from</p>
          <div className="appnewlinks">
          <a href="#"><img src="https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred_2x.png" height="45" width="" alt="" /></a>
          <a href="#"><img src="assets/g.png" height="" width="" alt="" /></a>
          </div>
        </div>
          */}
      </div>
    </div>
  );
}
export default Banner;