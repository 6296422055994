import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { setTemporaryInputFields } from '../../../../../actions/setState';
import { CheckCircle } from '../../../../../images/icons/IconSets';
import LoginModal from '../../../../Common/LoginModal';
import RegisterModal from '../../../../Common/RegisterModal';
import GuestuserDetails from "../guestPanel/GuestuserDetails";
import AuthFlow from "./authFlow/AuthFlow";
import './Panel.css';
function UserDetailsHeader(props) {
  const reduxState = useSelector(state => state.user?.userDetails)
  const dispatch = useDispatch();
  const user = useSelector(state => state.user)

  const [loginShow, setloginShow] = useState(false)
  const [registerShow, setregisterShow] = useState(false)
  const [detailsuser, setdetailsuser] = useState(false);
  const [customerData, setCustomerData] = useState({
    fullname: '',
    email: '',
    phoneNumber: '',
    country: '',
    city: '',
    fbAccessToken: ''
  });

  const { guest } = user.temporaryInputFields

  const handleshowlogin = () => {
    setloginShow(true)
  }
  const handleshowdetails = () => {
    setdetailsuser(true)
  }
  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setCustomerData({
      ...customerData,
      [name]: value
    })

  };

  const handleGuest = (param) => {
    dispatch(setTemporaryInputFields({ guest: param }))
  }

  return (
    <div className="panel-card your-detail-mobile">
      {loginShow && (
        <LoginModal show={loginShow} setShow={setloginShow} {...props} />
      )}
      {registerShow && (
        <RegisterModal show={registerShow} setShow={setregisterShow} {...props} />
      )}
      {user.isAuthenticated && reduxState?.profile ?
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <text style={{
              color: '#553A28', fontWeight: 'bold',
              fontSize: 'large'
            }}>Your details&nbsp;&nbsp;</text>

            <CheckCircle width={20} height={20} color="#28a745" style={{ float: 'right' }} className="checkcircle-fr" />
          </div>


          <div style={{ display: 'flex', flexDirection: 'row', color: '#282c3f', fontSize: '18px', fontWeight: '500' }}>
            <div>
              <span className="namepay">{reduxState.profile?.profile.name}</span>
            </div>
            <div>
              <span>{reduxState.profile?.profile.phoneNumber}</span>
            </div>
          </div>
        </div>
        :
        guest === true ?
          <GuestuserDetails />
          :
          <div style={{ display: 'flex', flexDirection: 'column', width: 'max-content', }}>
            <text style={{ color: '#553A28', fontWeight: 'bold', fontSize: 'large' }} >
              Personal Details</text>

            <p className="accounttext-fr">Enter your phone number to continue.</p>

            <AuthFlow />

            <p className=""
              onClick={() => handleGuest(true)}
              style={{ fontSize: 15, margin: 20, textAlign: 'center', cursor: 'pointer' }}>Continue as a Guest</p>
          </div>
      }
    </div>
  )
}
export default UserDetailsHeader