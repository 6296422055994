import React, { Component, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';

// import TrackMapModal from '../../Common/TrackMap/TrackMapModal';
import { transactionHistory, transactionDetails } from '../../../reducers/cart'

export const OrderHistory = (props) => {
    const dispatch = useDispatch()

    // useEffect(() => {
    //     props.requestOrderTransactionHistory();
    // }, []);
    const [viewdetails, setviewdetails] = useState(false);
    const [viewmore, setviewmore] = useState(false);
    const handleRateOrder = () => {

    };

    const handleReorder = () => {

    };
    const handleVieworder = (params) => {
        let args = {
            authToken: props.user.otpResponse.authToken,
            id: params.orderId,
            merchantId: props.homepage.nearestMerchantDetails.id,
        }
        dispatch(transactionDetails(args, result => { }))
        setviewdetails(true)
    }

    const handlesettrackmap = () => {
        setviewdetails(false)
    }
    const handleviewmore = () => {
        setviewmore(true)
    }

    return (
        <div>
            <div>
                <span className="tr-head-title order-history">{'Order History'}</span>
            </div>
            {
                props.cart.transactionHistory?.map((item, i) =>
                    <div key={i} className="orderBox">
                        {/* {viewdetails && <TrackMapModal show={viewdetails} setShow={handlesettrackmap} />} */}
                        <div className="orderDetails">
                            <h6>{item.merchantName}</h6>
                            <div className="orderItems"><div>4 Items</div>
                                <div className="orderPrice">{item.details.total}</div>
                                <img src="assets/checked.svg" alt="" />
                                <span>{item.status}</span></div>
                            <div className="items-name" style={{ display: 'flex', flexDirection: 'row' }}>  {
                                item.details.orderItems.map((orderItems, j) =>
                                    <div key={j} className="orderQty">{orderItems.name}X{orderItems.quantity},&nbsp; </div>
                                )
                            }</div>

                            <div>{`Order ID • ${item.orderId}, ${item.date}`}</div>
                            <button onClick={() => handleVieworder(item)} className="viewbtn">view details</button>
                            {/* {JSON.stringify()} */}
                        </div>
                        {/* <div className="orderBtns">
                            <button onClick={handleReorder} className="lightBtn">reorder</button>
                            <button onClick={handleRateOrder} className="darkBtn">rate order</button>
                        </div> */}
                    </div>
                )
            }






            {/* <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <button onClick={handleviewmore} className="viewmorebtn">View More</button>
            </div> */}
        </div>
    )
}

const mapStateToProps = (state) => ({
    homepage: state.homepage,
    userDetails: state.user.userDetails?.profile,
    user: state.user,
    cart: state.cart,
    allCatalogues: state.homepage.allCatalogues,
    cartItems: state.cart.items,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory)
