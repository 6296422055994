import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addCart, removeCart } from '../../../reducers/cart'
import './cartalertModal.css';
import {
  Popover,
  Tooltip,
  Modal
} from 'react-bootstrap';
import { clearCart } from '../../../reducers/cart';
import Button from 'react-bootstrap/Button'


const CartAlertModal = (props) => {
  return (
    <div>
      <Modal show={props.show} onHide={() => { props.setShow({ status: false }) }} animation={false}>
        <Modal.Header />
        <Modal.Body>
          <div className="modalHeading">Alert</div>
          <p className="pointpara">{props.show.message}</p>
          <div className="px-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div />
            {/* <button className="modalPrimaryBtn justify-content-center px-1" onClick={handleClearCart}  >Clear Cart</button> */}
            <button closeButton className="modalSecondaryBtn justify-content-center px-1"
              onClick={() => props.setShow({ status: false })}>Close
              </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
};
export default CartAlertModal