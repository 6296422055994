import apiClient from '../api/index';
import Geocode from "react-geocode";

let unique = arr => arr.filter((item, i, ar) => ar.indexOf(item) === i);

const initialState = {
  merchants: [],
  loader: false,
  merchantsByMode: [],
  cities: [],
  merchantIds: [],
  config: {},
  ourMission: [],
  cms: [],
  gallery: [],
  homePageConfig: [],
  coords: {
    lat: 17.4164404,
    lng: 78.4109285,
  },
  address: '',
  udid: '',
  homeSliders: null,
  sldierLoader: false,
  buttonsConfig: null,
  orderModes: null,
  popularFor: null,
  merchantsByEta: null,
  merchantsEtaLoader: false,
};

const HOME_INIT = "home/HOME_INIT"
const HOME_CONFIG = "home/HOME_CONFIG"
const HOME_PAGE_CONFIG_START = "home/HOME_PAGE_CONFIG_START"
const HOME_PAGE_CONFIG = "home/HOME_PAGE_CONFIG"
const CMS_CONFIG = "home/CMS_CONFIG"
const GALLERY = "home/GALLERY"
const OUR_MISSION = "home/OUR_MISSION"
const SETADDRESS = "home/SETADDRESS"
const SETLATLNG = "home/SETLATLNG"
const SET_DEVICE_TOKEN = "home/SET_DEVICE_TOKEN"
const SET_USER_COORDS = "home/SET_USER_COORDS"
const HOME_PAGE_SLIDER = "home/HOME_PAGE_SLIDER"
const HOME_PAGE_SLIDER_START = "home/HOME_PAGE_SLIDER_START"
const HOME_BUTTON_CONFIG = "home/HOME_BUTTON_CONFIG"
const HOME_POPULAR_FOR = "home/HOME_POPULAR_FOR"
const HOME_MERCHANTS_BY_ETA = "home/HOME_MERCHANTS_BY_ETA"
const HOME_MERCHANTS_BY_ETA_START = "home/HOME_MERCHANTS_BY_ETA_START"

export const homePageConfig = () => async (dispatch, getState) => {

  dispatch({
    type: HOME_PAGE_CONFIG_START,
  });

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'homePageSettings',
    });
    if (response.success) {
      dispatch({
        type: HOME_PAGE_CONFIG,
        homePageConfig: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const homePageSlider = () => async (dispatch, getState) => {

  dispatch({
    type: HOME_PAGE_SLIDER_START,
  });

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'homeBanners',
    });

    if (response.success) {
      dispatch({
        type: HOME_PAGE_SLIDER,
        homeSliders: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};


export const homeButtonConfig = () => async (dispatch, getState) => {


  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'modeButtonLabels',
    });

    if (response.success) {
      dispatch({
        type: HOME_BUTTON_CONFIG,
        buttonsConfig: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const homePopularFor = () => async (dispatch, getState) => {

  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'popularFor',
    });

    if (response.success) {
      dispatch({
        type: HOME_POPULAR_FOR,
        popularFor: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const homeMerchantsByEta = (mode) => async (dispatch, getState) => {

  const state = getState();
  const { config, coords } = state.home;
  const { merchantAccessToken } = config;

  dispatch({
    type: HOME_MERCHANTS_BY_ETA_START,
  });

  try {
    const response = await apiClient.post(apiClient.Urls.serviceability, {
      merchantAccessToken,
      userLatitude: coords.lat,
      userLongitude: coords.lng,
      selectedOrderMode: mode,
    });



    if (response.success) {
      dispatch({
        type: HOME_MERCHANTS_BY_ETA,
        merchantsByEta: response.merchants,
      });
    } else {
    }
  } catch (error) {
  }
};


export const setAddress = (address) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SETADDRESS,
      address: address,
    });
  } catch (error) {
    console.error("set address error", error);
  }
};

export const fetchDeviceToken = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { merchantAccessToken } = state.home.config;
    const width = window.screen.availWidth;
    const height = window.screen.availHeight;
    const resolution = parseInt(width) + 'x' + parseInt(height); // try to get resolution of connected monitor;

    const deviceInfo = {
      deviceUid: "9999999999",
      screenSize: resolution,
      resolution: resolution,
      deviceModel: "G50-80",
      OSVersion: "WINDOWS",
      merchantAccessToken,
      deviceType: "DESKTOP",
      platform: "WINDOWS",
    };
    const response = await apiClient.post(apiClient.Urls.device, deviceInfo);
    dispatch(setDeviceToken(response.udid));
  } catch (e) {

    dispatch((e.message, 'Something went wrong. Please close and open the App'));
  }
};

export const getUserLatLng = () => async (dispatch, getState) => {

  const state = getState();
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { authToken } = state.session;

  try {

    navigator.geolocation.getCurrentPosition(
      s => {
        dispatch({
          type: SET_USER_COORDS,
          coords: { lat: s.coords.latitude, lng: s.coords.longitude },
        });
      },
      e => {

        const response = apiClient.get(apiClient.Urls.getLatLng, {
          authToken,
          merchantAccessToken
        });
        if (response.success) {
          dispatch({
            type: SET_USER_COORDS,
            coords: { lat: response.latitude, lng: response.longitude },
          });
        }

      });

  } catch (e) {
    // dispatch(error(e.message, 'Something went wrong. Please close and open the App'));
  }
}

export const setDeviceToken = (udid) => ({
  type: SET_DEVICE_TOKEN,
  udid,
});


const getAddressFromCoords = () => async (dispatch, getState) => {

  const state = getState();

  const coords = state.home.coords;

  Geocode.setApiKey("AIzaSyAt6ZUt38nllarxV0jpSWDPYeU1HlQcD2I");
  // set response language. Defaults to english.
  Geocode.setLanguage("en");
  // Get address from latitude & longitude.
  Geocode.fromLatLng(coords.lat, coords.lng).then(
    response => {
      const address = response.results[0].formatted_address;

      setAddress(address);
    },
    error => {
      console.error(error);
    }
  );
}

export const setLatLng = (coords) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SETLATLNG,
      coords: coords,
    });
  } catch (error) {
    console.error("set lat&lng error", error);
  }
};

export const homeConfig = () => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'configuration',
    });
    if (response.success) {
      dispatch({
        type: HOME_CONFIG,
        config: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const gallery = () => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'gallery',
    });
    if (response.success) {
      dispatch({
        type: GALLERY,
        gallery: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const ourMission = () => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'ourMission',
    });
    if (response.success) {
      dispatch({
        type: OUR_MISSION,
        ourMission: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};


export const cmsConfig = () => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.config, {
      model: 'cmsLoyalty',
    });
    if (response.success) {
      dispatch({
        type: CMS_CONFIG,
        cms: response.data,
      });
    } else {
    }
  } catch (error) {
  }
};

export const homeInit = () => async (dispatch, getState) => {
  const state = getState();
  const { config } = state.home;

  try {

    const response = await apiClient.get(apiClient.Urls.merchants, {
      merchantAccessToken: config.merchantAccessToken,
    });

    if (response.success) {
      getAddressFromCoords();
      const cities = unique(response.data.map(o => o.city));
      const ids = unique(response.data.map(o => o.id));

      let allModes = [];
      response.data.map(o => {
        o.availableOrderModes.map((i) => {
          return allModes.push(i);
        });
      });

      const modes = unique(allModes.map(o => o.type));

      var res_take_away = [];
      var res_delivery = [];
      var res_dinein = [];
      var res = {};

      response.data.map((merchant) => {
        var d = merchant;
        var avail_order_modes = d.availableOrderModes;

        avail_order_modes.map((mode) => {
          if (mode.type == 'takeAway') {
            res_take_away.push(merchant);
          }

          if (mode.type == 'delivery') {
            res_delivery.push(merchant);
          }

          if (mode.type == 'dineIn') {
            res_dinein.push(merchant);
          }
        }
        );
      });

      res["takeAway"] = res_take_away;
      res["delivery"] = res_delivery;
      res["dineIn"] = res_dinein;
      const merchants = res;

      dispatch({
        type: HOME_INIT,
        data: response.data,
        cities,
        ids,
        merchants,
        modes,
      });
    } else {

    }

  } catch (error) {

  }
};



const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case HOME_INIT: {
      return {
        ...state,
        merchants: action.data || [],
        merchantsByMode: action.merchants || [],
        cities: action.cities,
        merchantIds: action.ids,
        orderModes: action.modes,
        // merchants: action.merchants,
      }
    }
    case HOME_CONFIG: {
      return {
        ...state,
        config: action.config,
      }
    }
    case OUR_MISSION: {
      return {
        ...state,
        ourMission: action.ourMission,
      }
    }
    case GALLERY: {
      return {
        ...state,
        gallery: action.gallery,
      }
    }
    case CMS_CONFIG: {
      return {
        ...state,
        cms: action.cms,
      }
    }
    case HOME_PAGE_CONFIG: {
      return {
        ...state,
        loader: false,
        homePageConfig: action.homePageConfig,
      }
    }
    case HOME_PAGE_CONFIG_START: {
      return {
        ...state,
        loader: true,
      }
    }

    case HOME_MERCHANTS_BY_ETA: {
      return {
        ...state,
        merchantsByEta: action.merchantsByEta,
        merchantsEtaLoader: false,
      }
    }
    case HOME_MERCHANTS_BY_ETA_START: {
      return {
        ...state,
        merchantsEtaLoader: true,
      }
    }
    case HOME_PAGE_SLIDER: {
      return {
        ...state,
        sldierLoader: false,
        homeSliders: action.homeSliders,
      }
    }
    case HOME_PAGE_SLIDER_START: {
      return {
        ...state,
        sldierLoader: true,
      }
    }
    case HOME_BUTTON_CONFIG: {
      return {
        ...state,
        buttonsConfig: action.buttonsConfig
      }
    }
    case HOME_POPULAR_FOR: {
      return {
        ...state,
        popularFor: action.popularFor
      }
    }
    case SETADDRESS: {
      return {
        ...state,
        address: action.address,
      }
    }

    case SETLATLNG: {
      return {
        ...state,
        coords: action.coords,
      }
    }

    case SET_DEVICE_TOKEN: {
      return {
        ...state,
        udid: action.udid
      }
    }

    case SET_USER_COORDS: {
      return {
        ...state,
        coords: action.coords,
      }
    }

    default: return state;
  }
};

export default homeReducer;
