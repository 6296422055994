import React, { useState, useEffect } from 'react';

import './couponModal.css'
export default function SuccessPrompt(props) {
  useEffect(() => {
    setTimeout(() => {
      props.setShow(false)
    }, 3000);
  }, [])

  return (
    <div>
      <img src="/assets/successBg.png" alt="SuccessBg" className="successBg" />
      <div className="success-cpn">
        <img src="/assets/successIcon.svg" alt="Success" />
        <div className="success-cpn">Success!</div>
        <div className="success-cpn-text">Coupon is applied successfully!</div>
      </div>
    </div>
  )
}
