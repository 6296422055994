import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import 'react-phone-number-input/style.css'
import PhoneInput, { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'

import { requestLogin, setPhone, setLoginPassword, setTemporaryInputFields, setOtpResponseReset } from '../../../actions';
import { getAddressChunkFromAddress, getAddressFromCoordinates } from '../../../utils/index'

import './loginModel.css';

import * as Facebook from 'fb-sdk-wrapper';
import rightArrowLight from '../../../images/icons/rightArrowLight.svg'
import SocialLogin from '../socialLogin';

function LoginPage(props) {
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.user)

  const [phone, setPhone] = useState();
  const [defaultCountry, setdefaultCountry] = useState()
  const { show, setShow, user, setRegisterShow } = props;
  const userDetails = user.userDetails !== null ? user.userDetails : null;
  const reduxPhone = user.temporaryInputFields?.phone;


  const handleProceed = () => {
    dispatch(setTemporaryInputFields({ phone: phone }))
    const body = getRequestOptions(phone);
    dispatch(requestLogin(body, body.mobileNumber, result => { }));
    props.loginParams(body)
  };

  const fblogin = () => {
    return Facebook.login()
      .then((response) => {
        if (response.status === 'connected') {
          // logged in

        } else {
          // not logged in

        }
      })
  }

  const getRequestOptions = (param) => {
    const countryCode = param && formatPhoneNumberIntl(param).split(' ')[0];
    const formattedPhone = param && formatPhoneNumber(param).slice(1).split(' ').join('');
    const body = {
      mobileNumber: formattedPhone,
      countryCode,
      referralCode: props.referralCode,
    };
    return body;
  }

  useEffect(() => {
    let currentAddress = reduxUser.userDetails?.locationPreference

    setdefaultCountry(currentAddress?.countryCode)

    setPhone(reduxUser.temporaryInputFields?.phone)
  }, [])

  useEffect(() => {
    if (userDetails?.success === true) {
      props.setCurrentScreen(1)
    }
  }, [user.loginInProgress]);


  const handleCreateNow = () => {
    dispatch(setOtpResponseReset())
    setShow(false);
    setRegisterShow(true);
  };


  return (
    <div>
      <div className="registerheader">Log In</div>
      <div className="inner">
        <p className="pera" style={{ textAlign: 'center' }}>Please log in to your account to continue with your order</p>
        <div className="d-flex" >
          <PhoneInput
            placeholder="Enter phone number"
            value={phone}
            onChange={setPhone}
            limitMaxLength={true}
            defaultCountry={defaultCountry}
            className='countryFlag'
          />
        </div>
        {!user.loginInProgress && user.userDetails && !user.userDetails.success &&
          <p className='my-2 text-danger h6'>{user.userDetails.message}</p>}
        <button className="modalLoginBtn" onClick={handleProceed}
          style={{
            backgroundColor: "#553A28",
            color: "#FFFFFF"
          }}>Proceed

          {user.loginInProgress ? <div className='spinner-border text-white' /> : <img className="registerarrow" src={rightArrowLight} />}
        </button>
        <div className="haveAcc">Don’t have an account? <a className="createnowbtn" onClick={handleCreateNow} href="#">Create Now</a></div>

        <SocialLogin
          gotoRegister={handleCreateNow}
          setLoginShow={props.setShowModal}
          setRegisterShow={setRegisterShow} />

      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  homepage: state,
});


export default connect(mapStateToProps,)(LoginPage);
