import React, { useState, useEffect, useRef } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { requestValidateOtp, requestLogin, resetUserDetails, requestOrderTimeSlots } from '../../../actions';
import './loginModel.css';
import resend from '../../../images/icons/resend.svg'
import phone from '../../../images/icons/phone.svg'
import rightArrowLight from '../../../images/icons/rightArrowLight.svg'
import OtpInput from 'react-otp-input';

const ValidateOtpModal = (props) => {
    const dispatch = useDispatch()
    const { setShow, user, homepage, requestValidateOtp, isLoginPage = true } = props;
    const [otp, setOtp] = useState();
    const [isResendOtp, setisResendOtp] = useState(false)
    const reduxState = useSelector(state => state.user?.otpResponse)
    const [counter, setCounter] = React.useState(30);
    const [errorPrompt, setErrorPrompt] = useState('')
    const counterTime = () => {

        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }

    const resendOtp = () => {
        if (counter === 0) {
            setCounter(30);
            setisResendOtp(true)
            dispatch(requestLogin(props.loginInfo, null, result => { }));
            counterTime();
        }
        else {

        }
        //    window.setTimeout(counterTime(), 500000)
    }

    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);


    const handleGoBack = () => {
        setisResendOtp(false)
        props.resetUserDetails()
        props.setCurrentScreen(0)
    };

    const handleValidateOtp = () => {
        let requestOptions;
        if (isLoginPage) {
            requestOptions = {
                merchantAccessToken: 'qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E',
                mobileNumber: props.loginInfo.mobileNumber,
                otp: Number.parseInt(otp),
                udid: homepage.deviceDetails?.udid,
              countryCode: props.loginInfo.countryCode,
              referralCode: props.referralCode,
            };
        } else {
            requestOptions = {
                udid: homepage.deviceDetails.udid,
                uuid: user.registerResponse.uuid,
                mobileNumber: props.loginInfo.mobileNumber,
                countryCode: props.loginInfo.countryCode,
                otp: Number.parseInt(otp),
              referralCode: props.referralCode,
            }
        }


        requestValidateOtp(requestOptions, result => {
            console.log({ result })
            if (result.success) {
                if (window.clarity) {
                    window.clarity('set', 'UserId', result.uuid)
                }

                if (!!props.merchant?.id) {
                    props.requestOrderTimeSlots(props.merchant?.id)
                }

                setShow(false);
                setTimeout(() => {
                    console.log('RELOAD CALL')
                    window.location.reload()
                }, 1000);
            }
            else {
                setErrorPrompt(result?.message)
            }

        });
        setisResendOtp(true)

    };

    // useEffect(() => {
    //     if (reduxState?.success) {

    //         setShow(false);
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 1000);

    //     }
    // }, [reduxState?.success])

    let style = {
        otpTimer: {
            margin: '10px',
            color: '#49CE8B',
            fontSize: "12px"
        },
        resendBtn: {
            color: '#49CE8B',
            backgroundColor: 'white',
            marginLeft: "51px",
            position: "absolute",
            bottom: "141px"
        }
    }

    return (
        <div>
            <div className="modalHeading">This is Verify OTP Page</div>
            <p className="pera">Please log in to your account to continue with your order</p>
            <div className="contactNumber">
                <img src={phone} alt="Mobile" />
          <p>
            {`(${props.loginInfo?.countryCode})`}&nbsp;
            {props.loginInfo?.mobileNumber.replace(/[^a-zA-Z0-9]/g, '')}</p>  

                <button onClick={handleGoBack}>Edit</button>
            </div>
            <div className="login-otp">
                <OtpInput
                    inputStyle={{ padding: 0 }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    isInputNum={true}
                    separator={<span>-</span>}
                />
            </div>

            <div onClick={resendOtp} className="resend" style={{ cursor: 'pointer' }}>
                <div>Resend OTP code</div>
                <img src={resend} alt="Mobile" />
            </div>
            <div style={{ color: '#49CE8B' }}>
                00: {counter}</div>
            {isResendOtp && <p className='my-2 text-success h6'>OTP is sent.</p>}

            {!user.otpVerifyInProgress && user.otpResponse && !user.otpResponse.success &&
                <p className='my-2 text-danger h6'>
                {errorPrompt}
                </p>}
            <button onClick={handleValidateOtp} className="modalLoginBtn">
                Verify OTP
                {user.otpVerifyInProgress ?
                    <div className='spinner-border text-white' /> :
                    <img src={rightArrowLight} alt="Arrow" />}
            </button>

        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user,
    homepage: state.homepage,
    merchant: state.merchant.merchant
});

export default connect(mapStateToProps, { requestValidateOtp, requestLogin, resetUserDetails, requestOrderTimeSlots })(ValidateOtpModal);