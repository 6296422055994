import apiClient from "../api";

const initialState = {
  userDetails: null,
  userPoints: null,
  rewards: null,
  redeemed: null,
  merchantId: 4,
  loyaltyId: 5,
  loading: false,
  loyaltyCardId: null,
  expiredFreebies: [],
  activeFreebies: [],
  freebies: [],
  baseFreebies: [],
  nextCardStatus: null,
  cards: [],
  conversionRate: 5,
  isSignUpModalOpen: false,
  showOtpDiv: false,
  showForm: false,
};


const GET_USER_DETAILS_SUCCESS = "loyalty/GET_USER_DETAILS_SUCCESS";
const GET_EXPIRED_FREEBIES_SUCCESS = "loyalty/GET_EXPIRED_FREEBIES_SUCCESS";
const GET_ACTIVE_FREEBIES_SUCCESS = "loyalty/GET_ACTIVE_FREEBIES_SUCCESS";
const GET_NEXT_CARD_STATUS_SUCCESS = "loyalty/GET_NEXT_CARD_STATUS_SUCCESS";
const GET_FREEBIES_SUCCESS = 'loyalty/GET_FREEBIES_SUCCESS';
const GET_ALL_CARDS_SUCCESS = "loyalty/GET_ALL_CARDS_SUCCESS";
const GET_FREEBIE_BUY_SUCCESS = 'loyalty/GET_FREEBIE_BUY_SUCCESS';

const GET_FREEBIES = 'loyalty/GET_FREEBIES';

const GET_BASE_FREEBIES = 'loyalty/GET_BASE_FREEBIES';

const SET_LOYALTY_CARD_ID = "loyalty/SET_LOYALTY_CARD_ID";
const SET_USER_POINTS = "loyalty/SET_USER_POINTS";
const ERROR = "loyalty/ERROR";
const SET_LOYALTY_DETAILS = "loyalty/SET_LOYALTY_DETAILS";

const OPEN_SIGN_UP_MODAL = "loyalty/OPEN_SIGN_UP_MODAL";
const CLOSE_SIGN_UP_MODAL = "loyalty/CLOSE_SIGN_UP_MODAL";
const GET_LOYALTY_OTP = "loyalty/GET_LOYALTY_OTP";

const VALIDATE_LOYALTY_OTP = "loyalty/VALIDATE_LOYALTY_OTP";

export const setUserPoints = (userPoints) => ({
  type: SET_USER_POINTS,
  userPoints,
});

export const setLoyaltyCardId = (loyaltyCardId) => async (dispatch, getState) => {
  dispatch({
    type: SET_LOYALTY_CARD_ID,
    loyaltyCardId,
  });
};

export const setLoyaltyDetails = () => (dispatch, getState) => {

  dispatch({
    type: SET_LOYALTY_DETAILS,
    merchantId,
    loyaltyId,
  });
  // dispatch(clearFreebiesData());
};

export const callApis = () => async (dispatch, getState) => {
  dispatch(getNextCardStatus());
  dispatch(getAllCards());
  dispatch(getFreebies());
};

export const callBaseFreebiesApis = () => async (dispatch, getState) => {
  const state = getState();
  const authToken = state.user.otpResponse?.authToken
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { loyaltyId } = state.loyalty;
  const { cards } = state.loyalty;

  let url = apiClient.Urls.loyalty + '/' + loyaltyId + '/cards';

  try {
    const response = await apiClient.get(url, {
      authToken,
      merchantAccessToken,
    });

    if (response.success) {
      if (response.cards.length > 0) {
        console.log("base", response.cards);
        const baseCard = response.cards.filter((item) => {
          return item.isBaseCard;
        });
        if (baseCard && baseCard[0]) {
          dispatch(getBaseFreebies(baseCard[0].id));
          console.log("base freebies", baseCard[0]);
        }
      }
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }



};



export const callMyFreebies = () => async (dispatch, getState) => {
  dispatch(getExpiredFreebies());
  dispatch(getActiveFreebies());
};

export const getUserDetails = () => async (dispatch, getState) => {
  const state = getState();
  const authToken = state.user.otpResponse?.authToken
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { loyaltyId } = state.loyalty;

  let url = apiClient.Urls.loyalty + '/' + loyaltyId + '/user/details';


  try {
    const response = await apiClient.get(url, {
      authToken,
      merchantAccessToken,
    });

    if (response.success) {

      dispatch(setUserPoints(response.points));
      dispatch(setLoyaltyCardId(response.currentCardId));
      dispatch(callApis());
      dispatch(callMyFreebies());

      dispatch({
        type: GET_USER_DETAILS_SUCCESS,
        userDetails: response,
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};

export const getExpiredFreebies = () => async (dispatch, getState) => {
  const state = getState();
  const authToken = state.user.otpResponse?.authToken
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { loyaltyId } = state.loyalty;

  let url = apiClient.Urls.loyalty + '/' + loyaltyId + '/user/freebies/history';


  try {
    const response = await apiClient.get(url, {
      authToken,
      merchantAccessToken,
    });

    if (response.success) {
      dispatch({
        type: GET_EXPIRED_FREEBIES_SUCCESS,
        expiredFreebies: response.freebies,
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};

export const getActiveFreebies = () => async (dispatch, getState) => {
  const state = getState();
  const authToken = state.user.otpResponse?.authToken;
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { loyaltyId } = state.loyalty;

  let url = apiClient.Urls.loyalty + '/' + loyaltyId + '/user/freebies/live';

  try {
    const response = await apiClient.get(url, {
      authToken,
      merchantAccessToken,
    });

    if (response.success) {
      dispatch({
        type: GET_ACTIVE_FREEBIES_SUCCESS,
        activeFreebies: response.freebies,
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};

export const getNextCardStatus = () => async (dispatch, getState) => {
  const state = getState();
  const authToken = state.user.otpResponse?.authToken;
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { loyaltyId } = state.loyalty;

  let url = apiClient.Urls.loyalty + '/' + loyaltyId + '/next-card-status';

  try {
    const response = await apiClient.get(url, {
      authToken,
      merchantAccessToken,
    });

    if (response.success) {
      dispatch({
        type: GET_NEXT_CARD_STATUS_SUCCESS,
        nextCardStatus: { percent: response.percent, text: response.text },
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};


export const getAllCards = () => async (dispatch, getState) => {
  const state = getState();
  const authToken = state.user.otpResponse?.authToken;
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { loyaltyId } = state.loyalty;

  let url = apiClient.Urls.loyalty + '/' + loyaltyId + '/cards';

  try {
    const response = await apiClient.get(url, {
      authToken,
      merchantAccessToken,
    });

    if (response.success) {
      dispatch({
        type: GET_ALL_CARDS_SUCCESS,
        cards: response.cards,
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};

export const getFreebies = () => async (dispatch, getState) => {
  const state = getState();
  const authToken = state.user.otpResponse?.authToken;
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { loyaltyId, loyaltyCardId } = state.loyalty;

  let url = apiClient.Urls.loyalty + '/' + loyaltyId + '/cards/' + loyaltyCardId + '/freebies/base';
  console.log("res-----", url);
  try {
    const response = await apiClient.get(url, {
      authToken,
      merchantAccessToken,
    });

    console.log("res-----", response);

    if (response.success) {
      dispatch({
        type: GET_FREEBIES_SUCCESS,
        freebies: response.freebies,
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};

export const getBaseFreebies = (id) => async (dispatch, getState) => {
  const state = getState();
  const authToken = state.user.otpResponse?.authToken;
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { loyaltyId } = state.loyalty;

  let url = apiClient.Urls.loyalty + '/' + loyaltyId + '/cards/' + id + '/freebies/base';
  console.log("res-----", url);
  try {
    const response = await apiClient.get(url, {
      authToken,
      merchantAccessToken,
    });

    console.log("res-----", response);

    if (response.success) {

      dispatch({
        type: GET_BASE_FREEBIES,
        baseFreebies: response.freebies,
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};


export const redeemFreebie = (freebieId) => async (dispatch, getState) => {
  const state = getState();
  const authToken = state.user.otpResponse?.authToken;
  const { config } = state.home;
  const { merchantAccessToken } = config;
  const { loyaltyId, loyaltyCardId } = state.loyalty;

  let url = apiClient.Urls.loyalty + '/' + loyaltyId + '/cards/' + loyaltyCardId + '/freebies/base/' + freebieId + '/buy';

  try {
    const response = await apiClient.get(url, {
      authToken,
      merchantAccessToken,
    });

    console.log("res-----", response);

    if (response.success) {
      dispatch({
        type: GET_FREEBIE_BUY_SUCCESS,
        res: response,
      });
    } else {
      dispatch(displayError('', response.message));
    }
  } catch (e) {
    dispatch(displayError('EXCEPTION', e.message));
  }
};

const displayError = (title, message, loading = 'loading') => ({
  type: ERROR,
  title,
  message,
  loading,
});

export const openSignUpModal = () => async (dispatch, getState) => {

  try {
    dispatch({
      type: OPEN_SIGN_UP_MODAL,
    });
  }
  catch (e) {

  }
}

export const closeSignUpModal = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLOSE_SIGN_UP_MODAL,
    });
  }
  catch (e) {

  }
}

export const getLoyaltyOtp = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: GET_LOYALTY_OTP,
    });
  }
  catch (e) {

  }
}

export const validateLoyaltyOtp = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: VALIDATE_LOYALTY_OTP,
    });
  }
  catch (e) {

  }
}

const loyaltyReducer = (state = initialState, action) => {
  switch (action.type) {

    case OPEN_SIGN_UP_MODAL: {
      return {
        ...state,
        isSignUpModalOpen: true,
      }
    }
    case CLOSE_SIGN_UP_MODAL: {
      return {
        ...state,
        isSignUpModalOpen: false,
        showOtpDiv: false,
        showForm: false,
      }
    }
    case GET_LOYALTY_OTP: {
      return {
        ...state,
        showOtpDiv: true,
      }
    }
    case VALIDATE_LOYALTY_OTP: {
      return {
        ...state,
        showForm: true,
        showOtpDiv: false,
      }
    }

    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        loading: false,
        userDetails: action.userDetails
      }
    }
    case SET_USER_POINTS: {
      return {
        ...state,
        userPoints: action.userPoints,
      }
    }
    case SET_LOYALTY_CARD_ID: {
      return {
        ...state,
        loyaltyCardId: action.loyaltyCardId
      }
    }
    case GET_BASE_FREEBIES: {
      return {
        ...state,
        baseFreebies: action.baseFreebies
      }
    }
    case ERROR: {
      return {
        ...state,
        [action.loading]: false,
        error: {
          title: action.title,
          message: action.message,
        }
      }
    }
    case GET_EXPIRED_FREEBIES_SUCCESS: {
      return {
        ...state,
        expiredFreebies: action.expiredFreebies
      }
    }

    case GET_ACTIVE_FREEBIES_SUCCESS: {
      return {
        ...state,
        activeFreebies: action.activeFreebies
      }
    }
    case GET_NEXT_CARD_STATUS_SUCCESS: {
      return {
        ...state,
        nextCardStatus: action.nextCardStatus
      }
    }
    case GET_ALL_CARDS_SUCCESS: {
      return {
        ...state,
        cards: action.cards
      }
    }

    case GET_FREEBIES_SUCCESS: {
      return {
        ...state,
        freebies: action.freebies
      }
    }
    case GET_FREEBIE_BUY_SUCCESS: {
      return {
        ...state,
        freebie: action.res
      }
    }

    default: return state;
  }

}


export default loyaltyReducer;