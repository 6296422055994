import apiClient from '../api/index';
import config from '../../src/config';

import {
  LOGIN_PROGRESS,
  LOGOUT_PROGRESS,
  REQUEST_REGISTER,
  REGISTER_CONFIRM_BUFFER,
  RESPONSE_REGISTER_ONE,
  RESPONSE_REGISTER_THREE,
  RESPONSE_OTP_VERIFY_FAILED,
  RESPONSE_REGISTER_TWO,
  RESPONSE_OTP_VALIDATE,
  VERIFY_OTP_PROGRESS,
  RESPONSE_LOGIN,
  RESPONSE_LOGOUT,
  SET_OTP,
  SET_VEG,
  SET_LOCATION,
  SET_OTP_RESPONSE_RESET,
  SET_SIGN_OUT_RESET,
  REQUEST_EXACT_ADDRESS,
  RESPONSE_EXACT_ADDRESS_SUCCESS,
  RESPONSE_REGISTER_FAILED,
  SET_REGISTER_RESPONSE_RESET,
  SET_CUSTOMER_DATA,
  SET_LOGIN_PASSWORD,
  REQUEST_ORDER_TRANSACTION_HISTORY,
  REQUEST_ADD_NEW_ADDRESS,
  REQUEST_NEW_ADDRESS,
  REQUEST_USER_PROFILE,
  REQUEST_USER_UPDATED_PROFILE,
  REQUEST_USER_LOCATION_PREFERENCE,
  REQUEST_ORDER_USER_ADDRESS,
  REQUEST_ORDER_TIME_SLOTS,
  RESPONSE_LOGIN_FAILED,
  RESET_USER_DETAILS,
  TEMPORARY_INPUT_FIELDS,
  ADDRESS_CHUNKS_FROM_COORDINATES
} from '../actions';
import { param } from 'jquery';
const initialState = {
   isAuthenticated: false,
   loginInProgress: false,
   logoutProgress: false,
   otpVerifyInProgress: false,
   registerInProgress: false,
   userAddressInProgress: false,
   userLocation: null,
   otpDetails: null,
   userDetails: null,
   registerResponse: null,
   otpResponse: {},
   registrationDetails: {},
   navbardetails: [],
   globalbanners: [],
   mobileNumber: '',
   fullname: '',
   email: '',
   orderTransactionHistory: [],
   errorMessage: "",
   isOpen: false,
   updatedDetails: {},
  temporaryInputFields: { abc: 'fdf' },
  referralCode: null,
  referralData: null
};
const HEADER_THEME = 'header/HEADER_THEME';
const GLOBAL_BANNERS = 'home/GLOBAL_BANNERS';

const REFERRAL_CODE = "user/REFERRAL_CODE";
const REFERRAL_DATA = 'user/REFERRAL_DATA';
const SOCIAL_LOGIN = 'user/SOCIAL_LOGIN';
const GOOGLE_LOGIN = 'user/GOOGLE_LOGIN';

function getRequestOptionsfor() {
  return {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  };
}
function getRequestOptions() {
  return {
    method: 'POST',
    headers: {
      'Accept': 'application/json'
    }
  };
}


export const facebookLoginCheckAction = (param, callback) => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.facebookLoginCheck, {
      merchantAccessToken: config.merchantAccessToken,
      fbAccessToken: param.accessToken,
      udid: state.homepage.deviceDetails?.udid
    });
    if (response.success) {
      let args = {
        accessToken: param.accessToken,
        name: param.name,
        email: param.email,
        imageUrl: param.picture.data.url,
        loginType: 'facebookLogin'
      }
      if (response.authToken) {
        await localStorage.setItem("auth_token", response.authToken)
        dispatch({
          type: RESPONSE_REGISTER_THREE,
          payload: { ...response, ...args },
        });
        if (window.clarity && !!response.uuid) {
          window.clarity('set', 'UserId', response.uuid)
        }
      }
      else {
        dispatch({
          type: SOCIAL_LOGIN,
          payload: { ...response.data, facebookData: args },
        });
      }

      callback(response)
    } else {
      console.log(response.message)
    }
  } catch (error) {
    console.log(error)
  }
};




export const googleLoginCheckAction = (param, callback) => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.googleLoginCheck, {
      merchantAccessToken: config.merchantAccessToken,
      gmailAccessToken: param.tokenObj.id_token,
      udid: state.homepage.deviceDetails?.udid
    });
    if (response.success) {
      let args = {
        accessToken: param.tokenObj.id_token,
        name: param.googleData.name,
        email: param.googleData.email,
        imageUrl: param.googleData.imageUrl,
        loginType: 'googleLogin'
      }
      if (response.authToken) {
        await localStorage.setItem("auth_token", response.authToken)
        dispatch({
          type: RESPONSE_REGISTER_THREE,
          payload: { ...response, ...args },
        });
        if (window.clarity && !!response.uuid) {
          window.clarity('set', 'UserId', response.uuid)
        }
      }
      else {
        dispatch({
          type: SOCIAL_LOGIN,
          payload: { ...response.data, googleData: args },
        });
      }

      callback(response)
    } else {
      console.log(response.message)
    }
  } catch (error) {
    console.log(error)
  }
};


export const headerTheme = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'configuration'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const navbarres = await response.json();

    if (navbarres.success) {
      dispatch({
        type: HEADER_THEME,
        navbardetails: navbarres.data
      });
    } else {

    }

  } catch (error) {


  }
};

export const referralCodeAction = (param) => async (dispatch, getState) => {

  dispatch({
    type: REFERRAL_CODE,
    referralCode: param
  });

};


export const validateReferral = (param) => async (dispatch, getState) => {
  try {
    const requestOptions = getRequestOptions();
    const state = getState();
    const response = await fetch(`https://dashboard.froogal.ai/api/referral/v1/user/referrer?referrerCode=${param.referralCode}&merchantAccessToken=qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E&programId=4`, requestOptions)
    const referalresponse = await response.json();
    if (referalresponse.success) {
      dispatch({
        type: REFERRAL_DATA,
        referralData: referalresponse
      });
    } else {
      dispatch({
        type: REFERRAL_DATA,
        referralData: referalresponse
      });
      console.log(referalresponse.message)
    }
  } catch (error) {
    console.log(error)
  }


};

export const globalBanners = () => async (dispatch, getState) => {
  try {
    const requestOptionsFor = getRequestOptionsfor();
    const response = await fetch(`${config.apiUrl}/content?model=${'globalBanners'}&template=${'biryanis-website'}&merchantAccessToken=${config.merchantAccessToken}`, requestOptionsFor)
    const globalbanners = await response.json();

    if (globalbanners.success) {
      dispatch({
        type: GLOBAL_BANNERS,
        globalbanners: globalbanners.data
      });
    } else {
      console.log("not fetching data");
    }
  } catch (error) {
  }
};

export default function userReducer(state = initialState, action) {
  let updatedState = { ...state };

  switch (action.type) {
    case LOGIN_PROGRESS:
      return {
        ...state, loginInProgress: true,
        mobileNumber: action.mobileNumber
      };
    case HEADER_THEME: {
      return {
        ...state,
        navbardetails: action.navbardetails,
        errorMessage: action.errorMessage
      }
    }
    case REFERRAL_CODE: {
      return {
        ...state,
        referralCode: action.referralCode
      }
    }
    case REFERRAL_DATA: {
      return {
        ...state,
        referralData: action.referralData
      }
    }
    case GLOBAL_BANNERS: {
      return {
        ...state,
        globalbanners: action.globalbanners,
        errorMessage: action.errorMessage
      }
    }
    case REQUEST_REGISTER:
      return { ...state, registerInProgress: true };

    case VERIFY_OTP_PROGRESS:
      return { ...state, otpVerifyInProgress: true };

    case REGISTER_CONFIRM_BUFFER:
      return { ...state, registerConfirmInProgress: true };

    /* LOGIN STEP 1 */
    case RESPONSE_LOGIN:
      localStorage.setItem('uuid', action.payload.uuid);
      localStorage.setItem('auth_token', action.payload.authToken);
      return { ...state, loginInProgress: false, userDetails: { ...state.userDetails, ...action.payload } };

    /* LOGIN STEP 2 */
    case RESPONSE_OTP_VALIDATE:
      if (action.payload.authToken) {
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
      return { ...state, otpVerifyInProgress: false, otpResponse: { ...action.payload } };


    case RESPONSE_LOGIN_FAILED:
      return { ...state, loginInProgress: false, userDetails: { ...state.userDetails, ...action.payload } };


    case SET_OTP_RESPONSE_RESET:
      return { ...state, otpResponse: null };

    /* REGISTRATION STEP 1 */
    case RESPONSE_REGISTER_ONE:
      if (action.payload.success) {
        state.registerStepCompleted = 1
      }
      return {
        ...state,
        registerConfirmInProgress: false,
        registerInProgress: false,
        registerResponse: { ...action.payload },
        /* assigned to otpResponse to have a clean logout after registration */
        // otpResponse: { ...action.payload }
      };

    /* REGISTRATION STEP 2 */
    case RESPONSE_REGISTER_TWO:
      if (action.payload.success) {
        state.registerStepCompleted = 2
      }
      return {
        ...state,
        otpVerifyInProgress: false,
        otpResponse: { ...action.payload }
      };

    /* REGISTRATION STEP 3 */
    case RESPONSE_REGISTER_THREE:
      if (action.payload.authToken) {
        state.registerStepCompleted = 3
        state.isAuthenticated = true;
      } else {
        state.isAuthenticated = false;
      }
      return {
        ...state, otpVerifyInProgress: false,
        registerConfirmInProgress: false,
        registerInProgress: false,
        otpResponse: { ...action.payload }
      };

    case SOCIAL_LOGIN: {
      return {
        ...state,
        socialLogin: action.payload
      }
    }

    case GOOGLE_LOGIN: {
      return {
        ...state,
        googleLogin: action.payload
      }
    }

    case SET_REGISTER_RESPONSE_RESET:
      return { ...state, registerResponse: null, registerStepCompleted: 0 };

    case SET_CUSTOMER_DATA:
      return { ...state, registrationDetails: { ...action.payload } };

    case SET_OTP:
      return { ...state, otpDetails: action.payload };

    case SET_LOGIN_PASSWORD:
      return { ...state, userDetails: { ...state.userDetails, ...action.payload } };

    case SET_LOCATION:
      return { ...state, userLocation: action.payload };

    case REQUEST_EXACT_ADDRESS:
      return { ...state, userAddressInProgress: true };

    case ADDRESS_CHUNKS_FROM_COORDINATES:
      return { ...state, userAddressChunks: action.payload };


    case RESPONSE_EXACT_ADDRESS_SUCCESS:
      return { ...state, userAddressInProgress: false, userLocation: { ...state.userLocation, ...action.payload } };

    case REQUEST_ORDER_TRANSACTION_HISTORY:
      return { ...state, orderTransactionHistory: { ...action.payload } };

    case REQUEST_ADD_NEW_ADDRESS:
      return { ...state, userDetails: { ...state.userDetails, addressId: { ...action.payload } } };

    case REQUEST_NEW_ADDRESS:
      return { ...state, userDetails: { ...state.userDetails, address: { ...action.payload } } };

    case REQUEST_USER_PROFILE:
      return { ...state, userDetails: { ...state.userDetails, profile: { ...action.payload } } };

    case REQUEST_USER_UPDATED_PROFILE:
      return { ...state, updatedDetails: { ...state.updatedDetails, profile: { ...action.payload } } };


    case REQUEST_USER_LOCATION_PREFERENCE:
      return { ...state, userDetails: { ...state.userDetails, locationPreference: { ...action.payload } } };

    case REQUEST_ORDER_USER_ADDRESS:
      return { ...state, userDetails: { ...state.userDetails, orderUserAddress: { ...action.payload } } };

    case REQUEST_ORDER_TIME_SLOTS:
      return { ...state, userDetails: { ...state.userDetails, orderTimeSlots: { ...action.payload } } };

    case RESET_USER_DETAILS:
      return { ...state, userDetails: null };

    case SET_VEG:
      return { ...state, isVeg: action.payload };

    case TEMPORARY_INPUT_FIELDS:
      const a = state.temporaryInputFields
      return { ...state, temporaryInputFields: { ...a, ...action.payload } };

    /* LOGOUT */
    case LOGOUT_PROGRESS:
      return { ...state, logoutProgress: true };

    case RESPONSE_LOGOUT:
      return {
        ...state,
        responseLogout: { ...action.payload },
        logoutProgress: false,
        otpResponse: null,
        userDetails: null,
        otpDetails: null,
        isAuthenticated: false
      };

    case SET_SIGN_OUT_RESET:
      return {}


      return updatedState;
    default:
      return state;
  }
}
