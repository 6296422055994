import React, { memo, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import { useSelector } from 'react-redux';
import AddAddonButton from './AddAddonButtton';
import './AddonModal.css';

function AddonModal(props) {
  const { show, setShow, item, } = props || {}

  const reduxHomepage = useSelector(state => state.homepage)
  const reduxAddons = useSelector(state => state.homepage.allCatalogues?.addons)

  const [addons, setaddons] = useState()
  const [showAlert, setShowAlert] = useState(false);
  const [totalAmount, settotalAmount] = useState(0);
  const [choiceList, setchoiceList] = useState([]);
  const [alertText, setalertText] = useState('')

  const handleCheck = (e) => {
    let abc = choiceList
    let data = JSON.parse(e.target.value)

    const { id: addonId, minChoices, maxChoices, name } = data.selectedAddonGroup
    const { id: selectedChoiceId, name: selectedChoiceName, rate } = data.selectedChoice

    let checkCount = choiceList?.filter(item => item.addonId === addonId).length

    if (e.target.checked === true) {
      if (checkCount < maxChoices) {
        setShowAlert(false)
        //added addonid to compaare in a group
        setchoiceList(
          [...abc, { id: selectedChoiceId, name: selectedChoiceName, rate, addonId: addonId }]
        )
      }
      else if (minChoices === maxChoices && maxChoices === checkCount) {

        setShowAlert(false)
        let temp = choiceList?.filter(item => item.addonId != addonId)

        setchoiceList(
          [...temp, {
            id: selectedChoiceId,
            name: selectedChoiceName, rate,
            addonId: addonId
          }]
        )
      }
      else {
        setalertText(`You can select a maximum ${maxChoices} choice of ${name}.`)
        setShowAlert(true)
        setTimeout(() => {
          setShowAlert(false)
        }, 3500);

      }
    }
    else
      if (e.target.checked === false) {

        if (checkCount > minChoices) {
          setShowAlert(false)
          setchoiceList(choiceList?.filter(item => item.addonId != addonId))
        }
        else {
          setalertText(`Please select a minimum ${minChoices} choice of ${name}.`)
          setShowAlert(true)
          setTimeout(() => {
            setShowAlert(false)
          }, 3500);

        }
      }
  }
  useEffect(() => {
    const sumValues = choiceList.reduce((total, obj) => parseFloat(obj.rate) + parseFloat(total), 0)

    settotalAmount(sumValues)
  }, [choiceList])

  useEffect(() => {
    const filteredaddons = reduxAddons?.filter(a => item.addons.includes(a.id));
    setaddons(filteredaddons)
    // setaddons(reduxAddons)
    return () => {
      settotalAmount(0)
      setchoiceList([])
    }
  }, [])

  const handleAlert = (param, alertText) => {
    setalertText(alertText)
    setShowAlert(param)
  }

  return (
    <div>
      <Modal show={show} onHide={() => { setShow(false) }} animation={false}>
        <Modal.Header closeButton />
        <Modal.Body className='addon-modal-body'>
          <div className="modalHeading">Addons</div>
          <p className="freebiepara">
            Available Addons for {item.name}
          </p>
          <div className='addon-root' >
            {
              addons?.map((value, i) =>
                <div key={i} className="addon-item" style={{ width: 'inherit', marginTop: 5, marginBottom: 10 }}>
                  <div
                    style={{
                      display: 'flex', flexDirection: 'row',
                      padding: 15, color: "#553A28"
                    }}>
                    <text style={{
                      color: "#553A28", fontSize: 17, marginRight: 5

                    }}>{value.name}</text>
                    <text
                      style={{
                        color: 'black', fontSize: 11, top: 6,
                        position: "relative"
                      }} >
                      &nbsp;(Maximum {value.maxChoices})</text>
                    {value.minChoices === 0 ?
                      <text
                        style={{
                          color: 'black', fontSize: 11, top: 6,
                          position: "relative"
                        }} >
                        &nbsp;(Optional)</text>
                      : null}
                  </div>

                  {value.choices.map((choice, i) =>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: 'inherit', height: 40 }}
                    >
                      <label class="container"
                        style={{ display: 'flex', justifyContent: 'space-between', cursor: 'pointer' }} >
                        <div>
                          <input type="checkbox" className='checkbox-compatible' style={{ marginRight: 10, opacity: 1, appearance: 'auto' }}
                            value={
                              JSON.stringify({ selectedAddonGroup: value, selectedChoice: choice })
                            }
                            checked={
                              choiceList.filter(item => item.id === choice.id).length > 0 ? true : false
                            }
                            onClick={handleCheck}
                          />&#09;&#09;{choice.name}
                        </div>

                        {parseFloat(choice.rate) > 0 && <text style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                          + {reduxHomepage.allCatalogues.currency}{choice.rate}
                        </text>}
                      </label>
                    </div>
                  )}
                </div>
              )
            }
            {showAlert ?
              <Alert
                variant='primary'
                className="alert-anim"
                onClose={() => setShowAlert(false)}   >
                {alertText}
              </Alert>

              : null}
          </div>
          <AddAddonButton
            // {...props}
            setShow={setShow}
            item={item}
            totalAmount={totalAmount}
            addons={addons}
            choiceList={choiceList}
            handleAlert={handleAlert}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
};
export default memo(AddonModal);