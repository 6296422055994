import React, { PureComponent } from 'react';
import { DeleteCross, CheckCircle, ExclamationTriangleFilled, ExclamationCircleFilled } from '../../../images/icons/IconSets';
import Styles from './snackbar.module.css'

export default class Snackbar extends PureComponent {
  state = {
    isActive: false,
    snackdata: null,
    mainColor: '#fff'
  }

  openSnackBar = (param) => {
    console.log({ param })
    this.setState({ snackdata: param })

    if (param?.type === 'success') {
      this.setState({ mainColor: '#5CB346' })
    }
    else if (param?.type === 'info') {
      this.setState({ mainColor: '#e3f7fd' })
    }
    else if (param?.type === 'error') {
      this.setState({ mainColor: '#f7dfdf' })
    }

    this.setState({ isActive: true }, () => {
      setTimeout(() => {
        this.setState({ isActive: false });
      }, 3500);
    });
  }

  PromptIcon = () => {
    const {
      title, type, description } = this.state.snackdata || {}

    return (
      (type === 'success')
        ?
        <CheckCircle color='#fff' height={16} width={16} />
        :
        (type === 'info')
          ?
          <ExclamationCircleFilled color='#13caf0' height={16} width={16} />
          :
          (type === 'error')
            ?
            <ExclamationTriangleFilled color='#fdb0b0' height={16} width={16} />
            :
            null
    )
  }

  render() {
    const { isActive, snackdata, mainColor } = this.state;
    return (
      <div className={isActive ? [Styles.snackbar, Styles.show].join(" ") : Styles.snackbar}>
        <div style={{
          display: 'flex', alignItems: 'center', justifyContent: 'space-between',
          padding: 6,
          width: '100%', height: 35, backgroundColor: mainColor, borderTopLeftRadius: 6, borderTopRightRadius: 6
        }}>
          <div style={{ display: 'flex', alignItems: 'center', color: 'grey' }}>
            <this.PromptIcon />
            <text style={{ marginLeft: 10 }}>{snackdata?.title}</text>
          </div>

          <div style={{ cursor: 'pointer' }} onClick={() => this.setState({ isActive: false })}>
            <DeleteCross color='#000000' height={20} width={20} />
          </div>
        </div>
        {
          snackdata?.description
            ?
            <div style={{ padding: 8, textAlign: 'left' }}>
              <text style={{ font: 'normal 13px Lato' }}>
                {snackdata?.description}
              </text>
            </div>
            : null
        }

      </div>
    )
  }
}