import React from 'react'
import PropTypes from 'prop-types'
import takeawayimg from '../../images/Group 36759.svg';
import dineinimg from '../../images/Group 36760.svg';
import menuimg from '../../images/Group 36762.svg';
import new1 from '../../images/Group 36756.svg';
import deliverywhite from '../../images/Group 36757.svg';
import menuimgwhite from '../../images/Group 36754.svg';
import { CheckCircle, RightArrow } from '../../images/icons/IconSets';
import deliveryimg from '../../images/Group 35369.png';
import dineinimgwhite from '../../images/Group 36755.svg';
import './menu.css';


function OrdermodeButtons(props) {
  const { allModes, handleSelectOrderMode, current } = props || {}
  const imgurl2 = [deliverywhite, new1, dineinimgwhite, menuimgwhite];
  const imgurl = [deliveryimg, takeawayimg, dineinimg, menuimg];


  return (
    <div className="category">
      {allModes?.map((item, index) => {
        return (
          <div
            key={index}
            onClick={() => handleSelectOrderMode(index)}
            style={{
              height: 90, borderRadius: 5,
              marginBottom: 8
            }}  >
            <div
              className={
                `categoryBox
                 ${current === index ? "active" : item?.serviceable ? "" : "disabled"}`}
            >
              <div style={{ position: 'relative' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                  <img src={current === index ? imgurl2[index] : imgurl[index]} alt={`${item?.label}`} height="40px" style={{ width: 40, color: "#553a28" }} />
                  <text style={{ marginLeft: 5, }} >{item?.label}</text>
                </div>
                {
                  !item?.serviceable &&
                  <text style={{
                    position: 'absolute', bottom: -7,
                    left: 45, fontSize: 12, fontWeight: 'normal', width: 'max-content'
                    }}>{item?.unserviceableReason}</text>
                }
              </div>
              <div style={{
                display: 'flex',
                width: 20, height: 20,
                backgroundColor: '#FFFFFF',
                justifyContent: 'center',
                borderRadius: 11
              }}>
                {current === index ?
                  <CheckCircle width={20} height={20} color="#28a745" />
                  :
                  <RightArrow width={20} height={20} color="#dddddd" />
                }
              </div>
            </div>
          </div>
        );
      })}
    </div>
  )
}

OrdermodeButtons.propTypes = {

}

export default OrdermodeButtons
