import React, { useState, useEffect } from 'react'
import './authFlow.css';
import { useDispatch, useSelector } from 'react-redux'

import {
    requestRegister,
    requestRegisterConfirm,
    setPhone,
    setCustomerData,
    requestOrderTimeSlots,
    requestUserProfile,
} from '../../../../../../actions';
import icon from '../../../../../../../src/images/Group_29936.png';
import rightArrowLight from '../../../../../../images/icons/rightArrowLight.svg'
import { getCart, getServiceableAddressAction } from '../../../../../../reducers/cart';

function Form(props) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const homepage = useSelector(state => state.homepage)
    const reduxMerchant = useSelector(state => state.merchant?.merchant)
    const [reqBody, setreqBody] = useState()
    const [customerData, setCustomerData] = useState({
        fullname: '',
        email: '',
        phoneNumber: '',
        country: '',
        city: '',
        fbAccessToken: ''
    });


    const handleInputChange = (e) => {
        const { name, value } = e.currentTarget;
        setCustomerData({
            ...customerData,
            [name]: value
        })

    };

    const handleProceed = () => {
        const parametersbody = {
            ...reqBody, fullname: customerData.fullname,
            email: customerData.email
        };
        dispatch(requestRegisterConfirm(parametersbody, result => {


            if (result.success === true) {
                if (window.clarity && !!result.uuid) {
                    window.clarity('set', 'UserId', result.uuid)
                }
                dispatch(getServiceableAddressAction({
                    merchantId: reduxMerchant?.id,
                    authToken: result.authToken
                }))
                dispatch(getCart());
                dispatch(requestOrderTimeSlots(reduxMerchant?.id))
                dispatch(requestUserProfile());
            }
        }));
    }

    useEffect(() => {
        const parametersbody = {
            uuid: user.registerResponse?.uuid,
            fullname: customerData.fullname,
            email: customerData.email,
            phoneNumber: props.loginInfo.mobileNumber,
            udid: homepage.deviceDetails.udid,
            otp: props.otp
            // gender: 'male',
        };
        setreqBody(parametersbody)
    }, []);

    return (
        <div>
            {/* <div className="registerlogo"><img className="iconlogo" src={icon} /></div> */}
            {/*  <div className="registerheader">Create your account</div>
            <p className="pera" style={{ textAlign: 'center' }}>Please create account to continue with your order</p> */}
            <div className="ptext">
                <input
                    name='fullname'
                    value={customerData.fullname}
                    onChange={handleInputChange}
                    className="phnnumber" placeholder="Enter your full name" type="text" />
            </div>
            <div className="ptext">
                <input
                    name='email'
                    value={customerData.email}
                    onChange={handleInputChange}
                    className="phnnumber" placeholder="email" type="email" />
            </div>
            {!user.registerConfirmInProgress && user.otpResponse && !user.otpResponse.success && <p className='my-2 text-danger h6'>{user.otpResponse.message}</p>}

            <button onClick={handleProceed} className="modalRegBtn">Submit
                {user.registerInProgress ?
                    <div className='spinner-border text-primary' />
                    :
                    <img src={rightArrowLight} alt="Arrow" />}
            </button>
        </div>
    )
}

export default Form
