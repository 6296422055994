// import { type } from 'ramda';
// import state from 'sweetalert/typings/modules/state';
import apiClient from '../api/index';
import { loadStripe } from "@stripe/stripe-js";
import config from '../config';
import { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input'

const initialState = {
  netbanking: [],
  wallets: [],
  savedCards: [],
  userInfo: {},
  addressess: [],
  deliveryCharges: 0,
};

const PAYMENT_MODES = "payment/PAYMENT_MODES"
const SAVED_CARDS = "payment/SAVED_CARDS"
const DELETE_CARD = "payment/DELETE_CARD"
const VALIDATE_VPA = "payment/VALIDATE_VPA"
const GUEST_PLACE_ORDER = "payment/PLACE_ORDER"

const SET_ADDRESS = "payment/SET_ADDRESS"
const SET_USERINFO = "payment/SET_USERINFO"
const GET_DELIVERY_CHARGES = "payment/GET_DELIVERY_CHARGES"

export const paymentMODES = () => async (dispatch, getState) => {
  try {
    const response = await apiClient.get(apiClient.Urls.paymentModes);

    if (response.success) {
      dispatch({
        type: PAYMENT_MODES,
        netbanking: response.paymentModes.netbanking,
        wallets: response.paymentModes.wallets,
      });
    } else {

    }

  } catch (error) {

  }
};

export const validateVpa = () => async (dispatch, getState) => {
  try {
    const response = await apiClient.post(apiClient.Urls.validateVPA, {
      vpa: 'test@okicici',
    });

    if (response.success) {
      dispatch({
        type: VALIDATE_VPA,
        vpa: response.response,
      });
    } else {

    }

  } catch (error) {

  }
};
export const savedCard = () => async (dispatch, getState) => {

  try {
    const state = getState();

    const response = await apiClient.post(apiClient.Urls.getSavedCards, {
      phoneNumber: state.user.mobileNumber,
      authToken: localStorage.auth_token
      // phoneNumber: state.payment.userInfo.phoneNumber,
    });

    if (response.success) {
      dispatch({
        type: SAVED_CARDS,
        savedCards: response.savedCards.cards,
      });
    } else {

    }

  } catch (error) {

  }
};

export const paymentCheckout = () => async (dispatch, getState) => {

  try {
    const state = getState();

    const response = await apiClient.get(apiClient.Urls.getPaymentCheckout, {
      phoneNumber: state.user.otpResponse.profile.phoneNumber,
      authToken: localStorage.auth_token,
      merchantAccessToken: 'qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E',
      cartId: state.cart.cartId,
      merchantId: state.homepage.nearestMerchantDetails.id,
    });

    if (response.success) {

      // dispatch({
      // 	type: SAVED_CARDS,
      // 	savedCards: response.savedCards.cards,
      // });
    } else {

    }

  } catch (error) {

  }
};

export const deleteCard = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.post(apiClient.Urls.deleteCard, {
      phoneNumber: state.payment.userInfo.phoneNumber,
      cardId: state.cartId,
    });

    if (response.success) {
      dispatch({
        type: DELETE_CARD,
        message: response.response,
      });
    } else {

    }

  } catch (error) {

  }
};

export const guestPlaceOrder = (paymentDetails) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { otpResponse, temporaryInputFields } = state.user
    const {
      guest, fullname, email, phoneNumber, instructions
    } = temporaryInputFields || {}


    const countryCode = phoneNumber && formatPhoneNumberIntl(phoneNumber).split(' ')[0];
    const formattedPhone = phoneNumber && formatPhoneNumber(phoneNumber).slice(1).split(' ').join('');

    const params = {
      // userInfo: state.payment.userInfo,
      shippingCharges: state.payment.deliveryCharges,
      cartId: state.cart.cartId,
      // authToken: otpResponse?.authToken,
      merchantAccessToken: config.merchantAccessToken,
      failureUrl: `${window.location.origin}/cart?paymentStatus=failure`,
      // CARTID initials as 20
      successUrl: `${window.location.origin}/order-status/20${state.cart.cartId}?paymentStatus=success`
    };

    params.authToken = !!otpResponse?.authToken ? otpResponse.authToken : null

    if (paymentDetails) {
      params.paymentDetails = paymentDetails;
    }

    if (!!guest === true) {
      params.userInfo = {
        countryCode: !!countryCode ? countryCode : null,
        phoneNumber: !!formattedPhone ? formattedPhone : null,
        name: !!fullname ? fullname : null,
        email: !!email ? email : null
      }
    }
    else {
      params.userInfo = state.payment.userInfo
    }

    const response = await apiClient.post(apiClient.Urls.guestCheckout, params);
    if (response.success) {
      window.fbq('track', 'InitiateCheckout');
      if (response.response.gateway === 'stripe') {
        const { stripeApiKey, sessionId } = response.response;
        const stripeLoader = loadStripe(stripeApiKey)
        try {
          const stripe = await stripeLoader;
          const result = await stripe.redirectToCheckout({
            sessionId: sessionId,
          });
          if (result.error) {
            alert(result.error.message);
          }
        } catch (error) {
          console.log('error', error);
        }
      } else {
        dispatch({
          type: GUEST_PLACE_ORDER,
          response: response.response,
        });
      }
    } else {
      alert(response.message);
    }
  } catch (error) {
    console.log(error)
  }
};

export const setAddress = (address) => async (dispatch, getState) => {
  dispatch({
    type: SET_ADDRESS,
    addressess: address
  });
  const state = getState();
  if (state.payment.addressess) {
    dispatch(getDeliveryCharges())
  }
};

export const setUserInfo = (param) => {
  return {
    type: SET_USERINFO,
    name: param.name,
    phoneNumber: param.phoneNumber,
    email: param.email,
  };
};

export const getDeliveryCharges = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const response = await apiClient.get(apiClient.Urls.getDeliveryCharges, {
      address: state.payment.addressess[0].address,
      merchantId: state.menu.id,
      cartId: state.cart.cartId
    });

    if (response.success) {
      dispatch({
        type: GET_DELIVERY_CHARGES,
        deliveryCharges: response.charges,
      });
    } else {

    }

  } catch (error) {

  }
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {

    case PAYMENT_MODES: {
      return {
        ...state,
        netbanking: action.netbanking,
        wallets: action.wallets
      }
    }

    case VALIDATE_VPA: {
      return {
        ...state,
        vpa: action.validateVpa,
      }
    }
    case SAVED_CARDS: {
      return {
        ...state,
        savedCards: action.savedCards,
      }
    }

    case DELETE_CARD: {
      return {
        ...state,
        message: action.deleteCard,
      }
    }

    case GUEST_PLACE_ORDER: {
      return {
        ...state,
        orderDetails: action.response,
      }
    }

    case GET_DELIVERY_CHARGES: {
      return {
        ...state,
        deliveryCharges: action.deliveryCharges,
      }
    }

    case SET_ADDRESS: {
      return {
        ...state,
        addressess: [
          ...state,
          {
            address: action.addressess.address.address,
            faltNo: action.addressess.address.faltNo,
            landMark: action.addressess.address.landMark
          },
        ]
      }
    }

    case SET_USERINFO: {
      return {
        ...state,
        userInfo: {
          name: action.name,
          phoneNumber: action.phoneNumber,
          email: action.email,
        }
      }
    }

    default: return state;
  }
};

export default paymentReducer;
