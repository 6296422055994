import React from 'react';
import './successModel.css';
import { 
  Popover,
  Tooltip,
  Modal
} from 'react-bootstrap';

const SuccessModal = ({ show, setShow }) => {

    return (
    <div>
            <Modal className="successModel" show={show} onHide={() => { setShow(false) }} animation={false}>
      <Modal.Header closeButton/>
          <Modal.Body>
          <img src="/assets/successBg.png" alt="SuccessBg" className="successBg" />
            <div className="modalErrors">
            <img src="/assets/successIcon.svg" alt="Success" />
              <div className="errorHeading">Success!</div>
              <div className="errorText">Your order has been placed</div>
            </div>
          </Modal.Body>
      </Modal>
    </div>
  )
};
export default SuccessModal