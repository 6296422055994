import React, { useEffect, useState } from 'react';
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import { requestLogin, requestRegister, setOtpResponseReset, setTemporaryInputFields } from '../../../../../../actions';
import rightArrowLight from '../../../../../../images/icons/rightArrowLight.svg';
import { getAddressFromCoordinates } from '../../../../../../utils/index';
import './authFlow.css';

function LoginPageOne(props) {
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.user)
  const reduxHomepage = useSelector(state => state.homepage)
  const [phone, setPhone] = useState();
  const [defaultCountry, setdefaultCountry] = useState()
  const { show, setShow, user, setRegisterShow } = props;
  const userDetails = user.userDetails !== null ? user.userDetails : null;
  const reduxPhone = user.temporaryInputFields?.phone;


  const handleProceed = () => {
    dispatch(setTemporaryInputFields({ phone: phone }))
    const body = getRequestOptions(phone);
    dispatch(requestLogin(body, body.mobileNumber, result => {
      console.log(result.success);
      if (result.success === true) {
        /* go to VALIDATE OTP */
        props.setCurrentScreen(1)
      }
      else if (result.success === false && result.status === "RegistrationNotCompleted") {
        /* GO TO VERIFY OTP */
        const bodyReg = {
          phoneNumber: body.mobileNumber,
          countryCode: body.countryCode,
          udid: reduxHomepage.deviceDetails?.udid
        };
        dispatch(requestRegister(bodyReg))

        props.setCurrentScreen(2)
      }
      else {
        // alert(result.message)
      }

    }));
    props.loginParams(body)
  };



  const getRequestOptions = (param) => {
    const countryCode = param && formatPhoneNumberIntl(param).split(' ')[0];
    const formattedPhone = param && formatPhoneNumber(param).slice(1).split(' ').join('');
    const body = {
      mobileNumber: formattedPhone,
      countryCode,
    };
    return body;
  }

  useEffect(() => {
    setPhone(reduxUser.temporaryInputFields?.phoneNumber)
  }, [])

  useEffect(() => {
    // if (userDetails?.success === true) {
    //     props.setCurrentScreen(1)
    // }
  }, [user.loginInProgress]);


  const handleCreateNow = () => {
    dispatch(setOtpResponseReset())
    setShow(false);
    setRegisterShow(true);
  };

  useEffect(() => {
    let currentAddress = reduxUser.userLocation ? reduxUser.userLocation : reduxUser.userDetails?.locationPreference

    async function abc() {

      let a = await getAddressFromCoordinates(currentAddress)

      setdefaultCountry(a?.country_id)
    }
    if (!!reduxUser.userAddressChunks) {
      setdefaultCountry(reduxUser.userAddressChunks?.country_id)
    }
    else
      abc()
  }, [reduxUser.userLocation, reduxUser.userDetails])

  const handlePhoneInput = (e) => {
    console.log({ e })
    setPhone(e)
    dispatch(setTemporaryInputFields({ phoneNumber: e }))
  }

  return (
    <div>

      <div className="inner">

        <div className="d-flex" >
          <PhoneInput
            placeholder="Enter phone number"
            value={phone}
            onChange={handlePhoneInput}
            limitMaxLength={true}
            defaultCountry={defaultCountry}
            className='countryFlag'
          />
        </div>
        {!user.loginInProgress &&
          user.userDetails &&
          !user.userDetails.success &&
          user.userDetails.status !== "RegistrationNotCompleted" &&
          <p className='my-2 text-danger h6'>{user.userDetails.message}</p>}
        <button className="modalLoginBtn" onClick={handleProceed}
          style={{
            backgroundColor: "#553A28",
            color: "#FFFFFF"
          }}>Proceed

          {user.loginInProgress ? <div className='spinner-border text-white' /> : <img className="registerarrow" src={rightArrowLight} />}
        </button>


      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  homepage: state,
});


export default connect(mapStateToProps,)(LoginPageOne);
