import React, { Component } from 'react';
import { connect } from 'react-redux';
import MyloyaltyPage from './MyloyaltyPage';
import { callBaseFreebiesApis, getUserDetails, openSignUpModal, } from '../../reducers/loyalty';
import { callLoyaltySectionApis } from '../../reducers/loyaltystatic';
import Layout from '../Layout'
import Loader from '../loader';

class MyLoyalty extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getUserDetails()
    // this.props.callBaseFreebiesApis();
    this.props.callLoyaltySectionApis(result => {
      if (result === false) {
        this.setState({ loading: true })
      }
      else {
        this.setState({ loading: false })

      }
    });
  }


  render() {
    if (this.state.loading === true) {
      return (<Loader />)
      // return (<div style={{ backgroundColor: 'red', display: 'block', height: 500 }}>gjfirfji</div>)
    }
    else if (this.state.loading === false) {
      return (<Layout>
        <MyloyaltyPage {...this.props} />
      </Layout>)
    }
  }
}


const mapState = (state, ownprops) => {
  return {
    baseFreebies: state.loyaltystatic.baseFreebies,
    sectionOne: state.loyaltystatic.sectionOne,
    sectionTwo: state.loyaltystatic.sectionTwo,
    sectionThree: state.loyaltystatic.sectionThree,
    sectionOffer: state.loyaltystatic.sectionOffer,
    referAndEarn: state.loyaltystatic.referAndEarn,
    loyatltyTiers: state.loyaltystatic.loyatltyTiers,
    welcomeLoyalty: state.loyaltystatic.welcomeLoyalty,
    signUpOffer: state.loyaltystatic.signUpOffer,
    loyaltyIds: state.loyaltystatic.loyaltyIds,
    config: state.home.config,
    user: state.user,

  };
}

const mapDispatch = {
  callBaseFreebiesApis,
  openSignUpModal,
  callLoyaltySectionApis,
  getUserDetails
}

export default connect(mapState, mapDispatch)(MyLoyalty);
