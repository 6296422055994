import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Rating from '@mui/material/Rating';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import refercup from '../../../images/Group 35390.svg';
import arrowleft from '../../../images/leftarrow.svg';
import arrowright from '../../../images/rightarrow.svg';
import { homeFeaturedItems, homepageAboutUs, homePageFeatures, orderModeButtons, requestaboutcountries, requestCustomerTestinomials, setAnchorAction } from '../../../reducers/homepagecms';
import { setInitialOrderMode, setOrderMode } from '../../../reducers/orders';
import '../../About/Components/aboutUs.css';
import AddressModal from '../../Common/AddressModal';
import Banner from '../../Common/bottomBannner/Banner';
import LocationSwitchModal from '../../Common/LocationSwitchModal/index';
import LoginModal from '../../Common/LoginModal';
import Layout from '../../Layout/index';
import FeaturedItem from './FeaturedItems';
import GlobalBanners from './GlobalBanners';
import './HomepageNew.css';
import OrdermodeSelection from './OrdermodeSelection';
import RewardProgram from './RewardProgram';
import './slickdemo.css';
import './slider.css';


function HomepageNewComponent(props) {
  const anchorRef = React.createRef()
  const dispatch = useDispatch()

  const homepagecms = useSelector(state => state.homepagecms)

  const [locationshow, setlocationshow] = useState(false);
  const [addressShow, addressSetShow] = useState(false);
  const [loginshow, setLoginShow] = useState(false);

  const {
    homepagefeatures, ordermodebuttons, setAnchor, homefeatureditems,
    customerTestimonials, homepageaboutus, aboutUsCountries,
  } = homepagecms || {}

  useEffect(() => {
    window.scrollTo(0, 0)

    dispatch(requestCustomerTestinomials())
    dispatch(homePageFeatures())
    dispatch(orderModeButtons())
    dispatch(homepageAboutUs())
    dispatch(homeFeaturedItems())
    dispatch(requestaboutcountries())
    document.getElementById("splashspinner").style.display = "none";

  }, [])

  useEffect(() => {
    if (setAnchor) {
      handleAnchor()
      dispatch(setAnchorAction(false))
    }
  }, [setAnchor])

  const handleAnchor = (event) => {
    if (anchorRef.current) {
      anchorRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "start"
      })
      window.scrollBy(0, -105); // Adjust scrolling with a negative value here
    }
  }
  //inline instead of block
  const handleShowlocationModel = (param) => {
    selectedOrderType(param)
    return setlocationshow(true);
  };

  const selectedOrderType = (index) => {
    const orderTypes = [
      { id: 0, orderType: 'delivery' },
      { id: 1, orderType: 'takeAway' },
      { id: 2, orderType: 'dineIn' },
      { id: 3, orderType: 'menu' },
    ]
    dispatch(setOrderMode(orderTypes[index].orderType))
    if (index !== 3) {
      dispatch(setInitialOrderMode(orderTypes[index].orderType))
    }
  }

  const settings = {
    dots: false,
    // infinite: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    prevArrow: <img className="prev-fr" src={arrowleft} />,
    nextArrow: <img className="prev-fr" src={arrowright} width='45px' height="45px" />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  };
  const handleAddressShow = (param) => {
    addressSetShow(param)
  }
  return (
    <Layout>
      <div style={{ marginTop: '80px' }} >

        {locationshow &&
          <LocationSwitchModal
            show={locationshow}
            setShow={setlocationshow}
            addressSetShow={handleAddressShow} />
        }

        {addressShow &&
          <AddressModal
            show={addressShow}
            setShow={addressSetShow}
        />
        }

        {loginshow &&
          <LoginModal
            show={loginshow}
            setShow={setLoginShow} />
        }

        <GlobalBanners />

        <div className="about-fr" style={{ marginTop: 60, marginLeft: '20px', marginRight: '20px', marginBottom: 60 }}>
          <div className="about-services about-services-fr" style={{ backgroundColor: 'white', paddingTop: '53px', paddingBottom: '85px' }}>
            <div className="container">
              <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
                {homepagefeatures?.map((item, i) => (
                  <div key={i} className="col-lg-3 col-md-4 about-service-column-fr">
                    <div className="about-services-main">
                      <img src={item.image} />
                      <h2 className="homepagefeaturestitle">{item.title}</h2>
                      <p>{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="ordermodes-fr" ref={anchorRef} style={{ margin: 20 }}>
          <OrdermodeSelection
            ordermodeButtons={ordermodebuttons?.data}
            handleShowlocationModel={handleShowlocationModel}
          />
        </div>

        <div className="about-welcome" style={{ paddingBottom: '0px', marginTop: '51px', marginLeft: '20px', marginRight: '20px', borderRadius: '6px', marginBottom: 60 }}>
          <div className="container-fluid">
            <div className="row content">
              <div className="col-lg-6 col-md-6 padding-right">
                <div className="about-welcome-left fadeIn">
                  <h2 className="aboutusname">About us</h2>
                  <div className="content" dangerouslySetInnerHTML={{ __html: homepageaboutus?.about }}></div>

                  <h3>COUNTRIES WE ARE IN</h3>

                  <ul style={{ display: 'flex', flexDirection: "row" }}>
                    {aboutUsCountries?.map((item, i) => (
                      <li key={i} className="newcountries">
                        <img src={item.image} alt="images" />
                        <p className="newcountriespara">{item.name}</p>
                      </li>
                    ))}
                  </ul>
                </div>

              </div>
              <div className="col-lg-6 col-md-6 padding-left">
                <div className="about-welcome-right">
                  <img src={homepageaboutus?.image} alt="images" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {window.innerWidth > 768 &&
          <div className="featuremain" style={{ paddingTop: '51px', marginBottom: 60 }} >
            <FeaturedItem homefeatureditems={homefeatureditems} />
          </div>
        }

        <div className="feedback-fr" style={{ marginBottom: 60 }}>
          <h2 style={{ textAlign: 'center', paddingBottom: '21px', paddingTop: '10px' }}>our customers Feedback</h2>
          <div>
            <Slider {...settings}
              dots={true}
              //  autoplay={true}
              //  autoplaySpeed={3000}
              arrows={true}
            >
              {customerTestimonials?.map((v, i) =>
                <div className="neworder-fr" key={i}>
                  <div className="orderflex">
                    <img src={v.customerImage} style={{
                      top: -50, display: 'block',
                      position: 'absolute',
                      zIndex: '2'
                    }} height="125px" width="125px" />
                    <div style={{ boxShadow: 'rgb(236 238 244 / 77%) 1px 12px 8px' }} >
                      <Card elevation={0} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', height: '286px' }}>
                        <Box component="fieldset" className="feedbacktwo" mb={3} borderColor="transparent">
                          <Rating
                            name="read-only"
                            defaultValue={5}
                            precision={0.5}
                            readOnly
                          />
                        </Box>
                        <h3 className="customername">
                          {v.customerName}
                        </h3>
                        <p className="customermsg">
                          {v.message}
                        </p>
                      </Card>
                    </div>
                  </div>
                </div>
              )}
            </Slider>
          </div>
        </div>


        <div className="refer-section-fr" style={{ marginTop: '51px', marginLeft: '20px', marginRight: '20px' }}>
          <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 51, marginBottom: 51, padding: 17, }}>
            <div style={{ padding: 10, backgroundColor: '#FFFFFF', maxWidth: 600 }}>
              <RewardProgram />
            </div>
            <div
              className="col-lg-6 col-md-6col-sm-12"
              style={{ display: 'flex', flexDirection: 'column', padding: 27, backgroundColor: '#fff', justifyContent: 'space-between', textAlign: 'start' }}
            >
              <h2 className="refer-friends-header">Refer More, Earn More</h2>
              <p className="refer-friends-para">
                Earn Rewards with Every Milestone
              </p>
              <div className="refer-section-fr-icons" >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img src={refercup} width="52px" height="52px" />
                  <text className="refer-section-fr-icontext">Complete with friends</text>
                </div>
                <div style={{
                  alignSelf: 'center',
                  height: 15,
                  border: '1px solid #2C2C2C',
                  opacity: 0.1
                }} />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <img src={refercup} width="52px" height="52px" />
                  <text className="refer-section-fr-icontext-two">Earn Milestones when you refer</text>
                </div>
              </div>
              <h2 className="refer-friends-header">Coming soon ...</h2>
            </div>
          </div>
        </div>
        <Banner />
      </div>
    </Layout>
  );
}


export default HomepageNewComponent 