import React, { useState, useEffect, Component } from 'react';
import './Trackorder.scss';
import requestOrderTransactionHistory from '../../../actions/index';
import { connect } from 'react-redux';
import prepareorderimg from '../../../images/Group 33059.svg';
import foodimg from '../../../images/food.svg';
import scooterimg from '../../../images/scooter.svg';
import foodrestaurantimg from '../../../images/food-and-restaurant.svg';
import itemimg from '../../../images/itemimg.jpg';
import GoogleMaps from '../../Common/googleMaps/GoogleMaps'
import { Button } from 'react-bootstrap';
import TrackMapModal from '../../Common/TrackMap/TrackMapModal';
import { transactionDetails } from '../../../reducers/cart';
import phoneimg from '../../../images/Group 30030.svg';
import greencircle from '../../../images/Ellipse 511.svg';
import OrderDetailsModal from '../../Common/orderDetailsModal/OrderDetailsModal'
import { getDeliveryCharges } from '../../../reducers/payment'
class TrackorderThree extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trackmapshow: false,
      orderModalShow: false,
      checkOrder: false,
      tracktext: 'Track Map',
      more: false,
      itemslength: 2,
      showallitems: false,
      orderStages: { placed: 1, confirmed: 2, ready: 3, dispatched: 4, taken: 4, completed: 5 }

    }
    this.handletrackOrder = this.handletrackOrder.bind(this);
    this.handleviewmore = this.handleviewmore.bind(this);
  }
  componentDidMount() {
    this.props.getDeliveryCharges();
  }
  // handletrackOrder() {
  //     this.setState({trackmapshow: !this.state.trackmapshow})
  //   }
  handleviewmore = () => {
    this.setState({ more: true })
  }
  handletrackOrder = () => {
    this.props.history.push(`/order-status/${this.props.orderId}`)
    // this.setState({ trackmapshow: !this.state.trackmapshow });
  };
  handlesettrackmap = (param) => {
    this.setState({ trackmapshow: param });
  }

  handleOrderModalShow = (param) => {
    this.setState({ orderModalShow: param });
  }

  handleitemsall = (param) => {
    if (param.length > this.state.itemslength && this.state.showallitems === false) {

      return param.slice(0, this.state.itemslength)
    }
    else {
      return param
    }
  }


  render() {
    const { tracktext, orderModalShow } = this.state

    // const {
    //     date,
    //     details, discount, orderItems, subTotal,
    //     feedbackRating
    //     , invoiceId,
    //     merchantName,
    //     orderId,
    //     status,
    //     total,
    //     statusText,
    //     takeFeedback,
    //     type } = this.props.transactionDetails

    return (

      <div className="whiteBox" style={{ marginBottom: 20 }}>
        {orderModalShow &&
          <OrderDetailsModal
            show={orderModalShow}
            setShow={this.handleOrderModalShow}
            activeitem={this.props}
            deliveryCharges={this.props.deliveryCharges}
          />}

        <div className="orderwhite">
          <div className="mt-4rem container" style={{ marginLeft: '10px', marginRight: '10px', }}>
            <div className="columns">
              <div className="column" style={{ paddingBottom: '10px' }} >
                {/* <div>
                                    <span className="tr-head-title order-history fr-active" style={{ paddingBottom: '10px' }}>{'Active Order Details'}</span>
                                </div> */}
                <div className="column" style={{ marginTop: '20px' }}>
                  <div>
                    <span className="tr-head-title merchantname-fr">{this.props.merchantName}</span>
                  </div>
                  {/* <div>
                                    <span>{'16-3-992, Malakpet, Dilkusha Function Hall, 500036'}</span>
                                </div> */}

                </div>
                <div className="trackorder">
                  <div>
                    {/* <span>{placed}</span><br /> */}
                    <span style={{ paddingRight: '10px' }}>{this.props.status}</span>
                    {/* <span>Order ID • {invoiceId}</span> */}
                    <span>Order ID • {this.props.orderId}</span>
                  </div>
                  <div>
                    {/* <b>{currency}{total}</b> */}
                    <b>{this.props.details?.orderItems[0]?.currency}{this.props.details?.total}</b>
                  </div>
                </div>
              </div>
              <div>
                {
                  !!this.props.pointsAdded
                    ?
                    <div>
                      <span style={{ fontSize: '15px' }}>Points Added: {this.props.pointsAdded}</span>
                    </div>
                    :
                    null
                }

                <div>

                  <span style={{ fontSize: '15px' }}>{this.props.details?.orderItems?.length} Items</span>
                </div>
                <div className="columns">
                  <div className="column is-6">
                    {/* {orderItems && orderItems.map((i, index) => {
                    return <OrderItem key={index} item={i} currency={currency} />
                })} */}
                    {this.state.more === false ?
                      <div>
                        <div className="lesstext" style={{ display: 'flex', flexDirection: 'column', marginRight: 10, width: '100%' }}>
                          {this.props.details?.orderItems?.map((item, i) =>
                            <OrderItem {...item} />
                          )} </div>
                        {this.props.details?.orderItems?.length > 3 ?
                          <div style={{ marginTop: '20px' }}>
                            <button onClick={this.handleviewmore} className="viewbtn">View More</button>
                          </div>
                          :
                          null
                        }

                      </div>
                      :
                      <div className="moretext" style={{ display: 'flex', flexDirection: 'column', marginRight: 10, width: '100%', overflow: 'auto' }}>
                        {this.props.details?.orderItems.map((item, i) =>
                          <OrderItem {...item} />
                        )} </div>
                    }

                  </div>
                </div>

              </div>


            </div>
            <hr />
            {window.innerWidth > 768 ?
              <div style={{ marginBottom: '3rem' }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <h2 className="etatext-track">{this.props.date} </h2>
                  <div className="trackmapdetails">
                    {this.props.driverDetails?.name === "" ?
                      null
                      :
                      <div style={{ display: 'flex', flexDirection: 'row', width: 220 }}>
                        <a href={`tel:${this.props.driverDetails?.phoneNumber}`}><img style={{ width: 20 }} src={phoneimg} /></a>
                        <p className="trackmodalcontent"> {this.props.driverDetails?.name}&nbsp; is on his way to deliver your order.
                        </p>
                      </div>
                    }

                  </div>
                  <div>
                    <a style={{ backgroundColor: '#553a28', color: '#fff', border: 0, padding: '10px 20px', borderRadius: '6px', letterSpacing: '1.2px', marginRight: 10 }}
                      href={`tel:${this.props.outletPhoneNumber}`}>Call Restaurant</a>
                    {this.state.orderStages[this.props.status] <= 4 &&
                      <button style={{ backgroundColor: '#553a28', color: '#fff', border: 0, padding: '10px 20px', borderRadius: '6px', letterSpacing: '1.2px' }}
                      onClick={this.handletrackOrder}>  {!this.state.trackmapshow ? "Track Order Status" : "Track Order Map "}
                      </button>
                    }

                  </div>
                </div>

                {this.state.trackmapshow === true ?
                  <div>
                    <div className="modalHeading-track">
                      <h2 className="trackheader">
                        Track order here
                      </h2>
                      <button className="trackhelp-button">help</button>
                    </div>
                    <div className="row modelMap" style={{ alignItems: 'center' }}>
                      <div className="col-md-7">
                        <iframe
                          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.305935303!2d-74.25986548248684!3d40.69714941932609!2m3!1f0!2f0!3f0!3m2
        !1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1604749924401!5m2!1sen!2sin`}
                          width="100%"
                          height="244"
                          frameBorder="0"
                          allowfullscreen=""
                          aria-hidden="false"
                          tabIndex="0"
                        />
                      </div>
                      <div className="col-md-5 trackmapstatus">
                        <hr style={{ width: '73px' }} />
                        <div className="trackmapdetails">
                          <img src={scooterimg} />
                          <div>
                            <h2 className="trackmodalheadertext">Order picked up</h2>
                            <p className="trackmodalcontent">Nameer Khan is on his way to deliver your order.</p>
                            <p className="trackmodaltext"><img src={greencircle} style={{ marginRight: '10px' }} width="14px" height="14px" />Your food will arrive in 20 minutes</p>
                          </div>
                          <img src={phoneimg} />
                        </div>

                      </div>
                    </div>
                  </div>
                  :
                  <div>
                    <ol className="progtrckr" data-progtrckr-steps="5">
                      <li className={this.state.orderStages[this.props.status] >= 1 ? `progtrckr-done` : `progtrckr-todo`}>
                        <img className="statusimg" src={prepareorderimg} />
                        <p className="foodstatusmodes-details">Order Placed Successfully</p>
                        <p className="foodstatus">Order Placed Successfully</p>
                      </li>
                      <li className={this.state.orderStages[this.props.status] >= 2 ? `progtrckr-done` : `progtrckr-todo`}>
                        <img className="statusimg" src={prepareorderimg} />
                        <p className="foodstatusmodes-details">Preparing Order</p>
                        <p className="foodstatus">Your Order is confirmed. Food is preparing</p>
                      </li>
                      <li className={this.state.orderStages[this.props.status] >= 3 ? `progtrckr-done` : `progtrckr-todo`}> <img className="statusimg" style={{ marginRight: '10px' }} src={foodimg} />
                        <p className="foodstatusmodes-details">Order is Ready</p>
                        <p className="foodstatus">delivery boy pickedup</p>
                      </li>
                      <li className={this.state.orderStages[this.props?.status] >= 4 ? `progtrckr-done` : `progtrckr-todo`}> <img className="statusimg" style={{ marginRight: '10px' }} src={scooterimg} />
                        <p className="foodstatusmodes-details">
                          Order Picked up</p>
                        <p className="foodstatus">food is on the way</p>
                      </li>
                      <li className={this.state.orderStages[this.props.status] >= 5 ? `progtrckr-done` : `progtrckr-todo`}>

                        <img className="statusimg" style={{ marginRight: '10px' }} src={foodrestaurantimg} />
                        <p className="foodstatusmodes-details">
                          Order Delivered</p>
                        <p className="foodstatus">Successfully delivered</p></li>
                    </ol>
                    <p className="foodstatus-fr">{this.props.statusText}!</p>
                  </div>
                }
              </div>
              :
              <div style={{ marginBottom: '3rem' }}>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'baseline' }}>
                  <h2 className="etatext-track">{this.props.date} </h2>
                  <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px', width: '100%' }}>
                    {!!this.props?.driverDetails?.name?.length
                      &&
                      <div style={{ display: 'flex', alignItems: 'baseline', marginBlock: 10 }}>
                        <a href={`tel:${this.props.transactiondetails?.driverDetails?.phoneNumber}`} style={{ paddingRight: 10 }}><img src={phoneimg} /></a>
                        <p className="trackmodalcontent-fr">{this.props?.driverDetails?.name}&nbsp;is on his way to deliver your order.</p>
                      </div>
                    }
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBlock: 10 }}>
                      <a style={{ backgroundColor: '#553a28', textAlign: 'center', color: '#fff', border: 0, padding: '10px 20px', borderRadius: '6px', letterSpacing: '1.2px', marginRight: 10 }}
                        href={`tel:${this.props.outletPhoneNumber}`}>
                        Call Restaurant
                      </a>

                      {this.state.orderStages[this.props.status] <= 4 &&
                        <button style={{ backgroundColor: '#553a28', color: '#fff', border: 0, borderRadius: '6px', letterSpacing: '1.2px', paddingInline: 10 }}
                        onClick={this.handletrackOrder}>  {!this.state.trackmapshow ? "Track Order Status" : "Track Order Map "}
                        </button>}
                    </div>
                  </div>
                </div>

                {this.state.trackmapshow === true ?
                  <div>
                    <div className="modalHeading-track">
                      <h2 className="trackheader">
                        Track order here
                      </h2>
                      <button className="trackhelp-button">help</button>
                    </div>
                    <div className="row modelMap" style={{ alignItems: 'center' }}>
                      <div className="col-md-7">
                        <iframe
                          src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.305935303!2d-74.25986548248684!3d40.69714941932609!2m3!1f0!2f0!3f0!3m2
        !1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1604749924401!5m2!1sen!2sin`}
                          width="100%"
                          height="244"
                          frameBorder="0"
                          allowfullscreen=""
                          aria-hidden="false"
                          tabIndex="0"
                        />
                      </div>
                      <div className="col-md-5 trackmapstatus">
                        <hr style={{ width: '73px' }} />
                        <div className="trackmapdetails">
                          <img src={scooterimg} />
                          <div>
                            <h2 className="trackmodalheadertext">Order picked up</h2>
                            <p className="trackmodalcontent">Nameer Khan is on his way to deliver your order.</p>
                            <p className="trackmodaltext"><img src={greencircle} style={{ marginRight: '10px' }} width="14px" height="14px" />Your food will arrive in 20 minutes</p>
                          </div>
                          <img src={phoneimg} />
                        </div>

                      </div>
                    </div>
                  </div>
                  :
                  <div>
                    <ol className="progtrckr" data-progtrckr-steps="4">
                      <li className={this.state.orderStages[this.props.status] >= 1 ? `progtrckr-done` : `progtrckr-todo`}>
                        <img src={prepareorderimg} />
                        Order Placed Successfully
                        <span className="foodstatus-mobile">Your payment is completed and your order has been placed successfully!</span>
                      </li>
                      <li className={this.state.orderStages[this.props.status] >= 2 ? `progtrckr-done` : `progtrckr-todo`}>
                        <img src={prepareorderimg} />
                        Preparing Order
                        <span className="foodstatus-mobile">Your order is confirmed and is being prepared in our kitchen!</span>
                      </li>
                      <li className={this.state.orderStages[this.props.status] >= 3 ? `progtrckr-done` : `progtrckr-todo`}>
                        <img style={{ marginRight: '10px', marginLeft: '5px' }} src={foodimg} />
                        Order is Ready
                        <span className="foodstatus-mobile">Your order is ready to be picked up!</span>
                      </li>
                      <li className={this.state.orderStages[this.props.status] >= 4 ? `progtrckr-done` : `progtrckr-todo`}> <img style={{ marginRight: '10px', marginLeft: '5px' }} src={scooterimg} />Order Picked up
                        <span className="foodstatus-mobile">Your order has been picked up!</span>
                      </li>
                      <li className={this.state.orderStages[this.props.status] >= 5 ? `progtrckr-done` : `progtrckr-todo`}> <img style={{ marginRight: '10px', marginLeft: '5px' }} src={foodrestaurantimg} />Order delievered
                        <span className="foodstatus-mobile">Your order has been delivered to you successfully!</span>
                      </li>
                    </ol>

                  </div>
                }
              </div>
              //                         <div style={{ marginBottom: '3rem' }}>
              //                             <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
              //                                 {/* <h2 className="etatext-track">ETA • <b>20 Min</b></h2> */}
              //                                 <h2 className="etatext-track">{this.props.transactionDetails.date}</h2>
              //                                 <button style={{ backgroundColor: '#553a28', color: '#fff', border: 0, padding: '10px 20px', borderRadius: '6px', letterSpacing: '1.2px' }}
              //                                     onClick={this.handletrackOrder}>Track Map</button>
              //                             </div>
              //                             {/* <div>
              //     <h2 className="etatext-track">ETA • <b>20 Min</b></h2>
              // </div> */}
              //                             <ol className="progtrckr" data-progtrckr-steps="4">
              //                                 <li className="progtrckr-done">
              //                                     <img src={prepareorderimg} />
              //      Confirmed order
              //      <span className="foodstatus-mobile">Order confirmed</span>
              //                                 </li>
              //                                 <li className="progtrckr-done">
              //                                     <img src={prepareorderimg} />
              //      Preparing order
              //      <span className="foodstatus-mobile">Food is preparing</span>
              //                                 </li>
              //                                 <li className="progtrckr-done">
              //                                     <img style={{ marginRight: '10px', marginLeft: '5px' }} src={foodimg} />
              //     Ready for pickup
              //     <span className="foodstatus-mobile">delivery boy pickedup</span>
              //                                 </li>
              //                                 <li className="progtrckr-todo"> <img style={{ marginRight: '10px', marginLeft: '5px' }} src={scooterimg} />Order picked up
              //     <span className="foodstatus-mobile">food is on the way</span>
              //                                 </li>
              //                                 <li className="progtrckr-todo"> <img style={{ marginRight: '10px', marginLeft: '5px' }} src={foodrestaurantimg} />Order delievered
              //     <span className="foodstatus-mobile">Successfully delivered</span>
              //                                 </li>
              //                             </ol>

              //                         </div>

            }

            <hr />

            <button
              onClick={() => this.handleOrderModalShow(true)}
              className="viewbtn">
              View Order Details
            </button>
          </div >
        </div >
      </div >
    )
  }

}

class OrderItem extends Component {
  render() {

    return (

      <div style={{ display: 'flex', flexDirection: 'column', display: 'flex', justifyContent: 'space-between', marginRight: 30, }}>
        {/* <div className="column is-4">
                    <img className="itemimg-fr" src={itemimg} alt="item" />
                </div> */}
        <b  >{this.props.name} X {this.props.quantity}</b>

      </div>
    )
  }
}
const mapStateToProps = state => ({
  orderHistory: state.user.orderTransactionHistory,
  userDetails: state.user.userDetails?.profile,
  updatedDetails: state.user.updatedDetails?.profile,
  transactiondetails: state.cart?.transactiondetails,
  deliveryCharges: state.payment.deliveryCharges
});

export default connect(mapStateToProps, {
  transactionDetails,
  getDeliveryCharges
})(TrackorderThree);
