import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import { requestVerifyOtp, requestRegister, setRegisterResponseReset, requestLogin, requestRegisterConfirm } from '../../../actions';
import './Register.css';
import OtpInput from 'react-otp-input';
import rightArrowLight from '../../../images/icons/rightArrowLight.svg'
import resend from '../../../images/icons/resend.svg'
import phone from '../../../images/icons/phone.svg'

function VerifyOTP(props) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const reduxOtpRes = useSelector(state => state.user?.otpResponse)
    const [counter, setCounter] = React.useState(30);

    const homepage = useSelector(state => state.homepage)


    const [otp, setOtp] = useState('');
    const inputRefs = useRef([]);
    const [registershow, setRegisterShow] = useState(false);
    const [isResendOtp, setisResendOtp] = useState(false)
    const [errorPrompt, setErrorPrompt] = useState('')

    const counterTime = () => {

        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }
    const resendOtp = () => {
        if (counter === 0) {
            setCounter(30);
            setisResendOtp(true)
            // dispatch(requestLogin(props.loginInfo));
            dispatch(requestRegister(props.registerInfo));
            counterTime();
        }
        else {

        }
        //    window.setTimeout(counterTime(), 500000)
    }


    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    //     const resendOtp = () => {
    //         if(counter === 0) {
    //             handleVerifyOtp()
    //             // dispatch(requestRegister(props.registerInfo));
    //             setisResendOtp(true)
    //         }

    //     }
    //     React.useEffect(() => {
    //         const timer =
    //         counter > 0 &&  setInterval(() => setCounter(counter - 1), 1000);
    //       return () => clearInterval(timer);

    //   }, [counter]);
    const handleVerifyOtp = () => {
        let requestOptions = {
            udid: homepage.deviceDetails.udid,
            uuid: user.registerResponse.uuid,
            fullname: user.registrationDetails.name,
            email: user.registrationDetails.email,
            password: user.registrationDetails.password,
            gender: user.registrationDetails.gender,
            country: user.registrationDetails.country,
            city: user.registrationDetails.city,
            countryCode: props.registerInfo.countryCode,
            phoneNumber: props.registerInfo.phoneNumber,
            otp: Number.parseInt(otp),
          referralCode: props.referralCode
        }

        props.setOtp(Number.parseInt(otp))
        dispatch(requestVerifyOtp(requestOptions, result => {
            if (result.success) {
                console.log('reg stage 2 success')
                if (window.clarity && !!result.uuid) {
                    window.clarity('set', 'UserId', result.uuid)
                }
            }
            else {
                setErrorPrompt(result?.message)
            }

        }));

        if (user.otpResponse?.success) {
            props.setCurrentScreen(2)
        }
    };

    //  useEffect(() => {
    //      if (reduxOtpRes?.success) {
    //        props.setCurrentScreen(2)
    //   }
    //  }, [reduxOtpRes?.success])

    useEffect(() => {
        if (user.registerStepCompleted === 2) {
            props.setCurrentScreen(2)
        } else if (user.registerStepCompleted === 1) {
            props.setCurrentScreen(1)
        }
        else {
            props.setCurrentScreen(0)
        }
    }, [user.registerStepCompleted]);

    useEffect(() => {
        return () => {
            setisResendOtp(false)
        }
    }, [])

    const handleGoBack = () => {
        // setisResendOtp(false)
        dispatch(setRegisterResponseReset())
        // props.setCurrentScreen(0)
    };
    return (
        <div>
            <div className="modalHeading">This is Verify OTP Page</div>
            <p className="pera">Please verify your account to continue with your order</p>
            <div className="contactNumber">
                <img src={phone} alt="Mobile" />
          <p>
            {`(${props.registerInfo?.countryCode})`}&nbsp;
            {props.registerInfo?.phoneNumber.replace(/[^a-zA-Z0-9]/g, '')}</p> 
                <button onClick={handleGoBack}>Edit</button>
            </div>
            <div className="otp">
                <OtpInput
                    inputStyle={{ padding: 0 }}
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    isInputNum={true}
                    separator={<span>-</span>}
                />
            </div>
            <div onClick={resendOtp} className="resend" style={{ cursor: 'pointer' }}>
                <div>Resend OTP code</div>
                <img src={resend} alt="Mobile" />
            </div>
            <div style={{ color: '#49CE8B' }}>00: {counter}</div>
            {isResendOtp && <p className='my-2 text-success h6'>OTP is sent.</p>}

            {!user.otpVerifyInProgress && user.otpResponse && !user.otpResponse.success && <p className='my-2 text-danger h6'> {errorPrompt}</p>}

            <button onClick={handleVerifyOtp} className="modalRegBtn">Verify OTP
                {user.otpVerifyInProgress ?
                    <div className='spinner-border text-primary' />
                    :
                    <img src={rightArrowLight} alt="Arrow" />}
            </button>
        </div>
    );
}

export default VerifyOTP
