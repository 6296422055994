import config from '../config';

const initialState = {
terms:[],
};

const TERMS_AND_POLICIES = "TERMS_AND_POLICIES"


export const requestTerms = () => async (dispatch, getState) => {
  var requestOptions = {
    method: 'GET',
    redirect: 'follow'
  };
  await fetch(`${config.apiUrl}/content?model=termsAndPolicies&template=biryanis-website&merchantAccessToken=${config.merchantAccessToken}`, requestOptions)
    .then(response => response.json())
    .then(result => {
      dispatch({
        type: TERMS_AND_POLICIES,
        terms: result.data,
      });
    })
    .catch(error => console.log('error', error));
};




const termsReducer = (state = initialState, action) => {
  switch (action.type) {

    case TERMS_AND_POLICIES: {
      return {
        ...state,
       terms: action.terms
      }
    }


    default: return state;
  }
};


export default termsReducer;
