import React, { useEffect } from 'react'
import './Settings.css';
import './SettingAccount.css';
import { Link } from 'react-router-dom';
export default function SettingAccount() {
    useEffect(() => {
        window.scrollTo(0, 0)
        document.getElementById("splashspinner").style.display = "none";

    }, [])
    return (
        <div className="SettingAccount">
            <div className="settingbody">
                <div className='settingheader'>
                    <h3 className="settinghead"><Link to='/Settings'><i class="fa fa-arrow-circle-left" aria-hidden="true"></i></Link>My Account</h3>
                </div>
                <div className="settingaccountrow">
                    <a href='#'>
                        <i class="fa fa-location-arrow iconsa" aria-hidden="true"></i> <span className="accountname"> Manage Addresses <Link to="/SettingsManageAddress" ><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></Link></span>
                    </a>
                </div>
                <div className="settingaccountrow">
                    <a href='#'>
                        <i class="fa fa-paypal iconsa" aria-hidden="true"></i> <span className="accountname"> Payments <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="settingaccountrow">
                    <a href='#'>
                        <i class="fa fa-money iconsa" aria-hidden="true"></i> <span className="accountname"> Offers <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                    </a>
                </div>
                <div className="settingaccountrow">
                    <a href='#'>
                        <i class="fa fa-link iconsa" aria-hidden="true"></i> <span className="accountname"> Social Accounts <i class="fa fa-arrow-circle-right" aria-hidden="true"></i></span>
                    </a>
                </div>
            </div>
        </div>
    )
}
