import React, { useState, useEffect, createRef } from "react";
import config from '../../../../../config';
import apiClient from '../../../../../api';
import "../../custom.css";
import './Panel.css'

import Checkout from '../../../../../containers/Checkout'
import CheckoutPage from '../../../checkout/CheckoutPage'
import { useSelector, useDispatch } from 'react-redux';

import "react-datetime/css/react-datetime.css";

import { saveInfo } from '../../../../../reducers/checkout';
import cartarrow from '../../../../../images/Regular-M.svg';
import OrderModeSelect from './OrderModeSelect'
import UserDetailsHeader from './UserDetailsHeader'
import LoginModal from '../../../../Common/LoginModal';
import DeliveryMode from './Ordermode.Delivery'
import TakeawayMode from './Ordermode.Takeaway'
import DineinMode from './Ordermode.Dinein'
import AddInstructions from './AddInstructions'
import CartAlertModal from '../../CartAlertModal'
import StripeElements from './../../../checkoutStripe/StripeElements'
import { paymentMODES, validateVpa, savedCard, deleteCard, guestPlaceOrder, guestPlaceOrderStripe, setAddress, getDeliveryCharges, setUserInfo, paymentCheckout } from "../../../../../reducers/PaymentTwo";
function CheckoutPanel(props) {
  const dispatch = useDispatch();
  const anchorRef0 = createRef()
  const anchorRef1 = createRef()
  const anchorRef2 = createRef()
  const anchorRef3 = createRef()

  const reduxCheckout = useSelector(state => state.checkout)
  const reduxCart = useSelector(state => state.cart)
  const reduxCurrency = useSelector(state => state.homepage.allCatalogues?.currency)
  const reduxUser = useSelector(state => state.user)
  const reduxOrderStates = useSelector(state => state.orders)
  const reduxOrder = useSelector(state => state.orders?.initialOrderMode)
  const paymentGatewayRedux = useSelector(state => state.homepage.selectedMerchantServiceability?.paymentGateway);
  const selectedMerchantServiceabilityRedux = useSelector(state => state.homepage.selectedMerchantServiceability);
  const reduxmerchant = useSelector(state => state.homepage?.nearestMerchantDetails?.id)

  const [loginShow, setloginShow] = useState(false)
  const [paymentshow, setPaymentShow] = useState(false);
  const [note, setNote] = useState();
  const [alertShow, setalertShow] = useState({ status: false, message: 'Please select Address' })
  const orderTypes = {
    delivery: 'Delivery',
    takeAway: 'Takeaway',
    dineIn: 'Dine In',
    menu: 'Menu'
  }
  const { guest, userSelectedAddressId } = reduxUser.temporaryInputFields

  const validations = async () => {

    // let location = reduxUser.userLocation?.latitude ?
    //   reduxUser.userLocation :
    //   reduxUser.userDetails?.locationPreference
    // const params = { ...location, orderMode: reduxOrderStates.initialOrderMode }
    // const response = await apiClient.post(apiClient.Urls.serviceability, {
    //   merchantAccessToken: config.merchantAccessToken,
    //   selectedOrderMode: params.orderMode,
    //   userLatitude: params.latitude,
    //   userLongitude: params.longitude,
    //   merchantId: selectedMerchantServiceabilityRedux.id
    // });

    // let merchantid = selectedMerchantServiceabilityRedux.id
    // let serviceableMerchants = response?.merchants

    // let selectedMerchant = serviceableMerchants?.filter((item) => {
    //   return item.id === merchantid
    // })

    // if ((!!selectedMerchant && selectedMerchant[0]?.serviceable) === false) {
    //   setalertShow({
    //     status: true,
    //     message: `Restaurant is not serviceable for
    //         ${orderTypes[reduxOrderStates.initialOrderMode]}, please choose different order mode or select another nearby restaurant.`
    //   })
    //   return false
    // }

    if (
      (reduxCheckout?.selectedDeliveryAddressId === '' && reduxOrder === "delivery" && guest === false)

    ) {
      setalertShow({ status: true, message: 'Please select Address!' })
      return false
    }
    if (reduxCheckout?.selectedDateTime === '' && reduxOrder === "takeAway") {
      setalertShow({ status: true, message: 'Please select Date and Time\n for Take Away.' })
      return false
    }
    if (reduxCheckout?.selectedDateTime === '' && reduxOrder === "dineIn") {
      setalertShow({ status: true, message: 'Please select Date and Time\n for Dine in.' })
      return false
    }
    return (true);
  }

  const handlePayment = async () => {
    let isValid = await validations()
    if (isValid) {
      dispatch(saveInfo({
        note: reduxUser.temporaryInputFields?.instructions,
        pickupAt: reduxUser.temporaryInputFields?.pickup,
        merchantId: reduxmerchant,
        addressId: reduxCheckout.selectedDeliveryAddressId,
        tip: reduxUser.temporaryInputFields?.tip,
        // tip: null,
        custom: reduxUser.temporaryInputFields?.custom,
        tipType: reduxUser.temporaryInputFields?.tipType,
        // tipType: null,

      }, result => {
        if (result.success) {
          dispatch(guestPlaceOrder());
        }
        else {
          setalertShow({ status: true, message: result.message })
        }
      }));
    }
    return;

    if (paymentGatewayRedux === 'Stripe') {
      // Should be ideally called once save info is done.
      dispatch(guestPlaceOrder());
    }
    else {
      setPaymentShow(true);
      setTimeout(() => {
        handleAnchorRef2()
      }, 300);
    }
  }

  const handleAnchorRef0 = (reference) => {
    if (anchorRef0.current) {
      anchorRef0.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      })
      //  window.scrollBy(0, -105); // Adjust scrolling with a negative value here
    }
  }
  const handleAnchorRef1 = (reference) => {
    if (anchorRef1.current) {
      anchorRef1.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      })
      //  window.scrollBy(0, -105); // Adjust scrolling with a negative value here
    }
  }
  const handleAnchorRef2 = (reference) => {
    if (anchorRef2.current) {
      anchorRef2.current.scrollIntoView({
        behavior: "smooth",
        block: "center"
      })
      //  window.scrollBy(0, -105); // Adjust scrolling with a negative value here
    }
  }
  return (
    <div className="col-lg-8 col-md-12"
      style={{
        // boxShadow: ' 0px 0px 20px #0000000D',
        // backgroundColor: ' #fff',
        // borderRadius: '0px 0px 6px 6px',
        // opacity: 1, height: 'fit-content'
      }}
    >
      {/* <text onClick={handleAnchorRef0}>fdfksdf</text> */}
      {loginShow && (
        <LoginModal show={loginShow} setShow={setloginShow} {...props} />
      )}
      {alertShow.status && <CartAlertModal show={alertShow} setShow={setalertShow} />}

      < UserDetailsHeader />

      {reduxUser.isAuthenticated || reduxUser.temporaryInputFields.guest === true
        ?
        <div>
          <OrderModeSelect scrollDown={handleAnchorRef0} />

          <div ref={anchorRef0} >
            {reduxOrder === 'delivery' ?
              <DeliveryMode scrollDown={handleAnchorRef1} />
              : reduxOrder === 'takeAway' ?
                <TakeawayMode scrollDown={handleAnchorRef1} />
                :
                <DineinMode scrollDown={handleAnchorRef1} />
            }
          </div>

          <div ref={anchorRef1} >
            < AddInstructions />
          </div>

          <div className="panel-card choose-order-proceed" style={{ borderBottomLeftRadius: 6, borderBottomRightRadius: 6 }} >
            <button style={{
              padding: '6px 24px', color: '#FFFFFF', backgroundColor: '#553A28', minWidth: 200, maxWidth: '350px', outline: 'none', border: 'none', textAlign: 'start', borderRadius: '6px'
            }} onClick={() => handlePayment()}>Proceed To Payment<span className='cartarrow'><img src={cartarrow} /></span></button>
            <text className="order-total" style={{
              color: '#553A28', fontWeight: 'bold',
              fontSize: 'large'
            }}>Order Total {'  ' + reduxCurrency + reduxCart.cartTotal?.toFixed(2)}</text>
          </div>

        </div>
        :
        null
        // reduxUser.temporaryInputFields.guest === true ?
        //   null
        //   :
        //   <OrderModeSelect scrollDown={handleAnchorRef0} />
      }

      <div className="panel-card panel-card-fr choose-payment-hide" ref={anchorRef2} style={{ borderBottomLeftRadius: 6, borderBottomRightRadius: 6 }} >
        <div className="paymenttag" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
          <text style={{
            color: '#553A28', fontWeight: 'bold',
            fontSize: 'large'
          }}>Choose Payment Method</text>
          <text style={{
            color: '#553A28', fontWeight: 'bold',
            fontSize: 'large'
          }}>Order Total {reduxCurrency + reduxCart.cartTotal?.toFixed(2)}</text>
        </div>

        <div >
          {paymentshow &&
            <Checkout {...props} finalAmount={reduxCart.cartTotal?.toFixed(2)} />
          }
        </div>
      </div>
    </div>
  )
}

export default CheckoutPanel