// import state from 'sweetalert/typings/modules/state';
import apiClient from '../api/index';

const initialState = {
    netbanking: [],
    wallets: [],
    savedCards: [],
    userInfo: {},
    addressess: [],
    deliveryCharges: 0,
};

const PAYMENT_MODES = "payment/PAYMENT_MODES"
const SAVED_CARDS = "payment/SAVED_CARDS"
const DELETE_CARD = "payment/DELETE_CARD"
const VALIDATE_VPA = "payment/VALIDATE_VPA"
const GUEST_PLCAE_ORDER = "payment/PLCAE_ORDER"
const SET_ADDRESS = "payment/SET_ADDRESS"
const SET_USERINFO = "payment/SET_USERINFO"
const GET_DELIVERY_CHARGES = "payment/GET_DELIVERY_CHARGES"

export const paymentMODES = () => async (dispatch, getState) => {
    try {
        const response = await apiClient.get(apiClient.Urls.paymentModes);

        if (response.success) {
            dispatch({
                type: PAYMENT_MODES,
                netbanking: response.paymentModes.netbanking,
                wallets: response.paymentModes.wallets,
            });
        } else {

        }

    } catch (error) {

    }
};

export const validateVpa = () => async (dispatch, getState) => {
    try {
        const response = await apiClient.post(apiClient.Urls.validateVPA, {
            vpa: 'test@okicici',
        });

        if (response.success) {
            dispatch({
                type: VALIDATE_VPA,
                vpa: response.response,
            });
        } else {

        }

    } catch (error) {

    }
};
export const savedCard = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const response = await apiClient.post(apiClient.Urls.getSavedCards, {
            phoneNumber: state.payment.userInfo.phoneNumber,
        });

        if (response.success) {
            dispatch({
                type: SAVED_CARDS,
                savedCards: response.savedCards.cards,
            });
        } else {

        }

    } catch (error) {

    }
};

export const deleteCard = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const response = await apiClient.post(apiClient.Urls.deleteCard, {
            phoneNumber: state.payment.userInfo.phoneNumber,
            cardId: state.cartId,
        });

        if (response.success) {
            dispatch({
                type: DELETE_CARD,
                message: response.response,
            });
        } else {

        }

    } catch (error) {

    }
};

export const guestPlaceOrder = (paymentDetails) => async (dispatch, getState) => {
    try {
        const state = getState();
        const response = await apiClient.post(apiClient.Urls.guestCheckout, {
            userInfo: state.payment.userInfo,
            shippingCharges: state.payment.deliveryCharges,
            cartId: state.cart.cartId,
            paymentDetails: paymentDetails,
        });
        if (response.success) {
            window.fbq('track', 'InitiateCheckout');
            dispatch({
                type: GUEST_PLCAE_ORDER,
                response: response.response,
            });
        } else {

        }

    } catch (error) {

    }
};

export const setAddress = (address) => async (dispatch, getState) => {
    dispatch({
        type: SET_ADDRESS,
        addressess: address
    });
    const state = getState();
    if (state.payment.addressess) {
        dispatch(getDeliveryCharges())
    }
};

export const setUserInfo = (name, phoneNumber, email) => {

    return {
        type: SET_USERINFO,
        name: name,
        phoneNumber: phoneNumber,
        email: email,
    };
};

export const getDeliveryCharges = () => async (dispatch, getState) => {
    try {
        const state = getState();
        const response = await apiClient.get(apiClient.Urls.getDeliveryCharges, {
            address: state.payment.addressess[0].address,
            merchantId: state.menu.id,
            cartId: state.cart.cartId
        });

        if (response.success) {
            dispatch({
                type: GET_DELIVERY_CHARGES,
                deliveryCharges: response.charges,
            });
        } else {

        }

    } catch (error) {

    }
};

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {

        case PAYMENT_MODES: {
            return {
                ...state,
                netbanking: action.netbanking,
                wallets: action.wallets
            }
        }

        case VALIDATE_VPA: {
            return {
                ...state,
                vpa: action.validateVpa,
            }
        }
        case SAVED_CARDS: {
            return {
                ...state,
                savedCards: action.savedCards,
            }
        }

        case DELETE_CARD: {
            return {
                ...state,
                message: action.deleteCard,
            }
        }

        case GUEST_PLCAE_ORDER: {
            return {
                ...state,
                orderDetails: action.response,
            }
        }

        case GET_DELIVERY_CHARGES: {
            return {
                ...state,
                deliveryCharges: action.deliveryCharges,
            }
        }

        case SET_ADDRESS: {
            return {
                ...state,
                addressess: [
                    ...state,
                    {
                        address: action.addressess.address.address,
                        faltNo: action.addressess.address.faltNo,
                        landMark: action.addressess.address.landMark
                    },
                ]
            }
        }

        case SET_USERINFO: {
            return {
                ...state,
                userInfo: {
                    name: action.name,
                    phoneNumber: action.phoneNumber,
                    email: action.email,
                }
            }
        }

        default: return state;
    }
};

export default paymentReducer;
