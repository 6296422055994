import React, { useState, useEffect } from 'react';
import './alertModal.css';
import {
    Modal
} from 'react-bootstrap';

export function CommonAlertModal(props) {
    return (
        <div>
            <Modal show={props.show} onHide={() => { props.setShow(false) }} animation={false}>
                <Modal.Header closeButton />
                <Modal.Body style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}>
                    <div className="modalHeading">Alert</div>
                    <p className="pointpara">{props.alertText}</p>
                    <button className="modalPrimaryBtn justify-content-center"
                        style={{ width: 150 }}
                        onClick={() => props.setShow(false)}  >OK</button>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>



                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
};
