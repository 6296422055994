import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import './enquiry.css';
import Form from 'react-bootstrap/Form'
import franchiseimg from '../../../images/Franchise (1).jpg';
import {

  setNearestMerchantDetails,
  addItemToCart, contactUsFormSubmit, franchiseEnquiryFormSubmit
} from "../../../actions";
import ContactSuccessModal from '../../Common/ContactSuccess/ContactSuccessModal';
function Franchise(props) {
  //   const { referralsearch } = useLocation();
  // const referralcodeparam = new URLSearchParams(referralsearch);
  // const Referralcode = referralcodeparam.get("Referralcode");

  const dispatch = useDispatch();
  const [address, setAddress] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    occupation: '',
    pastExperienceMessage: '',
    city: '',
    liquidasset: ''
  });
  const [validated, setValidated] = useState(false);
  const [showFormSuccessful, setFormSuccessful] = useState(false);
  const handlechangenote = (e) => {
    const { name, value } = e.currentTarget;
    setAddress({
      ...address,
      [name]: value
    });
  }

  const handleSubmitTwo = () => {
    const requestOptions = {
      ...address,
      name: address.name,
      email: address.email,
      phoneNumber: address.phoneNumber,
      occupation: address.occupation,
      pastExperienceMessage: address.pastExperienceMessage,
      message: address.message,
      city: address.city,
      liquidasset: address.liquidasset


    };
    if (
      address.name.length > 0 &&
      address.message.length > 0 &&
      address.liquidasset.length > 0

    ) {
      dispatch(franchiseEnquiryFormSubmit(requestOptions))
      setFormSuccessful(true)
    }
    else {
      setValidated(true);
    }
  }
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  const setSuccessShow = () => {
    setFormSuccessful(false)
  }
  return (
    <div className="franch">
      <div className="about-services">
        <div className="container">
          <div className="enquiryModel">
            <h2>FRANCHISE MODEL</h2>
            <p>We work on a franchise model and all of our renters are franchise partners.</p>
            <p>We established our first franchise in 2015 at Vijayawada, Andhra Pradesh</p>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="about-services-main">
                <img src="assets/HighStreetModel.svg" />
                <h2>HIGH STREET</h2>
                <p>Its semi casual dine-in concept</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="about-services-main">
                <img src="assets/ExpressModel.svg" />
                <h2>EXPRESS</h2>
                <p>Good for food courts or malls</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="about-services-main">
                <img src="assets/TakeawayModel.svg" />
                <h2>TAKEAWAY</h2>
                <p>Built for takeaway</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="FranchiseEnquiry">
          <div className="row">
            <div className="col-lg-8 col-md-6">
              <div className="formSection formSection-fr">
                <h2>Franchise Enquiry</h2>
                <p>Thank you for your interest in a franchise enquiry of Biryanis and More...!</p>
                {ContactSuccessModal && <ContactSuccessModal setShow={setSuccessShow} show={showFormSuccessful} />}
                <Form className="modelForm-fr" noValidate validated={validated} onSubmit={handleSubmit}>

                  <Form.Group md="4" className="nametext" controlId="validationCustom01">
                    <Form.Label>Name<span class="required-fr">*</span></Form.Label>
                    <Form.Control
                      name='name'
                      value={address.name}
                      onChange={handlechangenote}
                      required="required-fr"
                      type="text"
                      className="name-fr"
                      placeholder="Enter your name"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide name.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group md="4" className="nametext" controlId="validationCustom01">
                    <Form.Label>Occupation<span class="required-fr">*</span></Form.Label>
                    <Form.Control
                      name='occupation'
                      value={address.occupation}
                      onChange={handlechangenote}
                      required="required-fr"
                      type="text"
                      className="name-fr"
                      placeholder="Enter your occupation"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide occupation.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group md="4" className="nametext" controlId="validationCustom01">
                    <Form.Label>Email<span class="required-fr">*</span></Form.Label>
                    <Form.Control
                      name='email'
                      value={address.email}
                      type="text"
                      onChange={handlechangenote}
                      className="name-fr"
                      required="required-fr"
                      placeholder="Enter your email"

                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide email.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group md="4" className="nametext" controlId="validationCustom01">
                    <Form.Label>Contact number<span class="required-fr">*</span></Form.Label>
                    <Form.Control
                      name='phoneNumber'
                      value={address.phoneNumber}
                      type="text"
                      onChange={handlechangenote}
                      required="required-fr"
                      className="name-fr"
                      placeholder="Enter your contact number"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide phone number.</Form.Control.Feedback>

                  </Form.Group>

                  <Form.Group md="4" className="nametext" controlId="validationCustom01">
                    <Form.Label>City/state<span class="required-fr">*</span></Form.Label>
                    <Form.Control
                      name='city'
                      value={address.city}
                      onChange={handlechangenote}
                      required="required-fr"
                      type="text"
                      className="name-fr"
                      placeholder="city/state"
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide city.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="nametext" controlId="validationCustom01">
                    <Form.Label>Select liquid assets worth <span class="required-fr">*</span></Form.Label>
                    <Form.Control className="name-fr" required="required-fr" placeholder="Select liquid assets worth" value={address.liquidasset} name="liquidasset" onChange={handlechangenote} as="select">
                      <option name="liquidasset" value="">Select liquid assets worth</option>
                      {/* <option name="liquidasset" value="25,00,000.00 to 35,00,000.00">25,00,000.00 to 35,00,000.00</option> */}
                      <option name="liquidasset" value="50,00,000.00 to 75,00,000.00">50,00,000.00 to 75,00,000.00</option>
                      <option name="liquidasset" value="80,00,000.00 & above">80,00,000.00 & above</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please  choose option.</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group md="4" className="nametext" controlId="validationCustom01">
                    <Form.Label>Message<span class="required-fr">*</span></Form.Label>
                    <Form.Control as="textarea" rows={3}
                      name='message'
                      value={address.message}
                      onChange={handlechangenote}
                      type="text"
                      className="name-fr"
                      required="required-fr"
                      placeholder="Why do you want to own a Biryanis and More Franchise?"
                    />
                    <Form.Control.Feedback type="invalid">
                      please provide feedback</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group md="4" className="nametext" controlId="validationCustom01">
                    <Form.Label>Past Experience<span class="required-fr">*</span></Form.Label>
                    <Form.Control as="textarea" rows={3}
                      name='pastExperienceMessage'
                      value={address.pastExperienceMessage}
                      onChange={handlechangenote}
                      type="text"
                      className="name-fr"
                      required="required-fr"
                      placeholder="Past or current business experience"
                    />

                    <Form.Control.Feedback type="invalid">
                      provide your past experience
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
                <div className="row">
                  {/* <div className="col-md-6">
                                    <input className="secondaryBtn" type="file" id="upload" hidden />
                                    <label className="secondaryBtn secondaryBtn-fr" for="upload">UPLOAD DOCUMENT</label>
                                    </div> */}
                  <div className="col-md-6 submitnew mmt">
                    <button className="primaryBtn" onClick={handleSubmitTwo}>SUBMIT</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <img src={franchiseimg} alt="Franchise Enquiry" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default Franchise;