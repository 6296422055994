import config from './../config';

const BASE_URL = config.apiUrl + '/';
const URLS = {
  device: BASE_URL + 'device/init',
  merchantSlug: BASE_URL + 'merchants/merchant-slug',
  merchants: BASE_URL + 'merchants',
  loyalty: BASE_URL + 'loyalty/v2',
  catalogue: BASE_URL + 'merchants/catalogue',
  resendOTP: BASE_URL + 'auth/resend-otp',
  validateOtp: BASE_URL + 'auth/login/otp/validate',
  sendOtp: BASE_URL + 'auth/login/otp/request',
  orders: BASE_URL + 'transaction-history',
  register: BASE_URL + 'register/phone',
  verifyRegOtp: BASE_URL + 'auth/verify-otp',
  getProfile: BASE_URL + 'profile',
  logout: BASE_URL + 'user/logout',
  config: BASE_URL + 'content',
  registerConfirm: BASE_URL + 'register/confirm',
  // getPoints: BASE_URL + 'loyalty/points',
  getPoints: BASE_URL + 'cart/apply-points',

  getFreebies: BASE_URL + 'loyalty/my-freebie/list',
  // postApplyCoupon: BASE_URL + 'loyalty/coupon/redeem',
  // postApplyPoints: BASE_URL + 'loyalty/points/redeem',

  saveInfo: BASE_URL + 'cart/save-info',

  postApplyFreebie: BASE_URL + 'loyalty/freebie/redeem',
  getUser: BASE_URL + 'user',
  // addToCart: BASE_URL + 'guest/add-to-cart',
  addToCart: BASE_URL + 'cart/add',
  // getCart: BASE_URL + 'guest/get-cart',
  getCart: BASE_URL + 'cart/get',
  //active-cart
  activeCart: BASE_URL + 'cart/active',
  //remove-cart
  removefromcart: BASE_URL + 'cart/remove',

  cartIncrement: BASE_URL + 'cart/item/increment',
  cartDecrement: BASE_URL + 'cart/item/decrement',
  cartClear: BASE_URL + 'cart/clear',

  getOrderDetails: BASE_URL + 'transaction-details',
  // guestCheckout: BASE_URL + 'guest/place-order',
  guestCheckout: BASE_URL + 'cart/place-order',
  getDeliveryCharges: BASE_URL + 'guest/get-delivery-charges',
  paymentModes: BASE_URL + 'payment/payment-modes',
  getSavedCards: BASE_URL + 'payment/saved-cards',
  getPaymentCheckout: BASE_URL + 'payment/checkout',

  deleteCard: BASE_URL + 'payment/delete-card',
  validateVPA: BASE_URL + 'payment/validate-vpa',
  getLatLng: BASE_URL + 'user/location-preference',
  serviceability: BASE_URL + 'merchants/serviceability',
  updateProfile: BASE_URL + 'profile/update',
  contactUsForm: BASE_URL + 'content/form/submit',
  franchiseEnquiryFrom: BASE_URL + 'content/form/submit',

  getServiceableAddress: BASE_URL + 'cart/address/list',
  addServiceableAddress: BASE_URL + 'cart/address/add',
  // editServiceableAddress: BASE_URL + 'cart/address/edit',
  editServiceableAddress: BASE_URL + 'cart/address/add', //as of now calling /add

  getMerchantIdfromSlug: BASE_URL + 'merchants/merchant-slug',
  getCardFreebies: (loyaltyId, loyaltyCardId) => BASE_URL + 'loyalty/v2/' + loyaltyId + '/cards/' + loyaltyCardId + '/freebies/base',

  getCartConfig: BASE_URL + 'cart/config',

  facebookLoginCheck: BASE_URL + 'facebook/login',
  googleLoginCheck: BASE_URL + 'gmail/login',

  applyFreebie: BASE_URL + 'cart/apply-freebie',
  removeFreebie: BASE_URL + 'cart/remove-freebie',

  getCoupons: BASE_URL + 'loyalty/coupons/list',
  postApplyCoupon: BASE_URL + 'cart/apply-coupon',
  removeCoupon: BASE_URL + 'cart/remove-coupon',

  loyaltyPoints: BASE_URL + 'loyalty/points',
  postApplyPoints: BASE_URL + 'cart/apply-points',
  removePoints: BASE_URL + 'cart/remove-points',

  /* NEW LOYALTY APIS W.R.TO CART */
  cartPoints: BASE_URL + 'cart/fetch-points',
  cartCoupons: BASE_URL + 'cart/fetch-coupons',
  cartFreebies: BASE_URL + 'cart/fetch-freebies',

  getTimeSlots: BASE_URL + 'merchants/time-slots',

};

const apiClient = {
  Urls: URLS,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },

  make(url, method, params = {}) {
    params.merchantAccessToken = params.merchantAccessToken || window.merchantAccessToken || config.merchantAccessToken;
    const reqUrl = method === 'GET' ? `${url}?${new URLSearchParams(params).toString()}` : url;
    return fetch(reqUrl, {
      method,
      headers: apiClient.headers,
      ...(method === 'GET' ? {} : { body: JSON.stringify(params) }),
    }).then(response => response.json());
  },

  post(url, params) {
    return this.make(url, 'POST', params);
  },

  get(url, params) {
    return this.make(url, 'GET', params);
  },

}

export default apiClient;
