import React, { useEffect } from 'react'

import ContentLoader from "react-content-loader"

export const ExploreMenuCardLoader = <ContentLoader
    speed={1}
    width={240}
    height={500}
    viewBox="0 0 240 500"
    backgroundColor="#ecebeb"
    foregroundColor="#fafafa"

>
    <rect x="0" y="0" rx="3" ry="3" width="384" height="180" />
    <rect x="0" y="205" rx="3" ry="3" width="384" height="6" />
    <rect x="0" y="220" rx="3" ry="3" width="384" height="6" />
    <rect x="0" y="235" rx="3" ry="3" width="384" height="6" />


</ContentLoader>