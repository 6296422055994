import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom'
import { deviceDetect } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';
import Config from '../../config'
import { initialize } from '@froogal.ai/referral-web-sdk';
import '@froogal.ai/referral-web-sdk/dist/index.css';
import './referral-custom.css';

const Referral = (props) => {

   useEffect(() => {
      initialize(document.getElementById('referral-root'), {
         authToken: props.user.otpResponse?.authToken,
         merchantAccessToken: Config.merchantAccessToken,
         loyaltyId: props.user.userDetails?.profile.primaryLoyaltyId,
        programId: 4,
        shareReferralInfo: {
          type: 'url',
          baseUrl: `${window.location.origin}/register?referralCode=`
        },
         milestones: [],
         program: []
      })
   }, []);

   return (
      <div id="referral-root"></div>
   );
}


// export default Referral;
const mapStateToProps = (state) => ({
   homepage: state.homepage,
   userDetails: state.user.userDetails?.profile,
   user: state.user,
   cart: state.cart,
   allCatalogues: state.homepage.allCatalogues,
})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Referral)
