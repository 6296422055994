import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import Card from 'react-bootstrap/Card'
import Badge from 'react-bootstrap/Badge'
import LocationImg from './location.svg'
import LocationImgSelected from './locationselected.svg'

class GoogleMaps extends Component {
   constructor(props) {
      super(props);
      this.state = {
         location: {
            lat: this.props?.selectedMerchant && parseFloat(this.props.selectedMerchant.latitude),
            lng: this.props?.selectedMerchant && parseFloat(this.props.selectedMerchant.longitude)
         },
         selectedPlace: 'props',
         activeMarker: {},
         showingInfoWindow: true,
         selectedMerchant: null,
         userCoords: {}
      }
   }

   componentDidMount() {
      this.setState({
         location: this.props.location,
         fromDate: this.props.fromDate,
         toDate: this.props.toDate,
      })
      this.setState({
         userCoords:
            !!this.props.user.userLocation?.latitude ?
               {
                  lat: this.props.user.userLocation?.latitude,
                  lng: this.props.user.userLocation?.longitude
               } :
               {
                  lat: this.props.user.userDetails?.locationPreference.latitude,
                  lng: this.props.user.userDetails?.locationPreference.longitude,
               }
      })
   }

   componentDidUpdate() {
      if (this.state.selectedMerchant !== this.props.selectedMerchant) {
         this.onInfoWindowClose()
         this.setState({ selectedMerchant: this.props.selectedMerchant })
      }
   }

   onMarkerClick = (props, marker) => {
      this.setState({
         activeMarker: marker,
         selectedPlace: props.name,
         showingInfoWindow: true
      });
      // this.props.setselectedMerchant(this.props.selectedMerchant)
   }

   onInfoWindowClose = () => {
      // this.props.setselectedMerchant()
      this.setState({
         activeMarker: null,
         showingInfoWindow: false
      });
   }

   render() {
      return (
         <div style={{ height: window.innerWidth > 768 ? '100%' : '70vh' }} >
            <Map
               initialCenter={
                  !!this.props.user.userLocation?.latitude ?
                     {
                        lat: this.props.user.userLocation?.latitude,
                        lng: this.props.user.userLocation?.longitude
                     } :
                     {
                        lat: this.props.user.userDetails?.locationPreference.latitude,
                        lng: this.props.user.userDetails?.locationPreference.longitude,
                     }}
               center={this.props.selectedMerchant && {
                  lat: this.props.selectedMerchant && parseFloat(this.props.selectedMerchant.latitude),
                  lng: this.props.selectedMerchant && parseFloat(this.props.selectedMerchant.longitude)
               }}
               google={window.google}
               // google={this.props.google}
               zoom={!!this.props.selectedMerchant ? 11 : 2}
            >
               {
                  this.props.homepage.availableModesDetails?.data?.map((value, i) =>
                     value !== "" ?
                        <Marker
                           title={value.name}
                           key={i}
                              //   icon={LocationImgSelected}
                              icon={
                                  this.props.selectedMerchant?.id === value.id ? LocationImgSelected : LocationImg}

                           position={{
                              lat: value.latitude,
                              lng: value.longitude
                           }}
                           name={value}
                           onClick={this.onMarkerClick}
                        /> : null
                  )
               }
               <InfoWindow
                  marker={this.state.activeMarker}
                  onClose={this.onInfoWindowClose}
                  visible={this.state.showingInfoWindow}
               >
                  <Card style={{ maxWidth: '18rem', minHeight: 60, borderWidth: 0 }}  >
                     <Card.Body style={{ padding: 0, position: 'relative' }} >
                        <h6 color='#4D3A21'
                                  style={{ color: '#4D3A21', fontWeight: 'bold', textAlign: 'start', }}>
                           {this.state.selectedPlace.name}
                        </h6>
                              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', paddingBottom: 35 }}>
                                  {this.state.selectedPlace?.address1}
                        </div>
                        <Card.Link
                           className="getdirection-fr"
                           style={{ position: 'absolute', bottom: 0, left: 0 }}
                           target="_blank"
                           onClick={() => this.handleDirection()}
                           href={`https://www.google.com/maps?saddr=${this.state.userCoords.lat + ',' + this.state.userCoords.lng}&daddr=${this.state.selectedPlace?.latitude}, ${this.state.selectedPlace?.longitude}`}>
                           Get Directions
                    </Card.Link>
                     </Card.Body>
                  </Card>
               </InfoWindow>
            </Map>
         </div >
      );
   }
}
const mapStateToProps = (state) => ({
   homepage: state.homepage,
   user: state.user
})

export default connect(mapStateToProps)(GoogleMaps)
