import config from '../config';


export function createActionTypes(base, actions = []) {
  return actions.reduce((acc, type) => {
    acc[type] = `${base}_${type}`

    return acc
  }, {})
}

export function createAction(type, data = {}) {
  return { type, payload: data }
}

export function getCalculateScreenSize(width, height) {
  const size = (Math.sqrt((width * width) + (height * height))).toFixed(1);
  return size;
}


export async function getAddressFromCoordinates(params = {}) {
  let response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${params?.latitude},${params?.longitude}&key=${config.googleMapApiKey}`)
  response = await response.json()
  if (response.status === 'OK') {

    const address_components = response?.results[0];
    let components = {};
    address_components.address_components?.map((value, index) => {
      value.types.map((value2, index2) => {
        components[value2] = value.long_name;
        if (value2 === 'country')
          components.country_id = value.short_name;
        if (value2 === 'administrative_area_level_1')
          components.state_code = value.short_name;
      })
    })
    components = {
      ...components, formatted_address: address_components.formatted_address
    }
    return components
  }
}


export function getAddressChunkFromAddress(params) {

  const address_components = params
  let components = {};

  address_components && address_components?.map((value, index) => {
    value.types?.map((value2, index2) => {
      components[value2] = value.long_name;
      if (value2 === 'country')
        components.country_id = value.short_name;
      if (value2 === 'administrative_area_level_1')
        components.state_code = value.short_name;
    })
  })
  components = {
    ...components, formatted_address: address_components.formatted_address
  }

  return components

}
