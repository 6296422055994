import React, { Component } from 'react';
import OurStoryComponent from '../Components/index'
import Layout from '../../Layout/index'
class OurStory extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        document.title = 'Our Story - Biryanis and More'
    }
    render() {
        return (
            <div>
                <Layout >
                    <OurStoryComponent />
                </Layout>
            </div>
        );
    }
}
export default OurStory;
