import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { requestVerifyOtp, requestLogin, requestRegisterConfirm } from '../../../actions';
import './loginModel.css';
import Createpage from './Createpage';
import { Redirect } from 'react-router-dom';
import OtpInput from 'react-otp-input';
const OtpModal = (props) => {
  const { show, setShow, user, homepage, requestVerifyOtp, isLoginPage = true } = props;
  const [otp, setOtp] = useState('');
  const inputRefs = useRef([]);
  const [registershow, setRegisterShow] = useState(false);
  const [createpageshow, setcreatepageshow] = useState(false);
  const resendOtp = () => {
    const body = props.resendOtpDetails;
      requestLogin(body, null, result => { });
  }

  const handleVerifyOtp = () => {
    let requestOptions;
    if (isLoginPage) {
      requestOptions = {
        udid: homepage.deviceDetails.udid,
        uuid: user.userDetails.uuid,
        name: user.userDetails.profile.name,
        email: user.userDetails.profile.email,
        password: user.userDetails.password,
        mobileNumber: user.profile.phoneNumber,
        otp: Number.parseInt(otp),
      };
    } else {
      requestOptions = {
        udid: homepage.deviceDetails.udid,
        uuid: user.registerResponse.uuid,
        fullname: user.registrationDetails.name,
        email: user.registrationDetails.email,
        password: user.registrationDetails.password,
        gender: user.registrationDetails.gender,
        country: user.registrationDetails.country,
        city: user.registrationDetails.city,
        phoneNumber: user.phoneNumber,
        otp: Number.parseInt(otp),
      }
    }

      requestVerifyOtp(requestOptions, result => { });
    const parametersbody = {

      uuid: user.registerResponse.uuid,
      fullname: 'sailaxmi',
      email: 'sailaxmi@gmail.com',
      phoneNumber: user.mobileNumber,
      udid: homepage.deviceDetails.udid,
      otp: Number.parseInt(otp),
      gender: 'male',
    };

      requestRegisterConfirm(parametersbody, result => { });

    setcreatepageshow(true);
  };

  useEffect(() => {
    if (user.otpResponse && user.otpResponse.success) {

      setcreatepageshow(true);
    }
  }, [user.otpVerifyInProgress]);

  const hideModal = (param) => {
    setShow(false);
    setcreatepageshow(true);
  }

  const handleCreateNow = () => {
    setShow(false);
    setRegisterShow(true);
  };

  return (
    <div>
      <Modal show={show} onHide={() => { setShow(false); }} animation={false}>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="modalHeading">This is Verify OTP Page</div>
          <p className="pera">Please verify your account to continue with your order</p>
          <div className="contactNumber">
            <img src="assets/phone.svg" alt="Mobile" />
            <button onClick={handleCreateNow}>Edit</button>
          </div>
          <div className="otp">
            <OtpInput
                          inputStyle={{ padding: 0 }}
              value={otp}
              onChange={setOtp}
              numInputs={6}
                          isInputNum={true}
              separator={<span>-</span>}
            />
          </div>
          <div onClick={resendOtp} className="resend" style={{ cursor: 'pointer' }}>
            <div>Resend OTP code</div>
            <img src="assets/resend.svg" alt="Mobile" />
          </div>
          {!user.otpVerifyInProgress && user.otpResponse && !user.otpResponse.success && <p className='my-2 text-danger h6'>{user.otpResponse.message}</p>}
              <button onClick={handleVerifyOtp} className="modalRegBtn">Verify OTP
            {user.otpVerifyInProgress ? <div className='spinner-border text-primary' /> : <img src="assets/rightArrowLight.svg" alt="Arrow" />}
          </button>
        </Modal.Body>
      </Modal>
      { user.otpResponse.success ? <Createpage /> : null}

    </div>
  )
};

const mapStateToProps = state => ({
  user: state.user,
  homepage: state.homepage
});

export default connect(mapStateToProps, { requestVerifyOtp, requestLogin })(OtpModal);