import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import packageInfo from '../package.json';

const isProduction = true;
try {
   if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      isProduction = false;
   }
} catch (e) {
}

if (isProduction) {
   Sentry.init({
      dsn: "https://cb11cde0a6034d44977fc4502d7dc8ab@o554821.ingest.sentry.io/5855938",
      release: 'biryanis-website@' + packageInfo.version,
      integrations: [
         new Integrations.BrowserTracing(),
        //   new Sentry.Integrations.Breadcrumbs({ console: false })
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
   });
}


import './styles/styles.scss'

import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import configureStore from './store'
import App from './App'
import 'bootstrap/dist/js/bootstrap.js';

const history = createBrowserHistory()
const { store, persistor } = configureStore()

const render = Component => {
   ReactDOM.render(
      <Provider store={store}>
         <PersistGate loading={null} persistor={persistor} >
            <BrowserRouter history={history}>
               <Component />
            </BrowserRouter>
         </PersistGate>
      </Provider>,
      document.getElementById('root'),
   )
}

render(App)

if (module.hot) {
   module.hot.accept(
      './App',
      () => {
         // eslint-disable-next-line
         const App = require('./App').default
         render(App)
      },
   )
}
