import 'font-awesome/css/font-awesome.min.css';
import React, { PureComponent } from 'react';
import LazyLoad from 'react-lazyload';
import AddCartButton from '../Common/addCartButton/AddCartButtton';
import { ExploreMenuCardLoader } from '../Common/contentLoaders/ContentLoader';
import VegIcon from '../Common/vegnonvegIcons/Veg-nonvegIcons';
import './menu.css';


class ExplorerMenuCard extends PureComponent {
  state = {
    loaded: false,
    showAllDescription: false,
    charactersLimit: 120
  }

  //image onLoad handler to update state to loaded
  onLoad = () => {
    this.setState(() => ({ loaded: true }));
  };

  handlePara = (param) => {
    if (param.length > this.state.charactersLimit && this.state.showAllDescription === false) {
      return param.slice(0, this.state.charactersLimit)
    }
    else {
      return param
    }
  }

  render() {
    const { item, addToCart, cartItem, data, current, orderMode } = this.props;
    const addedItem = cartItem || {};
    let { className, loadedClassName, loadingClassName, } = this.props;

    className = `${className} ${this.state.loaded
      ? loadedClassName
      : loadingClassName}`;
    return (
      <div className="explorecardmasonry">
        <div className="exploreItem">
          <div className="imgBox">

            {
              item.image !== null ?
                <LazyLoad height={260} >
                  <img className={className} src={item.image} alt={ExploreMenuCardLoader} onLoad={this.onLoad} />
                </LazyLoad>
                : null}
          </div>
          <div className="exploreContent">
            <div className="itemDetails-exp">
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', maxWidth: 260, height: 'fit-content' }}>
                <div style={{}}>
                  <VegIcon width={12} height={12} color={item.type === 'nonveg' ? '#C60000' : 'green'} />
                </div>
                <text style={{ marginLeft: 10, marginRight: 8 }} className="name">{item.name}</text>
              </div>

              {
                item.enabled ?
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 0 }}>
                    {(orderMode !== 'menu') &&
                      <AddCartButton
                        count={addedItem.length || 0}
                        className=""
                        {...this.props}
                      />
                    }
                    {((item.addons.length > 0) && (orderMode !== 'menu')) ?
                      <text className="descriptoin">Customisable</text>
                      : null}
                  </div>
                  :
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 'auto', marginRight: 0 }}>
                    <div style={{
                      width: 96,
                      height: 30,
                      margin: 0,
                      // fontWeight: 600,
                      // backgroundColor: '#E5AB4E',
                      borderRadius: 6,
                      borderColor: 'Grey',
                      textAlign: 'center',
                      fontSize: 'medium',
                      color: '#788495',
                      border: '1px solid #00000021',
                      padding: 2,
                    }}><text>Unavailable</text></div>
                  </div>
              }

            </div>
            {item.description &&
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10, marginBottom: 10 }}>
                {window.innerWidth < 768
                  ?
                  <div style={{ fontFamily: 'Lato', color: '#000000', fontSize: 14 }}>
                    <text> {this.handlePara(item.description)}</text>
                    {item.description.length > this.state.charactersLimit &&
                      (this.state.showAllDescription === false ?
                        <text onClick={() => this.setState({
                          showAllDescription: !this.state.showAllDescription
                        })} >
                          ...

                          <text style={{ fontWeight: 'bold', color: '#227FBB' }}> more
                          </text>
                        </text>
                        :
                        <text
                          style={{ fontWeight: 'bold', color: '#227FBB' }}
                          onClick={() => this.setState({
                            showAllDescription: !this.state.showAllDescription
                          })} >
                          {"\t"}less
                        </text>)
                    }
                  </div>
                  :
                  <div style={{ fontFamily: 'Lato', color: '#000000', fontSize: 14 }}>
                    <text> {item.description}</text>
                  </div>
                }
              </div>
            }
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <text className="currency">{this.props.currencySymbol}</text>
              <h4 className="price"> {item.rate}</h4>
            </div>

          </div>
        </div>
      </div >
    )
  }
}

export default ExplorerMenuCard