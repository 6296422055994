
import React, { useEffect, useState, } from 'react'
import { useSelector, useDispatch } from 'react-redux';

// import LoyaltySignup from './loyaltySignup/LoyaltySignup'
import './Myloyaltypage.css';
import { initializeRegistrationForm, RegistrationForm } from '@froogal.ai/loyalty-sdk-js';
import config from '../../config'
import loyaltyBanner from './loyaltybanner.png'
import loyaltybannermobile from './loyaltybannermobile.png'

import biryanisicon from './biryanisicon.png'
import { CheckCircle } from '../../images/icons/IconSets';
import loyaltyPoint from './loyaltyPoint.png'
import { getBaseFreebies } from '../../reducers/loyaltystatic';

export default function MyloyaltyPage(props) {
  const dispatch = useDispatch()
  const reduxUser = useSelector(state => state.user)

  const { referAndEarn, loyatltyTiers, sectionOne, welcomeLoyalty,
    signUpOffer, sectionOffer, sectionTwo, loyaltyIds } = props;

  let primaryLoyaltyId = props.user.userDetails?.profile?.primaryLoyaltyId //gets if loggedin
  let loyalty = loyaltyIds?.find(item => parseInt(item.loyaltyId) === primaryLoyaltyId)

  let selectedLoyalty = loyalty
    ? loyalty
    :
    loyaltyIds
      ?
      loyaltyIds.find(a => a.countryCode === reduxUser.userAddressChunks?.country_id)
      :
      null

  console.log({ selectedLoyalty })

  useEffect(() => {
    initializeRegistrationForm(document.getElementById('loyalty-signup-root'), {
      authToken: props.user.otpResponse?.authToken,
      merchantAccessToken: config.merchantAccessToken,
      loyaltyId: primaryLoyaltyId ? primaryLoyaltyId : loyaltyIds[0].loyaltyId,
      termsUrl: `${window.location.origin}/loyaltytermsandcondition`,
      tabStyle: true,
      imageBaseUrl: 'https://assets-cdn.froogal.ai/loyalty-sdk/v1.0.34/images/',
      isMultiCountryEnable: true,
      defaultCountry: reduxUser.userAddressChunks?.country_id,
      loyaltyUrl: `${window.location.origin}/myDetails?tab=rewards`
    })
    dispatch(getBaseFreebies());

  }, [reduxUser.userAddressChunks?.country_id])

  console.log('@@@@@ss', { primaryLoyaltyId, loyaltyIds, selectedLoyalty })

  let displaySectionOne = sectionOne?.find(item => item.countryCode === selectedLoyalty?.countryCode)
    ||
    sectionOne?.find(item => !item.countryCode)

  console.log({ sectionOne })
  let displaySectionOffer = sectionOffer?.find(item => item.countryCode === selectedLoyalty?.countryCode)
    ||
    sectionOffer?.find(item => !item.countryCode)

  let displayWelcomeLoyalty = welcomeLoyalty?.find(item => item.countryCode === selectedLoyalty?.countryCode)
    ||
    welcomeLoyalty?.find(item => !item.countryCode)

  let displaySignUpOffer = signUpOffer?.find(item => item.countryCode === selectedLoyalty?.countryCode)
    ||
    signUpOffer?.find(item => !item.countryCode)

  let displayReferAndEarn = referAndEarn?.find(item => item.countryCode === selectedLoyalty?.countryCode)
    ||
    referAndEarn?.find(item => !item.countryCode)

  let displayLoyaltyTiers = loyatltyTiers?.filter(item => item.countryCode === selectedLoyalty?.countryCode)
    ||
    loyatltyTiers?.filter(item => item.countryCode)

  console.log({ displayLoyaltyTiers })

  return (
    <div style={{
      display: 'flex', flexDirection: 'column',
      marginTop: displaySectionOne ? 70 : 150, backgroundColor: '#FAFBFC'
    }}>
      {displaySectionOne ?
        <div className="columns"
          style={{
            position: 'relative',
            backgroundImage: `url(${window.innerWidth > 412 ? loyaltyBanner : loyaltybannermobile})`,
            // backgroundImage: `url("${displaySectionOne.background}")`,

            backgroundColor: `#${displaySectionOne.bgColor}`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '100%', height: 570
          }}>
          <div style={{
            position: 'absolute',
            bottom: 0,
            width: '100%', height: 560,
            background: 'linear-gradient(to bottom, transparent 5%, rgba(69,32,5, 0.95) 70%)',
            zIndex: 1
          }} />

          <div style={{ position: 'absolute', bottom: 50, right: 0, left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'center', zIndex: 2 }}>
            <div className="column is-12-mobile is-8-desktop sectiontext" dangerouslySetInnerHTML={{ __html: displaySectionOne.content }}>
            </div>
            {/* <button className="fr-loyalty-btn button"
            // onClick={() => props.openSignUpModal()}
            >
              Signup Now
            </button> */}
          </div>
        </div>
        : null}

      <div className="is-centered fr-second-block">
        {displaySectionOffer ?
          <div className="fr-absolute-block fr-flex-row-center">
            <figure>
              <img className="image fr-star-image" src={displaySectionOffer.imageOne} alt="freebies" />
            </figure>
            <span className="is-size-5 is-size-6-mobile" style={{ textAlign: "center" }}>{displaySectionOffer.text}</span>
            <figure>
              <img className="image fr-star-image" src={displaySectionOffer.imageTwo} alt="freebies" />
            </figure>
          </div>
          :
          null}

        {/* 3 CIRCLE STICKERS */}
        <div className="columns fr-second-inner-block is-centered">
          {sectionTwo?.map((item, index) =>
            <div className="column is-3 fr-flex-column-center" key={{ index }}>
              <figure className="fr-m-b-25">
                <img className="image fr-80x80" src={item.image} alt="freebies" />
              </figure>
              <span className="is-size-5 is-size-6-mobile"
                style={{ color: `#${item.color}` }}>
                {item.title}
              </span>
            </div>
          )
          }
        </div>
      </div>

      {displayWelcomeLoyalty ?
        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 30
        }}>
          <text style={{ color: '#553A28', fontSize: 20 }}>WELCOME TO THE</text>

          <text style={{ color: '#553A28', fontSize: 35, textAlign: 'center', font: 'normal normal 600 35px/34px Lato;' }}>{displayWelcomeLoyalty.title || ''}</text>
          <text style={{ color: '#2C2C2C', fontSize: 35, font: 'normal normal normal 20px/28px Lato', maxWidth: 602, textAlign: 'center', marginTop: 20 }}>{displayWelcomeLoyalty.description || ''}</text>
        </div>
        : null}

      {/* {props.sectionThree ?
                <div className="columns fr-third-block is-centered">
                    <div className="column is-12-mobile is-2-desktop fr-vip-image">
                        <img className="image fr-140x140" src={require('../images/vip.png')} alt="freebies" />
                    </div>
                    <div className="column is-12-mobile is-6-desktop fr-thick-vip">

                        <div dangerouslySetInnerHTML={{ __html: props.sectionThree.content }}></div>
                    </div>
                </div>
                : null} */}


      {/* LOYALTY SIGNUP */}

      <div className='signup-loyalty-grid'>

        {/* <LoyaltySignup /> */}
        <div id="loyalty-signup-root"></div>
        {/* <RegistrationForm
          merchantAccessToken={config.merchantAccessToken}
          loyaltyId={props.user.userDetails?.profile.primaryLoyaltyId}
          defaultCountry='IN'
        /> */}
        {displaySignUpOffer ?
          <div className='signup-loyalty-card-two'>
            <text style={{ color: '#553A28', font: 'normal normal normal 20px/34px Lato', textTransform: 'uppercase' }}>{displaySignUpOffer && displaySignUpOffer.text}</text>
            <text style={{ color: '#553A28', fontSize: 35, font: 'normal normal 600 35px/40px Lato' }}>{displaySignUpOffer && displaySignUpOffer.title}</text>
            <text style={{ color: '#2C2C2C', fontSize: 35, font: 'normal normal normal 14px/20px Lato', maxWidth: 602, marginTop: 20 }}>{displaySignUpOffer && displaySignUpOffer.description}</text>
          </div>
          : null}


      </div>

      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 54, padding: 40, backgroundColor: '#FFFFFF' }}>
        <text style={{
          color: '#2C2C2C', fontSize: 35, font: 'normal normal 600 35px/34px Lato;',
          padding: '34px 0px'
        }}>Tiers & Benefits of each programme</text>
        {displayLoyaltyTiers && displayLoyaltyTiers.length > 0 ?
          <div className='tier-grid' >
            {/* basic tier */}
            {displayLoyaltyTiers && displayLoyaltyTiers.map((i, index) => {
              return <div className='tier-card' style={{
                background: `transparent linear-gradient(148deg, ${i.color} 0%, ${i.color} 100%) 0% 0% no-repeat padding-box`
              }}>
                <div style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 15 }}>
                  <img src={biryanisicon} style={{ width: 65 }} />
                  <text style={{ padding: '20px 0px 10px 10px', font: 'normal normal bold 25px/30px Lato' }}>{i.title}</text>

                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ display: 'flex', margin: 25 }}>
                    <div className="wrapper">
                      <div className="arc arc_start"
                        style={{ borderColor: '#9B8D72 #DBAB49 #DBAB49 #DBAB49' }} />
                      <img style={{ width: 27, objectFit: 'contain', }} src={i.image} alt="" />
                    </div>
                  </div>

                  <text style={{ font: 'normal normal 600 20px/24px Lato' }}>{i.subTitle}</text>
                </div>

                <div style={{ margin: 15, alignSelf: 'center' }}>
                  <div style={{ width: 350, height: 2, backgroundColor: '#967D4D', margin: 5 }} />
                </div>
                <div style={{ padding: 30 }}>
                  <div className="tier-list-div" dangerouslySetInnerHTML={{ __html: i.description }}></div>
                </div>
              </div>


            })}
          </div>
          : null}
      </div>

      {
        !!displayReferAndEarn
        &&

        <>
          <div style={{
            display: 'flex', flexDirection: 'column', alignItems: 'center',
            margin: 20
          }}>
            <text style={{ color: '#553A28', fontSize: 35, font: 'normal normal 600 35px/34px Lato;' }}>Refer & Earn</text>
            <text style={{ color: '#2C2C2C', fontSize: 35, font: 'normal normal normal 20px/28px Lato', maxWidth: 602, textAlign: 'center', marginTop: 20 }}>{displayReferAndEarn && displayReferAndEarn.description ? displayReferAndEarn.description : ''}</text>
          </div>

          <div className='refer-earn'>
            <div className='refer-earn-card' >
              <text style={{ font: 'normal normal 600 25px/30px Lato', color: '#553A28' }}>What You Get</text>
              <div style={{ margin: '15px 0px 15px' }}>
                <div style={{ width: 300, height: 2, backgroundColor: '#967D4D', margin: 5 }} />
              </div>

              <div dangerouslySetInnerHTML={{ __html: displayReferAndEarn && displayReferAndEarn.youGetDescription ? displayReferAndEarn.youGetDescription : '' }}></div>
            </div>

            <div className='refer-earn-card' >
              <text style={{ font: 'normal normal 600 25px/30px Lato', color: '#553A28' }}>What Your Friend Gets</text>
              <div style={{ margin: '15px 0px 15px' }}>
                <div style={{ width: 300, height: 2, backgroundColor: '#967D4D', margin: 5 }} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: displayReferAndEarn && displayReferAndEarn.theyGetDescription ? displayReferAndEarn.theyGetDescription : '' }}></div>

            </div>
          </div>
        </>
      }

      <div className='rewards-boutique' style={{ margin: 30, }}>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', padding: 15 }}>
          <text style={{ color: '#553A28', font: 'normal normal normal 20px/34px Lato', textTransform: 'uppercase' }}>DELIGHTFUL STUPENDOUS AND EXTRAORDINARY REWARDS COLLECTION</text>
          <text style={{ color: '#553A28', fontSize: 35, font: 'normal normal 600 35px/63px Lato' }}>Biryanis Rewards Boutique</text>
          <text style={{ color: '#2C2C2C', fontSize: 35, font: 'normal normal normal 14px/20px Lato', maxWidth: 602, marginTop: 20 }}>We’ve curated our boutique with just about everything you need to make your experience at Biryanis, unforgettable. Get exclusive access to accessories, Our products and brand experiences that you cannot find anywhere else. What are you waiting for?</text>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: '45%', padding: 30 }}>

          <div style={{ width: '100%' }}>
            {props.baseFreebies && props.baseFreebies.length > 0 ?
              props.baseFreebies.slice(0, 3).map((i, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
                      width: 370, minHeight: 100,
                      boxShadow: ' 0 2px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05)',
                      backgroundColor: ' #fff',
                      borderRadius: 6,
                      opacity: 1,
                      marginBottom: 29, position: 'relative',
                      float: index == 1 ? 'right' : 'left'
                    }}>
                    <img
                      style={{ width: 110, minHeight: 100, objectFit: 'cover', borderRadius: 6 }}
                      src={i.image}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', width: '100%' }}>
                      <text style={{ color: '#553A28', font: 'normal normal 600 13px/17px Lato' }} >
                        {i.name}
                      </text>
                      <text style={{ marginTop: 6, color: '#2C2C2C', font: 'normal normal normal 10px/14px Lato' }} >
                        {i.description}
                      </text>
                    </div>
                    <span style={{ position: 'absolute', right: 10, bottom: 5 }} >
                      <img src={loyaltyPoint} style={{ width: 15 }} />
                      {Math.trunc(i.worth)}
                    </span>
                  </div>
                )
              })
              : null}
          </div>
        </div>
      </div>
    </div >
  )
}