import * as Facebook from "fb-sdk-wrapper"
import React, { Component } from "react"
import Slider from "react-slick"
import AddCartButton from "../Common/addCartButton/AddCartButtton"
import VegIcon from "../Common/vegnonvegIcons/Veg-nonvegIcons"
import { extractTags } from "../Home/Components/utility"
import "./hotFavroitSlider.css"
class HotFav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hotfav: null,
            allCatalogues: [],
        }
    }
    componentDidMount() {
        Facebook.load().then(() => {
            Facebook.init({
                appId: "567248643641114",
            })
        })
    }
    componentDidUpdate() {
        if (this.state.allCatalogues !== this.props.allCatalogues) {
            if (this.props.allCatalogues?.categories.length > 0) {
                let cat = this.props.allCatalogues?.categories
                let fullList = []
                for (let i = 0; i < cat?.length; i++) {
                    for (let j = 0; j < cat[i].items.length; j++) {
                        let item = cat[i].items[j]
                        item = { ...item, searchableText: item.name.toLowerCase() }
                        fullList.push(item)
                    }
                    for (let k = 0; k < cat[i].categories.length; k++) {
                        for (let l = 0; l < cat[i].categories[k].items.length; l++) {
                            let subItem = cat[i].categories[k].items[l]
                            subItem = { ...subItem, searchableText: subItem.name.toLowerCase() }
                            fullList.push(subItem)
                        }
                    }
                }
                // const datanew = fullList;
                const hotfavourites =
                    fullList &&
                    fullList?.filter(
                        (item) => item.tags?.length > 0 && item.tags[0] === "favourite"
                    )
                this.setState({ hotfav: hotfavourites, allCatalogues: this.props.allCatalogues })
            }
        }
    }

    getSlides = (data) => {
        return
    }

    render() {
        const orderMode = this.props.orders
        const hotFavFilterArray = this.state.hotfav?.filter((item) => item.type === "veg")
        const hotFavArray = this.props.isVeg ? hotFavFilterArray : this.state.hotfav

        return this.state.hotfav && this.state.hotfav.length > 0 ? (
            <div className=" col-12 hotFavourites">
                <h2
                    style={{
                        height: "22px",
                        textAlign: "left",
                        font: "normal normal bold 18px/22px Lato",
                        letterSpacing: "2.7px",
                        color: "#553A28",
                        textTransform: "uppercase",
                        opacity: "1",
                    }}
                >
                    Hot favourites
                </h2>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowX: "scroll",
                        overflowY: "visible",
                    }}
                >
                    {hotFavArray &&
                        hotFavArray.map((num, index) => (
                            <div key={index} className="imgpad2">
                                <img src={num.image} width="100%" height="281px" alt="" />

                                <div className="itemDetails" style={{ position: "relative" }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "flex-start",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <VegIcon
                                            width={12}
                                            height={12}
                                            color={num.type === "nonveg" ? "#C60000" : "green"}
                                        />
                                        <text className="favname">{num.name}</text>
                                        {orderMode.orderMode !== "menu" ? (
                                            <div style={{ position: "absolute", right: 0 }}>
                                                <AddCartButton item={num} />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <h4 className="price" style={{ marginLeft: 18 }}>
                                    {" "}
                                    <sup className="currency">
                                        {this.props.allCatalogues?.currency}
                                    </sup>
                                    {num.rate}
                                </h4>

                                {/* <h5 className="favprice">{this.props.allCatalogues?.currency}{num.rate}</h5> */}
                            </div>
                        ))}
                </div>
            </div>
        ) : null
    }
}

export default HotFav
