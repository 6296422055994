import { ResponsiveEmbed } from 'react-bootstrap';
import config from '../config';

export const getUserService = {
  login,
  register,
  verifyOtp,
  validateOtp,
  registerConfirm,
  logoutConfirm,
  getExactAddressByReverseGeocoding,
};

function getRequestOptions() {
  return {
    method: 'POST',
    headers: {
      'Accept': 'application/json'
    }
  };
}
function getRequestOptionsfor() {
  return {
    method: 'GET',
    headers: {
      'Accept': 'application/json'
    }
  };
}

/* LOGIN STEP 1 */
async function login(params = {}) {
  const requestOptionsFor = getRequestOptionsfor();
  const response = await fetch(`${config.apiUrl}/auth/login/otp/request?merchantAccessToken=${config.merchantAccessToken}&mobileNumber=${params.mobileNumber}&countryCode=${params.countryCode}&referralCode=${params.referralCode}`, requestOptionsFor)
  if (response.status !== 200) {
    return Promise.reject('Unable to login');
  } else {
    return response.json();
  }
}

/* LOGIN STEP 2 */
async function validateOtp(params = {}) {
  const requestOptionsFor = getRequestOptionsfor();
  const response = await fetch(`${config.apiUrl}/auth/login/otp/validate?merchantAccessToken=${config.merchantAccessToken}&mobileNumber=${params.mobileNumber}&otp=${params.otp}&udid=${params.udid}&countryCode=${params.countryCode}&referralCode=${params.referralCode}`, requestOptionsFor);
  if (response.status !== 200) {
    Promise.reject('Unable to verfiy otp');
  }
  return response.json();
}

/* REGISTRATION STEP 1 */
async function register(params = {}) {
  const requestOptions = getRequestOptions();
  const response = await fetch(`${config.apiUrl}/register/phone?phoneNumber=${params.phoneNumber}&udid=${params.udid}&countryCode=${params.countryCode}&merchantAccessToken=${config.merchantAccessToken}&referralCode=${params.referralCode}`, requestOptions);
  if (response.status !== 201) {
    Promise.reject('Unable to regiseter');
  }
  return response.json();
}

/* REGISTRATION STEP 2 */
async function verifyOtp(params = {}) {
  const requestOptions = getRequestOptions();
  const response = await fetch(`${config.apiUrl}/auth/verify-otp?udid=${params.udid}&otp=${params.otp}&uuid=${params.uuid}&merchantAccessToken=${config.merchantAccessToken}&countryCode=${params.countryCode}&phoneNumber=${params.phoneNumber}&referralCode=${params.referralCode}`, requestOptions);
  if (response.status !== 200) {
    Promise.reject('Unable to verfiy otp');
  }
  return response.json();
}

/* REGISTRATION STEP 3 */
async function registerConfirm(params = {}) {
  const requestOptions = getRequestOptions();
  const response = await fetch(`${config.apiUrl}/register/confirm?&name=${params.fullname}&phoneNumber=${params.phoneNumber}&email=${params.email}&udid=${params.udid}&password=${'000'}&otp=${params.otp}&uuid=${params.uuid}&merchantAccessToken=${config.merchantAccessToken}&referralCode=${params.referralCode}&fbAccessToken=${params.fbAccessToken}&gmailAccessToken=${params.gmailAccessToken}`, requestOptions);
  if (response.status !== 200) {
    Promise.reject('Unable to verfiy otp');
  }
  return response.json();
}

/* LOGOUT */
async function logoutConfirm(params = {}) {

    const requestOptions = getRequestOptions();
  const response = await fetch(`${config.apiUrl}/user/logout?authToken=${params.authToken}&merchantAccessToken=${config.merchantAccessToken}`, requestOptions);
  if (response.status !== 200) {
    Promise.reject('Unable to verfiy authToken');
  }
  return response.json();
}

async function getExactAddressByReverseGeocoding(params = {}) {
  const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${params.latitude},${params.longitude}&key=${config.googleMapApiKey}`);
  if (response.status !== 200) {
    return Promise.reject('Unable to find your location');
  }
  return response.json();
}