import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getMerchantIdfromSlugAction } from "../../reducers/merchant";
import { setInitialOrderMode, setOrderMode } from '../../reducers/orders';
import { AlertModal } from '../Common/alertModal/AlertModal';
// import Banner from '../Home/Components/banner';
import Banner from "../Common/bottomBannner/Banner";
import Layout from "../Layout";
import ExploreMenu from './ExploreMenu';
import FeaturedItemsCategorywise from './FeaturedItemsCategorywise';
import HotFav from './HotFavroitSlider';
import './menu.css';
import OrdermodeButtons from "./OrdermodeButtons";
import SearchSection from './SearchSection';
import ViewCartFloater from './ViewCartFloater';

// import TopCategories from '../Home/Components/TopCategories';

function Home(props) {
  let location = !!props.user.userLocation?.latitude ?
    props.user.userLocation :
    props.user.userDetails?.locationPreference
  let { merchantslug, ordermode } = useParams();
  const dispatch = useDispatch()
  const [current, setCurrent] = useState(0)
  const [locationshow, setLocationshow] = useState(false)
  const [alertShow, setalertShow] = useState(false)

  const ordersRedux = useSelector(state => state.orders)

  const { nearestMerchantDetails, currentAvailableModes } = props.homepage || {}
  let allModes = currentAvailableModes


  const orderTypes = [
    { id: 0, orderType: 'delivery' },
    { id: 1, orderType: 'takeAway' },
    { id: 2, orderType: 'dineIn' },
    { id: 3, orderType: 'menu' },
  ]

  useEffect(() => {
    window.scrollTo(0, 0)
    let obj = orderTypes.find(item => item.orderType === ordermode)
    setCurrent(obj?.id)
    if (!!ordermode && (ordermode !== ordersRedux.initialOrderMode)) {
      dispatch(setOrderMode(ordermode))
      if (obj?.id !== 3) {
        dispatch(setInitialOrderMode(ordermode))
      }
    }
  }, [])

  useEffect(() => {
    if (!!merchantslug && props.homepage.nearestMerchantDetails === null && !!location) {
      let params = { merchantSlug: merchantslug }
      dispatch(getMerchantIdfromSlugAction(params))
    }
  }, [merchantslug, location])

  useEffect(() => {
    if (props.homepage.currentAvailableModes?.length > 0
      &&
      props.homepage.currentAvailableModes[0]?.id === props.merchant.merchant?.id
    ) {
      let objOrderType = orderTypes.find(item => item.orderType === ordermode)

      let objCurrentAvailableMode = props.homepage.currentAvailableModes?.find(item =>
        item.orderMode === ordermode)

      if (objCurrentAvailableMode?.serviceable === true) {
        setCurrent(objOrderType?.id)
      }
      else {
        props.history.replace({ pathname: `/order/${merchantslug}/menu` })
        dispatch(setOrderMode('menu'))
        setCurrent(3)
      }
    }
  }, [props.homepage.currentAvailableModes, props.merchant.merchant?.id])

  const getCartCount = () => {
    let cartItems = props.cartItems
    const total = cartItems && Object.values(cartItems).reduce((prev, { quantity }) => prev + 1 * quantity, 0)
    return total;
  }

  const selectedOrderType = (index) => {
    dispatch(setOrderMode(orderTypes[index].orderType))
    if (index !== 3) {
      dispatch(setInitialOrderMode(orderTypes[index].orderType))
    }
    props.history.replace({ pathname: `/order/${merchantslug}/${orderTypes[index].orderType}` })
  }

  const handleSelectOrderMode = (param) => {
    if (allModes[param].serviceable === true) {
      selectedOrderType(param)
      setCurrent(param)
    }
  }

  return (
    <Layout>
      {alertShow && <AlertModal show={alertShow} setShow={setalertShow} />}
      <OrdermodeButtons
        current={current}
        allModes={allModes}
        handleSelectOrderMode={handleSelectOrderMode}
      />

      <SearchSection
        isVeg={props.isVeg}
        homepage={props.homepage}
        allCatalogues={props.allCatalogues}
      />
      {/* <TopCategories {...props} /> */}

      <HotFav
        isVeg={props.isVeg}
        orders={props.orders}
        allCatalogues={props.allCatalogues} />

      <FeaturedItemsCategorywise
        orderMode={props.orders?.orderMode}
        allCatalogues={props.allCatalogues}
        isVeg={props.isVeg} />

      <ExploreMenu
        orders={props.orders}
        isVeg={props.isVeg}
        cartItems={props.cartItems}
      />

      <Banner />

      {
        (props.orders?.orderMode !== 'menu' && getCartCount() > 0)
          ?
          <ViewCartFloater
            count={getCartCount()}
            history={props.history}
          />
          : null
      }
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  homepage: state.homepage,
  user: state.user,
  isVeg: state.user.isVeg,
  cart: state.cart,
  cartItems: state.cart.items,
  orders: state.orders,
  globalBanners: state.user.globalBanners,
  allCatalogues: state.homepage.allCatalogues,
  merchant: state.merchant
});

const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Home);