import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LazyLoad from 'react-lazyload';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { globalBanners } from '../../../reducers/user';
import './GlobalBanners.css';
import './slickdemo.css';
import './slider.css';
function GlobalBanners(props) {
  const dispatch = useDispatch()
  const reduxstate = useSelector(state => state.user.globalbanners)
  const reduxUser = useSelector(state => state.user)
  const countrycodebanner = reduxstate.filter(item => item.countryCode === reduxUser.userAddressChunks?.country_id)
  const banners = countrycodebanner.length > 0 ? countrycodebanner : reduxstate

  useEffect(() => {
    dispatch(globalBanners())
  }, [])

  const styban = {
    borderRadius: '6px',
    opacity: '1'
  }
  const stag = {
    background: '#C4703E',
    borderRadius: '4px',
    opacity: '1',
    width: '125px'
  }
  const styHeading = {
    textAlign: 'left',
    font: 'normal normal  30px/36px Lato',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: '1'
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    variableWidth: true,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="section-fr section" >
      <Slider {...settings}>
        {
          banners.map((value, i) => (
            <div key={i} className="slides">
              <LazyLoad offset={100}>
                {window.innerWidth > 768
                  ?
                  <img className="globalimgone" src={value.image} style={styban} />
                  :
                  <img className="globalimgone" src={value.mobileImage} style={styban} />
                }
              </LazyLoad>
              <div className="slideContent">
                <div className="textstyle">
                  <div style={stag}><p className="stytext">{value.tag}</p></div>
                  <div className="styHeading-fr">{value.title}</div>
                  <p className="bigsavingstext-fr">{value.text}</p>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div >
  );
}

export default GlobalBanners 