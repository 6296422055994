import React from "react";

export const BoxTitle = ({ title, children }) => (
	<div className="">
		<h1 className="card-header-title has-text-weight-medium has-text-theme" style={{ fontSize: 25 }}>{title}</h1>
		{/* <hr style={{margin: '0 1em'}} /> */}
		{children || ''}
	</div>
)

export const BoxBody = ({ children }) => (
	<div className="card-content">
		<div className="content">
			{children}
		</div>
	</div>
)

export const Box = ({ children } = {}) => (
	<div className="card mt-5">
		{children}
	</div>
);
