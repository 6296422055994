import config from '../config';
import { getRequestOptions } from './homepage.service';

export const userOrderServices = {
  getOrderUserAddress,
  getOrderTimeSlots
};

const getAuthToken = () => localStorage.getItem('auth_token');

async function getOrderUserAddress() {
  const requestOptions = getRequestOptions();
  const authToken = getAuthToken();

  const response = await fetch(`${config.apiUrl}/order/user/address?authToken=${authToken}&merchantAccessToken=${config.merchantAccessToken}`, requestOptions);
  // console.log("auth  token", response);
  if (response.status !== 200) {
    Promise.reject('Unable to fetch data');
  }
  return response.json();
}

async function getOrderTimeSlots(id='') {
  const requestOptions = getRequestOptions();
  const authToken = getAuthToken();

  const response = await fetch(`${config.apiUrl}/merchants/time-slots?authToken=${authToken}&merchantAccessToken=${config.merchantAccessToken}&merchantId=${id}`, requestOptions);
  // console.log("time slots", response);
  if (response.status !== 200) {
    Promise.reject('Unable to fetch data');
  }
  return response.json();
}
