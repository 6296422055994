import React, { Component } from 'react';
import AboutComponent from '../Components/index'
import Layout from '../../Layout/index'
class LoyaltyTermsandConditions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
    document.title = 'Loyalty Program T&C - Biryanis and More'
  }

  render() {
    return (
      <div>
        <Layout >
          <AboutComponent />
        </Layout>
      </div>
    );
  }
}
export default LoyaltyTermsandConditions;
