import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LocationComponent from '../Component/index';
import Layout from '../../Layout/index'
import { setNearestMerchantDetails } from '../../../actions';
class index extends Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    componentWillReceiveProps(nextProps) {

    }

    componentWillUpdate(nextProps, nextState) {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div>
                <LocationComponent />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    user: state.user,
    homepageDetails: state.homepage,
});
index.propTypes = {

};

export default connect(mapStateToProps, { setNearestMerchantDetails, })(index);
