export const SET_DEVICE_DATA = 'SET_DEVICE_DATA';
export const SET_MERCHANT_DATA = 'SET_MERCHANT_DATA';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_NEAREST_MERCHANT = 'SET_NEAREST_MERCHANT';
export const SET_ALL_CATALOGUES = 'SET_ALL_CATALOGUES';
export const SET_OTP = 'SET_OTP';
export const SET_PHONE = 'SET_PHONE';
export const SET_VEG = 'SET_VEG';

export const SET_OTP_RESPONSE_RESET = 'SET_OTP_RESPONSE_RESET';
export const SET_REGISTER_RESPONSE_RESET = 'SET_REGISTER_RESPONSE_RESET';
export const SET_LOGIN_DETAILS_RESET = 'SET_LOGIN_DETAILS_RESET';
export const SET_CUSTOMER_DATA = 'SET_CUSTOMER_DATA';
export const SET_LOGIN_PASSWORD = 'SET_LOGIN_PASSWORD';
export const ADD_ITEM_TO_CART = 'ADD_ITEM_TO_CART';
export const REMOVE_ITEM_FROM_CART = 'REMOVE_ITEM_FROM_CART';

export const INCREASE_ITEM = 'INCREASE_ITEM';
export const DECREASE_ITEM = 'DECREASE_ITEM';
export const RESET_USER_DETAILS = 'RESET_USER_DETAILS';

export const SET_SELECTED_MERCHANT = 'SET_SELECTED_MERCHANT';
export const SET_SELECTED_MERCHANT_SERVICEABILITY = 'SET_SELECTED_MERCHANT_SERVICEABILITY';
export const SET_CURRENT_AVAILABLE_MODES = 'SET_CURRENT_AVAILABLE_MODES';

export const SET_SIGN_OUT_RESET = 'SET_SIGN_OUT_RESET';
export const TEMPORARY_INPUT_FIELDS = 'TEMPORARY_INPUT_FIELDS';
const CLEAR_CART_ALERT = "cart/CLEAR_CART_ALERT"


export const setDeviceDetails = payload => ({ type: SET_DEVICE_DATA, payload });
export const setMerchantDetails = payload => ({ type: SET_MERCHANT_DATA, payload });
export const setUserLocation = payload => ({ type: SET_LOCATION, payload });
export const setAllCatalogues = payload => ({ type: SET_ALL_CATALOGUES, payload });
export const setOtp = payload => ({ type: SET_OTP, payload });
export const setPhone = payload => ({ type: SET_PHONE, payload });
export const setNearestMerchantDetails = payload => ({ type: SET_NEAREST_MERCHANT, payload });
export const setOtpResponseReset = () => ({ type: SET_OTP_RESPONSE_RESET });
export const setRegisterResponseReset = () => ({ type: SET_REGISTER_RESPONSE_RESET });
export const resetLoginDetails = () => ({ type: SET_LOGIN_DETAILS_RESET });
export const setCustomerData = payload => ({ type: SET_CUSTOMER_DATA, payload });
export const setLoginPassword = payload => ({ type: SET_LOGIN_PASSWORD, payload });
export const addItemToCart = payload => ({ type: ADD_ITEM_TO_CART, payload });
export const removeItemFromCart = payload => ({ type: REMOVE_ITEM_FROM_CART, payload });

export const increaseItemQuantity = payload => ({ type: INCREASE_ITEM, payload });
export const decreaseItemQuantity = payload => ({ type: DECREASE_ITEM, payload });
export const resetUserDetails = () => ({ type: RESET_USER_DETAILS });

export const setSelectedMerchantDetails = payload => ({ type: SET_NEAREST_MERCHANT, payload });
export const setSelectedMerchantServiceability = payload => ({ type: SET_SELECTED_MERCHANT_SERVICEABILITY, payload });
export const setCurrentAvailableModes = payload => ({ type: SET_CURRENT_AVAILABLE_MODES, payload });

export const setVeg = payload => ({ type: SET_VEG, payload });
export const setSignOutReset = () => ({ type: SET_SIGN_OUT_RESET });
export const setTemporaryInputFields = payload => ({ type: TEMPORARY_INPUT_FIELDS, payload });
export const clearTemporaryInputFields = payload => ({ type: TEMPORARY_INPUT_FIELDS });
export const clearCartAlert = payload => ({ type: CLEAR_CART_ALERT })