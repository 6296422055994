import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import './custom.css';
import { Modal } from 'react-bootstrap';
import { getAddressChunkFromAddress, getAddressFromCoordinates } from '../../../utils/index'
import './index.css';
import { CheckCircle, RightArrow } from '../../../images/icons/IconSets'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import GoogleMaps from '../../Common/googleMaps/GoogleMaps';
import StoreGoogleModal from '../../Common/Storegooglemapmodal/StoreGoogleModal';
import Dropdown from 'react-bootstrap/Dropdown';
import { requestAvailableModes, } from '../../../actions';
import { outletImages } from '../../../reducers/homepagecms';
function Location(props) {
   const dispatch = useDispatch()

   const reduxStates = useSelector(state => state.homepage)
   const reduxUser = useSelector(state => state.user)

   const [searchText, setsearchText] = useState('')
   const [allMerchants, setallMerchants] = useState(reduxStates.availableModesDetails?.data)
   const [filteredMerchants, setfilteredMerchants] = useState([])
   const [selectedMerchant, setselectedMerchant] = useState()
   const [storemap, setstoremap] = useState(false);
   const [valueone, setvalueone] = useState('IN')
   const [current, setcurrent] = useState('IN');
   const [userCoords, setuserCoords] = useState()
   const handleChange = (event) => {
      setsearchText(event.target.value)
      // let allMerchants = reduxStates.availableModesDetails.data
      let filtered = allMerchants.filter((item) => {
         if (
            item.name?.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
            ||
            item.address1?.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
            ||
            item.city?.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
            ||
            item.state?.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
            ||
            item.phoneNumber?.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1
         ) {
            return item
         }
         else return null
      })
      setfilteredMerchants(filtered)

   }
   const handleSubmit = () => {


   }

   const handleClearSelectedMerchant = (param) => {
      setselectedMerchant()
   }

   useEffect(() => {
      dispatch(outletImages());
   }, [])

   useEffect(() => {
      /* select dropdown default 0 */
      // let merchants = reduxStates.availableModesDetails?.data.filter(item => item.countryCode === reduxUser.userAddressChunks?.country_id)
      setallMerchants(reduxStates.availableModesDetails?.data);
      setuserCoords(!!reduxUser.userLocation?.latitude ?
         {
            lat: reduxUser.userLocation?.latitude,
            lng: reduxUser.userLocation?.longitude
         } :
         {
            lat: reduxUser.userDetails?.locationPreference.latitude,
            lng: reduxUser.userDetails?.locationPreference.longitude,
         })

   }, [reduxUser.userLocation, reduxUser.userDetails])

   const handleSelect = (e) => {

       setvalueone(e);
      let merchants = reduxStates.availableModesDetails?.data.filter(item => item.countryCode === e.value)
      setallMerchants(e.value === "All" ? reduxStates.availableModesDetails?.data : merchants);
      setselectedMerchant(e.value === "All" ? reduxStates.availableModesDetails?.data[0] : merchants[0])
   }

   const countries = reduxStates.availableModesDetails?.data.map((v, i) => {
      return v.countryCode
   });

   const uniqcountries = ["All", ...new Set(countries)];


   const handleDirection = (param) => {
      window.open(`https://www.google.com/maps?saddr=${userCoords.lat + ',' + userCoords.lng}&daddr=${param?.latitude + ',' + param?.longitude}`)
   }

   return (
      <div className="store">
         {
            window.innerWidth > 768 ?
               <div>
                  <div class="store-locator-header">
                     <div class="container-fluid">
                        <div class="row">
                           <div class="col-lg-12">
                              <h2>Store Locator</h2>
                              <div class="input-group">
                                 <input class="form-control form1" type="search" value="search"
                                    value={searchText}
                                    autoCapitalize={false}
                                    autoCorrect={false}
                                    onChange={handleChange}
                                    onSubmit={() => handleSubmit()}
                                    id="example-search-input" />
                                 <span class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button">
                                       <img src="assets/search.svg" alt="" />
                                    </button>
                                 </span>
                              </div>

                              <div className="store-dropdown">
                                 <FormControl className="tiemsot" style={{ width: '198px', marginBottom: '20px' }}>
                                    <InputLabel htmlFor="grouped-native-select"></InputLabel>
                                    <Select onChange={handleSelect} id="grouped-native-select" options={uniqcountries?.map((v, i) => ({ label: v, value: v }))}
                                       defaultValue={{ label: uniqcountries?.[0], value: uniqcountries?.[0] }}
                                       theme={theme => ({
                                          ...theme,
                                          borderRadius: 4,
                                          color: '#FFF',
                                          colors: {
                                             ...theme.colors,
                                             primary25: ' rgb(77 58 33 / 70%)',
                                             primary: '#553A20'
                                          },
                                       })}
                                    />
                                 </FormControl>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div
                     class="row"
                     style={{ width: '100%', margin: 0 }}>
                     <div
                        class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"
                        style={{ width: '100%', padding: 0, minHeight: 350 }} >
                        <div className='dir-root'>
                           {
                              ((filteredMerchants.length > 0 || searchText.length > 0) ? filteredMerchants : allMerchants)?.map((value, i) =>
                                 <div
                                    key={i}
                                    className='dir-maincard'
                                    onClick={() => { setselectedMerchant(value) }}>
                                    <div style={{ display: 'flex', width: 'inherit', position: 'relative' }}>
                                       <img height='62px' class='pl-2 pr-3' src="assets/hotel.png" alt="" />
                                       <div style={{ display: 'flex', flexDirection: 'column', width: 'inherit', }}>
                                          <div
                                             style={{
                                                display: 'flex', flexDirection: 'row',
                                                width: '100%',
                                                justifyContent: 'space-between',
                                                position: 'relative',
                                                paddingRight: 50
                                             }}>
                                             <h6 color='#4D3A21'
                                                style={{ color: '#4D3A21', fontWeight: 'bold', }}>
                                                {value.name}
                                             </h6>
                                             <div style={{ position: 'absolute', right: 0 }}><p variant="subtitle2">{value.distance}</p>
                                             </div>
                                          </div>
                                          <text style={{ fontSize: 14, color: '#2C2C2C', paddingRight: 50 }} >{value.address1}</text>

                                          <a href={`tel:${value.phoneNumber}`} className="phnnumber-store" variant="body2">Phone No.&nbsp;&nbsp;{value.phoneNumber}</a>
                                          <a className="getdirection-fr"
                                             style={{ zIndex: 5 }}
                                             target="_blank"
                                             onClick={() => handleDirection(value)}
                                          >
                                             Get Directions
                                                        </a>
                                       </div>
                                       {selectedMerchant?.id === value.id &&
                                          <i style={{
                                             alignSelf: 'center', position: 'absolute', right: 11,
                                             marginTop: 'auto', marginBottom: 'auto', textliAgn: 'center',
                                             top: '40%', bottom: 0
                                          }} className="fa fa-angle-right"></i>
                                       }
                                    </div>
                                 </div>
                              )
                           }
                        </div>

                     </div>
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8"
                        style={{ width: '100%', padding: 0 }}  >
                        <GoogleMaps
                           allMerchants={reduxStates.availableModesDetails && reduxStates.availableModesDetails.data}
                           setselectedMerchant={handleClearSelectedMerchant}
                           selectedMerchant={selectedMerchant} />
                     </div>
                  </div>

               </div>
               :
               <div className="store-locator-mobile">
                  <div class="store-locator-header">
                     <div class="container-fluid">
                        <div class="row">
                           <div class="col-lg-12">
                              <h2>Store Locator</h2>
                              <div class="input-group">
                                 <input class="form-control form1" type="search" value="search"
                                    value={searchText}
                                    autoCapitalize={false}
                                    autoCorrect={false}
                                    onChange={handleChange}
                                    onSubmit={() => handleSubmit()}
                                    id="example-search-input" />
                                 <span class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button">
                                       <img src="assets/search.svg" alt="" />
                                    </button>
                                 </span>
                              </div>
                              {/* <Link to="locationStore"><p className="formp">VIEW complete store list<img src="assets/arrow.svg" alt="" /></p></Link> */}
                              <div className="store-dropdown">
                                 <FormControl className="tiemsot" style={{ width: '198px', marginBottom: '20px' }}>
                                    <InputLabel htmlFor="grouped-native-select"></InputLabel>
                                    <Select onChange={handleSelect} id="grouped-native-select" options={uniqcountries?.map((v, i) => ({ label: v, value: v }))}
                                       defaultValue={{ label: uniqcountries?.[0], value: uniqcountries?.[0] }}
                                       theme={theme => ({
                                          ...theme,
                                          borderRadius: 4,
                                          color: '#FFF',
                                          colors: {
                                             ...theme.colors,
                                             primary25: ' rgb(77 58 33 / 70%)',
                                             primary: '#553A20'
                                          },
                                       })}
                                    />
                                 </FormControl>
                              </div>

                           </div>
                        </div>
                     </div>
                  </div>


                  <div
                     class="row"
                     style={{ width: '100%', margin: 0 }}>
                     <div
                        class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4"
                        style={{ width: '100%', padding: 0 }} >
                        <div className='dir-root'>
                           {
                              ((filteredMerchants.length > 0 || searchText.length > 0) ? filteredMerchants : allMerchants)?.map((value, i) =>
                                 <div
                                    key={i}
                                    className='dir-maincard'
                                    onClick={() => { setselectedMerchant(value); setstoremap(true); }}>
                                    <div style={{ display: 'flex', width: 'inherit', position: 'relative' }}>
                                       <img height='62px' class='pl-2 pr-3' src="assets/hotel.png" alt="" />
                                       <div style={{ display: 'flex', flexDirection: 'column', width: 'inherit', }}>
                                          <div
                                             style={{
                                                display: 'flex', flexDirection: 'row',
                                                width: '100%',
                                                justifyContent: 'space-between',
                                                position: 'relative',
                                                paddingRight: 50
                                             }}>
                                             <h6 color='#4D3A21'
                                                style={{ color: '#4D3A21', fontWeight: 'bold', }}>
                                                {value.name}
                                             </h6>
                                             <div style={{ position: 'absolute', right: 0 }}><p variant="subtitle2">{value.distance}</p >
                                             </div>
                                          </div>
                                          <text style={{ fontSize: 14, color: '#2C2C2C', paddingRight: 50 }} >{value.address1}</text>

                                          <a href="tel:#{value.phoneNumber}" className="phnnumber-store" variant="body2">Phone No.&nbsp;&nbsp;{value.phoneNumber}</a>
                                          <a className="getdirection-fr"
                                             style={{ zIndex: 5 }}
                                             target="_blank"
                                             onClick={() => handleDirection(value)}
                                          >
                                             Get Directions
                                                        </a>
                                       </div>

                                       {selectedMerchant?.id === value.id &&
                                          <i style={{
                                             alignSelf: 'center', position: 'absolute', right: 11,
                                             marginTop: 'auto', marginBottom: 'auto', textliAgn: 'center',
                                             top: '40%', bottom: 0
                                          }} className="fa fa-angle-right"></i>
                                       }
                                    </div>
                                 </div>
                              )
                           }
                        </div>

                     </div>

                     {storemap &&
                        <div>
                           <Modal show={storemap} onHide={() => { setstoremap(false) }} animation={false}>
                              <Modal.Header closeButton />
                              <Modal.Body className="modalstore">
                                 <div>
                                    <GoogleMaps
                                       allMerchants={reduxStates.availableModesDetails && reduxStates.availableModesDetails.data}
                                       setselectedMerchant={handleClearSelectedMerchant}
                                       selectedMerchant={selectedMerchant} />

                              </div>

                           </Modal.Body>
                        </Modal>
                     </div>

                     }

                  </div>


               </div>
         }

      </div>
   );
}

export default Location;