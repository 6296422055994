import React, { useState, useEffect } from 'react'
import './Register.css';
import { useDispatch, useSelector } from 'react-redux'

import {
  requestRegister,
  requestRegisterConfirm,
  setPhone,
  setCustomerData,
} from '../../../actions';
import icon from '../../../../src/images/Group_29936.png';
import rightArrowLight from '../../../images/icons/rightArrowLight.svg'

function Form(props) {

  const user = useSelector(state => state.user)
  const homepage = useSelector(state => state.homepage)
  const registerResponse = user.registerResponse !== null ? user.registerResponse : null;

  const dispatch = useDispatch()
  const [reqBody, setreqBody] = useState()
  const [customerData, setCustomerData] = useState({
    fullname: '',
    email: '',
    phoneNumber: '',
    country: '',
    city: '',
    fbAccessToken: ''
  });
  const [emailDisabled, setemailDisabled] = useState(false)

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setCustomerData({
      ...customerData,
      [name]: value
    })
    // dispatch(setCustomerData({
    //     ...customerData,
    //     [name]: value
    // }));
  };

  const handleProceed = () => {

    let parametersbody = {
      ...reqBody, fullname: customerData.fullname,
      email: customerData.email,
      referralCode: props.referralCode
    };
    if (user.socialLogin) {
      if (user.socialLogin?.facebookData) {
        parametersbody.fbAccessToken = user.socialLogin?.facebookData?.accessToken
      }
      else {
        parametersbody.fbAccessToken = null
      }
      if (user.socialLogin?.googleData) {
        parametersbody.gmailAccessToken = user.socialLogin?.googleData?.accessToken
      }
      else {
        parametersbody.gmailAccessToken = null
      }
    }

    console.log({ parametersbody })
    dispatch(requestRegisterConfirm(parametersbody, result => {
      if (window.clarity && !!result.uuid) {
        window.clarity('set', 'UserId', result.uuid)
      }
    }));
    if (customerData.fullname.length > 2
      &&
      customerData.email.length > 2
      &&
      user.otpResponse.success === true) {

    }
  }

  useEffect(() => {
    if (user.otpResponse.success === true && user.registerStepCompleted === 3) {

      props.setShowModal(false)
      setTimeout(() => {
        window.location.reload()
      }, 1000);

    }
  }, [user.otpResponse.success, user.registerStepCompleted])

  useEffect(() => {
    if (user.socialLogin) {
      if (user.socialLogin?.facebookData?.email) {
        setemailDisabled(true)
      }
      if (user.socialLogin?.googleData?.email) {
        setemailDisabled(true)
      }
      else {
        setemailDisabled(false)
      }

      if (user.socialLogin?.facebookData) {
        setCustomerData({
          ...customerData,
          email: user.socialLogin?.facebookData?.email
        })
      }
      if (user.socialLogin?.googleData) {
        setCustomerData({
          ...customerData,
          email: user.socialLogin?.googleData?.email
        })
      }
    }

    const parametersbody = {
      uuid: user.registerResponse?.uuid,
      fullname: customerData.fullname,
      email: customerData.email,
      phoneNumber: props.registerInfo?.phoneNumber,
      udid: homepage.deviceDetails.udid,
      otp: props.otp
      // gender: 'male',
    };
    setreqBody(parametersbody)
  }, []);


  return (
    <div>
      <div className="registerlogo"><img className="iconlogo" src={icon} /></div>
      <div className="registerheader">Create your account</div>
      <p className="pera" style={{ textAlign: 'center' }}>Please create account to continue with your order</p>
      <div className="ptext">
        <input
          name='fullname'
          value={customerData.fullname}
          onChange={handleInputChange}
          className="phnnumber" placeholder="Enter your full name" type="text" />
      </div>
      <div className="ptext">
        <input
          disabled={emailDisabled}
          name='email'
          value={customerData.email}
          onChange={handleInputChange}
          className="phnnumber" placeholder="email" type="email" />
      </div>
      {!user.registerConfirmInProgress && user.otpResponse && !user.otpResponse.success && <p className='my-2 text-danger h6'>{user.otpResponse.message}</p>}

      <button onClick={handleProceed} className="modalRegBtn">{user.socialLogin ? 'Confirm Signup' : 'Verify OTP'}
        {user.registerInProgress ?
          <div className='spinner-border text-primary' />
          :
          <img src={rightArrowLight} alt="Arrow" />}
      </button>
    </div>
  )
}

export default Form
