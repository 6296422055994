// import config from '../config';
import apiClient from '../api/index';
import config from '../config';
import { getCart, getCartPointsAction } from './cart';

const initialState = {

};

const GET_POINTS = "points/GET_POINTS"
const APPLY_POINTS = 'points/APPLY_POINTS';
const REMOVE_POINTS = 'points/REMOVE_POINTS';

// const APPLY_COUPON = "points/APPLY_COUPON"
// const REMOVE_COUPON = "points/REMOVE_COUPON"



export const getPointsAction = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken, uuid } = state.user.otpResponse || {}

    const response = await apiClient.get(apiClient.Urls.loyaltyPoints, {
      merchantAccessToken: config.merchantAccessToken,
      authToken: authToken,
    });

    if (response.success) {
      dispatch({
        type: GET_POINTS,
        payload: response,
      });
    } else {

    }
  } catch (error) {
  }
};

export const applyPointsAction = (callback) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken, uuid } = state.user.otpResponse || {}
    const { merchantId, cartId, cartSubTotal, cartPoints } = state.cart || {}
    const { currencyConversionRate } = state.checkout || {}

    const { loyaltyPoints } = state.loyaltyPoints?.points || {}

    let pointsToBeUsed = 0



    const response = await apiClient.post(apiClient.Urls.postApplyPoints, {
      merchantAccessToken: config.merchantAccessToken,
      pointsToRedeem: cartPoints.pointsToRedeem,
      merchantId: state.home.merchantDetails?.id || merchantId,
      authToken: authToken,
      cartId: cartId,
    });
    if (response.success) {
      // dispatch({
      //   type: APPLY_POINTS,
      //   payload: response.discount,
      // });
      dispatch(getCart())
      dispatch(getCartPointsAction())
      // dispatch(getPointsAction())
    } else {
    }
    callback && callback(response)
  } catch (error) { }
};


export const removePointsAction = (callback) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken } = state.user.otpResponse || {}
    const { merchantId, cartId } = state.cart

    const response = await apiClient.post(apiClient.Urls.removePoints, {
      merchantAccessToken: config.merchantAccessToken,
      merchantId: merchantId,
      authToken: authToken,
      cartId: cartId,
    });
    if (response.success) {
      // dispatch({
      //   type: REMOVE_POINTS,
      //   payload: response,
      // });
      dispatch(getCart())
      dispatch(getCartPointsAction())

    } else {
    }
    callback && callback(response)
  } catch (error) { }
};

const pointsReducer = (state = initialState, action) => {
  switch (action.type) {

    case GET_POINTS: {
      return {
        ...state,
        points: action.payload,
      }
    }
    case APPLY_POINTS: {
      return {
        ...state,
        pointsDiscount: action.payload,
      };
    }
    case REMOVE_POINTS: {
      return {
        ...state,
        pointsRemoved: action.payload,
      };
    }
    default: return state;
  }
};

export default pointsReducer;
