import apiClient from '../api/index';
import config from '../config';
import { getCart, getCartPointsAction } from './cart';

const initialState = {

};

const GET_FREEBIES = "freebie/GET_FREEBIES"
const APPLY_FREEBIE = "freebie/APPLY_FREEBIE"
const REMOVE_FREEBIE = "freebie/REMOVE_FREEBIE"

export const getFreebies = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken, uuid } = state.user.otpResponse || {}
    const response = await apiClient.get(apiClient.Urls.getFreebies, {
      merchantAccessToken: config.merchantAccessToken,
      uuid: uuid,
      authToken: authToken,
    });

    if (response.success) {
      dispatch({
        type: GET_FREEBIES,
        freebies: response,
      });
    } else {

    }

  } catch (error) {

  }
};


export const applyFreebieAction = (params, callback) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken } = state.user.otpResponse || {}
    const { merchantId, cartId } = state.cart || {}
    const response = await apiClient.get(apiClient.Urls.applyFreebie, {
      merchantAccessToken: config.merchantAccessToken,
      authToken: authToken,
      merchantId: merchantId,
      cartId: cartId,
      freebieId: params.freebieId,

    });
    if (response.success) {
      dispatch({
        type: APPLY_FREEBIE,
        coupons: response.coupons,
      });
      dispatch(getCartPointsAction())
      dispatch(getCart())
    } else {

    }
    callback && callback(response)
  } catch (error) {
  }
};

export const removeFreebieAction = (params) => async (dispatch, getState) => {
  try {
    const state = getState();
    const { authToken } = state.user.otpResponse || {}
    const { merchantId, cartId } = state.cart || {}
    const response = await apiClient.get(apiClient.Urls.removeFreebie, {
      merchantAccessToken: config.merchantAccessToken,
      authToken: authToken,
      merchantId: merchantId,
      cartId: cartId,
    });
    if (response.success) {
      dispatch({
        type: APPLY_FREEBIE,
        payload: response,
      });
      dispatch(getCartPointsAction())
      dispatch(getCart())
    } else {

    }
  } catch (error) {
  }
};

const freebiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_FREEBIE: {
      return {
        ...state,
        coupons: action.coupons,
      }
    }

    case REMOVE_FREEBIE: {
      return {
        ...state,
        coupons: action.payload,
      }
    }

    case GET_FREEBIES: {
      return {
        ...state,
        freebies: action.freebies,
      }
    }
    default: return state;
  }
};

export default freebiesReducer;
