import React, { Component, useEffect } from 'react';

import { connect, useSelector, useDispatch } from 'react-redux';
import Layout from '../../Layout/index'

import { GiftingRoot } from '@froogal.ai/gifting-sdk-js'
import '@froogal.ai/gifting-sdk-js/dist/index.css'
import config from '../../../config'
class Gifting extends Component {
  componentDidMount() {
    document.title = 'Gifting - Biryanis and More'

  }

  render() {
    const { authToken } = this.props.user?.otpResponse || {}
    return (
      <Layout >
        <div style={{ marginTop: 70 }}>
          <GiftingRoot
            authToken={authToken}
            merchantAccessToken={config.merchantAccessToken}
            baseUrl={config.apiUrl + '/'}
          />
        </div>
      </Layout>
    );
  }
}


const mapStateToProps = (state) => ({
  homepage: state.homepage,
  user: state.user,
  cart: state.cart,
  orders: state.orders,
  globalBanners: state.user.globalBanners,
  merchant: state.merchant
});

const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Gifting);

