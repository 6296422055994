import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { setPhone, setLoginPassword } from '../../../../../../actions';
import './authFlow.css';
import Page1Login from './Page1.Login'
import Page2ValidateOtp from './Page2.ValidateOtp.js';

import Page2VerifyOtp from './Page2.VerifyOTP';
import Page3Register from './Page3.Register';

const AuthFlow = (props) => {
    const [currentScreen, setCurrentScreen] = useState(0);
    const [phone, setPhone] = useState();
    const [loginInfo, setloginInfo] = useState()
    const [otp, setOtp] = useState();


    const setLoginParams = (param) => {
        setloginInfo(param)
        setPhone(param.mobileNumber)
    }
    const changeCurrentScreen = (param) => {
        setCurrentScreen(param)
    }
    const setShowModal = (param) => {
        setShow(param)
    }
    const changeSetOtp = (param) => {
        setOtp(param)
    }
    useEffect(() => {
      return () => {
            setCurrentScreen(0)
        }
    }, [])

    /* 
    1: LOGIN
    2: VALIDATE OTP
    3: VERIFY OTP
    4: USER DETAILS
    */

    return (

        <div style={{ display: 'flex', }} >
            {currentScreen === 0 ?
                <Page1Login
                    {...props}
                    loginParams={setLoginParams}
                    setCurrentScreen={changeCurrentScreen} />
                : currentScreen === 1 ?
                    <Page2ValidateOtp
                        {...props}
                        loginInfo={loginInfo}
                        setCurrentScreen={changeCurrentScreen}
                    />
                    : currentScreen === 2 ?
                        <Page2VerifyOtp
                            {...props}
                            setOtp={changeSetOtp}
                            loginInfo={loginInfo}
                            setCurrentScreen={changeCurrentScreen}
                        />
                        : currentScreen === 3 ?
                            <Page3Register
                                {...props}
                                phoneNumber={phone}
                                otp={otp}
                                loginInfo={loginInfo}
                                setCurrentScreen={changeCurrentScreen}
                            />
                            :
                            null
            }
        </div>

    )
};

const mapStateToProps = state => ({
    user: state.user,
    homepage: state,
});

export default connect(mapStateToProps, { setPhone, setLoginPassword })(AuthFlow);