import React, { Component, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { LeftArrowShort, LeftArrow } from '../../../images/icons/IconSets'
import { CheckCircle, RightArrow } from '../../../images/icons/IconSets';
import Backarrow from '../../../images/Path 64972.svg';
function ProceedDrawer(props) {

  function iconStyles() {
    return {
      successIcon: {
        color: 'error',
      },
      errorIcon: {
        color: 'red',
      },
      cart: {
        color: 'white',
      },
    }
  }
  const reduxuser = useSelector(state => state.user?.userLocation)
  const location = reduxuser !== null && Object.keys(reduxuser).includes('results') ? reduxuser.results[4]?.formatted_address : null;
  const history = useHistory()
  const { onClickViewCart, count } = props;
  const cartCount = count && !isNaN(count) ? count : 0;
  // const [selectedaddressobject, setselectedaddressobject] = useState()
  // const [selectedaddress , setselectedaddress] = useState(false)
  // const handlepreviousaddress = () => {
  //     setselectedaddress(false)
  // }
  // const handleaddresschange = (param) => {
  //     setselectedaddressobject(param)
  //     setselectedaddress(true)

  // }
  const handleProceed = (param) => {
    props.setCurrentScreen(param)
    window.scrollTo(0, 0)

  }
  return (
    <React.Fragment>
      {/* <div className="is-theme view-cart-sticky-drawer is-flex is-justify-content-space-between is-align-items-center  px-4 py-4"
                style={{
                    background: '0px center #553A28', top: 78, position: 'fixed',
                    height: 70, zIndex: 1, justifyContent: 'space-between'
                }}>
                <button
                    onClick={() => handleProceed()}
                    className={`button button-fr`}
                    style={{ justifyContent: 'space-between' }}
                >
                    <span className="icon is-small ml-2">
                        <ArrowForward fontSize='default' />
                    </span>
                </button>
            </div> */}
      {/* {
                history.location.pathname.indexOf('cart') > -1 ?

    null
            :
            <div style={{position:'fixed', zIndex:1,justifyContent:'space-between',top:103}}>

                    <img src={Backarrow} onClick={() => handleProceed(0)} />
            </div>
            } */}
      {props.currentScreen === 0 ?
        null :
        <div style={{ justifyContent: 'space-between', top: 95, left: 10, paddingTop: '10px', marginLeft: '10px' }} onClick={() => handleProceed(0)} >
          <LeftArrow color='#B2BAC3' height={20} width={20} /> &nbsp; Go back to  Items
          {/* <img src={Backarrow} onClick={() => handleProceed(0)} /> */}
        </div>


      }

      {props.currentScreen === 1 ?
        null :

        <div className="is-theme view-cart-sticky-drawer view-cart-sticky-drawer-fr is-flex is-justify-content-space-between is-align-items-center  px-1 py-2"
          style={{
            display: 'flex', flexDirection: 'column',
            background: '1px center #553A28', bottom: 0, zIndex: 1, justifyContent: 'space-between'
          }}>

          {/* <div className="is-size-4 vc-cart-count-wrap has-text-white m-1">
                    <span className="pl-3 is-size-6" style={{ color: '#FFF' }}>Back</span>
                </div> */}

          {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div className="home-img">
              <img src="assets/home.svg" alt="Img" />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '10px' }}>
              <h2 className="deliveryaddress-fr  homedeliver">Deliver to Home </h2>
              <CheckCircle width={20} height={20} color="#28a745" style={{ float: 'right' }} className="checkcircle-fr" />
              <button className="changebtn-fr">change</button>
              <h4 className="hometext-fr">Home</h4>
              <p className="selectedaddress-fr-text">{location}</p>
              <h5 className="etaaddress-fr homeeta"></h5>
            </div>
          </div> */}

          <button
            onClick={() => handleProceed(1)}
            className={`button-fr-new is-inverted is-outlined is-theme is-uppercase`}
          >
            Proceed
            <span className="icon is-small ml-2">
              <ArrowForward fontSize='default' />
            </span>
          </button>
        </div>
      }
    </React.Fragment>
  )
}
export default ProceedDrawer