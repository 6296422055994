
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Rating from '@mui/material/Rating';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import {
  addItemToCart, setNearestMerchantDetails
} from "../../../actions";
import refercup from '../../../images/Group 35390.svg';
// import { TruckFlatbed } from 'react-bootstrap-icons';
import arrowleft from '../../../images/leftarrow.svg';
import referarrowimg from '../../../images/referarrowimg.svg';
import regularimg from '../../../images/Regular-M.svg';
import arrowright from '../../../images/rightarrow.svg';
import { addCart } from '../../../reducers/cart';
import { homeFeaturedItems, homepageAboutUs, homePageFeatures, orderModeButtons, requestCustomerTestinomials, setAnchorAction } from '../../../reducers/homepagecms';
import { setInitialOrderMode, setOrderMode } from '../../../reducers/orders';
import { globalBanners } from '../../../reducers/user';
import '../../About/Components/aboutUs.css';
import AddressModal from '../../Common/AddressModal';
import LocationSwitchModal from '../../Common/LocationSwitchModal/index';
import LoginModal from '../../Common/LoginModal';
import RegisterModal from '../../Common/RegisterModal';
import Layout from '../../Layout/index';
import Banner from '../../Common/bottomBannner/Banner';
import FeaturedItem from './FeaturedItems';
import GlobalBanners from './GlobalBanners';
import './HomepageNew.css';
import RewardProgram from './RewardProgram';
import './slickdemo.css';
import './slider.css';


function Referralhome(props) {
  const { onClick } = props;
  const anchorRef = React.createRef()
  const selector = useSelector(state => state.user)
  const history = useHistory()
  const reduxStates = useSelector(state => state.homepage)
  // const reduxhomecms = useSelector(state => state.homepagecms?.customertestinomial)

  const reduxhomeAnchor = useSelector(state => state.homepagecms?.setAnchor)
  const reduxmodeorder = useSelector(state => state.homepagecms?.ordermodebuttons)
  const reduxfeatureditems = useSelector(state => state.homepagecms?.homefeatureditems)

  const dispatch = useDispatch()

  const [locationshow, setlocationshow] = useState(false);
  const [addressShow, addressSetShow] = useState(false);

  const [setAnchor, setsetAnchor] = useState(false)
  const { homepagefeatures, customerTestimonials, homepageaboutus, homefeatureditems } = props;


  const { classes } = props;
  const styslide = {
    color: "#553a28"
  }
  const [loginshow, setLoginShow] = useState(false);
  const [registershow, setRegistershow] = useState(true);
  const handleletsgo = () => {
    if (selector.isAuthenticated) {
      history.push("/myDetails#RefferalProgram")
    } else {
      return setLoginShow(true);
    }
  }

  const stytitle = {
    font: 'normal normal 600 20px/24px Lato',
    letterSpacing: '0px',
    color: '#2C2C2C',
    opacity: '1'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    document.getElementById("splashspinner").style.display = "none";
    if (selector.isAuthenticated) {
        history.push("/")
        setRegistershow(false)
      }
      else {
        setRegistershow(true)
      }
    // const body = {
    //   referralCode: props.referralCode
    // }
    dispatch(requestCustomerTestinomials())
    dispatch(homePageFeatures())
    dispatch(orderModeButtons())
    dispatch(homepageAboutUs())
    dispatch(homeFeaturedItems())

  }, [])

  useEffect(() => {
    if (reduxhomeAnchor) {
      handleAnchor()
      dispatch(setAnchorAction(false))
    }
  }, [reduxhomeAnchor])

  const handleAnchor = (event) => {
    if (anchorRef.current) {
      anchorRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "start"
      })
      window.scrollBy(0, -105); // Adjust scrolling with a negative value here
    }
  }
  //inline instead of block
  const handleShowlocationModel = (param) => {
    selectedOrderType(param)
    return setlocationshow(true);
  };

  const selectedOrderType = (index) => {
    const orderTypes = [
      { id: 0, orderType: 'delivery' },
      { id: 1, orderType: 'takeAway' },
      { id: 2, orderType: 'dineIn' },
      { id: 3, orderType: 'menu' },
    ]
    dispatch(setOrderMode(orderTypes[index].orderType))
      if (index !== 3) {
          dispatch(setInitialOrderMode(orderTypes[index].orderType))
      }
  }
  const reduxhomecms = customerTestimonials != undefined && customerTestimonials != null && customerTestimonials;

  const reduxhomeabout = homepageaboutus != undefined && homepageaboutus != null && homepageaboutus;


  const styles = {
    card: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    prevArrow: <img className="prev-fr" src={arrowleft} />,
    nextArrow: <img className="prev-fr" src={arrowright} width='45px' height="45px" />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  };
  const handleAddressShow = (param) => {
    addressSetShow(param)
  }
  return (
    <div>
      {
        <Layout>
          <div style={{ marginTop: '80px' }} >
            <GlobalBanners />
            {locationshow && <LocationSwitchModal show={locationshow} setShow={setlocationshow}
              addressSetShow={handleAddressShow} />}
            {addressShow && <AddressModal show={addressShow} setShow={addressSetShow}  {...props} />}

            {loginshow && <LoginModal show={loginshow} setShow={setLoginShow} />}
            {registershow && <RegisterModal show={registershow} setShow={setRegistershow} />}
            <div className="about-fr" style={{ marginTop: 60, marginLeft: '20px', marginRight: '20px', marginBottom: 60 }}>
              <div className="about-services about-services-fr" style={{ backgroundColor: 'white', paddingTop: '53px', paddingBottom: '85px' }}>
                <div className="container">
                  <div className="row">
                    <div className="col-lg-3 col-md-4 about-service-column-fr">
                      <div className="about-services-main">
                        <img src={homepagefeatures?.[0]?.image} />
                        {/* <img src="assets/our-cuisine.svg" /> */}
                        <h2 className="homepagefeaturestitle">{homepagefeatures?.[0]?.title}</h2>
                        <p>{homepagefeatures?.[0]?.text}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-4 about-service-column">
                      <div className="about-services-main">
                        {/* <img src="assets/quality.svg" />
                        <h2>Quality & Consistency</h2>
                        <p>Our biryani taste is the same served across all our destinations</p> */}
                        <img src={homepagefeatures?.[1]?.image} />
                        <h2 className="homepagefeaturestitle">{homepagefeatures?.[1]?.title}</h2>
                        <p>{homepagefeatures?.[1]?.text}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-4 about-service-column">
                      <div className="about-services-main">
                        {/* <img src="assets/our-cuisine.svg" />
                        <h2>The ambience</h2>
                        <p>Comfortable with contemporary interiors for a seamless experience</p> */}
                        <img src={homepagefeatures?.[2]?.image} />
                        <h2 className="homepagefeaturestitle">{homepagefeatures?.[2]?.title}</h2>
                        <p>{homepagefeatures?.[2]?.text}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ordermodes-fr" ref={anchorRef} style={{ margin: 20 }}>
              <div className="order-body-fr"  >
                <div className="ordermode-gradient"
                  style={{
                    padding: 15, width: '100%', height: '100%', borderRadius: 6,
                    background: 'linear-gradient(to left, transparent 35%, rgba(62, 51, 29, 0.95) 70%)'
                  }}>

                  <div style={{ paddingTop: 10, paddingLeft: 10 }}>
                    <h3 className="orderheader">Biryanis and More <span className="suborderheader">Online Ordering</span></h3>
                    <p className="orderptag">Prepared fresh and delivered fast!</p>
                  </div>



                  <div   >
                    <div className="col-lg-8 col-md-12 col-sm-12 ordermain"   >
                      <div className="row ordersubrow" >

                        <div className="col-md-5 col-sm-6 ordermodebox"
                          style={{ display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer' }}
                          onClick={() => handleShowlocationModel(0)}>
                          <div>
                            <img src={reduxmodeorder?.data?.[0].icon} height="40px" style={styslide} />
                            <strong className="ordertitle">{reduxmodeorder?.data?.[0].label}</strong>
                            {/* <p className="ordertext">Lorem ipsum dolor sit amet, consetetur sadipscing consetetur</p> */}
                          </div>

                          <p className="ordertext">{reduxmodeorder?.data?.[0].description}</p>
                          <img src={regularimg}
                            style={{
                              alignSelf: 'center', position: 'absolute', right: 11,
                              marginTop: 'auto', marginBottom: 'auto', textliAgn: 'center',
                              top: 0, bottom: 0
                            }} />
                        </div>

                        <div className="col-md-5 col-sm-6 ordermodebox ordertakeaway-fr"
                          style={{ display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer' }}
                          onClick={() => handleShowlocationModel(1)}>
                          <div>
                            <img src={reduxmodeorder?.data?.[1].icon} height="40px" style={styslide} />
                            <strong className="ordertitle">{reduxmodeorder?.data?.[1].label}</strong>
                            {/* <p className="ordertext">Lorem ipsum dolor sit amet, consetetur sadipscing consetetur</p> */}
                          </div>
                          <p className="ordertext">{reduxmodeorder?.data?.[1].description}</p>
                          <img src={regularimg} style={{
                            alignSelf: 'center', position: 'absolute', right: 11,
                            marginTop: 'auto', marginBottom: 'auto', textliAgn: 'center',
                            top: 0, bottom: 0
                          }} />
                        </div>

                      </div>
                      <div className="row ordersubrow">
                        <div className="col-md-5 col-sm-6 ordermodebox"
                          style={{ display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer' }}
                          onClick={() => handleShowlocationModel(2)}>
                          <div>
                            <img src={reduxmodeorder?.data?.[2].icon} height="40px" style={styslide} />
                            <strong className="ordertitle" >{reduxmodeorder?.data?.[2].label}</strong>
                            {/* <p className="ordertext">Lorem ipsum dolor sit amet, consetetur sadipscing consetetur</p> */}
                          </div>

                          <p className="ordertext">{reduxmodeorder?.data?.[2].description}</p>
                          <img src={regularimg} className="dineinimgmobile"
                            style={{
                              alignSelf: 'center', position: 'absolute', right: 11,
                              marginTop: 'auto', marginBottom: 'auto', textliAgn: 'center',
                              top: 0, bottom: 0
                            }} />
                        </div>

                        <div className="col-md-5 col-sm-6 ordermodebox ordertakeaway-fr"
                          style={{ display: 'flex', flexDirection: 'column', position: 'relative', cursor: 'pointer' }}
                          onClick={() => handleShowlocationModel(3)}  >
                          <div>
                            <img src={reduxmodeorder?.data?.[3].icon} height="40px" style={styslide} />
                            <strong className="ordertitle">{reduxmodeorder?.data?.[3].label}</strong>
                            {/* <p className="ordertext">Lorem ipsum dolor sit amet, consetetur sadipscing consetetur</p> */}
                          </div>

                          <p className="ordertext">{reduxmodeorder?.data?.[3].description}</p>
                          <img src={regularimg}
                            style={{
                              alignSelf: 'center', position: 'absolute', right: 11,
                              marginTop: 'auto', marginBottom: 'auto', textliAgn: 'center',
                              top: 0, bottom: 0
                            }} />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="about-welcome" style={{ paddingBottom: '0px', marginTop: '51px', marginLeft: '20px', marginRight: '20px', borderRadius: '6px', marginBottom: 60 }}>
              <div className="container-fluid">
                <div className="row content">
                  <div className="col-lg-6 col-md-6 padding-right">
                    <div className="about-welcome-left fadeIn">
                      <h2 className="aboutusname">About us</h2>
                      <div className="content" dangerouslySetInnerHTML={{ __html: reduxhomeabout?.about }}></div>
                      {/* <p>{reduxhomeabout?.about}</p> */}
                      {/* <p className="aboutustext">Started under the hegemony of Mr. Ramanaidu Suryadevara (Surya), Biryanis and More has several restaurant chains under its umbrella in the United States. Headquartered in Vijayawada, the millennial city of India, Biryanis and More is geared to serve the ever-increasing epicurean, gastronomic populace of India and the overseas customers.</p> */}
                      <h3>COUNTRIES WE ARE IN</h3>
                      <ul>
                        <li><img src="assets/india.svg" alt="images" />India</li>
                        <li><img src="assets/canada.svg" alt="images" />Canada</li>
                        <li><img src="assets/united-kingdom.svg" alt="images" />USA</li>
                        <li><img src="assets/united-arab-emirates.svg" alt="images" />UAE</li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 padding-left">
                    <div className="about-welcome-right">
                      {/* <img src="assets/about-right.jpg" alt="images" className="img-fluid" /> */}
                      <img src={reduxhomeabout?.image} alt="images" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {window.innerWidth > 768 &&
              <div className="featuremain" style={{ paddingTop: '51px', marginBottom: 60 }} >
                <FeaturedItem homefeatureditems={props.homefeatureditems} {...props} />
              </div>
            }

            <div className="feedback-fr" style={{ marginBottom: 60 }}>
              <h2 style={{ textAlign: 'center', paddingBottom: '21px', paddingTop: '10px' }}>our customers Feedback</h2>
              <div>
                <Slider {...settings}
                  dots={true}
                  //  autoplay={true}
                  //  autoplaySpeed={3000}
                  arrows={true}
                >
                  {reduxhomecms?.map((v, i) =>
                    <div className="neworder-fr" key={i}>
                      <div className="orderflex">
                        <img src={v.customerImage} style={{
                          top: -50, display: 'block',
                          position: 'absolute',
                          zIndex: '2'
                        }} height="125px" width="125px" />
                        <div style={{ boxShadow: 'rgb(236 238 244 / 77%) 1px 12px 8px' }} >
                          <Card elevation={0} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', height: '286px' }}>
                            <Box component="fieldset" className="feedbacktwo" mb={3} borderColor="transparent">
                              <Rating
                                name="read-only"
                                defaultValue={5}
                                precision={0.5}
                                readOnly
                              />
                            </Box>
                            <h3 className="customername">
                              {v.customerName}
                            </h3>
                            <p className="customermsg">
                              {v.message}
                              {/* Lorem ipsum dolor sit amet, consetetur sit amet sadipscing elitr,dolor sit amet, consetetur elitr,dolor sit amet, consetetur */}
                            </p>
                          </Card>
                        </div>
                      </div>
                    </div>

                  )}


                  {/* <div className="neworder-fr">
                <div className="orderflex">
                  <img src={feedbackimg3} style={{
                    top: -50, display: 'block',
                    position: 'absolute',
                    zIndex: '2'
                  }} height="125px" width="125px" />
                   <div style={{ boxShadow: 'rgb(236 238 244 / 77%) 1px 12px 8px'}} >
                  <Card elevation={0} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', height: '286px' }}>
                    <Box component="fieldset" className="feedbacktwo" mb={3} borderColor="transparent">
                      <Rating
                        name="read-only"
                        defaultValue={3}
                        precision={0.5}
                        readOnly
                      />
                    </Box>
                    <h3 className="customername">
                    Rupesh Reddy
                          </h3>
                           <p className="customermsg">
                            {reduxhomecms?.message}
                            {/* Lorem ipsum dolor sit amet, consetetur sit amet sadipscing elitr,dolor sit amet, consetetur elitr,dolor sit amet, consetetur */}
                  {/* </p>
                  </Card>
                  </div>
                </div>
               </div> */}

                  {/* <div className="neworder-fr">
                <div className="orderflex">
                  <img src={feedbackimg2} style={{
                    top: -50, display: 'block',
                    position: 'absolute',
                    zIndex: '2'
                  }} height="125px" width="125px" />
                   <div style={{ boxShadow: 'rgb(236 238 244 / 77%) 1px 12px 8px'}} >
                  <Card elevation={0} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', height: '286px' }}>
                    <Box component="fieldset" className="feedbacktwo" mb={3} borderColor="transparent">
                      <Rating
                        name="read-only"
                        defaultValue={4}
                        precision={0.5}
                        readOnly
                      />
                    </Box>
                    <h3 className="customername">
                    Akshita Reddy
                          </h3>
                           <p className="customermsg">
                            {reduxhomecms?.message}
                            {/* Lorem ipsum dolor sit amet, consetetur sit amet sadipscing elitr,dolor sit amet, consetetur elitr,dolor sit amet, consetetur */}
                  {/* </p>
                  </Card>
                  </div>
                </div>
               </div> */}
                </Slider>
              </div>
            </div>


            <div className="refer-section-fr" style={{ marginTop: '51px', marginLeft: '20px', marginRight: '20px' }}>
              <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 51, marginBottom: 51, padding: 17, }}>
                <div style={{ padding: 10, backgroundColor: '#FFFFFF', maxWidth: 600 }}>
                  <RewardProgram />
                </div>

                {/* <div className="col-lg-6 col-md-6 col-sm-12" style={{textAlign:'center'}}>
                      <img className="refer-section-fr-img" src={refermainimg} width="393px" height="393px" />
                  </div> */}
                <div
                  className="col-lg-6 col-md-6col-sm-12"
                  style={{ display: 'flex', flexDirection: 'column', padding: 27, backgroundColor: '#fff', justifyContent: 'space-between', textAlign: 'start' }}
                >
                  <h2 className="refer-friends-header">Refer More, Earn More</h2>
                  <p className="refer-friends-para">
                    Earn Rewards with Every Milestone
                    </p>
                  <div className="refer-section-fr-icons" >
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <img src={refercup} width="52px" height="52px" />
                      <text className="refer-section-fr-icontext">Complete with friends</text>
                    </div>
                    <div style={{
                      alignSelf: 'center',
                      height: 15,
                      border: '1px solid #2C2C2C',
                      opacity: 0.1
                    }} />
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <img src={refercup} width="52px" height="52px" />
                      <text className="refer-section-fr-icontext-two">Earn Milestones when you refer</text>
                    </div>
                  </div>
                  <button className="refer-section-fr-button px-3" onClick={handleletsgo}>Let’ Go <img className="referarrow" src={referarrowimg} /></button>
                </div>
              </div>
            </div>

            <Banner {...props} />
          </div>
        </Layout>
      }
    </div>
  );
}

const mapStateToProps = (state) => ({
  homepageDetails: state.homepage,
  user: state.user,
  cart: state.cart,
  cartItems: state.cart.items,
  homefeatureditems: state.homepagecms.homefeatureditems,
  homepagefeatures: state.homepagecms.homepagefeatures,
  globalBanners: state.user.globalBanners,
  ordermodebuttonsfr: state.homepagecms.ordermodebuttons,
  customerTestimonials: state.homepagecms.customerTestimonials,
  homepageaboutus: state.homepagecms.homepageaboutus,
  // referralCode: state.user?.referralCode,

});

const mapDispatchToProps = {
  setNearestMerchantDetails,
  addItemToCart,
  addCart,
  globalBanners,
  homePageFeatures,
  homeFeaturedItems,
  orderModeButtons,
  requestCustomerTestinomials,
  homepageAboutUs,

};

export default connect(mapStateToProps, mapDispatchToProps)(Referralhome);
