import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setTemporaryInputFields } from '../../../actions/setState';
import { Dash, Plus } from '../../../images/icons/IconSets';
import { addCart, removeCart } from '../../../reducers/cart';
import AddonModal from '../../Common/AddonModal/index';
import { CommonAlertModal } from '../alertModal/CommonAlert';
import './AddCartButton.css';

export default function AddCartButton(props) {
  const dispatch = useDispatch()

  const [addonOpen, setaddonOpen] = useState(false)
  const [quantity, setquantity] = useState()
  const [alertShow, setAlertShow] = useState(false)

  const { count, changeQuantity } = props;
  const countNum = count && !isNaN(count) ? Number(count) : 0;
  const reduxCartItems = useSelector(state => state.cart?.items)

  useEffect(() => {
    let cartItems = reduxCartItems
    let propsId = props.item?.id
    let sum = 0
    for (let i = 0; i < cartItems.length; i++) {
      if (propsId === cartItems[i].itemId) {
        sum = sum + cartItems[i].quantity
      }
    }
    setquantity(sum)
  }, [reduxCartItems, props.item?.id])


  const handleAddonOpen = (param) => {
    setaddonOpen(true)
  }

  const handleCloseAddon = (param) => {
    setaddonOpen(param)
  }

  const handleChangeQty = () => {
    if (props.item?.addons.length > 0) {
      handleAddonOpen(true)
    }
    else {
      dispatch(setTemporaryInputFields(props.item))
      dispatch(addCart(props.item.id, null, null))
    }
  }

  const handleRemoveQty = () => {
    if (reduxCartItems.filter((item, i) => item.itemId === props.item.id).length > 1) {
      setAlertShow(true)
    }
    else {
      console.log('remove from cart')
      dispatch(removeCart(props.item.id, null, null))
    }
  }


  return (
    <div>
      {alertShow &&
        <CommonAlertModal
          show={alertShow}
          setShow={setAlertShow}
          alertText="This item has multiple customizations added. Remove the correct item from the cart." />}
      {addonOpen === true ?
        <AddonModal
          show={addonOpen}
          setShow={handleCloseAddon}
          {...props}
          countNum={countNum}
        /> : null}

      {quantity === 0
        ?
        <Button className="add-cart-button" size="sm"
          onClick={() => handleChangeQty()}
        >
          Add
        </Button>
        :
        <div color='default'
          style={{ display: 'flex', flexDirection: 'row', margin: 0 }}  >
          <button className="add-cart-button-group"
            style={{ borderTopLeftRadius: 4, borderBottomLeftRadius: 4, paddingBottom: 3 }}
            onClick={() => handleRemoveQty()}>
            <Dash width={23} height={23} color='#5CB346' />
          </button>

          <button disabled={true}
            style={{
              backgroundColor: '#ECEEF4C4',
              color: '#5CB346', opacity: 1,
              fontWeight: 'bold',
            }}>{quantity}</button>

          <button className="add-cart-button-group"
            style={{ borderTopRightRadius: 4, borderBottomRightRadius: 4, paddingBottom: 3 }}
            onClick={() => handleChangeQty()}>
            <Plus width={23} height={23} color='#5CB346' />
          </button>
        </div>
      }
    </div>
  );
}
