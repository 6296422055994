import React, { Component } from 'react';
import AboutComponent from '../Components/index'
import Layout from '../../Layout/index'
class AboutUs extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        document.getElementById("splashspinner").style.display = "none";
        document.title = 'About - Biryanis and More'
    }

    render() {
        return (
            <div>
                <Layout >
                    <AboutComponent />
                </Layout>
            </div>
        );
    }
}
export default AboutUs;
