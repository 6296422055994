import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import '../AddressModal/addressModel.css';
import '../../MyDetails/Components/Trackorder.scss';
import { requestNewAddress,requestAddNewAddress } from '../../../actions';
import scooterimg from '../../../images/scooter.svg';
import phoneimg from '../../../images/Group 30030.svg';
import greencircle from '../../../images/Ellipse 511.svg';
function TrackMapModal(props) {
  const [address, setAddress] = useState({
    houseNumber: '',
    landMark: '',
    street: '',
    city: '',
    locality: '',
  });

  const { show, setShow , requestNewAddress ,requestAddNewAddress} = props;
  

  return (
    <div>
      <Modal show={show} onHide={() => { setShow(false) }} animation={false}>
        <Modal.Header  closeButton />
        <Modal.Body>
        <div className="modalHeading-track">
              <h2 className="trackheader">
             View order details
            </h2>
            </div>
          {/* <p className="pera">Add a new address that we can deliver to!</p> */}
          <div className="modelMap">
           
          </div>
          <div className="trackmapstatus">
              <hr style={{width:'53px'}} />
              <div>
              <div className="lesstext" style={{ display: 'flex', flexDirection: 'column', marginRight: 10, width: '100%', overflow: 'auto' }}>
                       {props.activeitem.details?.orderItems?.map((item, i) =>
                                            <div key={i} style={{ display: 'flex', flexDirection: 'column', display: 'flex', justifyContent: 'space-between', marginRight: 30, }}>
                                            <b  >{item.name} X {item.quantity}</b>
                            
                                        </div>
                                        )} </div>
                
                  <p className="trackmodalcontent">Order ID • {props.activeitem.orderId} </p>
              </div>
              <div>
              <div className="main-item-total">
                    <div className="item-total">
                        <p>Item Total</p>
                        <p>{props.activeitem.details?.subTotal}</p>
                    </div>
                    {/* <div className="item-total">
                        <p>Delivery Fee</p>
                        <p>₹53</p>
                    </div> */}
                </div>
                <hr />
                <div className="item-total">
                    <p>Tax & Charges</p>
                    <p>{props.activeitem.details?.tax}</p>
                </div>
            </div>
            <div className="pay-fr">
                <h6>Paid amount</h6>
                <h6>{props.activeitem.details?.total}</h6>
            </div>
             
          </div> 
          {/* <div className="modalHeading-track">
              <h2 className="trackheader">
              Track order here
            </h2>
            <button className="trackhelp-button">help</button>
            </div>
          {/* <p className="pera">Add a new address that we can deliver to!</p> */}
          {/* <div className="modelMap">
            <iframe
              src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387193.305935303!2d-74.25986548248684!3d40.69714941932609!2m3!1f0!2f0!3f0!3m2
                !1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1604749924401!5m2!1sen!2sin`}
              width="100%"
              height="244"
              frameBorder="0"
              allowfullscreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
          <div className="trackmapstatus">
              <hr style={{width:'73px'}} />
              <div className="trackmapdetails">
              <img src={scooterimg} />
              <div>
                  <h2 className="trackmodalheadertext">Order picked up</h2>
                  <p className="trackmodalcontent">Nameer Khan is on his way to deliver your order.</p>
                  <p className="trackmodaltext"><img src={greencircle} style={{marginRight:'10px'}} width="14px" height="14px" />Your food will arrive in 20 minutes</p>
              </div>
              <img src={phoneimg} />
              </div>
             
          </div> */} 
        </Modal.Body>
      </Modal>
    </div>
  )
};

export default TrackMapModal;