import React, { Component } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import oflineicon from "../../images/Group 35260.png"
import arrowleft from "../../images/leftarrow.svg"
import arrowright from "../../images/rightarrow.svg"
import AddCartButton from "../Common/addCartButton/AddCartButtton"
import FeaturedItemCard from "../Common/featuredItemCard/FeaturedItemCard"
class FeaturedItemsCategorywise extends Component {
    constructor(props) {
        super(props)
        this.state = {
            featureitems: null,
            allCatalogues: [],
            moreTagTextChange: false,
            showAllDescription: false,
            charactersLimit: 60,
        }
    }
    componentDidUpdate() {
        if (this.props.allCatalogues !== this.state.allCatalogues) {
            if (!!this.props.allCatalogues?.categories) {
                let cat = this.props.allCatalogues?.categories
                let fullList = []
                for (let i = 0; i < cat?.length; i++) {
                    for (let j = 0; j < cat[i].items.length; j++) {
                        let item = cat[i].items[j]
                        item = { ...item, searchableText: item.name.toLowerCase() }
                        fullList.push(item)
                    }
                    for (let k = 0; k < cat[i].categories.length; k++) {
                        for (let l = 0; l < cat[i].categories[k].items.length; l++) {
                            let subItem = cat[i].categories[k].items[l]
                            subItem = { ...subItem, searchableText: subItem.name.toLowerCase() }
                            fullList.push(subItem)
                        }
                    }
                }
                // const datanew = fullList;
                console.log({ fullList })
                const featureditemslist =
                    fullList &&
                    fullList?.filter((item) => item.tags?.length > 0 && item.tags[0] === "featured")
                this.setState({
                    featureitems: featureditemslist,
                    allCatalogues: this.props.allCatalogues,
                })
            }
        }
    }
    handlePara = (param) => {
        if (param.length > this.state.charactersLimit && this.state.showAllDescription === false) {
            return param.slice(0, this.state.charactersLimit)
        } else {
            return param
        }
    }

    getImageSlides = (props) => {
        const featuredFilterArray = this.state.featureitems?.filter((item) => item.type === "veg")
        const featuredArray = this.props.isVeg ? featuredFilterArray : this.state.featureitems
        return featuredArray && featuredArray?.map((item, i) => <FeaturedItemCard item={item} />)
    }

    render() {
        const settings = {
            dots: true,
            // infinite: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: true,
            prevArrow: <img className="prev-fr" src={arrowleft} />,
            nextArrow: <img className="prev-fr" src={arrowright} width="45px" height="45px" />,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        arrows: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false,
                    },
                },
            ],
        }
        return this.state.featureitems && this.state.featureitems.length > 0 ? (
            <div className="feature-cat">
                <div class="container-fluid">
                    {this.state.featureitems && this.state.featureitems.length > 0 ? (
                        <h2 className="heading-fr">FEATURED ITEMS</h2>
                    ) : null}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowX: "scroll",
                        overflowY: "visible",
                    }}
                >
                    {this.getImageSlides()}
                </div>
                {/* <Slider
            dots={true}
            slidesToShow={3}
            slidesToScroll={1}
            autoplay={true}
            autoplaySpeed={3000}
            arrows={true} className="responsive"
            {...settings}
          >

          </Slider> */}
            </div>
        ) : null
    }
}

export default FeaturedItemsCategorywise
