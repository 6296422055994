import React, { Fragment } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import './App.css'
import { headerTheme } from '../../reducers/user';
import { connect } from 'react-redux';
import { socialLinks } from '../../reducers/homepagecms';
import { requestUserProfile } from '../../actions'
class HeaderFooter extends React.Component {
  componentDidMount() {
    this.props.headerTheme();
    this.props.socialLinks();
    if (this.props.user.isAuthenticated) {
      this.props.requestUserProfile();
    }
  }
  render() {
    return (
      <Fragment>
        <Header navbardetails={this.props.navbardetails} userDetails={this.props.userDetails} />
        {this.props.children}
        <Footer navbardetails={this.props.navbardetails} sociallinks={this.props.sociallinks} />
      </Fragment>
    )
  }
}

const mapState = (state, ownprops) => {
  return {
    navbardetails: state.user.navbardetails,
    sociallinks: state.homepagecms?.sociallinks,
    userDetails: state.user.userDetails?.profile,
    user: state.user
  };
}

const mapDispatch = {
  headerTheme,
  socialLinks,
  requestUserProfile
}


export default connect(mapState, mapDispatch)(HeaderFooter);

