import React, { useState, useEffect } from 'react'
import './SignOut.css'
import { Redirect, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { requestLogout } from '../../../actions'
import SignOutImg from '../../../../src/images/SignOut.svg';

function SignOut() {
    const history = useHistory()
    const user = useSelector(state => state.user)
    const responseLogout = user.responseLogout !== null ? user.responseLogout : null;

    const [navigate, setnavigate] = useState(false)
    const dispatch = useDispatch()

    const handleSignOut = () => {
        setnavigate(false)
        dispatch(requestLogout({ authToken: user.otpResponse?.authToken }))
        if (navigate === true) {

            setTimeout(() => {
                localStorage.clear();
                history.replace('/')
                window.location.reload()
            }, 500);
        }
    }
    useEffect(() => {
        if (!user.logoutProgress) {
            setnavigate(true)
        }

    }, [user.logoutProgress])

    const signoutButton = {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        width: 120,
        height: 40,
        color: 'black',
        borderWidth: 2,
        borderColor: 'black',
        backgroundColor: 'whitesmoke',
    }
    return (
        <div className="signout-whiteBox"  >
            {/* {navigate ? <Redirect to='/' push={true} /> : null} */}
            <text>Do you want to Sign Out?</text>

            <button className="signoutBtn" onClick={() => handleSignOut()}
                style={{
                    backgroundColor: "#553A28",
                    color: "#FFFFFF"
                }}>
                Sign Out
                     {user.logoutProgress ?
                    <div className='spinner-border text-primary' /> :
                    <img src={SignOutImg} color='#FFFFFF' />
                }
            </button>
            {/* <div style={signoutButton} onClick={handleSignOut} >
                Sign Out
            </div> */}
        </div>
    )
}

export default SignOut