import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import homepage from './homepage';
import user from './user';
import cart from './cart';
import orders from './orders'
import checkout from './checkout'
import home from './home';
import payment from './PaymentTwo';
import menu from './menu';
import about from './about';
import terms from './terms';
import homepagecms from './homepagecms'
import merchant from './merchant'

import loyaltystatic from './loyaltystatic'

import loyalty from './loyalty'
import freebie from './freebie'
import coupon from './coupon'
import loyaltyPoints from './loyaltyPoints';
const createPersistedReducer = (key, reducer, whitelist = [], blacklist = []) => {
  const persistConfig = {
    key,
    storage,
    whitelist,
    blacklist
  }
  return persistReducer(persistConfig, reducer);
}

const rootReducer = {
  about,
  cart: createPersistedReducer('cart', cart, ['cartId', 'items']),
  checkout,
  coupon,
  freebie,
  home,
  homepage: createPersistedReducer('homepage', homepage, ['deviceDetails', 'nearestMerchantDetails', 'selectedMerchantServiceability', 'availableModesDetails', 'allCatalogues', 'currentAvailableModes']),
  homepagecms,
  loyalty,
  loyaltyPoints,
  loyaltystatic,
  menu,
  merchant: createPersistedReducer('merchant', merchant, ['merchant']),
  orders: createPersistedReducer('orders', orders, ['orderMode', 'serviceability', 'initialOrderMode']),
  payment,
  terms,
  user: createPersistedReducer('user', user, ['isAuthenticated', 'userLocation', 'userDetails', 'navbardetails', 'otpResponse', 'userAddressChunks', 'temporaryInputFields']),
}

export default combineReducers(rootReducer);