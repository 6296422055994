import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom'

import './ClearCart.css';

import { clearCart, } from '../../../reducers/cart';
import { setSelectedMerchantDetails, setSelectedMerchantServiceability } from '../../../actions';
import { useGetLocation } from '../../../utils/utilities'
import { setMerchantAction } from '../../../reducers/merchant';

function ClearCartModal(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const reduxCart = useSelector(state => state.cart)
    const reduxStates = useSelector(state => state.homepage)
    const reduxUserStates = useSelector(state => state.user)
    const reduxOrderStates = useSelector(state => state.orders)
    const userLocation = useGetLocation()
    const [prompt, setprompt] = useState('')

    const handleClearCart = async () => {
        dispatch(setSelectedMerchantDetails(null)); //whole object
        dispatch(clearCart(reduxCart.cartId))

        // let allMerchants = reduxStates.availableModesDetails.data
        // let selectedMerchant = null
        // for (let i = 0; i < allMerchants.length; i++) {
        //     if (allMerchants[i].id === props.selectedMerchant.id) {
        //         selectedMerchant = allMerchants[i]
        //     }
        // }
        dispatch(setMerchantAction(props.selectedMerchant))
        // dispatch(setSelectedMerchantDetails(selectedMerchant)); //whole object
        dispatch(setSelectedMerchantServiceability(props.selectedMerchant));
        props.tabSelected(1)
        props.setShow(false)
        if (props.selectedMerchant.serviceable) {
            history.push({ pathname: `/order/${props.selectedMerchant.slug}/${reduxOrderStates.orderMode}` })
        }
        else {
            history.push({ pathname: `/order/${props.selectedMerchant.slug}/menu` })
        }
    }
    useEffect(() => {
        setprompt(
            !!reduxCart && reduxCart.items?.length !== 0 ?
                "Your cart contains items from other restaurant. Would you like to reset your cart for adding items from this restaurant?" :
                "Would you like to change the restaurant?"
        )
    }, [])
    return (
        <div style={{ display: 'flex', flexDirection: 'column', position: 'relative', height: 395 }}>
            <div className="modalHeading">Alert</div>
            <p className="clrpara">{prompt}</p>
            <div className="px-2" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', position: 'absolute', bottom: 0 }}>
                <button className="clrPrimaryBtn justify-content-center px-3" onClick={() => handleClearCart()}>
                    {!!reduxCart && reduxCart.items?.length !== 0 ? "Clear Cart" : "Change restaurant"}
                </button>
                <button closeButton className="clrSecondaryBtn justify-content-center px-1" onClick={() => props.tabSelected(1)}>Cancel</button>
            </div>
        </div>
    )
};

export default ClearCartModal