import React, { useEffect, useState, Component } from 'react'
import './app.css'
import { connect } from 'react-redux';
import { Route, Switch, Link, useLocation } from 'react-router-dom'
import { deviceDetect } from 'react-device-detect';
import { useSelector, useDispatch } from 'react-redux';


import Referralhome from './Pages/Home/Components/Referralhome';

import ErrorBoundry from './hocs/ErrorBoundry'
import Home from './Pages/menu'
import About from '../src/Pages/About/Container'
import ContactUs from '../src/Pages/ContactUs/Container'
import OurStory from '../src/Pages/OurStory/Container'
import FranchiseEnquire from '../src/Pages/FranchiseEnquire/Container'
import Location from '../src/Pages/Location/Container'
import Gifting from '../src/Pages/Gifting/Container'
import MyDetails from '../src/Pages/MyDetails/Container'
import TermsAndCondition from '../src/Pages/TermsAndCondition/Container'
import LoyaltyTermsAndCondition from '../src/Pages/loyaltyTermsAndCondition/Container'

import PrivacyAndPolicy from '../src/Pages/PrivacyAndPolicy/Container'
import Cart from '../src/Pages/Cart/Container'
import LocationStore from '../src/Pages/Locations/Container'
import { requestUserLocationPreference, requestUniqueDeviceId, removeItemFromCart, addItemToCart, requestAvailableModes, requestAddressChunksfromCoordinates, setCurrentAvailableModes, requestAllCatalogues, setSelectedMerchantDetails, requestOrderTimeSlots, checkServiceability } from './actions';
import { getCalculateScreenSize } from './utils';
import faq from '../src/Pages/Faqs/faq';
import SettingAccount from './Pages/MyDetails/Components/SettingAccount';
import Settings from './Pages/MyDetails/Components/Settings';
import SettingsManageAddress from './Pages/MyDetails/Components/SettingsManageAddress';
import LocationSwitchModal from './Pages/Common/LocationSwitchModal/index';
import Emptycartpage from './Pages/Cart/Component/Emptycartpage';
import OrderStatus from './Pages/orderStatus/'
import Temp from './Pages/_temporary/Temp'
import { activeCart } from './reducers/cart';
import { getPoints } from './reducers/checkout';
import { getCoupons } from './reducers/checkout';
// import RefferalProgram from './Pages/MyDetails/Components/RefferalProgram';
import RefferalProgram from './Pages/Referral/Referral';

import RewardProgram from './Pages/Home/Components/RewardProgram';
import Notfound from './Pages/Notfound/Notfound';
import RegisterModal from './Pages/Common/RegisterModal/index';
// import GiftingAmount from './Pages/Gifting/Components/GiftingAmount';
// import GiftingInfo from './Pages/Gifting/Components/GiftingInfo';
// import axios from 'axios';
import HomepageNew from './Pages/Home/Components/HomepageNew';
import { referralCodeAction, validateReferral } from './reducers/user';
import { currentOrderModes, useGetLocation } from './utils/utilities';
// import Loyalty from './Pages/loyaltyold/Loyalty'
import loyaltyIntroScreen from './Pages/loyaltyIntroScreen';
import { getAppStoreInfoAction } from './reducers/homepagecms';
import { getFirebaseToken, onMessageListener } from './utils/firebase';

export function App(props) {
  const dispatch = useDispatch();
  const userLocationTwo = useGetLocation()
  const location = useLocation();
  const referralcodeparam = new URLSearchParams(location.search);
  const referralCode = referralcodeparam.get("referralCode");
  // const referralCodenum = referralCode.replace(/^\s+"|\s+$/g, "");
  const merchantRedux = useSelector(state => state.merchant)
  const homepageRedux = useSelector(state => state.homepage)
  const cartRedux = useSelector(state => state.cart)
  const userRedux = useSelector(state => state.user)
  const orderRedux = useSelector(state => state.orders)
  const [isTokenFound, setTokenFound] = useState(false);

  const { deviceDetails } = homepageRedux

  const getDeviceInitRequestOptions = (device) => {
    const size = getCalculateScreenSize(window.screen.width, window.screen.height);
    const requestOptions = {
      deviceUid: 999999999,
      resolution: `${window.screen.width}*${window.screen.height}`,
      screenSize: `${size}cm`,
      deviceModel: device.isMobile ? device.model : 'DELL-G900P',
      OSversion: device.osVersion,
      platform: device.isMobile ? `${device.os}`.toUpperCase() : `${device.osName}`.toUpperCase(),
      deviceType: device.isMobile ? 'MOBILE' : device.isTablet ? 'TABLET' : 'DESKTOP',
    };
    return requestOptions;
  };

  useEffect(() => {
    if (window.gtag && window.GTAG_ID) {
      window.gtag('config', window.GTAG_ID, { 'page_path': location.pathname + location.search });
      window.fbq('track', 'PageView');
    }
  }, [location]);

  useEffect(() => {

    document.getElementById("splashspinner").style.display = "none";
    props.requestUserLocationPreference() //api: /user/location-preference

    async function initialCheck() {

      if (!!deviceDetails?.udid === false) {

        let deviceInitRequestOptions = null

        const device = deviceDetect();
        deviceInitRequestOptions = getDeviceInitRequestOptions(device);

        dispatch(getAppStoreInfoAction())
        let firebaseNotificationToken = await getFirebaseToken(setTokenFound)

        deviceInitRequestOptions.notificationToken = firebaseNotificationToken

        //api: /device/init
        await props.requestUniqueDeviceId(deviceInitRequestOptions,
          result => {
            if (window.clarity) {
              window.clarity('set', 'DeviceId', result.udid)
            }
          });
      }
    }


    initialCheck()

    // setCurrentLocation(); //accurate location
    if (props.user.isAuthenticated) {
      dispatch(activeCart());
      dispatch(getPoints());
      dispatch(getCoupons());
    }
    dispatch(referralCodeAction(referralCode));
    const body = {
      referralCode: referralCode
    }
    dispatch(validateReferral(body));

    onMessageListener().then(payload => {
      // setShow(true);
      // setNotification({ title: payload.notification.title, body: payload.notification.body })
      console.log(payload);
    }).catch(err => console.log('failed: ', err));
  }, []);


  useEffect(() => {
    let location = !!props.user.userLocation?.latitude ?
      props.user.userLocation :
      props.user.userDetails?.locationPreference
    props.requestAvailableModes(location)
    console.log('check from App')
    props.requestAddressChunksfromCoordinates(location, result => { })
  }, [props.user.userLocation, props.user.userDetails?.locationPreference])


  useEffect(() => {
    async function getData() {

      if (!!merchantRedux.merchant?.id) {
        let location = userRedux.userLocation?.latitude ?
          userRedux.userLocation :
          userRedux.userDetails?.locationPreference

        let merchant = merchantRedux.merchant
        let allMerchants = homepageRedux.availableModesDetails?.data
        let selectedMerchant = null

        for (let i = 0; i < allMerchants?.length; i++) {
          if (allMerchants[i].id === merchant.id) {
            selectedMerchant = allMerchants[i]
          }
        }
        dispatch(setSelectedMerchantDetails(selectedMerchant)); //whole object

        dispatch(setCurrentAvailableModes([]))

        dispatch(checkServiceability({ orderMode: orderRedux.initialOrderMode, ...location }))

        dispatch(requestOrderTimeSlots(merchant?.id))


        dispatch(requestAllCatalogues(merchant.id))

      }
    }

    getData()
  }, [merchantRedux.merchant?.id])

  useEffect(() => {
    if (!!cartRedux.cartId) {
      if (window.clarity) {
        window.clarity('set', 'CartId', cartRedux.cartId)
      }
    }
  }, [cartRedux.cartId])

  return (
    <ErrorBoundry>
      <div
        className="main"
        style={{
          top: 0, left: 0, padding: 0, opacity: 1,
          // background: '#FAFBFC 0% 0% no-repeat padding-box',
          backgroundColor: '#FAFBFC'
        }}>
        <Switch>
          <Route exact path={["/"]} component={HomepageNew} />
          <Route exact path="/order-status/:orderId" component={OrderStatus} />
          <Route exact path="/order/:merchantslug/:ordermode" component={Home} />
          <Route exact path="/aboutUs" component={About} />
          <Route exact path="/contactUs" component={ContactUs} />
          <Route exact path="/location" component={Location} />
          <Route exact path="/ourStory" component={OurStory} />
          <Route exact path="/franchiseEnquiry" component={FranchiseEnquire} />
          <Route exact path={["/mydetails", "/mydetails?tab=:currentTab"]} component={MyDetails} />
          <Route exact path="/privacyandpolicy" component={PrivacyAndPolicy} />
          <Route exact path="/termsandcondition" component={TermsAndCondition} />
          <Route exact path="/loyaltytermsandcondition" component={LoyaltyTermsAndCondition} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/emptycart" component={Emptycartpage} />
          <Route exact path="/locationStore" component={LocationStore} />
          {/* <Route exact path="/faq" component={faq} /> */}
          <Route exact path="/SettingAccount" component={SettingAccount} />
          <Route exact path="/Settings" component={Settings} />
          <Route exact path="/SettingsManageAddress" component={SettingsManageAddress} />
          <Route exact path="/LocationSwitchModal" component={LocationSwitchModal} />
          <Route exact path="/RefferalProgram" component={RefferalProgram} />
          <Route exact path="/RewardProgram" component={RewardProgram} />
          <Route exact path="/temp" component={Temp} />
          <Route exact path="/myloyalty" component={loyaltyIntroScreen} />
          <Route exact path="/gifting" component={Gifting} />
          <Link to="/register?referralCode=:referralCode" component={Referralhome} />
          <Route path="*" component={Notfound} /> {/* KEEP IT LAST */}

        </Switch>
      </div>
    </ErrorBoundry>
  )
}

const mapStateToProps = state => ({
  userLocation: state.user.userLocation,
  activeCart: state.cart.activeCart,
  user: state.user,
  referralCode: state.user.referralCode,
  referralData: state.user?.referralData
})
const mapDispatchToProps = {
  requestUniqueDeviceId,
  requestUserLocationPreference,
  removeItemFromCart, addItemToCart,
  activeCart, requestAvailableModes,
  referralCodeAction,
  validateReferral,
  requestAddressChunksfromCoordinates, requestOrderTimeSlots
};
export default connect(mapStateToProps, mapDispatchToProps)(App)
