import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Spinner from 'react-bootstrap/Spinner'
import './CartList.css';
import { Link } from 'react-router-dom';
import { RightArrow } from '../../../images/icons/IconSets'

import { getCart, addCart } from '../../../reducers/cart'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
    backgroundColor: '#fff',
        margin: 10,
        width: '100%'
    },
    gridList: {
        width: 500,
        height: '40vh',
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
}));
function StoreLocation(props) {
    const classes = useStyles();
    const reduxCart = useSelector(state => state.cart)
    const reduxUserStates = useSelector(state => state.user)
    const reduxOrderStates = useSelector(state => state.orders)

    const dispatch = useDispatch()
    const history = useHistory()
    const [isLoading, setisLoading] = useState(false)


    useEffect(() => {
        setisLoading(true)
        setisLoading(false)
    }, [])

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div className="locationhead">Select Store</div>
            <p className="freebiepara">
                Lauren Ipsum
                    </p>

            <div className={classes.root}>
                {
                    reduxCart.items.map((val, i) =>
                        <div>
                            
                            abc
                        </div>
                    )
                }
            </div>

        </div>
    );


}

export default StoreLocation;