import React, { useEffect, useState, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { requestAboutUs, requestAboutUsCountries, requestAboutUsAwards } from '../../../reducers/about';
import './aboutUs.css';
import awardimg1 from '../../../../src/images/Foodservice-awards-recognise-the-very-best.png';
import awardimg2 from '../../../../src/images/UK_Vegan_Food_Awards-770x770.png';
import awardimg3 from '../../../../src/images/2497227_FoodBytes---Peoples-Choice.png';
import awardimg4 from '../../../../src/images/food-award-logo.png';
import awardimg5 from '../../../../src/images/images.png';
import awardimg6 from '../../../../src/images/award.png';
import awardimg7 from '../../../../src/images/masala-food-awards.png';
import awardimg8 from '../../../../src/images/specialty-foods.png';
import awardimg9 from '../../../../src/images/good-nutrition-awards-icon.png';
import locationnew from '../../../../src/images/newlocationmapabout.jpg';
import { RightArrow, LeftArrow } from '../../../images/icons/IconSets'

const awards = [
    {
        id: 1,
        image: awardimg1
    },
    {
        id: 2,
        image: awardimg2
    },
    {
        id: 3,
        image: awardimg3
    },
    {
        id: 4,
        image: awardimg4
    },
    {
        id: 5,
        image: awardimg5
    },
    {
        id: 6,
        image: awardimg6
    },
    {
        id: 7,
        image: awardimg7
    },
    {
        id: 8,
        image: awardimg8
    },
    {
        id: 9,
        image: awardimg9
    }
]
function AboutUs(props) {
    const dispatch = useDispatch()
    const myRef = createRef();
    const [leftBtnHidden, setleftBtnHidden] = useState(true)
    const [rightBtnHidden, setrightBtnHidden] = useState(false)

    useEffect(() => {
        dispatch(requestAboutUs())
        dispatch(requestAboutUsCountries())
        dispatch(requestAboutUsAwards())
    }, [])
    const reduxabout = useSelector(state => state.about?.aboutUs)
    const scroll = (scrollOffset) => {
        myRef.current.scrollLeft += scrollOffset;
    };

    const handleScroll = (e) => {
        const rightend = e.target.scrollWidth - e.target.scrollLeft === e.target.clientWidth;
        if (rightend) {
            setrightBtnHidden(true)
        } else setrightBtnHidden(false)

        if (e.target.scrollLeft === 0) {
            setleftBtnHidden(true)
        }
        else setleftBtnHidden(false)
    }

    return (
        <div>

            <div className="about-welcome">
                <div className="container-fluid">
                    <div className="row content">
                        <div className="col-lg-6 col-md-6 padding-right">
                            <div className="about-welcome-left fadeIn">
                                <h2 className="textabout">Welcome to Biryanis and More</h2>
                                <div className="content" dangerouslySetInnerHTML={{ __html: reduxabout?.aboutUsContent }}></div>
                                {/* <p>{reduxabout?.aboutUsContent}</p> */}
                                {/* <p>Started under the hegemony of Mr. Ramanaidu Suryadevara (Surya), Biryanis and More has several restaurant chains under its umbrella in the United States. Headquartered in Vijayawada, the millennial city of India, Biryanis and More is geared to serve the ever-increasing epicurean, gastronomic populace of India and the overseas customers.</p> */}
                                <h3>COUNTRIES WE ARE IN</h3>
                                <ul>
                                    <li><img src="assets/india.svg" alt="images" />India</li>
                                    <li><img src="assets/canada.svg" alt="images" />Canada</li>
                                    <li><img src="assets/united-kingdom.svg" alt="images" />USA</li>
                                    <li><img src="assets/united-arab-emirates.svg" alt="images" />UAE</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 padding-left">
                            <div className="about-welcome-right">
                                {/* <img src="assets/about-right.jpg" alt="images" className="img-fluid" /> */}
                                <img src={reduxabout?.aboutUsImage} alt="images" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            {/* <div className="about-services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-3">
                            <div className="about-services-main">
                                <img src="assets/our-cuisine.svg" />
                                <h2>OUR cuisine</h2>
                                <p>With over 40 flavour some biryanis, and more menu options</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="about-services-main">
                                <img src="assets/quality.svg" />
                                <h2>Quality & Consistency</h2>
                                <p>Our biryani taste is the same served across all our destinations</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3">
                            <div className="about-services-main">
                                <img src="assets/our-cuisine.svg" />
                                <h2>The ambience</h2>
                                <p>Comfortable with contemporary interiors for a seamless experience</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}



            <div className="about-founder">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="about-founder-left">
                                <img src={reduxabout?.founderImage} alt="images" className="img-fluid" />
                                <div className="about-founder-text">
                                    <h3>{reduxabout?.founderName}</h3>
                                    <h6 className="about-founder-text-p">Founder And Managing Director </h6    >
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="about-founder-right">
                                <h2 className="about-fr">About</h2>
                                <div className="content-fr" dangerouslySetInnerHTML={{ __html: reduxabout?.founderContent }}></div>
                                {/* <p>{reduxabout?.founderContent}</p> */}
                                {/* <p className="founder-text">Mr. Ramanaidu Suryadevara (Surya) had his humble beginning in the food and hospitality industry with a modest restaurant in Dallas, TX over 12 years ago. His keen focus on the quality of food and customer satisfaction has made the businesses under him see an astronomical growth. Having put Biryani and Indian cuisine on a global map, Mr. Surya has positioned himself as a market leader of Indian cuisine not only in USA but also India. The businesses, running under his able leadership are,</p>
                                <div className="founder-description">
                                    <h5>Bawarchi Biryani Point</h5>
                                    <p>With a presence in 20+ cities and an annual turnover of 200+ crores.</p>
                                </div>
                                <div className="founder-description">
                                    <h5>Hot Breads, Texas (USA)</h5>
                                    <p>A unique bakery, and cafe started in South India now has over 5 locations.</p>
                                </div>
                                <div className="founder-description">
                                    <h5>Biryanis and More (India)</h5>
                                    <p className="founder-description-fr">With a presence in 40+ cities and an annual turnover of 300+ crores.</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-welcome-fr">
                <div className="container-fluid">
                    <div className="row content">
                        <div className="col-lg-8 col-md-8 col-sm-6 padding-right">
                            <div className="about-welcome-left fadeIn">
                                <h2>Ourstory</h2>
                                <p className="ourstoryp">Started under the hegemony of Mr. Ramanaidu Suryadevara (Surya), Biryanis and More has several restaurant chains under its umbrella in the United States. Headquartered in Vijayawada, the millennial city of India, Biryanis and More is geared to serve the ever-increasing epicurean, gastronomic populace of India and the overseas customers.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6">

                            <div className="storyImg" style={{}}>
                                {/* <img src="assets/ourStory.png" /> */}
                                <img src="assets/ourstorytwo.png" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="map-locaion" >
                <div className="container">
                    <div className="row">
                        <h2>Our locations</h2>
                        <img src={locationnew} alt="images" className="img-fluid img-location-new" />
                    </div>
                </div>
            </div>



            <div className="our-kitchens">
                <div className="our-food">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="our-kitchens-left">
                                <h2>Our kitchens</h2>
                                <div className="content" dangerouslySetInnerHTML={{ __html: reduxabout?.ourKitchenContent }}></div>
                                {/* <p>{reduxabout?.ourKitchenContent}</p> */}
                                {/* <p>Biryanis and More, a fusion restaurant comprising exotic Indian and Mexican cuisines aspires to serve the growing needs with a specialist blend of authentic and unique food. We have over 40 different types of authentic biryani recipes that has enabled us to create a mark apart from the rest. We have exceptionally high quality standards in our kitchens and ensure that our staff is clean and well-equipped with all the facilities required to make an excellent infrastructure.</p>
                                <p>Our ingredients are hand-picked and we ensure an excellent presentation with extraordinary service that has taken the dining experience to the next level.</p> */}
                                <img src={reduxabout?.ourKitchenImage2} alt="images" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="our-kitchens-right">
                                <img src={reduxabout?.ourKitchenImage1} alt="images" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="container">
                <h2 style={{ textAlign: 'center', marginTop: 51, marginBottom: 51, color: '#553A28' }}>Awards & Recognition</h2>
                <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', alignItems: 'baseline' }}>
                    <div hidden={leftBtnHidden}
                        style={{
                            display: 'flex', float: 'left', position: 'absolute',
                            zIndex: 2, width: 25, height: '100%', alignItems: 'center',
                            background: 'linear-gradient(to left, transparent 0%, #FAFBFC 75%)'
                        }}
                        onClick={() => scroll(-250)}>
                        <div style={{ marginRight: 'auto', }}>
                            <LeftArrow color='#707070' height={25} width={25} />
                        </div>
                    </div>

                    <div className="awardrow" ref={myRef} onScroll={handleScroll}>
                        {awards.map((value, i) =>
                            <img className="awardimages" src={value.image} height="95px" />
                        )}
                    </div>

                    <div hidden={rightBtnHidden}
                        style={{
                            display: 'flex', float: 'right', right: 0, position: 'absolute', zIndex: 2, width: 25, height: '100%', alignItems: 'center',
                            background: 'linear-gradient(to right, transparent 0%, #FAFBFC 75%)',
                        }}
                        onClick={() => scroll(+250)}>
                        <div style={{ marginLeft: 'auto', right: 0 }}>
                            <RightArrow color='#707070' height={25} width={25} />
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );

}

export default AboutUs;