import React, { useState } from 'react';
import { connect } from 'react-redux';
import PhoneInput, { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input'
import { Modal } from 'react-bootstrap';
import OtpModal from '../LoginModal/verifyOtp';
import {
  requestRegister,
  requestRegisterConfirm,
  setPhone,
  setRegisterResponseReset,
  setCustomerData,
} from '../../../actions';
import 'react-phone-number-input/style.css'
import '../LoginModal/loginModel.css';
import '../RegisterModal/Register.css';
import icon from '../../../../src/images/Group_29936.png';

const screenMapping = {
  1: 'details',
  0: 'phone',
};

const Createpage = (props) => {
  const [otpShow, otpSetShow] = useState(false);
  const [otp, setOtp] = useState('');
  const [customerData, setCustomerData] = useState({
    fullname: '',
    email: '',
    phoneNumber: '',
    country: '',
    city: '',
    fbAccessToken: ''
  });

  const { show, setShow, user, homepage, requestRegister, setLoginShow } = props;
  const userDetails = user.registerResponse !== null ? user.registerResponse : null;
  const handleProceed = () => {
    props.setCustomerData(customerData);
    const parametersbody = {

        uuid:  user.registerResponse.uuid,
        fullname: 'sameer',
        email: 'sameer@gmail.com',
        phoneNumber: user.mobileNumber,
        udid: homepage.deviceDetails.udid,
        otp: Number.parseInt(otp),
        gender: 'male',
      };
  
      requestRegisterConfirm(parametersbody, result => { });
      
      setShow(false)
    
  };

  // const handleRegister = () => {
  //   const body = {
  //     uuid: user.registerResponse.uuid,
  //     fullname: user.registrationDetails.name,
  //     email: user.registrationDetails.email,
  //     phoneNumber: user.registrationDetails.phoneNumber,
  //     udid: homepage.deviceDetails.udid,
  //     otp: user.registrationDetails.otp,
  //     gender: user.registrationDetails.gender,
  //   }
  //   props.setCustomerData(customerData);
  //   requestRegisterConfirm(body);
  //   setCurrentScreen(1);
  // };

  React.useEffect(() => {
    if (userDetails !== null && userDetails.success) {
      otpSetShow(true);
    }
  }, [user.registerInProgress, userDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.currentTarget;
    setCustomerData({
      ...customerData,
      [name]: value
    });
  };

  React.useEffect(() => {
    return () => {
      props.setPhone(null);
      props.setRegisterResponseReset();
    }
  }, []);

  return (
    <div>
    <Modal show={show} onHide={() => { setShow(false) }} animation={false}>
        <Modal.Header closeButton />
      <Modal.Body>
          <div className="registerlogo"><img className="iconlogo" src={icon} /></div>
          <div className="registerheader">Create your account</div>
          <p className="pera" style={{textAlign:'center'}}>Please create account to continue with your order</p>
          <div className="ptext">
            <input className="phnnumber" placeholder="Enter your full name" type="text" />
          </div>
          <div className="ptext">
            <input onChange={handleInputChange} className="phnnumber" placeholder="email" type="email" />

          </div>

          <button onClick={handleProceed} className="probtn2 my-2">
            Proceed <img className="proimg" src="assets/rightArrowLight.svg" alt="Arrow" />
          </button>

        </Modal.Body>
      </Modal>
    </div>
  )
};

const mapStateToProps = state => ({
  user: state.user,
  homepage: state.homepage
});

export default connect(mapStateToProps, {
  requestRegister,
  setPhone,
  setRegisterResponseReset,
  setCustomerData,
})(Createpage);
