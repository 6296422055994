import React from 'react'
import PropTypes from 'prop-types'
import cartimg from '../../images/Group 36756.svg';
import ArrowForward from '@mui/icons-material/ArrowForward';

function ViewCartFloater(props) {
  const { count, history } = props || {};
  return (
    <React.Fragment>
      <div className="is-theme view-cart-sticky-drawer is-flex is-justify-content-space-between is-align-items-center  px-4 py-4"
        style={{
          background: '1px center #553A28', bottom: window.innerWidth > 768 ? 20 : 0,
          height: 70, zIndex: 1, justifyContent: 'space-between'
        }}>
        <div className="is-size-4 vc-cart-count-wrap has-text-white m-1">
          <div style={{ display: 'auto', position: 'relative' }}  >
            <img src={cartimg} />
            <div style={{
              display: 'flex', alignItems: 'center', justifyContent: 'center',
              backgroundColor: '#CE4632', right: -5, top: -5,
              width: 20, height: 20, borderRadius: 10,
              position: 'absolute', color: '#FFFFFF'
            }} >
              <text style={{ fontSize: 12, fontWeight: 'bold' }}>{count}</text>
            </div>
          </div>
          <span className="pl-3 is-size-6" style={{ color: '#FFF' }}>Items</span>
        </div>
        <button
          onClick={() => history.push("/cart")}
          className={`button button-fr is-inverted is-outlined is-theme is-uppercase`}
          disabled={count <= 0}
        >
          View Cart
          <span className="icon is-small ml-2">
            <ArrowForward fontSize='default' />
          </span>
        </button>
      </div>
    </React.Fragment>
  )
}

ViewCartFloater.propTypes = {}

export default ViewCartFloater
