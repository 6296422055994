import React from 'react';
import { Modal } from 'react-bootstrap';
import './TimingsModal.css';

const TimingsModal = (props) => {


   return (
      <div>
         <Modal show={props.show} onHide={() => { props.setShow(false) }} animation={false}>
            <Modal.Header closeButton />
            <Modal.Body className='timings-modal-body'>
               <div className="modalHeading">Timings</div>
               <p className="freebiepara">
                  {props.parentData?.name} timings
          </p>
               <div className="timings-item"  >
                  {
                     props.parentData?.timings.map((value, i) =>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 270, marginTop: 5, marginBottom: 10 }}>
                           <div>
                              {value.day}
                           </div>
                           <div>
                              &nbsp;  {value.timing}
                           </div>

                        </div>
                     )
                  }

               </div>
            </Modal.Body>
         </Modal>
      </div>
   )
};
export default TimingsModal;