import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import referarrowimg from '../../../images/referarrowimg.svg';
import RewardsSmall from '../../../images/Rewards-small.png';
import LoginModal from '../../Common/LoginModal';
import './RewardProgram.css';

function RewardProgram(props) {

  const selector = useSelector(state => state.user)
  const history = useHistory()
  const [loginshow, setLoginShow] = useState(false);

  const handlejoinNow = () => {
    if (selector.isAuthenticated) {
      history.push("/myDetails?tab=rewards")
    } else {
      return setLoginShow(true);
    }
  }

  return (
    <div>
      {window.innerWidth > 768 ?
        <div className="reward-root">
          {loginshow && <LoginModal show={loginshow} setShow={setLoginShow} />}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 'auto' }}>
            <h4 className="reward-header" >Join Our</h4>
            <span className="reward-subheader ">Rewards Program!</span>
            <p className="reward-description">Earn a FREE Coke on your first order and other exclusive benefits.</p>
            <button className="reward-btn px-3" onClick={handlejoinNow}>Join Now<img className="reward-btn-image" src={referarrowimg} /></button>
          </div>
          <div style={{ display: 'flex', position: 'relative', top: -50, right: -40 }}>
            <img className="reward-image" src={RewardsSmall} />
          </div>
        </div>
        :
        <div>
          <div className="reward-root">
            {loginshow && <LoginModal show={loginshow} setShow={setLoginShow} />}
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '41px' }}>
              <h4 className="reward-header-mobile" >Join Our Rewards Program!</h4>
              <p className="reward-description-mobile">Earn a FREE Coke on your first order <span className="reward-text" >and other exclusive benefits.</span> </p>
              <button className="reward-btn px-3" onClick={handlejoinNow}>Join Now<img className="reward-btn-image" src={referarrowimg} /></button>
            </div>
            <div style={{ display: 'flex', position: 'relative', top: -50, right: -40 }}>
              <img className="reward-image" src={RewardsSmall} />
            </div>
          </div>

        </div>

      }

    </div>

  )
}

RewardProgram.propTypes = {

}

export default RewardProgram