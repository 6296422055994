import React, { Component, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { setOrderMode, setInitialOrderMode } from '../../../../../reducers/orders';
import { CheckCircle, RightArrow } from '../../../../../images/icons/IconSets';
import { getCart, addCart } from '../../../../../reducers/cart'

import './Panel.css'
import { saveInfo } from "../../../../../reducers/checkout";
function OrderModeSelect(props) {

  const dispatch = useDispatch()
  const reduxOrder = useSelector(state => state.orders?.initialOrderMode)
  const reduxHomepage = useSelector(state => state.homepage)

  const [editEnabled, seteditEnabled] = useState(false)
  const handleEdit = () => {
    seteditEnabled(!editEnabled)
  }

  const optionChanged = value => {
    dispatch(setOrderMode(value.value))
    dispatch(setInitialOrderMode(value.value))
    dispatch(saveInfo({}, result => {
      if (!!result.success) {
        dispatch(getCart())
      }
    }))

    seteditEnabled(false)
    setTimeout(() => {
      props.scrollDown()
    }, 300);
  }

  return !!editEnabled ? (
    <div className="panel-card" style={{ marginBottom: "30px" }}>
      <text
        style={{
          color: "#553A28",
          fontWeight: "bold",
          fontSize: "large",
        }}
      >
        Choose Delivery Mode
      </text>

      <Select
        class="form-control ml-auto"
        onChange={optionChanged}
        options={ reduxHomepage.currentAvailableModes.length > 0 && reduxHomepage.currentAvailableModes
          ?.filter((a) => {
            if (a == null) {
              return false;
            } else if (a.orderMode !== "menu") {
              console.log(a)
              return a;
            }
          })
          .map((item, i) => ({
            label: item.serviceable
              ? item.label
              : item.label + ` (${item.unserviceableReason})`,
            value: item.orderMode,
            isDisabled: !item.serviceable,
          }))}
          defaultValue= {{
            label: reduxHomepage.currentAvailableModes[reduxHomepage.currentAvailableModes.findIndex(item => item && item.orderMode !== reduxOrder)]?.label,
            value: reduxOrder
          }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          color: "#FFF",
          colors: {
            ...theme.colors,
            primary25: "#F8F2E7",
            primary: "#553A20",
          },
        })}
      />
    </div>
  ) : (
    <div
      className="panel-card"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <text
            style={{
              color: "#553A28",
              fontWeight: "bold",
              fontSize: "large",
            }}
          >
            Order mode&nbsp;&nbsp;
          </text>

          <CheckCircle width={20} height={20} color="#28a745" />
        </div>
        <h5 style={{ fontSize: "18px" }}>
          {reduxHomepage.currentAvailableModes.length > 0 &&
            reduxHomepage.currentAvailableModes?.find((item) => {
              if (item == null) {
                return false
              } else if (item.orderMode === reduxOrder ) {
                return item
              }
            })?.label
          }
        </h5>
      </div>

      <button className="changebtn-fr" onClick={handleEdit}>
        change
      </button>
    </div>
  );
}
export default OrderModeSelect