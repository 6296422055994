import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import UserDetails from './UserDetails';
import Orders from './Orders';
// import Orders from '../../orderStatus/index'
import Addresses from './Adresses';
import './userdetails.css';
import './address.css';
import './orders.css';
import './rewards.css';
import './index.css';
import SpinWheel from './SpinWheel';
// import RefferalProgram from './RefferalProgram';
import Settings from './Settings';
import Loyalty from '../../Loyalty/Loyalty';
import Referral from '../../Referral/Referral';
import Gifting from './Gifting'
import SignOut from './SignOut'
import { LeftArrowShort, LeftArrow } from '../../../images/icons/IconSets';
import { getRequestOptionsForExactAddress, removeItemFromCart, addItemToCart, } from '../../../actions';
function MyDetails(props) {
  const history = useHistory()
  const [current, setCurrent] = useState(0);
  const [showButtons, setshowButtons] = useState(true)
  const tabs = { myDetails: 0, rewards: 1, orders: 2, gifting: 3, addresses: 4, referral: 7, signOut: 9 }

  const urlParams = new URLSearchParams(window.location.search);
  const currentTab = urlParams.get('tab')

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (!!currentTab) {
      if (tabs[currentTab] !== current) {
        let data = { id: tabs[currentTab], type: currentTab }
        setCurrent(tabs[currentTab])
      }
    }
  }, [currentTab])

  const handleSideButtonClick = param => {
    console.log({ param })
    setCurrent(param.id);
    history.replace({
      pathname:
        `/myDetails?tab=${param.type}`
    })

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    // window.scrollTo(0, 0)
  }

  const handleMobileButtonClick = param => {
    setshowButtons(false)
    history.replace({
      pathname:
        `/myDetails?tab=${param.type}`
    })
    setCurrent(param.id);
  }

  const sidesContainer = [
    { id: 0, name: 'My Details', type: 'myDetails', body: <UserDetails userDetails={props.userDetails} /> },
    { id: 1, name: 'Rewards', type: 'rewards', body: <Loyalty /> },
    { id: 2, name: 'Orders', type: 'orders', body: <Orders {...props} history={props.history} /> },
    { id: 3, name: 'Gifting', type: 'gifting', body: <Gifting /> },
    { id: 4, name: 'Addresses', type: 'addresses', body: <Addresses {...props} /> },
    // { id: 5, name: 'Saved Cards', body: <div /> },
    // { id: 6, name: 'Spin & Win', body: <SpinWheel /> },
    { id: 7, name: 'Referral Program', type: 'referral', body: <Referral /> },
    // { id: 7, name: 'Referral Program', body: <RefferalProgram /> },
    // { id: 8, name: 'Settings', body: <Settings /> },
    { id: 9, name: 'Sign Out', type: 'signOut', body: <SignOut {...props} /> },
  ];

  const currentSide = sidesContainer.find(i => i.id === current) || sidesContainer[0];

  return (
    <div style={{ display: 'flex', marginTop: 100 }}>
      {
        window.innerWidth > 991 ?
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-3 col-md-12 col-sm-12">
                <div className="accountSidebar">
                  <ul>
                    {sidesContainer.map((item, id) => (
                      <li key={id} onClick={() => handleSideButtonClick(item)}
                        className={current === item.id ? `active` : ''}>
                        <a >
                          <img src={`assets/${item.name.split(' ').join('').split('&').join('')}.svg`} alt="Icon" /> <span>{item.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-lg-9 col-md-12 col-sm-12 mmt sliderightmenu">
                {currentSide.body}
              </div>
            </div>
          </div>
          :
          <div className="container-fluid">

            <div
              style={{ display: 'flex', flexDirection: 'column', width: '95%' }}
            >
              {showButtons ?

                <div className="accountSidebar">
                  <ul>
                    {sidesContainer.map((item, id) => (
                      <li key={id} onClick={() => handleMobileButtonClick(item)}
                        className={current === item.id ? `active` : ''}>
                        <a >
                          <img src={`assets/${item.name.split(' ').join('').split('&').join('')}.svg`} alt="Icon" /> <span>{item.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                :
                <div  >
                  <div style={{
                    display: 'flex', position: 'fixed',
                    top: 75, alignItems: 'center', width: '100%',
                    height: 65, padding: 20, zIndex: 5,
                    backgroundColor: '#FAFBFC',
                    borderBottom: "1.5px solid #E7EAF0"
                  }}
                    onClick={() => { setshowButtons(true); window.scrollTo(0, 0) }}
                  >
                    <LeftArrow color={'#B2BAC3'} height={25} width={25} />
                    &nbsp;Go Back
                  </div>
                  <div style={{ marginTop: 50, alignSelf: 'center' }}>
                    {currentSide.body}
                  </div>

                </div>
              }
            </div>
          </div>
      }
    </div>
  );
}

export default MyDetails;