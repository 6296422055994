import React, { useState, useEffect, useRef } from 'react';
import './couponModal.css';
import {
  Popover,
  Tooltip,
  Modal
} from 'react-bootstrap';
import { } from '../../../reducers/checkout';
import { useSelector, useDispatch } from 'react-redux';
import { getCouponsAction, postApplyCoupon } from '../../../reducers/coupon';
import SuccessPrompt from './SuccessPrompt';
import Snackbar from '../alertSnackbar/snackbar';

export const CouponModal = ({ show, setShow }) => {
  const snackbarRef = useRef();
  const dispatch = useDispatch();

  const [couponCode, setCouponCode] = useState();
  const [couponStatus, setcouponStatus] = useState();
  const [showterms, setShowterms] = useState(false);
  const [inputamount, setinputamount] = useState('')
  const [showSuccess, setshowSuccess] = useState(false)
  const coupon = useSelector(state => state.coupon)
  const cart = useSelector(state => state.cart)

  const { coupons } = coupon || {}

  const {
    cartId,
    currencySymbol,
    cartDiscountType,
    cartDiscountValue,
    cartDiscount,
    discounts,
    cartCoupons,
    cartPoints,
    cartFreebies,
  } = cart || {}

  useEffect(() => {
    dispatch(getCouponsAction())
  }, [])
  const handleInputChange = (e) => {
    setCouponCode(e.target.value)
  }

  const handleApplycoupon = (param) => {
    const args = {}
    if (param === 'userInput') {
      args.couponCode = inputamount
      dispatch(postApplyCoupon(args, result => {
        setcouponStatus(result)
        if (result.success === true) {
          setshowSuccess(true)
        }
        else {
          let data = {
            title: 'Cannot add Coupon.',
            type: 'info',
            description: result.message
          }
          snackbarRef.current.openSnackBar(data);

          setshowSuccess(false)
        }
      }))
    }
    else {
      args.couponCode = param.code
      dispatch(postApplyCoupon(args, result => {
        setcouponStatus(result)
        if (result.success === true) {
          setshowSuccess(true)
        }
        else {
          let data = {
            title: 'Cannot add Coupon.',
            type: 'info',
            description: result.message
          }
          snackbarRef.current.openSnackBar(data);
          setshowSuccess(false)
        }
      }))
    }
  }

  const handleApplycouponnew = (item) => {
    const params = {
      couponCode: item?.freebieItem?.couponCode,
      merchantId: 4,
    }
    dispatch(postApplyCoupon(params))
    setcouponStatus(true)
  }

  const handleTerms = () => {
    setShowterms(true);
  }

  const handleOnChangeText = (e) => {
    const { name, value } = e.currentTarget;
    setinputamount(value)
  }

  return (
    <div>
      <Modal show={show} onHide={() => { setShow(false) }} animation={false}>
        <Modal.Header closeButton />
        <Modal.Body className="couponmodal">
          <Snackbar ref={snackbarRef} />

          {showSuccess ?
            <SuccessPrompt couponStatus={couponStatus}
              setShow={setShow}
            />
            :
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }} >
              <div className="couponhead">Coupons</div>
              <p className="couponpara">View all your available coupons</p>

              <div className='cpn-custom-div'>
                <div className='cpn-input'  >
                  <input
                    style={{
                      background: '#F0F1F2 0% 0% no-repeat padding-box'
                    }}
                    name='amount'
                    defaultValue="₹500"
                    value={inputamount}
                    onChange={(e) => handleOnChangeText(e)}
                  />
                </div>
                <button
                  className="couponbtn"
                  onClick={() => handleApplycoupon('userInput')}
                >
                  Apply
                </button>
              </div>

              {
                cartCoupons?.length === 0 ?
                  <h4>No Coupons Available</h4>
                  :
                  <div className="" style={{ maxHeight: '235px', overflow: 'auto' }}>
                    {!!cartCoupons && cartCoupons?.map((item, i) =>
                      <div className="newcouponbox" style={{ display: 'flex', flexDirection: 'column', background: '#fff', padding: '15px' }} key={i}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <a className="coupona">{item?.code}</a>
                          <button className="couponbtn" style={{ border: 0 }} onClick={() => handleApplycoupon(item)}>Apply</button>
                        </div>

                        <h3 className="couponh">{item.offer}</h3>
                        {!!item?.about &&
                          <p className="couponp"
                            dangerouslySetInnerHTML={{ __html: item?.about }}>
                          </p>}
                        {
                          item?.terms
                            ?
                            <>
                              <p class="couponview" onClick={handleTerms}>
                                <i class="fa fa-plus" aria-hidden="true"></i>VIEW T&C</p>
                              <div className="termscouponnew" dangerouslySetInnerHTML={{ __html: item?.terms }}>
                              </div>
                            </>
                            : null
                        }
                      </div>
                    )}
                  </div>
              }
            </div>
          }
        </Modal.Body>
      </Modal>
    </div>
  )
};