import config from '../config';

export const getHomepageService = {
  getAvailableOrderModes,
  getAllCatalogues,
  deviceInit,
};

export function getRequestOptions() {
  return {
    method: 'POST',
    headers: {
      'Accept': 'application/json'
    }
  };
}

async function deviceInit(params = {}) {
  const requestOptions = getRequestOptions();
  const response = await fetch(`${config.apiUrl}/device/init?deviceUid=${params.deviceUid}&screenSize=${params.screenSize}&resolution=${params.resolution}&deviceModel=${params.deviceModel}&OSVersion=${params.OSversion}&merchantAccessToken=${config.merchantAccessToken}&deviceType=${params.deviceType}&platform=${params.platform}&notificationToken=${params.notificationToken}`, requestOptions)
  if (response.status !== 201) {
    return Promise.reject('Unable to fetch');
  } else {
    return response.json();
  }
}

async function getAvailableOrderModes(params = {}) {
  /*
  https://www.froogal.in/dedicated-api/merchants?merchantAccessToken=qgxYq80Z72BcH0GluKM05J5f8m0RBEYP2FyrHx1E&latitude=32.8140177&longitude=-96.9488945
   */
  const requestOptions = getRequestOptions();
  const response = await fetch(`${config.apiUrl}/merchants?merchantAccessToken=${config.merchantAccessToken}&latitude=${params?.latitude}&longitude=${params?.longitude}`, requestOptions);
  if (response.status !== 200) {
    Promise.reject(response);
  } else {
    return response.json();
  }
}

async function getAllCatalogues(id = '') {
  const requestOptions = getRequestOptions();
  const response = await fetch(`${config.apiUrl}/merchants/catalogue?merchantAccessToken=${config.merchantAccessToken}&merchantId=${id}`, requestOptions);
  if (response.status !== 200) {
    Promise.reject(response);
  } else {
    return response.json();
  }
}

