import React, { useEffect } from 'react';
import './SpinWheel.css';
import './Settings.css';
import './address.css';
import { Link } from 'react-router-dom';
export default function Settings() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const handleRateOrder = () => {

    };

    const handleReorder = () => {

    };

    return (
        <div className="settingsmain">
            <div className="settingbody">
                <div className='settingheader'>
                    <h3 className="settinghead"><Link to="/mydetails"><i class="fa fa-arrow-circle-left" aria-hidden="true"></i></Link> Settings</h3>
                </div>
                <div className="settingrow">
                    <div className="delivery-address">
                        <div className="home-img">
                            <img src="assets/user.png" alt="" />
                        </div>
                        <div className="delivery-text">
                            <h4>Mr Rashid Ansari <a className="editprf">EDIT</a></h4>
                            <p>+91 8886809898 • Rashid@getnoor.com</p>
                        </div>
                    </div>
                    <div>
                        <div className="delivery-text">
                            <h4 className="myaccnt">My Account <a className="editi" href=""><Link to="/SettingAccount"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></Link></a></h4>
                            <p className="myp">Addresses, Payments, Offers & Social</p>
                        </div>
                        <div className="delivery-text">
                            <h4 className="myaccnt">Help <a className="editi"><i class="fa fa-arrow-circle-right" aria-hidden="true"></i></a></h4>
                            <p className="myp">Legal, FAQ’s & General Issues</p>
                        </div>
                    </div>

                </div>
                <div className="whiteBox">
                    <div className="orderwhite">
                        <div className="myAccedit">Past orders</div>
                        <div className="orderBox">
                            <div className="orderDetails">
                                <h6>Biryanis and More Gachibowli</h6>
                                <div className="orderItems"><div>4 Items</div> <div className="orderPrice">₹1499</div> <img src="assets/checked.svg" alt="" /> <span>Delivered</span></div>
                                <div className="orderQty">Chicken Biryani x1, Chicken Tandoori x1 </div>
                                <div>8 May, 2020 at 9:30 PM</div>
                            </div>
                            <div className="orderBtns">
                                <button onClick={handleReorder} className="lightBtn">reorder</button>
                                <button onClick={handleRateOrder} className="darkBtn">rate order</button>
                            </div>
                        </div>
                        <div className="orderBox">
                            <div className="orderDetails">
                                <h6>Biryanis and More Gachibowli</h6>
                                <div className="orderItems"><div>4 Items</div> <div className="orderPrice">₹650</div> <img src="assets/checked.svg" alt="" /> <span>Delivered</span></div>
                                <div className="orderQty">Manchurian x1, Oreo Thickshake x1 </div>
                                <div> 6 June, 2020 at 11:05 AM</div>
                            </div>
                            <div className="orderBtns">
                                <button onClick={handleReorder} className="lightBtn">reorder</button>
                                <button onClick={handleRateOrder} className="darkBtn">rate order</button>
                            </div>
                        </div>
                        <div className="orderBox">
                            <div className="orderDetails">
                                <h6>Biryanis and More Gachibowli</h6>
                                <div className="orderItems"><div>4 Items</div> <div className="orderPrice">₹650</div> <img src="assets/checked.svg" alt="" /> <span>Delivered</span></div>
                                <div className="orderQty">Manchurian x1, Oreo Thickshake x1 </div>
                                <div> 6 June, 2020 at 11:05 AM</div>
                            </div>
                            <div className="orderBtns">
                                <button onClick={handleReorder} className="lightBtn">reorder</button>
                                <button onClick={handleRateOrder} className="darkBtn">rate order</button>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
