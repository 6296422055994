import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PhoneInput, { formatPhoneNumberIntl, formatPhoneNumber } from 'react-phone-number-input'
import { Modal } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'
import OtpModal from '../LoginModal/verifyOtp';
import { getAddressChunkFromAddress, getAddressFromCoordinates } from '../../../utils/index'

import {
  requestRegister,
  requestRegisterConfirm,
  setPhone,
  setCustomerData, setTemporaryInputFields, setOtpResponseReset, setRegisterResponseReset
} from '../../../actions';
import 'react-phone-number-input/style.css'
import '../LoginModal/loginModel.css';
import './Register.css';
import icon from '../../../../src/images/Group_29936.png';
import rightArrowLight from '../../../images/icons/rightArrowLight.svg'

function Register(props) {
  const user = useSelector(state => state.user)
  const registerResponse = user.registerResponse !== null ? user.registerResponse : null;

  const homepage = useSelector(state => state.homepage)
  const dispatch = useDispatch()
  const [defaultCountry, setdefaultCountry] = useState()
  const [phone, setPhone] = useState('');
  const [apiSuccess, setapiSuccess] = useState(false)
  const handleProceed = () => {
    dispatch(setTemporaryInputFields({ phone: phone }))
    const countryCode = phone && formatPhoneNumberIntl(phone).split(' ')[0];
    const formattedPhone = phone && formatPhoneNumber(phone).slice(1).split(' ').join('');
    const body = {
      phoneNumber: formattedPhone,
      countryCode,
      udid: homepage.deviceDetails.udid,
      referralCode: props.referralCode
    };
    props.setPhone(formattedPhone);
    props.registerParams(body)
    dispatch(requestRegister(body));
  };

  useEffect(() => {
    setPhone(user.temporaryInputFields?.phone)
  }, [])

  useEffect(() => {
    if (user.registerStepCompleted === 1) {
      props.setCurrentScreen(1)
    } else {
      props.setCurrentScreen(0)
    }
  }, [user.registerStepCompleted]);

  const handleSwitchToLogin = () => {
    dispatch(setOtpResponseReset())
    dispatch(setRegisterResponseReset())
    props.setShow(false);
    props.setLoginShow(true);

  };

  useEffect(() => {
    let currentAddress = user.userLocation ? user.userLocation : user.userDetails?.locationPreference

    async function abc() {

      let a = await getAddressFromCoordinates(currentAddress)

      setdefaultCountry(a?.country_id)
    }
    if (!!user.userAddressChunks) {
      setdefaultCountry(user.userAddressChunks?.country_id)
    }
    else
      abc()
  }, [user.userLocation, user.userDetails])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="registerheader">Create new account!</div>
      {
        user.socialLogin
          &&
        (user.socialLogin?.facebookData
          ?
          <div style={{ display: 'flex', alignSelf: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <Image src={user.socialLogin?.facebookData?.imageUrl} alt="fb" roundedCircle />
            <text>&nbsp;&nbsp;Hey {user.socialLogin?.facebookData?.name}</text>
          </div>
          :
          <div style={{ display: 'flex', alignSelf: 'center', flexDirection: 'row', alignItems: 'center' }}>
            <Image src={user.socialLogin?.googleData?.imageUrl} height={50} width={50} alt="" roundedCircle />
            <text>&nbsp;&nbsp;Hey {user.socialLogin?.googleData?.name}</text>
          </div>)
      }

      {
        user.socialLogin
          ?
          <p className="pera">Please enter phone number to continue with your account</p>
          :
          props.referralCode !== null ?
            <p className="pera">You have been referred by {props.referralData?.referrerName}, Please create account to get more benefits</p>
            :
            <p className="pera">Please create account to continue with your order</p>
      }


      <div className="d-flex">
        <PhoneInput
          placeholder="Enter phone number"
          value={phone}
          onChange={setPhone}
          limitMaxLength={true}
          defaultCountry={defaultCountry}
          className='countryFlag'
        />
      </div>
      <div className='my-2 text-danger'>
        {(props.user.registerResponse !== null && !props.user.registerResponse.success) && props.user.registerResponse.message}
      </div>
      <button onClick={handleProceed} className="modalRegBtn">
        Proceed {user.registerInProgress ? <div className='spinner-border text-white' /> :
          <img src={rightArrowLight} alt="Arrow" />}
      </button>
      <div className="haveAcc">  Already a member ? <a onClick={handleSwitchToLogin} className='loginbtn'  >Log In</a> </div>

    </div>
  )
}

export default Register
