import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import './footer.css';
import 'font-awesome/css/font-awesome.min.css';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import appnewimg from '../../../images/appnewstore.svg';
import footerfb from '../../../images/footerfb.svg';
import footerinsta from '../../../images/Footer - Instagram Icon (1).svg';
import footerYoutube from '../../../images/Footer -  Youtube.svg';
import version from '../../../../package.json';
import LoginModal from "../../Common/LoginModal";
import RegisterModal from "../../Common/RegisterModal";

function Footer(props) {
  const history = useHistory()
  const [loginshow, setLoginShow] = useState(false);
  const [registerShow, setRegisterShow] = useState(false);
  const reduxUser = useSelector(state => state.user)

  const stObj = {
    paddingLeft: "0"
  }
  const { navbardetails, sociallinks } = props;

  const handleLink = (args) => {
    if (!!props.user.isAuthenticated) {
      history.push({ pathname: `/${args}` })

    }
    else {
      handleLoginShow(true)
    }
  }

  const handleLoginShow = (param) => {
    setLoginShow(param)
  }
  const handleRegisterShow = (param) => {
    setRegisterShow(param)
  }
  return (
    <div className="footer" style={{ marginTop: '51px' }}>
      {loginshow && <LoginModal show={loginshow} setShow={handleLoginShow} setRegisterShow={handleRegisterShow} />}
      {registerShow && <RegisterModal show={registerShow} setShow={handleRegisterShow} setLoginShow={handleLoginShow} />}
      <div className="footerContainer">
        <div className="footerCol">
          <h3>Company</h3>
          <ul>
            <li>
              <a href="/aboutUs">About</a>
            </li>
            {/* <li>
							<a href="/ourStory">Story</a>
						</li> */}
            <li>
              <a href="/franchiseEnquiry">Franchise Enquiry</a>
            </li>
          </ul>
        </div>
        <div className="footerCol">
          <h3>Help</h3>
          <ul>
            <li>
              <a href="/contactUs">Contact Us</a>
            </li>
            {/* <li>
							<a href="">FAQS</a>
						</li> */}
          </ul>
        </div>
        <div className="footerCol">
          <h3>Trust</h3>
          <ul>
            <li>
              <a href="/privacyandpolicy">Privacy</a>
            </li>
            <li>
              <a href="termsandcondition">Terms</a>
            </li>
            {reduxUser.userAddressChunks?.country_id === 'IN' ?
              null
              :

              <li>
                <a href="loyaltytermsandcondition">Loyalty Program T&C</a>
              </li>
            }

          </ul>
        </div>
        <div className="footerCol">
          <h3>Community</h3>
          <ul>
            <li>
              <a onClick={() => handleLink('RefferalProgram')} >Referral Program</a>
            </li>
            <li>
              <a onClick={() => history.push({ pathname: `/gifting` })}>Gifting</a>
            </li>
            <li>
              <a onClick={() => history.push({ pathname: `/myLoyalty` })}>Rewards</a>
            </li>
          </ul>
        </div>
        <div className="footerCol footer-fr">
          <div class="d-flex-fr">
            <h5 className="appnewfollow">
              Follow us
            </h5>
            {sociallinks.map((item, i) =>
              <a href={item.link} class="text-muted" key={i}>
                {i==0 ? <img src={footerfb} /> : i==1 ? <img src={footerinsta} />:<img src={footerYoutube} style={{ marginLeft: '12px'}}/>}
              </a>
            )}

            {/* <a href={sociallinks?.link} class="text-muted"><img src={footerfb} /></a>
						<a href={sociallinks.url}  class="text-muted"><i class="fa fa-instagram"></i></a> */}
          </div>
          {/* <div className="appnewfooter">
						<img  src={appnewimg} alt="" />
						<img src="assets/g.png"  alt="" />

					</div> */}
        </div>
      </div>
      <div className="copyRight">
        <p>Version {version.version}</p>
        <p>{navbardetails.footerCopyright}</p>
        <div>
          <span style={{ font: 'normal normal normal 14px/16px Lato' }} >Powered by
          </span>
          <a target="_blank" href="https://www.froogal.ai/">
            <img className="image froogal-logo" src="https://www.froogal.in/assets/images/froogal-purple-250w.png" alt="freebies" height="35px" style={{ marginLeft: '10px' }} /></a>
        </div>


      </div>
    </div>

  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
    homepage: state.homepage,
    navbardetails: state.user.navbardetails,
    allCatalogues: state.homepage.allCatalogues,
    cartItems: state.cart.items,
    sociallinks: state.homepagecms.sociallinks
  }
};

export default connect(mapStateToProps)(Footer);
