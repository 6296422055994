import React, { Component, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import { setAnchorAction } from "../../../reducers/homepagecms";
import { Link, useHistory } from 'react-router-dom';
import emptyimg from '../../../images/Group 8154.svg';
import Layout from '../../Layout/index';
import './emptycartpage.css';
function Emptycartpage(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const handleOrderNow = () => {
        dispatch(setAnchorAction(true))
        history.replace({ pathname: '/' })
      };
    const handleemptybtn = () => {
        if(true) {
            history.push('/')
        }
    }
    const reduxuser = useSelector(state => state.user.userDetails?.profile?.profile?.name)
    return (
        <div>
            <Layout>
            <div className="emptycatmain" style={{marginTop:'103px',textAlign:'center'}}>
            <img className="emptycartpageimg" src={emptyimg} />
            <h2 className="emptyheader"> "Hi {reduxuser}"</h2>
            <p className="emptycartpagetext"> You dont have any active orders. Start Order Now!</p>
            <button className="emptycartpagebtn" onClick={handleOrderNow}>Order Now </button>
            </div>
            </Layout>
        </div>
       
    )



}

export default Emptycartpage;