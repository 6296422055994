import React from 'react';
import './worngModel.css';
import { 
  Popover,
  Tooltip,
  Modal
} from 'react-bootstrap';
import { NavLink, Redirect } from 'react-router-dom';

const SomethingWentWrongModal = ({ show, setShow }) => {
  return (
    <div>
      <Modal className="successModel" show={show}
              onHide={() => { setShow(show) }}
        animation={false}>
      <Modal.Header closeButton/>
          <Modal.Body>
        <div className="modalErrors worng">
          <img src="assets/wrongIcon.svg" alt="Wrong" />
            <div className="errorHeading">Payment Failed!</div>
            <div className="errorText">Your payment is failed, please try again..</div>
            {/* <Redirect exact activeClassName='active' to='/' onClick={() => { setShow(false); }} >
            <p>GO BACK TO Home</p>
            </Redirect> */}
        </div>
      </Modal.Body>
      </Modal>
    </div>
  )
};
export default SomethingWentWrongModal