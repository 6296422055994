import config from '../config';
import { getRequestOptions } from './homepage.service';

export const userDetailsServices = {
    getOrderTransactionHistory,
    addNewAdress,
    getNewAddress,
    getUserProfile,
    getUserLocationPreference,
    getUserUpatedProfile,
    getAddressChunksFromCoordinates,
    deleteAddress
};

const getAuthToken = () => localStorage.getItem('auth_token');
// const authToken = 'QoRMnFA4IqPo6YJxdgCRlO0eRhuxW1Kh0zwIS4qrLb7315FYkcRoZbzJhpyC';

async function getOrderTransactionHistory() {
    const requestOptions = getRequestOptions();
    const authToken = getAuthToken();

    const response = await fetch(`${config.apiUrl}/transaction-history?authToken=${authToken}&merchantAccessToken=${config.merchantAccessToken}`, requestOptions);

    if (response.status !== 200) {
        Promise.reject('Unable to fetch data');
    }
    return response.json();
}

async function addNewAdress(params = {}) {
    const requestOptions = getRequestOptions();
    const authToken = getAuthToken();

    let paramArray = [];

    if (params.address1) {
        paramArray.push(`address1=${params.address1}`);
    }
    if (params.address2) {
        paramArray.push(`address2=${params.address2}`);
    }
    if (params.landmark) {
        paramArray.push(`landmark=${params.landmark}`);
    }
    if (params.city) {
        paramArray.push(`city=${params.city}`);
    }
    if (params.country) {
        paramArray.push(`country=${params.country}`);
    }
    if (params.pincode) {
        paramArray.push(`pincode=${params.pincode}`);
    }
    if (params.state) {
        paramArray.push(`state=${params.state}`);
    }
    if (params.locality) {
        paramArray.push(`locality=${params.locality}`);
    }

    if (params.tag) {
        paramArray.push(`tag=${params.tag}`);
    }
    if (params.latitude) {
        paramArray.push(`latitude=${params.latitude}`);
    }
    if (params.longitude) {
        paramArray.push(`longitude=${params.longitude}`);
    }
    if (params.tagName) {
        paramArray.push(`tagName=${params.tagName}`);
    }
    if (authToken) {
        paramArray.push(`authToken=${authToken}`);
    }
    if (config.merchantAccessToken) {
        paramArray.push(`merchantAccessToken=${config.merchantAccessToken}`);
    }

    const response = await fetch(`${config.apiUrl}/user/address/create?${paramArray.join('&')}`, requestOptions);
    if (response.status !== 200) {
        Promise.reject('Unable to create address');
    }
    return response.json();
}

async function deleteAddress(params = {}) {
    const requestOptions = getRequestOptions();
    const authToken = getAuthToken();
    let paramArray = [];

    if (params.id) {
        paramArray.push(`id=${params.id}`);
    }
    if (params.addressId) {
        paramArray.push(`addressId=${params.addressId}`);
    }
    if (authToken) {
        paramArray.push(`authToken=${authToken}`);
    }
    if (config.merchantAccessToken) {
        paramArray.push(`merchantAccessToken=${config.merchantAccessToken}`);
    }

    const response = await fetch(`${config.apiUrl}/user/address/delete?${paramArray.join('&')}`, requestOptions);
    if (response.status !== 200) {
        Promise.reject('Unable to remove address');
    }
    return response.json();
}

async function getNewAddress(params = {}) {
    const authToken = getAuthToken();
    const requestOptions = getRequestOptions();
    let paramArray = [];

    if (params.addressId) {
        paramArray.push(`addressId=${params.addressId}`);
    }

    if (params.latitude) {
        paramArray.push(`latitude=${params.latitude}`);
    }

    if (params.longitude) {
        paramArray.push(`longitude=${params.longitude}`);
    }

    if (authToken) {
        paramArray.push(`authToken=${authToken}`);
    }

    if (config.merchantAccessToken) {
        paramArray.push(`merchantAccessToken=${config.merchantAccessToken}`);
    }

    const response = await fetch(`${config.apiUrl}/user/address?${paramArray.join('&')}`, requestOptions);

    if (response.status !== 200) {
        Promise.reject('Unable to fetch user address');
    }
    return response.json();
}

async function getUserProfile() {
    const requestOptions = getRequestOptions();
    const authToken = getAuthToken();

    // if (authToken) {
    //   paramArray.push(`authToken=${authToken}`);
    // }
    // if (config.merchantAccessToken) {
    //   paramArray.push(`merchantAccessToken=${config.merchantAccessToken}`);
    // }

    const response = await fetch(`${config.apiUrl}/profile?authToken=${authToken}&merchantAccessToken=${config.merchantAccessToken}`, requestOptions);

    if (response.status !== 200) {
        Promise.reject('unable to fetch user profile');
    }
    return response.json();
}


async function getUserUpatedProfile(params = {}) {
    const requestOptions = getRequestOptions();
    const authToken = getAuthToken();

    // if (authToken) {
    //   paramArray.push(`authToken=${authToken}`);
    // }
    // if (config.merchantAccessToken) {
    //   paramArray.push(`merchantAccessToken=${config.merchantAccessToken}`);
    // }

    const response = await fetch(`${config.apiUrl}/profile/update?authToken=${authToken}&merchantAccessToken=${config.merchantAccessToken}&name=${params.name}&birthday=${params.birthday}&anniversaryDate=${params.anniversaryDate}&gender=${params.gender}`, requestOptions);

    if (response.status !== 200) {
        Promise.reject('unable to fetch user profile');
    }
    return response.json();
}



async function getUserLocationPreference(params) {

    let requestOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json'
        }
    };
    const response = await fetch(`${config.apiUrl}/user/location-preference?merchantAccessToken=${config.merchantAccessToken}&merchantId=${params}`, requestOptions);

    if (response.status !== 200) {
        Promise.reject(response);
    } else {
        return response.json();
    }
}


async function getAddressChunksFromCoordinates(params = {}) {
    let response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${params?.latitude},${params?.longitude}&key=${config.googleMapApiKey}`)
    response = await response.json()
    if (response.status === 'OK') {

        const address_components = response?.results[1];
        let components = {};
        address_components.address_components?.map((value, index) => {
            value.types.map((value2, index2) => {
                components[value2] = value.long_name;
                if (value2 === 'country')
                    components.country_id = value.short_name;
                if (value2 === 'administrative_area_level_1')
                    components.state_code = value.short_name;
            })
        })
        components = {
            ...components, formatted_address: address_components.formatted_address
        }

        return components

    }
}