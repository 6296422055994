import React, { Component, useState, useEffect } from "react";
import biryani67 from '../../../../../../src/images/Biryanis-067.png';
import "../../custom.css";
import { connect, useSelector } from 'react-redux';
import AddButton from './AddButtton';
import { RightArrow } from '../../../../../images/icons/IconSets'
class CartItem extends Component {
  render() {
    const buttonStyle = {
      height: '2px',
      width: '19px',
    };
    const buttonStyle1 = {
      height: "10px",
      width: "19px",
    }

    const { item, addToCart, allItems } = this.props;
    let itemImage = allItems?.find(a => a.id === item.itemId)
    return (
      <div style={{
        borderBottom: "1px solid #F5F5F5", borderBottomColor: '#F5F5F5',
        padding: '15px 0px 15px 0px'
      }}>
        <div style={{ position: "relative" }}>
          <div style={{ display: 'flex', paddingRight: 100 }}>
            {
              !!itemImage?.image &&
              <img style={{ borderRadius: 6, borderWidth: 0, objectFit: 'cover', width: 75, height: 67, marginRight: '10px' }}
                src={itemImage?.image}
              />
            }

            <div style={{
              display: 'flex', flexDirection: 'column',
              padding: 5
            }} >
              <text style={{ fontSize: 15, color: '#553A28' }}>{item.name}</text>
              <text style={{ fontSize: 16, }}>{this.props.homepage.allCatalogues?.currency}{item.subTotal}</text>
            </div>
          </div>

          {item.choices?.length > 0 &&
            <div style={{
              display: 'flex', flexDirection: 'row', alignItems: 'flex-start',
              fontSize: 14, fontWeight: 'bold', marginLeft: 15
            }}>
              <text>*Choices:&nbsp;</text>
              {this.props.item.choices?.map((item, i) =>
                <text
                  key={i}
                  style={{ color: '#5CB346', }}>
                  {item.name}&nbsp;
                </text>
              )}
            </div>}


          <div style={{ position: "absolute", right: 0, top: 0 }} >
            <AddButton {...this.props} />

          </div>
        </div>
        {/* <p className="lore">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore.</p> */}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  homepage: state.homepage
});

export default connect(mapStateToProps)(CartItem);