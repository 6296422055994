import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux'
import locationicon from '../../../../src/images/Group 30275.png';
import './index.css';
import LocationSearch from './LocationSearch'

import YourLocation from './YourLocation';
import StoreLocation from './StoreLocation';
import ClearCartModal from './ClearCart'

export default function LocationModal(props) {
  const { show, setShow } = props;
  const [tabSelected, settabSelected] = useState(0)
  const [selectedMerchant, setSelectedMerchant] = useState()
  const handleShow = (param) => {
    setShow(param)
  }

  const navigateModal = (param) => {
    settabSelected(param)
  }

  const handleOnHide = () => {
    settabSelected(props.navigateModal)
    handleShow(false)
  }

  useEffect(() => {
    props.navigateModal ? settabSelected(props.navigateModal) : settabSelected(0)
  }, [])

  const handleSelectMerchant = (param) => {
    setSelectedMerchant(param)
  }

  return (
    <Modal show={show} onHide={handleOnHide} animation={false} >
      <Modal.Header closeButton > { }</Modal.Header>

      <Modal.Body className="locationmodal-fr" style={{
        minHeight: 450,
        maxHeight: 700,
        flexDirection: 'column',
        justifyContent: 'center',
        top: 0
      }}>

        <div>
          {
            tabSelected === 0
              ?
              <YourLocation {...props}
                setShow={handleShow}
                tabSelected={navigateModal} />
              : tabSelected === 1 ?
                <StoreLocation {...props}
                  setShow={handleShow}
                  tabSelected={navigateModal}
                  handleSelectMerchant={handleSelectMerchant}
                />
                : tabSelected === 2 ?
                  <ClearCartModal {...props}
                    setShow={handleShow}
                    tabSelected={navigateModal}
                    selectedMerchant={selectedMerchant}
                  />
                  : null
          }
        </div>

      </Modal.Body>
    </Modal>
  )
}
