import React, { Component, useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { CheckCircle, RightArrow } from '../../../../../images/icons/IconSets';
import Select from 'react-select';
import { setTemporaryInputFields } from '../../../../../actions/setState'
import { saveInfo, selectDateTime } from '../../../../../reducers/checkout'
import CartAlertModal from '../../CartAlertModal'

import './Panel.css'
import { getCart } from "../../../../../reducers/cart";
function TakeawayMode(props) {
  const dispatch = useDispatch()
  const reduxmerchant = useSelector(state => state.homepage?.nearestMerchantDetails?.name)
  const availableTimeSlots = useSelector(state => state.user?.userDetails?.orderTimeSlots?.availableTimeSlots)
  const timeslots = availableTimeSlots != null && availableTimeSlots != undefined && availableTimeSlots[Object.keys(availableTimeSlots)[0]];
  const dates = Object.keys(timeslots);
  const [date, setdate] = useState({ label: dates[0], value: dates[0] });
  const times = timeslots[date.value] || []
  const [time, settime] = useState({ label: times[0], value: times[0] }) //defalut time selction
  const [timeshow, settimeahow] = useState(false)
  const [alertShow, setalertShow] = useState({ status: false, message: 'Please select Address' })
  const datechange = value => {
    setdate(value)
    // dispatch(setTemporaryInputFields({takeawayDatetime:}))
  }
  const timechange = value => {
    settime(value)
  }
  const pickupAt = date.value + time.value;
  const handletimeshow = () => {
    let isValid = validations()
    if (isValid) {
      settimeahow(true)
      dispatch(selectDateTime(pickupAt))
      dispatch(setTemporaryInputFields({ pickup: pickupAt }))
      dispatch(saveInfo({}, result => {
        if (!!result.success) {
          dispatch(getCart())
        }
      }))
    }

  }
  const handlepreviousaddress = () => {
    settimeahow(false)
  }

  const validations = () => {
    if (time.length === 0) {
      setalertShow({ status: true, message: 'Please select time' })
      // alert("Please select ")
      return false
    }
    return (true);
  }
  return (
    <div className="panel-card" >
      {alertShow.status && <CartAlertModal show={alertShow} setShow={setalertShow} />}



      {
        timeshow === false ?
          <div>
            <h2 className="arivaltime">Choose Arrival Time</h2>
            <div className="dropdowndatetime">

              {window.innerWidth > 768 ?
                <div>
                  <FormControl className="tiemsot" style={{ marginRight: '10px', width: '200px' }}>
                    <InputLabel htmlFor="grouped-native-select"></InputLabel>
                    <Select onChange={datechange} id="grouped-native-select" options={dates?.map((v, i) => ({ label: v, value: v }))}
                      defaultValue={{ label: dates[0], value: dates[0] }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 4,
                        color: '#FFF',
                        colors: {
                          ...theme.colors,
                          primary25: ' rgb(77 58 33 / 70%)',
                          primary: '#553A20'
                        },
                      })}
                    />
                  </FormControl>
                  <FormControl className="tiemsot" style={{ marginRight: '10px', width: '200px' }}>
                    <InputLabel htmlFor="grouped-native-select"></InputLabel>
                    <Select id="grouped-native-select" onChange={timechange} options={times.map((v, i) => ({ label: v, value: v }))}
                      defaultValue={{ label: times?.[0], value: times?.[0] }}
                      theme={theme => ({
                        ...theme,
                        borderRadius: 4,
                        color: '#FFF',
                        colors: {
                          ...theme.colors,
                          primary25: ' rgb(77 58 33 / 70%)',
                          primary: '#553A20'
                        },
                      })}
                    />
                  </FormControl>
                  <button onClick={handletimeshow} className="takeaway-btn">submit</button>
                </div>
                :
                <div>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: '10px' }}>
                    <FormControl className="tiemsot" style={{ marginRight: '10px', width: '150px', marginBottom: '20px' }}>
                      <InputLabel htmlFor="grouped-native-select"></InputLabel>
                      <Select onChange={datechange} id="grouped-native-select" options={dates?.map((v, i) => ({ label: v, value: v }))}
                        defaultValue={{ label: dates?.[0], value: dates?.[0] }}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 4,
                          color: '#FFF',
                          colors: {
                            ...theme.colors,
                            primary25: ' rgb(77 58 33 / 70%)',
                            primary: '#553A20'
                          },
                        })}
                      />
                    </FormControl>
                    <FormControl className="tiemsot" style={{ marginRight: '10px', width: '150px', marginBottom: '20px' }}>
                      <InputLabel htmlFor="grouped-native-select"></InputLabel>
                      <Select id="grouped-native-select" onChange={timechange} options={times.map((v, i) => ({ label: v, value: v }))}
                        defaultValue={{ label: times?.[0], value: times?.[0] }}
                        theme={theme => ({
                          ...theme,
                          borderRadius: 4,
                          color: '#FFF',
                          colors: {
                            ...theme.colors,
                            primary25: ' rgb(77 58 33 / 70%)',
                            primary: '#553A20'
                          },
                        })}
                      />
                    </FormControl>

                  </div>
                  <button onClick={() => handletimeshow()} className="takeaway-btn" style={{ marginTop: '10px' }}>submit</button>
                </div>

              }
            </div>
          </div>
          :
          <div>

            <div style={{ display: 'flex', flexDirection: 'row', }}>
              <text style={{ color: '#553A28', fontWeight: 'bold', fontSize: 'large' }} >
                Arrival Time&nbsp;&nbsp;&nbsp;
              </text>
              <CheckCircle width={20} height={20} color="#28a745" style={{ float: 'right' }} className="checkcircle-fr" />
              <button className="changebtn-fr" onClick={handlepreviousaddress}>change
              </button>
            </div>
            <div>
              <h6>Date & Time {date.value} {time.value}</h6>
            </div>
            <div>
              {
                window.innerWidth > 768 ?
                  <h5 style={{ fontSize: '18px' }}>
                    Takeaway From {reduxmerchant}
                  </h5>
                  :
                  <h5 style={{ fontSize: '18px' }}>
                    Takeaway From {reduxmerchant}
                  </h5>
              }
            </div>
          </div>

      }

    </div>
  )
}



export default TakeawayMode

